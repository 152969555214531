import React, { useEffect, useState } from 'react';
import { Divider, styled, Typography } from '@mui/material';
import { AddPlanDialog, AssignmentsDashboard, PlanSelect } from '../components';
import CreatePlanView from './CreatePlanView';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { TonnageDashboard } from '../components/dashboards/tonnage';
import { useAppDispatch, useAppSelector } from '../store/helpers';

import { getItem, LOCAL_STORAGE_KEYS, setItem } from '../helpers/localStorage';

import { ViewOnlyModeChip } from '../components/ViewOnlyModeChip';
import { ViewOnlyModeBanner } from '../components/ViewOnlyModeBanner/ViewOnlyModeBanner';
import { useAuth } from '../contexts';
import { GridCustomisationDrawer } from '../components/common/GridCustomisationDrawer/GridCustomisationDrawer';
import { FeatureFlagsEnum } from '../api/featureFlags';
import { PlanDateFilter } from '../components/common/PlanDateFilter/PlanDateFilter';
import dayjs from 'dayjs';
import { MonthPicker, MonthPickerSize } from '../components/common';
import { useFeatureFlags } from '../contexts/FeatureFlagsContext';
import { deselectAllCargoes, IDateRange, updateDateRange } from '../store';
import { onResizeEndResult, SplitView } from '../components/common/SplitView/SplitView';
import { useGridRefs } from '../contexts/GridRefsContext';
import { adjustLastColumnWidth, GridId } from '../components/common/grid/helpers';

export const FreightPlannerPage: React.FC = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isTonnageHidden, setIsTonnageHidden] = useState(getItem(LOCAL_STORAGE_KEYS.HIDE_TONNAGE_GRID) ?? false);
  const plans = useAppSelector((state) => state.plans.plans);
  const { hasWriteRights } = useAuth();
  const { isFeatureFlagActive } = useFeatureFlags();
  const dispatch = useAppDispatch();
  const dateRange = useAppSelector((state) => state.dateRange.dateRange);
  const assignmentsDashboardWidth = getItem(LOCAL_STORAGE_KEYS.ASSIGNMENTS_DASHBOARD_WIDTH) ?? window.innerWidth * 0.65;

  const openCreatePlanModal = () => setIsDialogOpen(true);
  const hideTonnageGrid = () => setIsTonnageHidden(true);
  const showTonnageGrid = () => setIsTonnageHidden(false);

  const { assignmentsGridRef, tonnagesGridRef } = useGridRefs();

  useEffect(() => {
    setItem(LOCAL_STORAGE_KEYS.HIDE_TONNAGE_GRID, isTonnageHidden);
  }, [isTonnageHidden]);

  const changeDateRange = (newDateRange: IDateRange) => {
    dispatch(updateDateRange(newDateRange));
    dispatch(deselectAllCargoes());
  };

  const onResizeEndCallback = ({ leftViewWidth }: onResizeEndResult) => {
    setItem(LOCAL_STORAGE_KEYS.ASSIGNMENTS_DASHBOARD_WIDTH, leftViewWidth);
    adjustLastColumnWidth(GridId.tonnage, tonnagesGridRef.current?.columnApi);
    adjustLastColumnWidth(GridId.cargoAssigment, assignmentsGridRef.current?.columnApi);
  };

  return (
    <StyledMainContainer>
      <StyledHeader>
        <Typography variant="h5">Freight Planner</Typography>
        {plans.length > 0 && <PlanSelect openCreatePlanModal={openCreatePlanModal} />}
        {!hasWriteRights && <ViewOnlyModeChip />}
        {isFeatureFlagActive(FeatureFlagsEnum.ff_dragToResizeTonnage) ? (
          <div style={{ marginLeft: 'auto' }}>
            {isFeatureFlagActive(FeatureFlagsEnum.ff_CustomDateRange) ? (
              <PlanDateFilter
                dateRange={{ from: dayjs(dateRange.from), to: dayjs(dateRange.to) }}
                updateDateRange={changeDateRange}
              />
            ) : (
              <MonthPicker
                dateRange={dateRange}
                updateDateRange={changeDateRange}
                id="current-plan"
                monthPickerSize={MonthPickerSize.LARGE}
              />
            )}
          </div>
        ) : null}
      </StyledHeader>
      {!hasWriteRights && <ViewOnlyModeBanner />}
      {plans.length ? (
        <>
          {isFeatureFlagActive(FeatureFlagsEnum.ff_dragToResizeTonnage) ? (
            <SplitView
              initialLeftViewWidth={assignmentsDashboardWidth as number}
              leftView={<AssignmentsDashboard showTonnage={showTonnageGrid} isTonnageHidden={isTonnageHidden} />}
              rightView={<TonnageDashboard hideTonnage={hideTonnageGrid} isTonnageHidden={isTonnageHidden} />}
              isRightViewHidden={isTonnageHidden}
              onResizeEndCallback={onResizeEndCallback}
              dataTestIdPrefix="grids"
            />
          ) : (
            <StyledGridsContainer>
              <AssignmentsDashboard showTonnage={showTonnageGrid} isTonnageHidden={isTonnageHidden} />
              <StyledDivider orientation="vertical" flexItem istonnagehidden={isTonnageHidden ? 1 : 0} />
              <TonnageDashboard hideTonnage={hideTonnageGrid} isTonnageHidden={isTonnageHidden} />
            </StyledGridsContainer>
          )}
        </>
      ) : (
        <CreatePlanView openCreatePlanModal={() => setIsDialogOpen(true)} />
      )}
      <AddPlanDialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
      <GridCustomisationDrawer />
    </StyledMainContainer>
  );
};

const StyledDivider = styled(Divider)((props: { istonnagehidden: number }) => ({
  display: props.istonnagehidden === 1 ? 'none' : 'inherit',
}));

const StyledMainContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledGridsContainer = styled('div')({
  display: 'flex',
  height: '100%',
});

const StyledHeader = styled('div')(({ theme }) => ({
  height: '65px',
  padding: '16px 24px',
  letterSpacing: '-0.02em',
  borderBottom: `1px solid ${theme.palette.text.disabled}`,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '24px',
}));
