import { Typography, styled } from '@mui/material';
import { blue, neutral } from '../../../../../../themes/palette';

interface ISearchEditorHeader {
  searchedOptionsTotalCount?: number;
  searchedOptionsCount?: number;
  title: string;
}

export const SearchEditorHeader: React.FC<ISearchEditorHeader> = (props) => {
  const { searchedOptionsTotalCount, searchedOptionsCount, title } = props;
  const textWithTotalCount = `Showing ${searchedOptionsCount} of ${searchedOptionsTotalCount} results`;
  const textWithoutTotalCount = `Showing ${searchedOptionsCount} results`;

  return (
    <StyledContainer>
      <StyledTypography sx={{ fontStyle: 'italic', color: neutral.n65 }}>{title}</StyledTypography>
      {searchedOptionsCount && (
        <StyledTypography sx={{ color: blue.b80 }}>
          {searchedOptionsTotalCount ? textWithTotalCount : textWithoutTotalCount}
        </StyledTypography>
      )}
    </StyledContainer>
  );
};

const StyledTypography = styled(Typography)({
  fontSize: '14px',
  fontWeight: 500,
});

const StyledContainer = styled('div')({
  backgroundColor: neutral.n200,
  position: 'sticky',
  height: '50px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 16px',
});
