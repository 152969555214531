import { Button, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { blue, grey } from '../../../../themes/palette';
import { useAuth } from '../../../../contexts';
import { GridApi } from 'ag-grid-community';
import { ICustomRowData } from '../hooks/useDefaultGridOptions';
import { useFeatureFlags } from '../../../../contexts/FeatureFlagsContext';
import { FeatureFlagsEnum } from '../../../../api/featureFlags';
import { AssignmentsGridDetails } from '../../../dashboards/cargo/footer/AssignmentsGridDetails';

interface IGridFooterProps<T> {
  onAddClick: () => void;
  addButtonLabel: string;
  gridData?: ICustomRowData<T>[] | null;
  gridApi?: GridApi;
}

export const GridFooter = <T,>(props: IGridFooterProps<T>) => {
  const { onAddClick, addButtonLabel, gridData, gridApi } = props;
  const { hasWriteRights } = useAuth();
  const { isFeatureFlagActive } = useFeatureFlags();

  return (
    <StyledContainer>
      {gridData && isFeatureFlagActive(FeatureFlagsEnum.ff_cargoVolumeTotals) && (
        <AssignmentsGridDetails gridData={gridData} gridApi={gridApi} />
      )}
      {hasWriteRights && (
        <StyledButton
          id={`button-${addButtonLabel}`}
          variant="text"
          startIcon={<AddIcon />}
          data-testid={`button-${addButtonLabel}`}
          onClick={onAddClick}
        >
          {addButtonLabel}
        </StyledButton>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled('div')({
  backgroundColor: grey.g1,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  height: 52,
  padding: 10,
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minWidth: '200px',
});

const StyledButton = styled(Button)({
  height: '36px',
  minWidth: 'initial',
  fontWeight: 500,
  fontSize: 14,
  color: blue.b70,
  border: `2px solid ${blue.b70}`,
  borderRadius: '4px',
  padding: '6px 12px',
  flexShrink: 0,
  marginLeft: 'auto',
});
