export const MuiAlertTitle = {
  styleOverrides: {
    root: {
      fontFamily: 'Noto Sans',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
      margin: 0,
      display: 'flex',
    },
  },
};

export const darkThemeAlertTitle = {
  styleOverrides: {
    ...MuiAlertTitle.styleOverrides,
    root: {
      ...MuiAlertTitle.styleOverrides.root,
    },
  },
};

export const lightThemeAlertTitle = {
  styleOverrides: {
    ...MuiAlertTitle.styleOverrides,
    root: {
      ...MuiAlertTitle.styleOverrides.root,
    },
  },
};
