import { Paper, Switch, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';

export interface ColumnSwitchProps {
  id: string;
  name?: string;
  isVisible: boolean;
}

export interface IColumnSwitchListElement extends ColumnSwitchProps {
  setVisibleHandler: (id: string, value: boolean) => void;
}
const StyledListItemWrapper = styled(Paper)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 16px',
  paddingRight: '4px',
  height: '48px',
});

export const ColumnSwitchListElement: React.FC<IColumnSwitchListElement> = ({ name, id, isVisible, setVisibleHandler }) => {
  const [checked, setChecked] = useState(isVisible);

  useEffect(() => {
    setChecked(isVisible);
  }, [isVisible]);

  const onChangeHandler = (value: boolean) => {
    setVisibleHandler(id, value);
    setChecked(value);
  };

  return (
    <StyledListItemWrapper elevation={1}>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '14px',
          letterSpacing: '0.17px',
        }}
      >
        {name}
      </Typography>

      <Switch
        data-testid={`column-visibility-switch-${id}`}
        checked={checked}
        onChange={(e) => onChangeHandler(e.target.checked)}
      />
    </StyledListItemWrapper>
  );
};
