import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { IDateRange } from '../../../../../store';
import utc from 'dayjs/plugin/utc';
import { LOCAL_STORAGE_KEYS, setItem } from '../../../../../helpers/localStorage';
import { DASHBOARD_DATE_RANGE_TYPE } from '../../PlanDateFilter';
import { styled } from '@mui/material';
import { PickerSelectionState } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types';
dayjs.extend(utc);

interface ICalendarMonthViewProps {
  dateRange: IDateRange;
  updateDateRange: (newRange: IDateRange) => void;
  onClose: () => void;
}

export const CalendarMonthView: React.FC<ICalendarMonthViewProps> = (props) => {
  const { dateRange, updateDateRange, onClose } = props;

  const onChange = (date: Dayjs | null, selectionState?: PickerSelectionState) => {
    if (date) {
      const newDateRange = {
        from: date.utc().startOf('month'),
        to: date.utc().endOf('month'),
      };
      updateDateRange(newDateRange);
      setItem<DASHBOARD_DATE_RANGE_TYPE>(LOCAL_STORAGE_KEYS.DASHBOARD_DATE_RANGE_TYPE, DASHBOARD_DATE_RANGE_TYPE.MONTH);
      if (selectionState === 'finish') {
        onClose();
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDateCalendar
        views={['year', 'month']}
        openTo="month"
        value={dateRange.from.utc()}
        onChange={onChange}
        data-testid="plandatefilter-modal-month-view-container"
      />
    </LocalizationProvider>
  );
};

const StyledDateCalendar = styled(DateCalendar<Dayjs>)({
  height: '100%',
});
