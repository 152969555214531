import { styled, Checkbox } from '@mui/material';
import { IHeaderColumn } from 'ag-grid-community';
import { useAppDispatch, useAppSelector } from '../../../../store/helpers';
import { deselectAllCargoes, selectAllCargoes } from '../../../../store';
import { ReactComponent as IntermediateStateIcon } from '../../../../assets/Intermediate.svg';
import { useAssignmentsGrid } from './hooks/useAssignmentsGrid';

const StyledContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  padding: 0,
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledCheckbox = styled(Checkbox)({
  '& .MuiSvgIcon-root': { fontSize: 24 },
});

export const BatchActionsSelectHeaderRenderer: React.FC<IHeaderColumn> = () => {
  const dispatch = useAppDispatch();
  const { selectAll, deselectAll } = useAssignmentsGrid();
  const allCargoesIds = useAppSelector((state) => state.assignments.allRowsCargoIds);
  const selectedCargoesNumber = useAppSelector((state) => state.assignments.selectedRows.size);

  const onClick = () => {
    if (allCargoesIds) {
      if (selectedCargoesNumber < allCargoesIds.length) {
        dispatch(selectAllCargoes());
        selectAll();
      } else {
        dispatch(deselectAllCargoes());
        deselectAll();
      }
    }
  };

  return (
    <StyledContainer onClick={onClick}>
      <StyledCheckbox
        checked={selectedCargoesNumber === allCargoesIds.length && selectedCargoesNumber > 0}
        icon={selectedCargoesNumber > 0 && selectedCargoesNumber < allCargoesIds.length ? <IntermediateStateIcon /> : undefined}
        data-testid="checkbox-select-all-cargoes"
      />
    </StyledContainer>
  );
};
