import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import { useAuth, UserRoles } from '../../contexts';
interface IPrivateRoutes {
  allowedUserRoles: UserRoles[];
}
export const PrivateRoutes: React.FC<IPrivateRoutes> = ({ allowedUserRoles }) => {
  const auth = useAuth();
  const location = useLocation();

  useEffect(() => {
    auth?.userManager?.getUser().then((user) => {
      if (!user) {
        auth?.userManager?.signinRedirectCallback().then((redirectUser) => {
          if (redirectUser) {
            auth.setUser(redirectUser);
          }
        });
      } else {
        auth.setUser(user);
      }
    });
  }, []);

  if (auth.isLoggedIn) {
    if (auth.isAuthorized(allowedUserRoles)) {
      return <Outlet />;
    } else {
      return <Navigate to="/no_access" state={{ from: location }} replace />;
    }
  } else {
    return null;
  }
};
