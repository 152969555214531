import { useEffect } from 'react';
import { styled } from '@mui/material';
import { Loader } from './Loader';
import { REQUEST_STATUS, useAppDispatch, useAppSelector } from '../../store/helpers';
import { getPlans } from '../../store';


const StyledMainContainer = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
});

interface ILayout {
  children: React.ReactNode;
}
export const Layout: React.FC<ILayout> = ({ children }) => {
  const dispatch = useAppDispatch();
  const getPlansStatus = useAppSelector((state) => state.plans.status.getPlans);
  const isGetPlansLoading = getPlansStatus === REQUEST_STATUS.pending;

  useEffect(() => {
    dispatch(getPlans());
  }, []);

  return isGetPlansLoading ? (
    <Loader id="app-loading" headerText="Freight Planner" footerText="Loading..." />
  ) : (
      <StyledMainContainer>{children}</StyledMainContainer>
  );
};
