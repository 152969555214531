import { useMemo, useRef, useState } from 'react';
import { ISearchCellEditorParams } from './SearchEditor';
import { debounce, isNil } from '../../../../helpers/helpers';
import { AutocompleteInputChangeReason } from '@mui/material';
import { KeyNames } from '../../Constants';
import { useAppDispatch } from '../../../../store/helpers';

interface UseSearchEditorHandlers<T> extends ISearchCellEditorParams<T> {
  setInputValue: (inputValue: string) => void;
}

export function useSearchEditorHandlers<T>(props: UseSearchEditorHandlers<T>) {
  const {
    mainUiOptionKey,
    optionIdentifierKey,
    searchedOptions,
    minSearchTriggerTextLength = 3,
    searchOptions,
    resetSearchedOptions,
    setEditorValue,
    editorValue,
    setInputValue,
  } = props;
  const [searchOptionsPromise, setSearchOptionsPromise] = useState<any>(null);
  const dispatch = useAppDispatch();
  const isHighlightedRef = useRef(false);

  const onChangeHandler = (_event: React.SyntheticEvent, newValue: T | NonNullable<string | T> | null) => {
    let returnedValue: T | null = { [mainUiOptionKey]: '' } as T;

    if (typeof newValue === 'string') {
      returnedValue[mainUiOptionKey] = newValue as T[keyof T];
    } else {
      returnedValue = newValue;
    }

    setEditorValue(returnedValue as T);
    if (typeof returnedValue === 'object' && returnedValue?.[mainUiOptionKey]) {
      setInputValue(returnedValue?.[mainUiOptionKey] as string);
    }
    dispatch(resetSearchedOptions());
  };

  const debouncedDispatchSearchOptions = useMemo(
    () =>
      debounce((newInputValue: string) => {
        dispatch(resetSearchedOptions());
        if (newInputValue.length >= minSearchTriggerTextLength) {
          const promise = dispatch(searchOptions(newInputValue));
          setSearchOptionsPromise(promise);
        }
      }, 300),
    []
  );

  const onInputChangeHandler = (_event: React.SyntheticEvent, newInputValue: string, reason: AutocompleteInputChangeReason) => {
    searchOptionsPromise?.abort();

    if (reason !== 'reset') {
      debouncedDispatchSearchOptions(newInputValue);
      setEditorValue({ [mainUiOptionKey]: newInputValue } as T);
      setInputValue(newInputValue);
      isHighlightedRef.current = false;
    }
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == KeyNames.ENTER) {
      if (
        (isNil(editorValue?.[optionIdentifierKey]) && !isHighlightedRef.current) ||
        (!isNil(editorValue?.[optionIdentifierKey]) && !searchedOptions.length)
      ) {
        props.stopEditing();
      }
    }
  };

  const onHighlightChangeHandler = (e: React.SyntheticEvent<Element, Event>) => {
    if (e?.type === 'keydown') {
      isHighlightedRef.current = true;
    }
  };

  const getOptions = () => {
    if (searchedOptions.length) {
      return searchedOptions;
    }
    if (editorValue !== null) {
      return [editorValue];
    }
    return [];
  };

  const getOptionLabel = (option: T | string): string =>
    typeof option !== 'string' && option?.[mainUiOptionKey] != null ? (option?.[mainUiOptionKey] as string) : '';

  return { onChangeHandler, onInputChangeHandler, onKeyDownHandler, onHighlightChangeHandler, getOptions, getOptionLabel };
}
