import axios from 'axios';

export enum FeatureFlagsEnum {
  copyAssignmentToClipboard = 'copyAssignmentToClipboard',
  retrySaveCell = 'retrySaveCell',
  ff_CustomDateRange = 'ff_CustomDateRange',
  ff_CustomizeCargoAssignmentsGrid = 'ff_CustomizeCargoAssignmentsGrid',
  ff_dragToResizeTonnage = 'ff_dragToResizeTonnage',
  ff_vesselCellDetails = 'ff_vesselCellDetails',
  ff_cargoVolumeTotals = 'ff_cargoVolumeTotals',
  ff_ownerRateDemurrageColumns = 'ff_ownerRateDemurrageColumns',
}

export type FeatureFlags = {
  [key in FeatureFlagsEnum]: boolean;
};

export async function loadFeatureFlags(): Promise<FeatureFlags> {
  const response = await axios.get('/featureflags.json');
  return response.data;
}
