import React from 'react';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { hexToRgb, neutral } from '../../../themes/palette';
import { styled } from '@mui/material';

interface IPlaceholderCellRenderer extends ICellRendererParams {}

const StyledItalic = styled('i')({
  color: `rgba(${hexToRgb(neutral.n0)}, 0.5)`,
});

export const PlaceholderCellRenderer: React.FC<IPlaceholderCellRenderer> = ({ colDef }) => {
  return <StyledItalic>{`${colDef?.headerName}...`}</StyledItalic>;
};
