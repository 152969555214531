import { CellEditingStoppedEvent, IRowNode } from 'ag-grid-community';
import { AssignmentDto, LinkedTonnageDto, UpdateTonnageCommand } from '../../../../../api/web-api-client';
import { getRowNodes } from '../../../../../helpers/gridUtilities';
import { updateTonnage } from '../../../../../store';
import { TonnagesGridColumn } from './useTonnageGrid';
import { useGridRefs } from '../../../../../contexts/GridRefsContext';
import { ICustomRowData, useDefaultGridOptions } from '../../../../common/grid/hooks/useDefaultGridOptions';
import { GridId } from '../../../../common/grid/helpers';
import { useAppDispatch } from '../../../../../store/helpers';
import { createVesselUpdateObject } from '../../../../../helpers/helpers';
import { isRejected } from '@reduxjs/toolkit';
import { markCellAsUnsaved, unmarkCellAsUnsaved } from '../../../helpers';

export type TonnagePatchProperty = keyof Omit<UpdateTonnageCommand, 'tonnageId'>;

export const useTonnageGridEditing = () => {
  const { assignmentsGridRef, tonnagesGridRef: gridRef } = useGridRefs();
  const dispatch = useAppDispatch();
  const { updateTonnageDetails } = useDefaultGridOptions(gridRef, GridId.tonnage);

  const updateAssignmentTonnageDetails = (data: ICustomRowData<LinkedTonnageDto>) => {
    const rowsToRefresh = getRowNodes<AssignmentDto>(assignmentsGridRef.current?.api, (row) => row.data?.tonnage?.id === data.id);
    if (gridRef.current && rowsToRefresh[0]?.data?.id) {
      updateTonnageDetails(rowsToRefresh[0].data.id, data);
    }
  };

  const updateCell = async (node: IRowNode<ICustomRowData<LinkedTonnageDto>>, column: string, newValue: any) => {
    const tonnageId = node?.data?.id;
    unmarkCellAsUnsaved(gridRef, node, column);
    const assignmentRows = getRowNodes<AssignmentDto>(
      assignmentsGridRef.current?.api,
      (row) => row.data?.tonnage?.id === node?.data?.id
    );

    assignmentRows.forEach((rowNode) => {
      unmarkCellAsUnsaved(assignmentsGridRef, rowNode, 'tonnage.' + column);
    });

    const isVesselColumn = column === TonnagesGridColumn.Vessel;

    const payload = {
      tonnageId: tonnageId!,
      [column]: {
        ...(isVesselColumn && createVesselUpdateObject(newValue?.name, newValue?.seaId)),
        ...(!isVesselColumn && { newValue }),
      },
    };

    const result = await dispatch(updateTonnage(payload));
    if (!node.data) {
      return;
    }

    if (!isRejected(result)) {
      updateAssignmentTonnageDetails(node.data);
      gridRef?.current?.api.refreshCells({ rowNodes: [node], columns: [TonnagesGridColumn.Options], force: true });
    } else {
      markCellAsUnsaved(gridRef, node, column);
      assignmentRows.forEach((rowNode) => {
        markCellAsUnsaved(assignmentsGridRef, rowNode, 'tonnage.' + column);
      });

      updateAssignmentTonnageDetails(node.data);
    }
  };

  const onCellEditingStopped = async (event: CellEditingStoppedEvent<LinkedTonnageDto>) => {
    if (event.valueChanged && event.data) {
      const propertyToPatch = event.column.getColId() as TonnagePatchProperty;
      const newValue = event.value;

      updateCell(event.node, propertyToPatch, newValue);
    }
  };
  return { onCellEditingStopped, updateCell };
};
