import { GridApi, IRowNode } from 'ag-grid-community';
import { FLASH_ANIMATION_TIME } from '../components/common/Constants';

/**
 * Removes rows from the grid with flashing.
 * Timeout is set for removal to allow rows to visibly flash.
 */
export const removeRows = (api: GridApi, rowsToDelete: IRowNode[]) => {
  api.flashCells({ rowNodes: rowsToDelete, flashDelay: FLASH_ANIMATION_TIME / 2, fadeDelay: FLASH_ANIMATION_TIME / 2 });
  setTimeout(() => api.applyTransaction({ remove: rowsToDelete }), FLASH_ANIMATION_TIME);
};

export const getRowNodes = <T>(api: GridApi | undefined, predicate?: (rowNode: IRowNode<T>) => boolean) => {
  const rowNodes: IRowNode<T>[] = [];
  if (api) {
    api.forEachNode(function (node) {
      if (predicate && !predicate(node)) {
        return;
      } else {
        rowNodes.push(node);
      }
    });
  }
  return rowNodes;
};

export const scrollToFirstAddedRow = <T extends { id?: number }>(api: GridApi | undefined, addedRows: T[]) => {
  const addedRowIds = addedRows.map((addedRow) => addedRow.id?.toString());
  let firstRowFound = false;
  api?.forEachNodeAfterFilterAndSort((rowNode) => {
    if (!firstRowFound && rowNode.id && addedRowIds.includes(rowNode.id) && rowNode.rowIndex) {
      firstRowFound = true;
      api?.ensureIndexVisible(rowNode.rowIndex);
    }
  });
};
