import React, { useEffect, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { blue, neutral, red } from '../../../themes/palette';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { getPinnedLeftRow, getRowCenter, GridId, kebabMenuOpenAttribute } from './helpers';
import { useAuth } from '../../../contexts';
import { FeatureFlagsEnum } from '../../../api/featureFlags';
import { useFeatureFlags } from '../../../contexts/FeatureFlagsContext';

const StyledContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  marginLeft: '15px',
});

export interface IActionsCellRendererParams extends ICellRendererParams {
  kebabTestId: string;
  ariaLabel: string;
  isDialogOpen: boolean;
  dialogs: React.ReactNode;
  preMenuIcon?: React.ReactNode;
  gridId: GridId;
  children: React.ReactNode;
}

interface IGridRowRefs {
  center?: Element | null;
  pinnedRight?: Element | null;
  pinnedLeft?: Element | null;
}

export const ActionsCellRenderer: React.FC<IActionsCellRendererParams> = (props) => {
  const { eParentOfValue, gridId, isDialogOpen, preMenuIcon, ariaLabel, kebabTestId, children, dialogs } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { hasWriteRights } = useAuth();
  const { isFeatureFlagActive } = useFeatureFlags();

  const rowRefs = useRef<IGridRowRefs>({
    center: null,
    pinnedRight: null,
    pinnedLeft: null,
  });

  const isMenuOpen = Boolean(anchorEl);

  const handleKebabButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const highlightGridRow = () => {
    //below is needed to highlight the grid row when kebab menu is opened. See AssignmentsDashboard grid styles.
    rowRefs.current.pinnedRight = eParentOfValue.parentElement;
    rowRefs.current.pinnedRight?.setAttribute(kebabMenuOpenAttribute, 'true');
    const rowId = eParentOfValue.parentElement?.getAttribute('row-id');
    rowRefs.current.pinnedLeft = getPinnedLeftRow(gridId, rowId);
    rowRefs.current.pinnedLeft?.setAttribute(kebabMenuOpenAttribute, 'true');
    rowRefs.current.center = getRowCenter(gridId, rowId);
    rowRefs?.current?.center?.setAttribute(kebabMenuOpenAttribute, 'true');
  };

  const unHighlightGridRow = () => {
    rowRefs?.current?.center?.removeAttribute(kebabMenuOpenAttribute);
    rowRefs.current.pinnedRight?.removeAttribute(kebabMenuOpenAttribute);
    rowRefs.current.pinnedLeft?.removeAttribute(kebabMenuOpenAttribute);
  };

  useEffect(() => {
    if (isDialogOpen) {
      handleMenuClose();
    }
  }, [isDialogOpen]);

  useEffect(() => {
    if (isMenuOpen) {
      highlightGridRow();
    }

    if (!isDialogOpen && !isMenuOpen) {
      unHighlightGridRow();
    }
  }, [isDialogOpen, isMenuOpen]);

  return (
    <StyledContainer>
      {preMenuIcon}
      {(isFeatureFlagActive(FeatureFlagsEnum.copyAssignmentToClipboard)
        ? gridId === 'cargo-assignment-grid' || hasWriteRights
        : hasWriteRights) && (
        <>
          <IconButton size="small" onClick={handleKebabButtonClick} aria-label={ariaLabel} data-testid={kebabTestId}>
            <MoreVertIcon sx={{ color: neutral.n65 }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
            MenuListProps={{
              dense: true,
              sx: { paddingTop: 'unset', paddingBottom: 'unset' },
              className: 'ag-custom-component-popup',
            }}
          >
            {children}
          </Menu>
        </>
      )}
      {dialogs}
    </StyledContainer>
  );
};

export const StyledMenuItem = styled(MenuItem)({
  height: '44px',
  fontSize: '14px',
  padding: '12px 16px',
  ':hover': {
    backgroundColor: blue.b10,
  },
  ':last-of-type': {
    borderTop: '1px solid #E5E7EB',
  },
  minWidth: '210px',
});

export const StyledUnlinkIcon = styled(LinkOffOutlinedIcon)({
  height: '24px',
  width: '24px',
  marginRight: '14px',
  fillOpacity: 0.54,
});

export const StyledDeleteSpan = styled('span')({
  color: red.r40,
});

export const StyledTrashIcon = styled(DeleteIcon)({
  height: '24px',
  width: '24px',
  marginRight: '14px',
  fill: red.r40,
});

export const StyledAddIcon = styled(AddCircleOutlineOutlinedIcon)({
  height: '24px',
  width: '24px',
  marginRight: '14px',
  fillOpacity: 0.54,
});
