import { BaseSingleInputFieldProps, DateValidationError, FieldSection, UseDateFieldProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { MonthPickerSize, MonthPickerStyles, monthPickerStylesBySize } from './MonthPicker';
import { Button, styled, Typography } from '@mui/material';
import { CalendarTodayOutlined } from '@mui/icons-material';

export interface IMonthPickerButtonProps
  extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<Dayjs | null, Dayjs, FieldSection, DateValidationError> {
  monthPickerSize: MonthPickerSize;
  setIsCalendarOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MonthPickerButton: React.FC<IMonthPickerButtonProps> = (props) => {
  const { monthPickerSize, setIsCalendarOpen, id, value, InputProps: { ref } = {} } = props;

  return (
    <StyledCalendarButton
      sx={{ paddingRight: 'unset' }} //fix for 'September' not fitting the calendar header
      monthpickersize={monthPickerSize}
      data-testid={`monthpicker-button-calendar-${id}`}
      variant="text"
      ref={ref}
      onClick={() => setIsCalendarOpen?.((prev) => !prev)}
      endIcon={<StyledCalendarIcon />}
    >
      <StyledCalendarText monthpickersize={monthPickerSize}>{value?.utc().format('MMMM YYYY')}</StyledCalendarText>
    </StyledCalendarButton>
  );
};

const StyledCalendarButton = styled(Button)<MonthPickerStyles>(({ monthpickersize }) => ({
  display: 'flex',
  alignItems: 'center',
  textTransform: 'none',
  padding: '10px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  ...monthPickerStylesBySize[monthpickersize].calendarButton,
}));

const StyledCalendarIcon = styled(CalendarTodayOutlined)({
  height: '16px',
  width: '16px',
  marginBottom: '2px',
});

const StyledCalendarText = styled(Typography)<MonthPickerStyles>(({ monthpickersize }) => ({
  ...monthPickerStylesBySize[monthpickersize].calendarText,
}));
