import { createRef, useEffect, useState } from 'react';
import { Divider, DIVIDER_WIDTH } from './Divider';
import { LeftPanel } from './LeftPanel';
import { styled } from '@mui/material';
import { RightPanel } from './RightPanel';

export interface onResizeEndResult {
  // can add more properties if needed in the future
  leftViewWidth: number;
}

interface ISplitViewProps {
  leftView: React.ReactElement;
  rightView: React.ReactElement;
  initialLeftViewWidth: number;
  dataTestIdPrefix: string;
  isRightViewHidden?: boolean;
  leftViewMinWidth?: number;
  rightViewMinWidth?: number;
  onResizeStartCallback?: () => void;
  onResizeEndCallback?: (onResizeEndResult: onResizeEndResult) => void;
}

export const SplitView: React.FC<ISplitViewProps> = (props) => {
  const {
    leftView,
    rightView,
    initialLeftViewWidth,
    dataTestIdPrefix,
    isRightViewHidden = false,
    leftViewMinWidth = 400,
    rightViewMinWidth = 400,
    onResizeStartCallback,
    onResizeEndCallback,
  } = props;
  const [leftViewWidth, setLeftViewWidth] = useState<number>(initialLeftViewWidth);
  const [dividerXPosition, setDividerXPosition] = useState<number>();
  const [dragging, setDragging] = useState(false);
  const splitContainerRef = createRef<HTMLDivElement>();

  const onMouseDown = (event: React.MouseEvent) => {
    setDividerXPosition(event.clientX);
    setDragging(true);
    onResizeStartCallback && onResizeStartCallback();
  };

  const onTouchStart = (event: React.TouchEvent) => {
    setDividerXPosition(event.touches[0].clientX);
    setDragging(true);
    onResizeStartCallback && onResizeStartCallback();
  };

  const onMove = (clientX: number, event: MouseEvent | TouchEvent) => {
    if (dragging && leftViewWidth && dividerXPosition) {
      // prevent default behaviour (selecting text while resizing in particular)
      // have to be here to avoid breaking other functionalities such as drag tonnage to assign to cargo
      event.preventDefault();
      const newLeftViewWidth = leftViewWidth + clientX - dividerXPosition;
      setDividerXPosition(clientX);

      if (newLeftViewWidth < leftViewMinWidth) {
        setLeftViewWidth(leftViewMinWidth);
        return;
      }

      if (splitContainerRef.current) {
        const splitContainerWidth = splitContainerRef.current.clientWidth - DIVIDER_WIDTH;

        if (newLeftViewWidth > splitContainerWidth - rightViewMinWidth) {
          setLeftViewWidth(splitContainerWidth - rightViewMinWidth);
          return;
        }
      }

      setLeftViewWidth(newLeftViewWidth);
    }
  };

  const onMouseMove = (event: MouseEvent) => {
    onMove(event.clientX, event);
  };

  const onTouchMove = (event: TouchEvent) => {
    onMove(event.touches[0].clientX, event);
  };

  const onMouseUp = () => {
    if (dragging) {
      onResizeEndCallback && onResizeEndCallback({ leftViewWidth: leftViewWidth });
      setDragging(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  });

  return (
    <StyledContainer ref={splitContainerRef}>
      <LeftPanel width={isRightViewHidden ? '100%' : `${leftViewWidth}px`} dataTestIdPrefix={dataTestIdPrefix}>
        {leftView}
      </LeftPanel>
      <Divider
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onTouchEnd={onMouseUp}
        isHidden={isRightViewHidden}
        dataTestIdPrefix={dataTestIdPrefix}
      />
      <RightPanel isHidden={isRightViewHidden} dataTestIdPrefix={dataTestIdPrefix}>
        {rightView}
      </RightPanel>
    </StyledContainer>
  );
};

const StyledContainer = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
});
