import { hexToRgb, neutral } from '../../palette';

export const MuiDialogComponents = {
  MuiDialog: {
    styleOverrides: {
      paperWidthSm: {
        width: '425px',
        maxWidth: '425px',
      },
      paper: {
        borderRadius: '8px',
        padding: '24px',
      },
    },
  },

  MuiDialogContent: {
    styleOverrides: {
      root: {
        margin: '24px 0',
        padding: '0',
        'overflow-x': 'hidden',
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column' as const,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        margin: '0',
        padding: '0',
        '>:not(:first-of-type)': {
          marginLeft: '16px',
        },
      },
    },
  },
};

export const lightThemeMuiDialogComponents = {
  MuiDialog: {
    styleOverrides: {
      ...MuiDialogComponents.MuiDialog.styleOverrides,
      paper: {
        ...MuiDialogComponents.MuiDialog.styleOverrides.paper,
      },
      container: `
        background-color: rgba(${hexToRgb(neutral.n15)}, 0.5);
      `,
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        ...MuiDialogComponents.MuiDialogContent.styleOverrides.root,
      },
    },
  },
  MuiDialogActions: { ...MuiDialogComponents.MuiDialogActions },
};
