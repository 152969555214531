import { styled } from '@mui/system';
import { ReactComponent as LinkIcon } from '../../../assets/linkedIcon.svg';
import { ReactComponent as UnlinkIcon } from '../../../assets/unlinkedIcon.svg';
import { blue, green, neutral } from '../../../themes/palette';
import { GridId } from './helpers';

type StatusIcon = {
  type?: GridId;
};

const StyledLink = styled(LinkIcon)<StatusIcon>(({ type }) => ({
  minWidth: 24,
  height: '10px',
  marginLeft: '2px',
  fill: type === GridId.tonnage ? neutral.n65 : blue.b70,
}));

const StyledUnlink = styled(UnlinkIcon)<StatusIcon>(({ type }) => ({
  marginLeft: '2px',
  minWidth: 24,
  fill: type === GridId.tonnage ? green.g50 : neutral.n65,
  height: '10px',
}));

interface IAssignmentStatusIcon {
  isLinked: boolean;
  type: GridId;
}

export const AssignmentStatusIcon: React.FC<IAssignmentStatusIcon> = ({ isLinked, type }) => {
  return isLinked ? (
    <StyledLink data-testid={`${type}-linked-status-icon`} type={type} />
  ) : (
    <StyledUnlink data-testid={`${type}-unlinked-status-icon`} type={type} />
  );
};
