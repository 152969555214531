import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PrivateRoutes } from './components';
import { FreightPlannerPage } from './pages/FreightPlannerPage';
import { UserRoles } from './contexts';
import React, { useEffect } from 'react';
import { YouHaveNoAccessPage } from './pages/YouHaveNoAccessPage';
import { getAppSettings } from './store';
import { useAppDispatch } from './store/helpers';
import { PATHNAME_SIGNIN_REDIRECT, PATHNAME_SILENT_REFRESH_REDIRECT } from './contexts/UserManager';
import { SilentRefreshPage } from './pages/SilentRefreshPage';
import { AppPage } from './pages/AppPage';
import { SigninPage } from './pages/SigninPage';
import { LiveZPage } from './pages/LiveZPage';

const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAppSettings());
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/livez" element={<LiveZPage />} />
        {/* Path to silent refresh user token */}
        <Route path={PATHNAME_SILENT_REFRESH_REDIRECT} element={<SilentRefreshPage />} />
        {/* Path to application */}-
        <Route path="/" element={<AppPage />}>
          <Route element={<PrivateRoutes allowedUserRoles={[UserRoles.Admin, UserRoles.View]} />}>
            <Route path="/" element={<FreightPlannerPage />} />
          </Route>
          <Route path={PATHNAME_SIGNIN_REDIRECT} element={<SigninPage />} />
          <Route path="/no_access" element={<YouHaveNoAccessPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default App;
