import { ColumnApi } from 'ag-grid-community';

export const kebabMenuOpenAttribute = 'kebab-menu-opened';

export const isDragging = 'is-dragging';
export const isDropTarget = 'is-drop-target';
export const enum GridId {
  tonnage = 'tonnage-grid',
  cargoAssigment = 'cargo-assignment-grid',
}

export const borderWidth = 1;

export const notLinkedApiIndicatorClass = 'not-linked-api-indicator';

export const cargoAssignmentPinnedColumnWidth = 90;

export const defaultIntegerDigitsNumber = 9;
export const defaultDecimalDigitsNumber = 2;

export const autoHeightTextarea = (textarea: HTMLTextAreaElement, gridId: GridId) => {
  textarea.style.height = '0px';
  const container = document.getElementById(gridId);
  const viewport = container?.querySelector('.ag-body-viewport') as HTMLDivElement;

  const hiddenScrollHeight = textarea.scrollHeight + 2;
  const maxHeight = viewport.clientHeight - 100;
  textarea.style.height = hiddenScrollHeight > maxHeight ? maxHeight + 'px' : hiddenScrollHeight + 'px';
};

export const getGridColumnHeaderElement = (gridId: GridId, colId?: string) =>
  document.querySelector(`#${gridId} .ag-header-cell[col-id="${colId}"]`);

export const getRowCenter = (gridId: GridId, rowId?: string | null) => {
  if (typeof rowId !== 'string') return;
  return document.querySelector(`#${gridId} .ag-center-cols-clipper [row-id="${rowId}"]`);
};

export const getPinnedLeftRow = (gridId: GridId, rowId?: string | null) => {
  if (typeof rowId !== 'string') return;
  return document.querySelector(`#${gridId} .ag-pinned-left-cols-container [row-id="${rowId}"]`);
};

export const getPinnedRightHeaderContainerWidth = (gridId: GridId) => {
  return document.querySelector(`#${gridId} .ag-pinned-right-header`)?.clientWidth ?? 0;
};

export const getContainerElement = (gridId: GridId) => document.querySelector(`#${gridId} .ag-root`);

/*simulateUserChange - if true, then event will be fired with type 'uiColumnResized', otherwise 'api'.
   Grid 'api' events suppresses sending requests to the server -> check 'updateGridState' method. */
export const adjustLastColumnWidth = (gridId: GridId, columnApi?: ColumnApi, simulateUserChange = true) => {
  if (columnApi) {
    const centerGridViewPortWidth = document.querySelector(`#${gridId} .ag-root .ag-center-cols-clipper`)?.clientWidth || 0;
    const allCenterColumns = columnApi?.getDisplayedCenterColumns();

    if (allCenterColumns?.length) {
      const actualGridWidthWithoutLastColumn = allCenterColumns
        .slice(0, allCenterColumns.length - 1)
        .reduce((sum, col) => sum + col.getActualWidth(), 0);

      const calculatedLastColumnWidth = centerGridViewPortWidth - actualGridWidthWithoutLastColumn;
      const lastColumActualWidth = allCenterColumns[allCenterColumns.length - 1]?.getActualWidth();

      if (calculatedLastColumnWidth > lastColumActualWidth) {
        columnApi.setColumnWidth(
          allCenterColumns[allCenterColumns.length - 1],
          calculatedLastColumnWidth,
          true,
          simulateUserChange ? 'uiColumnResized' : 'api'
        );
      }
    }
  }
};

export const isValidForEditorChar = (value: string | null) => {
  return value?.length === 1 && /^[a-z0-9!-~]+$/i.test(value);
};

export const isValidFloat = (
  value: string | null,
  maxInteger = defaultIntegerDigitsNumber,
  maxDecimal = defaultDecimalDigitsNumber,
  onlyPositive = false
) => {
  const regex = new RegExp(`^\\-{0,${Number(!onlyPositive)}}\\d{0,${maxInteger}}(\\.\\d{0,${maxDecimal}})?$`);

  return !!value?.length && regex.test(value);
};

export const isValidInteger = (value: string | null, maxIntegerDigitsNumber = defaultIntegerDigitsNumber): boolean => {
  return Boolean(value?.length && value?.length <= maxIntegerDigitsNumber);
};

export const parseFloatValue = (valueToParse: string, maxIntegerDigitsNumber: number, maxDecimalDigitsNumber: number): number => {
  if (valueToParse === '' || valueToParse === '.') {
    return NaN;
  }
  return isValidFloat(valueToParse, maxIntegerDigitsNumber, maxDecimalDigitsNumber) ? parseFloat(valueToParse) : NaN;
};
