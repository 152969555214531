import { Typography, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import WarningIcon from '@mui/icons-material/Warning';
import { SUPPORT_MAIL } from '../../../../Constants';

export enum InfoContentType {
  cannotFindMatch = 'cannot-find-match',
  cannotConnectToDb = 'cannot-connect-to-db',
}

export type InfoContentMessages = {
  [key in InfoContentType]: {
    main: string;
    details: string;
  };
};

interface IInfoContent {
  type: InfoContentType;
  messages: InfoContentMessages;
  testIdPrefix: string;
}

export const InfoContent: React.FC<IInfoContent> = (props) => {
  const { type, messages, testIdPrefix } = props;

  return (
    <StyledContainer>
      {type === InfoContentType.cannotFindMatch ? (
        <StyledSearchIcon data-testid={`${testIdPrefix}-search-icon`} />
      ) : (
        <StyledWarningIcon data-testid={`${testIdPrefix}-search-warning-icon`} />
      )}
      <StyledMsgContainer>
        <StyledMainMessage align="center">{messages[type].main}</StyledMainMessage>
        <StyledDetailMessage align="center">
          {messages[type].details}
          {type === InfoContentType.cannotConnectToDb && <a href={`mailto: ${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</a>}
        </StyledDetailMessage>
      </StyledMsgContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled('div')({
  paddingBottom: '28px',
  paddingTop: '34px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'flex-end',
});

const StyledSearchIcon = styled(SearchIcon)({
  color: 'rgba(0, 19, 27, 0.56)',
  fontSize: '50px',
});
const StyledWarningIcon = styled(WarningIcon)({
  color: 'rgba(0, 19, 27, 0.56)',
  fontSize: '50px',
});

const StyledMainMessage = styled(Typography)({
  fontSize: '15px',
  fontWeight: 500,
  letterSpacing: '-0.02em',
  whiteSpace: 'pre-line',
});

const StyledDetailMessage = styled(Typography)({
  fontSize: '13px',
  fontWeight: 500,
  letterSpacing: '-0.02em',
  marginTop: '10px',
});

const StyledMsgContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '15px',
});
