import { InputAdornment, InputLabel, TextField, TextFieldProps } from '@mui/material';

type CustomTextField = TextFieldProps & {
  label?: string;
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
};

export const CustomTextField: React.FC<CustomTextField> = ({ id, label, iconStart, iconEnd, ...props }) => {
  return (
    <>
      <InputLabel id={`${id}-label`} htmlFor={id}>
        {label}
      </InputLabel>
      <TextField
        id={id}
        {...props}
        InputProps={{
          startAdornment: iconStart ? (
            <InputAdornment data-testid={`${id}-icon-start`} position="start">
              {iconEnd}
            </InputAdornment>
          ) : null,
          endAdornment: iconEnd ? (
            <InputAdornment data-testid={`${id}-icon-end`} position="end">
              {iconEnd}
            </InputAdornment>
          ) : null,
        }}
      ></TextField>
    </>
  );
};
