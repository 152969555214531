import React from 'react';
import { styled } from '@mui/material';
import { ReactComponent as NoEditIcon } from '../assets/noEditIcon.svg';
import { orange } from '../themes/palette';

export const ViewOnlyModeChip: React.FC = () => {
  return (
    <StyledContainer data-testid="chip-view-only-mode">
      <NoEditIcon />
      <StyledText>View Only</StyledText>
    </StyledContainer>
  );
};

const StyledContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 4,
});

const StyledText = styled('span')({
  color: orange.o10,
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '18px',
  letterSpacing: '.16px',
  paddingLeft: 6,
});
