import { addAbortSignalListener, REQUEST_STATUS } from '../helpers';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiManager, IAppSettings } from '../../contexts/ApiManager';
import axios from 'axios';

export enum ApiActions {
  GET_APP_SETTINGS = 'getAppSettings',
}

export type ApiState = {
  apiManager: ApiManager | null;
  appSettings: IAppSettings | null;
  status: {
    [key in ApiActions]?: REQUEST_STATUS;
  };
};

export const apiInitialState: ApiState = {
  apiManager: null,
  appSettings: null,
  status: {
    [ApiActions.GET_APP_SETTINGS]: REQUEST_STATUS.idle,
  },
};

export const getAppSettings = createAsyncThunk(ApiActions.GET_APP_SETTINGS, async (_, { signal }) => {
  addAbortSignalListener(signal);
  try {
    const response = await axios.get('/appsettings.json');
    const appSettings = response.data;
    return appSettings;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

const apiSlice = createSlice({
  name: 'api',
  initialState: apiInitialState,
  reducers: {
    setApiManager: (state, action: PayloadAction<IAppSettings>) => {
      state.apiManager = new ApiManager(action.payload);
    },
  },
  extraReducers(builder) {
    builder.addCase(getAppSettings.fulfilled, (state, { payload }) => {
      state.appSettings = payload;
      state.status[ApiActions.GET_APP_SETTINGS] = REQUEST_STATUS.fulfilled;
    });
  },
});

export const { setApiManager } = apiSlice.actions;

export default apiSlice.reducer;
