import { AssignmentDto, LinkedTonnageDto } from '../../../../../api/web-api-client';
import { useGridRefs } from '../../../../../contexts/GridRefsContext';
import { getRowNodes, removeRows } from '../../../../../helpers/gridUtilities';
import { GridId } from '../../../../common/grid/helpers';
import { useAppDispatch, useAppSelector } from '../../../../../store/helpers';
import {
  deselectCargo,
  duplicateAssignmentCargo,
  removeAssignment,
  showNotificationSnackbar,
  unassignTonnage as unassignTonnageAPI,
} from '../../../../../store';
import { useDefaultGridOptions } from '../../../../common/grid/hooks/useDefaultGridOptions';
import { AssignmentRowData } from '../../AssignmentsDashboard';
import { useCopyToClipboard } from './useCopyToClipboard';
import {
  MixpanelEventAction,
  MixpanelEventCategory,
  MixpanelEventLabel,
  MixpanelEventLocation,
  MixpanelEventName,
  useMixpanel,
} from '../../../../../contexts/MixpanelContext';

export const useAssignmentsGridActions = () => {
  const { assignmentsGridRef: gridRef, tonnagesGridRef } = useGridRefs();
  const dispatch = useAppDispatch();
  const { customFlashRow, duplicateRow, unassignCargo, unassignTonnage } = useDefaultGridOptions(gridRef, GridId.cargoAssigment);
  const { trackEvent } = useMixpanel();
  const { copyToClipboard } = useCopyToClipboard(gridRef);

  const planId = useAppSelector((state) => state.plans.currentPlan?.id);

  const handleDelete = async (id: number) => {
    if (!gridRef?.current?.api) return;

    const rowToDelete = gridRef?.current?.api.getRowNode(id.toString());
    if (rowToDelete) {
      try {
        await dispatch(removeAssignment(rowToDelete.data!.cargo!.id!));
        if (rowToDelete.data?.tonnage?.id) {
          handleUnassign(id);
        }
        removeRows(gridRef.current.api, [rowToDelete]);
        dispatch(deselectCargo(rowToDelete.data!.cargo!.id!));
      } catch (error: unknown) {
        console.log(error);
      }
    }

    trackEvent(MixpanelEventCategory.INTERACTION, {
      event: MixpanelEventName.CONFIRM_DELETE_CARGO,
      eventAction: MixpanelEventAction.CLICK,
      eventLabel: MixpanelEventLabel.BUTTON,
      eventLocation: MixpanelEventLocation.DELETE_CARGO_MODAL,
      eventVersion: 1.1,
      assignmentId: rowToDelete?.data?.id,
      cargoId: rowToDelete?.data?.cargo?.id,
      planId,
    });
  };

  const handleUnassign = async (id: number) => {
    try {
      await dispatch(unassignTonnageAPI({ assignmentId: id }));
      const assignmentNode = gridRef.current?.api.getRowNode(id.toString());

      const linkedTonnageNodes = getRowNodes<LinkedTonnageDto>(
        tonnagesGridRef?.current?.api,
        (tonnage) => tonnage.data?.id === assignmentNode?.data?.tonnage?.id
      );

      if (assignmentNode) {
        unassignCargo(assignmentNode);
      }

      if (linkedTonnageNodes.length && linkedTonnageNodes[0]) {
        customFlashRow(GridId.tonnage, linkedTonnageNodes[0].data?.id);
        unassignTonnage(linkedTonnageNodes[0]);
      }

      trackEvent(MixpanelEventCategory.INTERACTION, {
        event: MixpanelEventName.CONFIRM_REMOVE_TONNAGE,
        eventAction: MixpanelEventAction.CLICK,
        eventLabel: MixpanelEventLabel.BUTTON,
        eventLocation: MixpanelEventLocation.REMOVE_TONNAGE_MODAL,
        eventVersion: 1.1,
        assignmentId: assignmentNode?.data?.id,
        cargoId: assignmentNode?.data?.cargo?.id,
        tonnageId: linkedTonnageNodes[0]?.data?.id,
        planId,
      });
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const handleDuplicate = async (data: AssignmentDto) => {
    try {
      if (!data?.cargo?.id) throw new Error('Duplicate:: There is no id!');

      const res = await dispatch(duplicateAssignmentCargo(data));
      const duplicatedRow = res.payload as AssignmentDto;
      removeFormatting(duplicatedRow);
      duplicateRow(duplicatedRow);

      trackEvent(MixpanelEventCategory.INTERACTION, {
        event: MixpanelEventName.SELECT_DUPLICATE_CARGO_OPTION,
        eventAction: MixpanelEventAction.CLICK,
        eventLabel: MixpanelEventLabel.MENU_OPTION,
        eventLocation: MixpanelEventLocation.CARGO_MENU,
        eventVersion: 1.1,
        oldAssignmentId: data?.id,
        oldCargoId: data?.cargo?.id,
        assignmentId: duplicatedRow?.id,
        cargoId: duplicatedRow?.cargo?.id,
        planId,
      });
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const handleCopyToClipboard = async (rowId: string) => {
    try {
      await copyToClipboard(rowId);
      dispatch(showNotificationSnackbar({ type: 'success', title: 'Cargo assignment copied to clipboard' }));
    } catch (error: unknown) {
      dispatch(showNotificationSnackbar({ type: 'error', title: 'Sorry, we had a problem. Please try again.' }));
    }
  };

  const removeFormatting = (row: AssignmentRowData) => {
    row.formatting = undefined;
    if (row.cargo) {
      row.cargo.formatting = undefined;
    }
    if (row.tonnage) {
      row.tonnage.formatting = undefined;
    }
  };

  return { handleDelete, handleUnassign, handleDuplicate, handleCopyToClipboard };
};
