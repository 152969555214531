import { Autocomplete, TextField, styled } from '@mui/material';
import { REQUEST_STATUS, useAppDispatch } from '../../../../store/helpers';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { blue, neutral } from '../../../../themes/palette';
import { CustomPaperComponent } from './components/CustomPaper/CustomPaperComponent';
import { isNil } from '../../../../helpers/helpers';
import { useSearchEditorHandlers } from './useSearchEditorHandlers';
import { InfoContentMessages } from './components/InfoContent/InfoContent';
import { IRenderOptions } from './components/SearchEditorRenderOptions';
import { ActionCreatorWithoutPayload, AsyncThunk } from '@reduxjs/toolkit';

export interface ValueState<T> {
  value: T | null;
  inputValue: string;
}

export interface Messages {
  userTip: string;
  optionNotLinkedMessageTitle: string;
  optionNotLinkedMessageDescription: string;
  loader: string;
  selectOption: string;
  infoContent: InfoContentMessages;
}

export interface ISearchCellEditorParams<T> extends ICellEditorParams {
  testIdPrefix: string;
  editorValue: T | null;
  setEditorValue: (editorValue: T) => void;
  searchedOptions: T[];
  searchedOptionsTotalCount?: number;
  searchOptions: AsyncThunk<any, string, any>;
  searchOptionsStatus: REQUEST_STATUS | undefined;
  resetSearchedOptions: ActionCreatorWithoutPayload;
  mainUiOptionKey: keyof T;
  optionIdentifierKey: keyof T;
  initialEditorState: ValueState<T>;
  maxLength: number;
  minSearchTriggerTextLength?: number;
  messages: Messages;
  renderOption: (props: IRenderOptions<T>) => JSX.Element;
}

export const SearchEditor = <T,>(props: ISearchCellEditorParams<T>) => {
  const {
    testIdPrefix,
    editorValue,
    mainUiOptionKey,
    optionIdentifierKey,
    initialEditorState,
    searchedOptions,
    minSearchTriggerTextLength = 3,
    searchedOptionsTotalCount,
    searchOptions,
    searchOptionsStatus,
    resetSearchedOptions,
    messages,
    renderOption,
  } = props;

  const [inputValue, setInputValue] = useState<string>(initialEditorState.inputValue);
  const refInput = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const isLoading = searchOptionsStatus === REQUEST_STATUS.pending;
  const isNotLinked = initialEditorState.value !== null && initialEditorState.value?.[optionIdentifierKey] == undefined;

  const { onChangeHandler, onInputChangeHandler, onKeyDownHandler, onHighlightChangeHandler, getOptions, getOptionLabel } =
    useSearchEditorHandlers({
      ...props,
      setInputValue,
    });

  useEffect(() => {
    const currentRef = refInput.current!;
    currentRef.focus();
    if (isNotLinked && inputValue.length >= minSearchTriggerTextLength) {
      dispatch(searchOptions(inputValue));
    }
    return () => {
      dispatch(resetSearchedOptions());
    };
  }, []);

  const isNoResults = !isLoading && !searchedOptions.length && isNil(editorValue?.[optionIdentifierKey]);
  const isOptionLinkedToDb =
    isNil(initialEditorState.value?.[optionIdentifierKey]) &&
    isNil(editorValue?.[optionIdentifierKey]) &&
    !isNil(initialEditorState.value?.[mainUiOptionKey]) &&
    initialEditorState.value?.[mainUiOptionKey] !== '';
  const areThereSearchedOptions = searchedOptionsTotalCount ? searchedOptionsTotalCount > 0 : searchedOptions.length > 0;

  const popperBackgroundColor =
    !isNil(editorValue?.[optionIdentifierKey]) && !searchedOptions.length && !isLoading ? blue.b10 : neutral.n100;
  const popperStyle: CSSProperties = {
    width: '372px',
    padding: 0,
    borderRadius: '4px',
    transform: 'translateX: (-50%)',
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)',
    backgroundColor: popperBackgroundColor,
  };

  return (
    <Autocomplete<T, false, boolean, boolean>
      data-testid={`${testIdPrefix}-search-autocomplete`}
      onKeyDown={onKeyDownHandler}
      onHighlightChange={onHighlightChangeHandler}
      freeSolo
      openOnFocus
      disableCloseOnSelect
      disableClearable={editorValue !== null}
      value={editorValue}
      inputValue={inputValue}
      forcePopupIcon={false}
      onChange={onChangeHandler}
      onInputChange={onInputChangeHandler}
      options={getOptions()}
      getOptionLabel={(option) => getOptionLabel(option)}
      renderOption={(optionProps, option, state) =>
        renderOption({ optionProps, option, state, minSearchTriggerTextLength, mainUiOptionKey })
      }
      filterOptions={(option) => option} //Disable native autocomplete filter
      componentsProps={{
        popper: {
          placement: 'bottom',
          style: popperStyle,
        },
      }}
      PaperComponent={(PaperProps) => (
        <CustomPaperComponent
          data-testid={`${testIdPrefix}-search-paper-component`}
          testIdPrefix={testIdPrefix}
          {...PaperProps}
          shouldShowUserTip={inputValue.length < minSearchTriggerTextLength}
          shouldShowOptionNotLinkedMessage={isOptionLinkedToDb}
          shouldShowNoResultsMessage={isNoResults}
          shouldShowCannotConnectToDbMessage={searchOptionsStatus === REQUEST_STATUS.rejected}
          shouldShowHeader={areThereSearchedOptions}
          searchedOptionsTotalCount={searchedOptionsTotalCount}
          searchedOptionsCount={searchedOptions.length}
          isLoading={isLoading}
          messages={messages}
        />
      )}
      ListboxComponent={StyledListBoxComponent}
      renderInput={(params) => (
        <TextField
          data-testid={`${testIdPrefix}-search-input`}
          {...params}
          inputRef={refInput}
          InputProps={{
            ...params.InputProps,
            endAdornment: null,
            className: 'ag-input-field-input ag-text-field-input',
          }}
          inputProps={{ ...params.inputProps, maxLength: props.maxLength }}
          style={{ height: 'var(--ag-row-height)' }}
        />
      )}
    />
  );
};

const StyledListBoxComponent = styled('div')({
  width: '100%',
  maxHeight: '250px !important',
  padding: '4px 0 !important',
});
