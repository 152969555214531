import { styled } from '@mui/material';
import React from 'react';
import { ReactComponent as ClockIcon } from '../../../../../../assets/clockIcon.svg';
import { blue, neutral } from '../../../../../../themes/palette';
import { MonthPicker } from '../../../../../common';
import { IDateRange } from '../../../../../../store';
import { useAppSelector } from '../../../../../../store/helpers';

interface IMonthPickerBox {
  dateRange: IDateRange;
  updateDateRange: (newRange: IDateRange) => void;
}

export const MonthPickerBox: React.FC<IMonthPickerBox> = ({ dateRange, updateDateRange }) => {
  const selectedRows = useAppSelector((state) => state.assignments.selectedRows);
  const isOneCargo = selectedRows.size && selectedRows.size === 1;
  const title = `Cargo Laycan${isOneCargo ? '' : 's'}`;

  return (
    <StyledContainer>
      <StyledBox>
        <ClockIcon />
        <StyledTitle>{title}</StyledTitle>
      </StyledBox>

      <StyledDescription>
        <span>Set start month to</span>
        <StyledDatePicker dateRange={dateRange} updateDateRange={updateDateRange} id="duplicate-cargoes-dialog" />
      </StyledDescription>
    </StyledContainer>
  );
};

const StyledContainer = styled('div')({
  backgroundColor: blue.b10,
  padding: '8px',
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

const StyledBox = styled('div')({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});

const StyledTitle = styled('div')({
  fontSize: '14px',
  lineHeight: '21px',
  fontWeight: 700,
  color: blue.b80,
});

const StyledDescription = styled('div')({
  fontSize: '14px',
  lineHeight: '150%',
  fontWeight: 500,
  color: neutral.n0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginLeft: '32px',
});

const StyledDatePicker = styled(MonthPicker)({
  backgroundColor: neutral.n100,
  '& button:first-child': {
    minWidth: 'initial',
  },
});
