import React, { createContext, PropsWithChildren, useContext } from 'react';

export enum MixpanelEventName {
  ADD_CARGO_FROM_EMPTY = 'Add Cargo From Empty',
  ADD_CARGO_FROM_FOOTER = 'Add Cargo From Footer',
  ADD_TONNAGE_FROM_EMPTY = 'Add Tonnage From Empty',
  ADD_TONNAGE_FROM_FOOTER = 'Add Tonnage From Footer',
  ADD_TONNAGE_FROM_CARGO_ASSIGNMENT = 'Add Tonnage From Cargo Assignment',
  ASSIGN_TONNAGE_BY_DRAG_AND_DROP = 'Assign Tonnage By Drag And Drop',
  CONFIRM_ADD_NEW_PLAN = 'Confirm Add New Plan',
  CONFIRM_BATCH_DUPLICATE = 'Confirm Batch Duplicate',
  CONFIRM_CREATE_PLAN = 'Confirm Create Plan',
  CONFIRM_DELETE_CARGO = 'Confirm Delete Cargo',
  CONFIRM_DELETE_TONNAGE = 'Confirm Delete Tonnage',
  CONFIRM_REMOVE_FROM_CARGO = 'Confirm Remove From Cargo',
  CONFIRM_REMOVE_TONNAGE = 'Confirm Remove Tonnage',
  CONFIRM_UPDATE_TONNAGE_ASSIGNMENT = 'Confirm Update Tonnage Assignment',
  CONFIRM_REASSIGN_TONNAGE = 'Confirm Reassign Tonnage',
  CONFIRM_REPLACE_TONNAGE = 'Confirm Replace Tonnage',
  OPEN_SEA_NET_LINK = 'Open Sea Net Link',
  SELECT_PLAN = 'Select Plan',
  SELECT_DUPLICATE_CARGO_OPTION = 'Select Duplicate Cargo Option',
}

export enum MixpanelEventCategory {
  INTERACTION = 'Interaction',
  NAVIGATION = 'Navigation',
}

export enum MixpanelEventAction {
  CLICK = 'Click',
  DRAG_AND_DROP = 'Drag and Drop',
  ENTER_TEXT = 'Enter Text',
  SELECT = 'Select',
}

export enum MixpanelEventLabel {
  BUTTON = 'Button',
  DRAG_SOURCE_AND_DRAG_TARGET = 'Drag Source and Drag Target',
  GRID_CELL_EDITOR = 'Grid Cell Editor',
  ICON_BUTTON = 'Icon Button',
  SELECT_OPTION = 'Select Option',
  MENU_OPTION = 'Menu Option',
}

export enum MixpanelEventLocation {
  BATCH_DUPLICATE_MODAL = 'Batch Duplicate Modal',
  CARGO_ASSIGNMENT_GRID = 'Cargo Assignment Grid',
  CARGO_MENU = 'Cargo Menu',
  CREATE_PLAN_MODAL = 'Create Plan Modal',
  DELETE_CARGO_MODAL = 'Delete Cargo Modal',
  DELETE_TONNAGE_MODAL = 'Delete Tonnage Modal',
  PLAN_SELECT = 'Plan Select',
  REASSIGN_TONNAGE_MODAL = 'Reassign Tonnage Modal',
  REMOVE_FROM_CARGO_MODAL = 'Remove From Cargo Modal',
  REMOVE_TONNAGE_MODAL = 'Remove Tonnage Modal',
  REPLACE_TONNAGE_MODAL = 'Replace Tonnage Modal',
  TONNAGE_GRID = 'Tonnage Grid',
  TONNAGE_ROW_AND_CARGO_ASSIGNMENT_ROW = 'Tonnage Row and Cargo Assignment Row',
  UPDATE_TONNAGE_ASSIGNMENT_MODAL = 'Update Tonnage Assignment Modal',
}

export interface IMixpanelProperties {
  event: MixpanelEventName;
  eventAction: MixpanelEventAction;
  eventLabel: MixpanelEventLabel;
  eventLocation?: MixpanelEventLocation;
  eventVersion: number;
  planId?: number;
  cargoId?: number;
  tonnageId?: number;
  [key: string]: unknown;
}

interface IMixpanelContext {
  trackEvent: (eventName: MixpanelEventCategory, properties: IMixpanelProperties) => void;
}

interface IMixpanelProvider {
  isTrackingEnabled: boolean;
}

export const MixpanelContext = createContext<IMixpanelContext | null>(null);

export const MixpanelProvider: React.FC<PropsWithChildren<IMixpanelProvider>> = ({ isTrackingEnabled, children }) => {
  const trackEvent = (eventName: MixpanelEventCategory, properties?: IMixpanelProperties) => {
    if (isTrackingEnabled && window.seaTracker) {
      window.seaTracker.trackEvent(eventName, properties);
    } else if (isTrackingEnabled && !window.seaTracker) {
      console.error(`Mixpanel tracking error: seaTracker not loaded`);
    } else {
      console.log(`Mixpanel event logged: Event name='${eventName}', Properties=${JSON.stringify(properties)}`);
    }
  };

  const value: IMixpanelContext = { trackEvent: trackEvent };

  return <MixpanelContext.Provider value={value}>{children}</MixpanelContext.Provider>;
};

export function useMixpanel() {
  const context = useContext(MixpanelContext);

  if (!context) {
    throw new Error('Component beyond MixpanelContext');
  }

  return context;
}
