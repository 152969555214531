import { CellFocusedEvent, IRowNode, RowSelectedEvent } from 'ag-grid-community';
import { ICustomRowData } from './useDefaultGridOptions';
import { AgGridReact } from 'ag-grid-react';
import { GridId } from '../helpers';
import { useUnhighlightRows } from './useUnhighlightRows ';

export const useGridActions = (gridRef: React.MutableRefObject<AgGridReact | null>, currentGridId: GridId) => {
  const { unhighlightOnMouseUp, unhighlightAll } = useUnhighlightRows(gridRef, currentGridId);

  const addEmptyRow = <T>(newEmptyRow: ICustomRowData<T>) => {
    newEmptyRow.shouldHighlight = true;
    newEmptyRow.showPlaceholders = true;
    gridRef.current?.api.applyTransactionAsync({ add: [newEmptyRow] }, () => onAddEmptyRow(newEmptyRow));
  };

  const duplicateRow = <T>(duplicatedRow: ICustomRowData<T>) => {
    duplicatedRow.shouldHighlight = true;

    gridRef.current?.api.applyTransactionAsync({ add: [duplicatedRow] }, () => {
      const newRowNode = gridRef.current?.api.getRowNode(duplicatedRow.id!.toString()) as IRowNode;
      document.body?.addEventListener('mouseup', unhighlightOnMouseUp);

      if (newRowNode.rowIndex) {
        gridRef.current?.api.ensureIndexVisible(newRowNode.rowIndex);
      }
    });
  };

  const onRowSelected = <T>(event: RowSelectedEvent<T>) => {
    event.api.refreshCells({ rowNodes: [event.node], force: true });
  };

  const onCellFocused = (event: CellFocusedEvent) => {
    const rowId = event.rowIndex;
    if (!rowId) return;
    const rowNode = event.api.getDisplayedRowAtIndex(rowId);
    if (rowNode?.data.shouldHighlight) return;
    unhighlightAll();
  };

  const onAddEmptyRow = <T>(newEmptyRow: ICustomRowData<T>) => {
    const newRowNode = gridRef.current?.api.getRowNode(newEmptyRow.id!.toString()) as IRowNode<ICustomRowData<T>>;

    document.body?.addEventListener('mouseup', unhighlightOnMouseUp);

    const firstEditableColumnName = getFirstEditableColumnName(newRowNode);
    if (!firstEditableColumnName) return;
    gridRef.current?.api.startEditingCell({
      rowIndex: newRowNode!.rowIndex!,
      colKey: firstEditableColumnName,
    });
  };

  const getFirstEditableColumnName = (rowNode: IRowNode): string | null => {
    const columns = gridRef.current?.columnApi.getAllDisplayedColumns();
    if (!columns) return null;
    const columnsCopy = [...columns];

    const sortedColumns = columnsCopy.sort((prev, next) => prev.getLeft()! - next.getLeft()!);
    const firstEditableColumn = sortedColumns?.find((col) => col.isCellEditable(rowNode));
    if (!firstEditableColumn) return null;

    return firstEditableColumn.getColId();
  };

  const selectAll = () => {
    gridRef.current?.api.selectAll();
  };

  const deselectAll = () => {
    gridRef.current?.api.deselectAll();
  };

  return { addEmptyRow, duplicateRow, onCellFocused, onRowSelected, unhighlightOnMouseUp, selectAll, deselectAll };
};
