import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline } from '@mui/material';
import '@typefaces-pack/typeface-inter';
import { setupStore } from './store';
import { Provider } from 'react-redux';
import DefaultThemeProvider from './themes/ThemeProvider';
import './index.css';
import { enableMapSet } from 'immer';
require('typeface-noto-sans');

enableMapSet(); // for using Set in redux store

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={setupStore()}>
      <DefaultThemeProvider>
        <CssBaseline />
        <App />
      </DefaultThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
