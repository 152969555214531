import { useAppSelector } from '../../../../store/helpers';
import { forwardRef, memo, useImperativeHandle, useMemo, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { searchVessels, resetState } from '../../../../store/slices/vessels.slice';
import { VesselDto } from '../../../../api/web-api-client';
import { isValidForEditorChar } from '../helpers';
import { InfoContentType } from '../SearchEditor/components/InfoContent/InfoContent';
import { SearchEditor } from '../SearchEditor/SearchEditor';
import { RenderVesselOption } from './RenderVesselOption';

interface IVesselSearchCellEditorParams extends ICellEditorParams {
  testIdPrefix: string;
  maxLength: number;
}

export const VesselSearchEditor = memo(
  forwardRef((props: IVesselSearchCellEditorParams, ref) => {
    const initialState = useMemo(() => {
      let startValue: VesselDto | null = null;
      let startInputValue = '';

      if (isValidForEditorChar(props.eventKey)) {
        startInputValue = props.eventKey!;
      } else {
        startValue = props?.value ?? null;
        startInputValue = props?.value?.name ?? '';
      }

      return {
        value: startValue,
        inputValue: startInputValue,
      };
    }, []);

    const [editorValue, setEditorValue] = useState<VesselDto | null>(initialState.value);
    const { vessels, vesselsTotalCount, status } = useAppSelector((state) => state.vessels);

    const messages = {
      userTip: 'Enter 3 characters of vessel name or IMO...',
      optionNotLinkedMessageTitle: 'Entry is not linked to our vessel database.',
      optionNotLinkedMessageDescription: 'Vessel information will not be available.',
      loader: 'Loading vessels...',
      selectOption: 'Select a vessel...',
      infoContent: {
        [InfoContentType.cannotFindMatch]: {
          main: 'Sorry, we couldn’t find a match',
          details: 'Please try a different vessel name or IMO',
        },
        [InfoContentType.cannotConnectToDb]: {
          main: 'Sorry, we couldn’t connect to \n our vessel database',
          details: 'Please try again later or contact\x20',
        },
      },
    };

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return editorValue?.name ? editorValue : undefined;
        },
      };
    });

    return (
      <SearchEditor<VesselDto>
        editorValue={editorValue}
        setEditorValue={setEditorValue}
        searchedOptions={vessels}
        searchedOptionsTotalCount={vesselsTotalCount}
        searchOptions={searchVessels}
        searchOptionsStatus={status.searchVessels}
        resetSearchedOptions={resetState}
        initialEditorState={initialState}
        mainUiOptionKey="name"
        optionIdentifierKey="seaId"
        messages={messages}
        renderOption={RenderVesselOption}
        {...props}
      />
    );
  })
);
