export const hexToRgb = (hex: string): string | undefined => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return `${r}, ${g}, ${b}`;
  }

  console.error('Wrong string format provided. Please provide string in hex format e.g. "#020413"');
};

export const neutral = {
  n0: '#000000',
  n10: '#111928',
  n15: '#131414',
  n30: '#9CA3AF',
  n40: '#BCBDBE',
  n60: '#CDCED3',
  n65: '#6A6C6D',
  n70: '#E5E5E5',
  n75: '#F3F4F6',
  n99: '#F8F9FA',
  n100: '#FFFFFF',
  n200: '#F2F4F5',
};

export const blue = {
  b10: '#E3F2FD',
  b15: '#E1EFFE',
  b20: '#AFE5F7',
  b25: '#8BD7F0',
  b30: '#7ECEE9',
  b50: '#1976D2',
  b70: '#325DB8',
  b80: '#0B3189',
};

export const yellow = {
  y10: '#F9EF96',
  y20: '#F2DD67',
  y30: '#EAD435',
  y50: '#FFCC08',
};
export const orange = {
  o2: '#FFECDC',
  o5: '#FFDBAF',
  o6: '#F9C381',
  o7: '#EEB772',
  o10: '#FF6C00',
  o15: '#E85404',
  o20: '#C15505',
  o50: '#ED6C02',
  o100: '#FFF0E5',
};

export const red = {
  r10: '#FBE8E8',
  r20: '#FED8D8',
  r25: '#F0AFAF',
  r30: '#EB9E9E',
  r40: '#D61414',
  r50: '#B3261E',
  r60: '#D32F2F',
};

export const green = {
  g10: '#BBEFAF',
  g20: '#9BDF9C',
  g30: '#8CD28D',
  g50: '#00A65A',
  g60: '#057135',
};

export const grey = {
  g1: '#E9EBEC',
  g2: '#C9C9C9',
  g3: '#9E9E9E',
  g4: '#AAA',
  g5: '#686B7C',
  g6: '#BCBEBE',
  g8: '#D9DBDB',
  g9: '#8D8E8E',
  g10: '#595B5C',
  g11: '#f8f9f9',
  g12: '#2A2D2E',
  g13: '#DDDEE0',
  g14: '#333E49',
  g15: '#6A6C6D',
};

export const purple = {
  p10: '#E0D7F4',
  p15: '#C0AFE4',
  p20: '#BAA9E0',
};

export const black = {
  b9: '#1f1f1f',
};
