import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import assignments from './slices/assignments.slice';
import tonnages from './slices/tonnages.slice';
import plans from './slices/plans.slice';
import api from './slices/api.slice';
import dialogs from './slices/dialogs.slice';
import dateRange from './slices/dateRange.slice';
import notifications from './slices/notifications.slice';
import vessels from './slices/vessels.slice';
import locations from './slices/locations.slice';
import gridConfigurations from './slices/gridConfigurations.slice';
import errors from './slices/errors.slice';
import { pageResetMiddleware } from './pageResetMiddleware';

export const rootReducer = combineReducers({
  api,
  assignments,
  dialogs,
  dateRange,
  notifications,
  plans,
  tonnages,
  vessels,
  locations,
  gridConfigurations,
  errors,
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(pageResetMiddleware),
    preloadedState,
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
