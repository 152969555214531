const arrowTemplate = `
<span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
<span ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
<span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
`;

const separator = '<span class="grid-vessel-header-details-separator"></span>';

export const getGridHeaderTemplate = (details: string[], dataTestIdPrefix: string) => {
  const headerDetails = details
    .map((detail, index) => {
      let detailSpanTemplate = '';
      let detailKey = 'unit';
      if (index > 0) {
        detailKey = detail.split(' ')[0].toLocaleLowerCase();
      }
      detailSpanTemplate = `<span class="grid-vessel-header-detail" data-testid="${dataTestIdPrefix}-${detailKey}">${detail}</span>`;
      if (details.length > 1 && index !== details.length - 1) {
        detailSpanTemplate = detailSpanTemplate + separator;
      }
      return detailSpanTemplate;
    })
    .join('');

  return `
    <div class="ag-cell-label-container" role="presentation">
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        <div class="grid-vessel-header ag-header-cell-text">
            <span ref="eText" class="ag-header-cell-text" role="columnheader"  data-testid="${dataTestIdPrefix}-name"></span>
            <div class="grid-vessel-header-details" data-testid="${dataTestIdPrefix}-details">
                ${headerDetails}
            </div>
        </div>
        ${arrowTemplate}
        </div>
    </div>
</div>
`;
};
