import dayjs from 'dayjs';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { formatDateRange } from './dateHelpers';
import { ISelectItem } from '../components/common/grid/SelectCellEditor';
import { incoterms } from '../components/common/Constants';
import { isNil } from './helpers';

export const dateRangeValueFormatter = (params: ValueFormatterParams): string =>
  params.value ? formatDateRange(params.value) : '';

export const dateValueFormatter = (params: ValueFormatterParams): string => {
  if (!params.value) return '';
  const date = dayjs.utc(params.value);
  const format = 'D MMM';
  return date.format(format);
};

export const integerValueFormatter = (params: ValueFormatterParams): string =>
  params.value != undefined ? params.value.toLocaleString('en-US') : '';

// TODO refactor this after backend task for incoterm table will be done
export const incotermValueFormatter = (params: ValueFormatterParams): string => {
  const foundIncoterm = incoterms.find((incoterm: ISelectItem) => incoterm.id === params.value);
  return foundIncoterm?.name ?? '';
};

export const vesselFormatter = (params: ValueFormatterParams): string => {
  return params?.value?.name ?? '';
};

export const locationFormatter = (params: ValueFormatterParams): string => {
  return params?.value?.name ?? '';
};

export const decimalValueFormatter = ({ value }: ValueFormatterParams): string => {
  if (isNil(value)) return '';
  if (value === 0) return '0';
  return value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
};
