import { tooltipClasses } from '@mui/material';

export const MuiTooltip = {
  styleOverrides: {
    tooltip: {
      [`.${tooltipClasses.popper}[data-popper-placement*="bottom"] &`]: {
        marginTop: '8px',
        backgroundColor: 'rgba(51, 62, 73, 0.9)',
        maxWidth: 'max-content',
        padding: '4px 8px',
        fontWeight: 400,
      },
    },
  },
};
