import { ColumnState } from 'ag-grid-community';
import { REQUEST_STATUS, addAbortSignalListener } from '../helpers';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GridConfigDto } from '../../api/web-api-client';
import { ApiManager } from '../../contexts/ApiManager';
import { GridId } from '../../components/common/grid/helpers';

export type GridConfiguration = {
  [key in GridId]?: ColumnState[];
};

export enum GridConfigurationActions {
  GET_GRID_CONFIGURATION = 'getGridConfiguration',
  UPDATE_GRID_CONFIGURATION = 'updateGridConfiguration',
}

export type GridConfigurationState = {
  gridConfigurations: GridConfiguration;
  status: {
    [key in GridConfigurationActions]?: REQUEST_STATUS;
  };
};

export const gridConfigurationInitialState: GridConfigurationState = {
  gridConfigurations: {
    [GridId.cargoAssigment]: [],
    [GridId.tonnage]: [],
  },
  status: {},
};

export interface IUpdateGridConfigurationPayload {
  gridConfigurations: GridConfiguration;
  planId?: number | undefined;
}

export const getGridConfigurations = createAsyncThunk(
  GridConfigurationActions.GET_GRID_CONFIGURATION,
  async (planId: number, { signal, getState }) => {
    addAbortSignalListener(signal);
    try {
      const state = (await getState()) as { api: { apiManager: ApiManager } };
      const gridConfigurations = await state.api.apiManager.gridConfigurationAPI.get(planId);
      if (!gridConfigurations.length) {
        return gridConfigurationInitialState.gridConfigurations;
      }
      const mappedGridConfigurations = gridConfigurations
        .filter((value) => value.gridId && value.gridState)
        .map((value) => [value.gridId as string, JSON.parse(value.gridState as string) as ColumnState[]]);
      return Object.fromEntries(mappedGridConfigurations);
    } catch (error: unknown) {
      console.error(error);
      throw error;
    }
  }
);

export const updateGridConfiguration = createAsyncThunk(
  GridConfigurationActions.UPDATE_GRID_CONFIGURATION,
  async (payload: IUpdateGridConfigurationPayload, { signal, getState }) => {
    addAbortSignalListener(signal);
    try {
      const gridConfigurations: GridConfigDto[] = Object.entries(payload.gridConfigurations).map((value) => {
        return {
          gridId: value[0],
          gridState: JSON.stringify(value[1]),
        };
      });
      const state = (await getState()) as { api: { apiManager: ApiManager } };
      await state.api.apiManager.gridConfigurationAPI.save(gridConfigurations, payload.planId);
      return payload.gridConfigurations;
    } catch (error: unknown) {
      console.error(error);
      throw error;
    }
  }
);

const gridConfigurationsSlice = createSlice({
  name: 'gridConfigurations',
  initialState: gridConfigurationInitialState,
  reducers: {
    resetGridConfiguration: (state) => {
      state.gridConfigurations = gridConfigurationInitialState.gridConfigurations;
    },
  },
  extraReducers(builder) {
    builder.addCase(getGridConfigurations.fulfilled, (state, { payload }) => {
      state.gridConfigurations = payload;
      state.status[GridConfigurationActions.GET_GRID_CONFIGURATION] = REQUEST_STATUS.fulfilled;
    });
    builder.addCase(getGridConfigurations.pending, (state) => {
      state.gridConfigurations = {};
      state.status[GridConfigurationActions.GET_GRID_CONFIGURATION] = REQUEST_STATUS.pending;
    });
    builder.addCase(updateGridConfiguration.fulfilled, (state, { payload }) => {
      state.gridConfigurations = payload;
      state.status[GridConfigurationActions.UPDATE_GRID_CONFIGURATION] = REQUEST_STATUS.fulfilled;
    });
  },
});
export const { resetGridConfiguration } = gridConfigurationsSlice.actions;
export default gridConfigurationsSlice.reducer;
