import { Middleware } from 'redux';
import { RootState } from './store';
import { deactivateBatchActionsSelectBox } from './slices/assignments.slice';

export const pageResetMiddleware: Middleware<object, RootState> = (storeApi) => (next) => (action) => {
  if (action.type === 'plans/selectCurrentPlan') {
    storeApi.dispatch(deactivateBatchActionsSelectBox());
  }
  return next(action);
};
