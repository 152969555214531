import { Typography, styled } from '@mui/material';
import { neutral } from '../../../../../../themes/palette';

interface IUserTip {
  text: string;
}

export const UserTip: React.FC<IUserTip> = ({ text }) => {
  return <StyledTypography>{text}</StyledTypography>;
};

const StyledTypography = styled(Typography)({
  backgroundColor: neutral.n200,
  color: neutral.n65,
  fontStyle: 'italic',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '150%',
  padding: '8px 16px',
  borderRadius: '8px',
});
