import { blue, neutral } from '../../palette';

const MenuProps = {
  PaperProps: {
    sx: {
      borderRadius: '8px',
      padding: '0',
      marginTop: '1px',
      boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2)',
    },
  },

  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const,
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const,
  },
};

const MuiMenuItem = {
  minWidth: '200px',
  height: '37px',
  fontWeight: 500,
  paddingTop: '8px',
  '&.Mui-selected': {
    fontWeight: '700',
    backgroundColor: blue.b10,
  },
};

const MuiSelect = {
  defaultProps: {
    MenuProps: { ...MenuProps },
    sx: {
      margin: '0',
      minWidth: '200px',
      height: '44px',
      borderRadius: '8px',
      fontWeight: 700,
      paddingRight: '5px',
      backgroundColor: neutral.n75,

      '&:hover': {
        backgroundColor: neutral.n70,
        borderColor: neutral.n70,
      },

      '&.MuiOutlinedInput-root': {
        '& fieldset': {
          borderWidth: '1px',
          borderColor: neutral.n75,
        },
        '&:hover fieldset': {
          borderColor: neutral.n70,
        },
        '&.Mui-focused fieldset': {
          borderWidth: '1px',
        },
        '&.Mui-focused:hover fieldset': {
          borderColor: blue.b50,
        },
      },
    },
  },
};

export const lightThemeSelect = {
  MuiSelect: {
    defaultProps: {
      ...MuiSelect.defaultProps,
      sx: {
        ...MuiSelect.defaultProps.sx,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        ...MuiMenuItem,
      },
    },
  },
};

export const darkThemeSelect = {};
