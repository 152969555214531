import { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GetRowIdParams, ICellRendererParams, RowClassRules } from 'ag-grid-community';

import { PlaceholderCellRenderer } from './../PlaceholderCellRenderer';
import { LoadingOverlay } from './../LoadingOverlay';
import { NoRowsOverlay } from './..//NoRowsOverlay';
import { GridId } from './../helpers';
import { FLASH_ANIMATION_TIME, KeyNames } from '../../Constants';
import { SuppressKeyboardEventParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { useGridConfiguration } from './useGridConfiguration';
import { useGridActions } from './useGridActions';
import { useGridDetailsAssignUpdater } from './useGridDetailsAssignUpdater';
import { isEmptyObject } from '../../../../helpers/helpers';
import { UnsavedValueCellRenderer } from '../UnsavedValueCellRenderer';
import { useFeatureFlags } from '../../../../contexts/FeatureFlagsContext';
import { FeatureFlagsEnum } from '../../../../api/featureFlags';
import { AssignmentsGridColumn } from '../../../dashboards/cargo/grid';
import { TonnagesGridColumn } from '../../../dashboards/tonnage/grid/hooks';
import { VesselCellRenderer } from '../VesselCellRenderer';

export interface IUiData {
  id?: number;
  showPlaceholders?: boolean;
  shouldHighlight?: boolean;
  unsavedColumnsNames?: Set<string>;
}

export type ICustomRowData<T> = T & IUiData;

export const useDefaultGridOptions = (gridRef: React.MutableRefObject<AgGridReact | null>, currentGridId: GridId) => {
  const { applyGridState, updateGridState } = useGridConfiguration(currentGridId);
  const gridActions = useGridActions(gridRef, currentGridId);
  const assign = useGridDetailsAssignUpdater();
  const { isFeatureFlagActive } = useFeatureFlags();

  const cellRendererSelector = <T,>(params: ICellRendererParams<ICustomRowData<T>>) => {
    const shouldRenderPlaceholder =
      params.node.data?.showPlaceholders &&
      params.colDef?.headerName &&
      (!params.value || (typeof params.value === 'object' && isEmptyObject(params.value)));

    if (shouldRenderPlaceholder) {
      return {
        component: PlaceholderCellRenderer,
      };
    }
    if (isFeatureFlagActive(FeatureFlagsEnum.retrySaveCell)) {
      const shouldRenderUnsavedValue = params.colDef?.field && params.node.data?.unsavedColumnsNames?.has(params.colDef?.field);
      if (shouldRenderUnsavedValue) {
        return {
          component: UnsavedValueCellRenderer,
        };
      }
    }
    if (isFeatureFlagActive(FeatureFlagsEnum.ff_vesselCellDetails)) {
      if (params.colDef?.field === AssignmentsGridColumn.TonnageVessel || params.colDef?.field === TonnagesGridColumn.Vessel) {
        return {
          component: VesselCellRenderer,
        };
      }
    }
  };

  const getRowId = <T,>(params: GetRowIdParams<ICustomRowData<T>>) => {
    return params.data.id?.toString() ?? '';
  };

  const loadingOverlayComponent = useMemo(() => {
    return LoadingOverlay;
  }, []);

  const noRowsOverlayComponent = useMemo(() => {
    return NoRowsOverlay;
  }, []);

  const suppressKeyboardEvent = <T,>(params: SuppressKeyboardEventParams<T>) => {
    const isMakingNewLine = params.editing && params.event.shiftKey && params.event.key === KeyNames.ENTER;
    const isBackspaceWithoutEdit = !params.editing && params.event.key === KeyNames.BACKSPACE;
    const isDeleteWithoutEdit = !params.editing && params.event.key === KeyNames.DELETE;
    const gridShouldDoNothing = isMakingNewLine || isBackspaceWithoutEdit || isDeleteWithoutEdit;
    return gridShouldDoNothing;
  };

  const supressCellRendererSelection = () => undefined;

  const customFlashRow = (gridId: GridId, id?: number) => {
    if (!id) return;

    const container = document?.getElementById(gridId);

    const rowCells = container?.querySelectorAll(`[row-id="${id}"] > .ag-cell `);
    if (rowCells) {
      rowCells.forEach((cell) => {
        cell?.classList.add('custom-flash-cell');

        setTimeout(() => {
          cell?.classList.remove('custom-flash-cell');
        }, FLASH_ANIMATION_TIME);
      });
    }
  };

  const rowClassRules: RowClassRules<ICustomRowData<unknown>> = {
    'default-highlight-row': (params) => params?.data?.shouldHighlight === true,
  };

  return {
    ...gridActions,
    ...assign,
    customFlashRow,
    getRowId,
    loadingOverlayComponent,
    noRowsOverlayComponent,
    rowClassRules,
    cellRendererSelector,
    suppressKeyboardEvent,
    supressCellRendererSelection,
    updateGridState,
    applyGridState,
  };
};
