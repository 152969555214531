import { UserManager as OidcUserManager, UserManagerSettings } from 'oidc-client-ts';
import { IAppSettings } from './ApiManager';

export const PATHNAME_SILENT_REFRESH_REDIRECT = '/silent-refresh.html';
export const PATHNAME_SIGNIN_REDIRECT = '/signin-callback.html';

const getUserManagerAuthority = (appSettings: IAppSettings) =>
  process.env.REACT_APP_STS_AUTHORITY ?? appSettings?.identityServerStsAuthority ?? '';
const getUserManagerClientId = (appSettings: IAppSettings) =>
  process.env.REACT_APP_CLIENT_ID ?? appSettings.identityServerClientId ?? '';
const getUserManagerScope = (appSettings: IAppSettings) => process.env.REACT_APP_CLIENT_SCOPE ?? appSettings.identityServerScope;

export class UserManager {
  private readonly identitySettings: UserManagerSettings;
  oidcUserManager: OidcUserManager;

  constructor(appSettings: IAppSettings) {
    this.identitySettings = {
      authority: getUserManagerAuthority(appSettings),
      client_id: getUserManagerClientId(appSettings),
      redirect_uri: `${window.location.origin}${PATHNAME_SIGNIN_REDIRECT}`,
      silent_redirect_uri: `${window.location.origin}${PATHNAME_SILENT_REFRESH_REDIRECT}`,
      post_logout_redirect_uri: `${window.location.origin}/`,
      response_type: 'code',
      scope: getUserManagerScope(appSettings),
      loadUserInfo: true,
      automaticSilentRenew: true,
      filterProtocolClaims: false,
      monitorSession: true,
      checkSessionIntervalInSeconds: 2,
    };

    this.oidcUserManager = new OidcUserManager(this.identitySettings);
  }
}

// UserManager instance just to make silent refresh user token call
export class SilentRefreshTokenUserManager {
  private readonly identitySettings: UserManagerSettings;
  oidcUserManager: OidcUserManager;

  constructor(appSettings: IAppSettings) {
    this.identitySettings = {
      authority: getUserManagerAuthority(appSettings),
      client_id: getUserManagerClientId(appSettings),
      redirect_uri: `${window.location.origin}${PATHNAME_SIGNIN_REDIRECT}`,
    };

    this.oidcUserManager = new OidcUserManager(this.identitySettings);
  }
}
