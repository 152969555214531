import { VesselDto } from '../../../../api/web-api-client';
import {
  IRenderOptions,
  SearchEditorRenderOptions,
  StyledSearchEditorInfoInBrackets,
  StyledSearchEditorInfoInTag,
} from '../SearchEditor/components/SearchEditorRenderOptions';

interface IRenderVesselOption extends IRenderOptions<VesselDto> {}

export const RenderVesselOption = (props: IRenderVesselOption) => {
  const { option, state } = props;
  const { inputValue } = state;

  const shouldBoldImo = inputValue == option?.imo?.toString();

  return (
    <SearchEditorRenderOptions<VesselDto>
      {...props}
      key={option?.seaId ?? option?.imo ?? option?.name}
      mainUiOptionKey={'name'}
      infoInBrackets={
        option.imo ? (
          <StyledSearchEditorInfoInBrackets bold={shouldBoldImo}>({option.imo})</StyledSearchEditorInfoInBrackets>
        ) : null
      }
      infoInTag={
        option.deadweight ? (
          <StyledSearchEditorInfoInTag>DWT {option.deadweight.toLocaleString('en-US')}</StyledSearchEditorInfoInTag>
        ) : null
      }
    />
  );
};
