import React, { createContext, createRef, PropsWithChildren, useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { AssignmentDto, LinkedTonnageDto } from '../api/web-api-client';
import { ICustomRowData } from '../components/common/grid/hooks/useDefaultGridOptions';

interface IGridRefsContext {
  assignmentsGridRef: React.MutableRefObject<AgGridReact<ICustomRowData<AssignmentDto>> | null>;
  tonnagesGridRef: React.MutableRefObject<AgGridReact<ICustomRowData<LinkedTonnageDto>> | null>;
}

const GridRefsContext = createContext<IGridRefsContext | null>(null);

export const GridRefsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const assignmentsGridRef = createRef<AgGridReact<AssignmentDto>>();
  const tonnagesGridRef = createRef<AgGridReact<LinkedTonnageDto>>();

  const value = {
    assignmentsGridRef,
    tonnagesGridRef,
  };

  return <GridRefsContext.Provider value={value as IGridRefsContext}>{children}</GridRefsContext.Provider>;
};

export function useGridRefs() {
  const context = useContext(GridRefsContext);

  if (!context) {
    throw new Error('Component beyond GridRefsContext');
  }

  return context;
}
