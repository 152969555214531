import { styled } from '@mui/material';
import { useState } from 'react';
import { PlanDateFilterModal } from './PlanDateFilterModal/PlanDateFilterModal';
import { PlanDateFilterModalButton } from './PlanDateFilterModal/PlanDateFilterModalButton';
import { grey } from '../../../themes/palette';
import { PlanDateFilterRangeChangeButton } from './PlanDateFilterRangeChangeButton/PlanDateFilterRangeChangeButton';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { IDateRange } from '../../../store';
import { getAmountOfDaysInDateRange } from '../../../helpers/dateHelpers';
import { getItem, LOCAL_STORAGE_KEYS } from '../../../helpers/localStorage';
import { Dayjs } from 'dayjs';

export enum DASHBOARD_DATE_RANGE_TYPE {
  MONTH = 0,
  CUSTOM = 1,
}

interface IPlanDateFilterProps {
  dateRange: IDateRange;
  updateDateRange: (newRange: IDateRange) => void;
}

export const PlanDateFilter: React.FC<IPlanDateFilterProps> = (props) => {
  const { dateRange, updateDateRange } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const dashboardDateRangeType =
    getItem<DASHBOARD_DATE_RANGE_TYPE>(LOCAL_STORAGE_KEYS.DASHBOARD_DATE_RANGE_TYPE) || DASHBOARD_DATE_RANGE_TYPE.MONTH;

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'plandatefilter-modal' : undefined;

  const setDateRange = (direction: 'previous' | 'next') => {
    let newDateRange: IDateRange;
    if (dashboardDateRangeType === DASHBOARD_DATE_RANGE_TYPE.MONTH) {
      let monthBeginning: Dayjs;
      if (direction === 'previous') {
        monthBeginning = dateRange.from.utc().subtract(1, 'month');
      } else {
        monthBeginning = dateRange.from.utc().add(1, 'month');
      }
      newDateRange = { from: monthBeginning.utc(), to: monthBeginning.utc().endOf('month') };
      updateDateRange(newDateRange);
    } else if (dashboardDateRangeType === DASHBOARD_DATE_RANGE_TYPE.CUSTOM) {
      const amountOfDaysInDateRange = getAmountOfDaysInDateRange(dateRange);
      if (direction === 'previous') {
        newDateRange = {
          from: dateRange.from.utc().subtract(amountOfDaysInDateRange, 'day'),
          to: dateRange.from.utc().subtract(1, 'day'),
        };
        updateDateRange(newDateRange);
      } else if (direction === 'next') {
        newDateRange = {
          from: dateRange.to.utc().add(1, 'day'),
          to: dateRange.to.utc().add(amountOfDaysInDateRange, 'day'),
        };
        updateDateRange(newDateRange);
      }
    }
  };

  return (
    <>
      <PlanDateFilterModal
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        dateRange={dateRange}
        updateDateRange={updateDateRange}
        dashboardDateRangeType={dashboardDateRangeType}
      />

      <StyledContainer>
        <PlanDateFilterModalButton
          aria-describedby={id}
          data-testid="monthpicker-button-calendar-current-plan"
          onClick={openPopover}
          dateRange={dateRange}
          dashboardDateRangeType={dashboardDateRangeType}
        />
        <PlanDateFilterRangeChangeButton
          data-testid="monthpicker-button-previous-month-current-plan"
          onClick={() => setDateRange('previous')}
        >
          <KeyboardArrowLeft fontSize="small" />
        </PlanDateFilterRangeChangeButton>
        <PlanDateFilterRangeChangeButton
          data-testid="monthpicker-button-next-month-current-plan"
          onClick={() => setDateRange('next')}
        >
          <KeyboardArrowRight fontSize="small" />
        </PlanDateFilterRangeChangeButton>
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled('div')({
  display: 'flex',
  borderRadius: '8px',
  height: '40px',
  backgroundColor: grey.g1,
  border: 'none',
});
