import { createTheme } from '@mui/material';
import ThemeBase from '../ThemeBase/ThemeBase';
import { lightThemePalette } from '../ThemeBase/styleOverrides/palette';
import { lightThemeButton } from '../ThemeBase/styleOverrides/MuiButton';
import { lightThemeMuiDialogComponents } from '../ThemeBase/styleOverrides/MuiDialog';
import { lightThemeTextField } from '../ThemeBase/styleOverrides/MuiTextField';
import { lightThemeInputLabel } from '../ThemeBase/styleOverrides/MuiInputLabel';
import { lightThemeSelect } from '../ThemeBase/styleOverrides/MuiSelect';
import { lightThemeAlert } from '../ThemeBase/styleOverrides/MuiAlert';

import { lightThemeMuiCheckbox } from '../ThemeBase/styleOverrides/MuiCheckbox';
import { lightThemeAutocomplete } from '../ThemeBase/styleOverrides/MuiAutocomplete';
import { lightThemeAlertTitle } from '../ThemeBase/styleOverrides/MuiAlertTitle';
import { lightThemeSwitch } from '../ThemeBase/styleOverrides/MuiSwitch';

const ThemeLight = createTheme({
  ...ThemeBase,
  components: {
    ...ThemeBase.components,
    ...lightThemeMuiDialogComponents,
    MuiButton: lightThemeButton,
    MuiTextField: lightThemeTextField,
    MuiInputLabel: lightThemeInputLabel,
    ...lightThemeSelect,
    MuiAlert: lightThemeAlert,
    MuiCheckbox: lightThemeMuiCheckbox,
    MuiAutocomplete: lightThemeAutocomplete,
    MuiAlertTitle: lightThemeAlertTitle,
    MuiSwitch: lightThemeSwitch,
  },
  palette: lightThemePalette,
});

export default ThemeLight;
