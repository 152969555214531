import { ChangeEvent, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import Dialog from '../../common/dialog/Dialog';
import { CustomTextField } from '../../common';
import { REQUEST_STATUS, useAppDispatch, useAppSelector } from '../../../store/helpers';
import { addPlan, selectCurrentPlan } from '../../../store';
import { PlanDto } from '../../../api/web-api-client';
import { ErrorMsg } from '../../../helpers/planErrors';
import {
  MixpanelEventAction,
  MixpanelEventCategory,
  MixpanelEventLabel,
  MixpanelEventLocation,
  MixpanelEventName,
  useMixpanel,
} from '../../../contexts/MixpanelContext';

const StyledErrorIcon = styled(ErrorIcon)(({ theme }) => ({
  width: '17px',
  color: theme.palette.error.main,
}));

const MAX_CHARACTERS = 100;

const errorMsgRenderer = (errorMsg: string[]) => {
  return (
    <>
      {errorMsg.map((error) => (
        <span style={{ display: 'block' }} key={error}>
          {error}
        </span>
      ))}
    </>
  );
};

const validateInput = (value: string): string[] => {
  const errorMsgs: string[] = [];
  if (!value.length) {
    errorMsgs.push(ErrorMsg.EMPTY);
  }
  if (value.length > MAX_CHARACTERS) {
    errorMsgs.push(ErrorMsg.TOO_LONG);
  }
  return errorMsgs;
};

const defaultValues = {
  value: '',
  isTouched: false,
  errorMessages: [],
};

interface IInputState {
  value: string;
  isTouched: boolean;
  errorMessages: string[];
}

interface IAddPlanDialog {
  isOpen: boolean;
  onClose: () => void;
}

export const AddPlanDialog: React.FC<IAddPlanDialog> = ({ isOpen, onClose }) => {
  const [input, setInput] = useState<IInputState>(defaultValues);
  const dispatch = useAppDispatch();
  const errors = useAppSelector((state) => state.plans.errors);
  const addPlanStatus = useAppSelector((state) => state.plans.status.addPlan);
  const isCreatingPlanInProgress = addPlanStatus === REQUEST_STATUS.pending;
  const isCreatingPlanFulfilled = addPlanStatus === REQUEST_STATUS.fulfilled;
  const { trackEvent } = useMixpanel();

  const onChangeCallback = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const errorMessages = validateInput(currentTarget.value.trim());
    setInput({ value: currentTarget.value, errorMessages, isTouched: true });
  };

  const addPlanCallback = async () => {
    const newPlanTitle = input.value.trim();
    const errorMessages = validateInput(newPlanTitle);
    if (errorMessages.length === 0) {
      const createPlanCommand = { title: newPlanTitle };
      const res = await dispatch(addPlan(createPlanCommand));
      const plan = res.payload as PlanDto;
      if (plan.id) {
        trackEvent(MixpanelEventCategory.INTERACTION, {
          event: MixpanelEventName.CONFIRM_CREATE_PLAN,
          eventAction: MixpanelEventAction.CLICK,
          eventLabel: MixpanelEventLabel.BUTTON,
          eventLocation: MixpanelEventLocation.CREATE_PLAN_MODAL,
          eventVersion: 1.1,
          planId: plan.id,
        });
        dispatch(selectCurrentPlan(plan));
        setInput(defaultValues);
      } else {
        errorMessages.concat(errors);
      }

      return;
    }
    setInput({ ...input, isTouched: true, errorMessages });
  };

  useEffect(() => {
    if (isCreatingPlanFulfilled) {
      onClose();
    }
  }, [addPlanStatus]);

  useEffect(() => {
    if (errors.length) {
      const errorMessages = [...validateInput(input.value.trim()), ...errors];
      setInput({ ...input, isTouched: true, errorMessages: errorMessages });
    }
  }, [errors]);

  const hasError = input.errorMessages.length !== 0 && input.isTouched;

  return (
    <Dialog
      id="create-plan-dialog"
      title="Create a plan"
      actionBtnContent="Create plan"
      open={isOpen}
      closeBtnCb={onClose}
      actionCb={addPlanCallback}
      loadingLabel="Creating plan..."
      isLoading={isCreatingPlanInProgress}
      shouldConfirmOnEnter
    >
      <CustomTextField
        id="create-plan-input"
        label="Plan name"
        autoFocus
        onChange={onChangeCallback}
        value={input.value}
        error={hasError}
        helperText={errorMsgRenderer(input.errorMessages)}
        iconEnd={hasError && <StyledErrorIcon />}
      />
    </Dialog>
  );
};
