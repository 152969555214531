import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';
import { getItem, LOCAL_STORAGE_KEYS, setItem } from '../../helpers/localStorage';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
dayjs.extend(utc);
dayjs.extend(weekOfYear);

export const getInitialDateRange = (): IDateRange => {
  const cachedDateRange = getItem<IDateRange>(LOCAL_STORAGE_KEYS.DASHBOARD_DATE_RANGE);

  if (cachedDateRange) {
    return { from: cachedDateRange.from, to: cachedDateRange.to };
  }
  const nextMonthDate = dayjs.utc().add(1, 'month');
  const nextMonthDateRange: IDateRange = { from: nextMonthDate.utc().startOf('month'), to: nextMonthDate.utc().endOf('month') };

  setItem(LOCAL_STORAGE_KEYS.DASHBOARD_DATE_RANGE, nextMonthDateRange);
  return nextMonthDateRange;
};

export interface IDateRange {
  from: Dayjs;
  to: Dayjs;
}

export type DateRangeState = {
  dateRange: IDateRange;
};

export const dateRangeInitialState: DateRangeState = {
  dateRange: getInitialDateRange(),
};

const dateRangeSlice = createSlice({
  name: 'dateRange',
  initialState: dateRangeInitialState,
  reducers: {
    updateDateRange: (state, action: PayloadAction<IDateRange>) => {
      state.dateRange = action.payload;
      setItem(LOCAL_STORAGE_KEYS.DASHBOARD_DATE_RANGE, action.payload);
    },
  },
});

export const { updateDateRange } = dateRangeSlice.actions;

export default dateRangeSlice.reducer;
