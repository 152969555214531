import styled from '@emotion/styled';
import { ErrorOutline } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { orange } from '../../../../../../themes/palette';

interface IOptionNotLinkedMessage {
  title: string;
  description: string;
  testIdPrefix: string;
}

export const OptionNotLinkedMessage: React.FC<IOptionNotLinkedMessage> = (props) => {
  const { title, description, testIdPrefix } = props;

  return (
    <StyledInfoContainer>
      <StyledIconContainer>
        <StyledErrorIcon data-testid={`${testIdPrefix}-search-not-linked-msg-icon`} />
      </StyledIconContainer>
      <StyledTextContainer>
        <StyledTypography>{title}</StyledTypography>
        <StyledTypography sx={{ fontStyle: 'italic' }}>{description}</StyledTypography>
      </StyledTextContainer>
    </StyledInfoContainer>
  );
};

const StyledInfoContainer = styled('div')({
  height: '67px',
  width: '100%',
  backgroundColor: orange.o100,
  padding: '12px 16px 16px 12px',
  display: 'flex',
  fontSize: '14px',
});

const StyledIconContainer = styled('div')({
  flexBasis: '20px',
  marginRight: '8px',
});

const StyledTextContainer = styled('div')({
  flexGrow: 1,
});

const StyledErrorIcon = styled(ErrorOutline)({
  width: '24px',
  color: orange.o10,
});

const StyledTypography = styled(Typography)({
  fontSize: '14px',
  color: orange.o20,
  fontWeight: 500,
});
