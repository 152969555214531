import React from 'react';
import arrowDown from '../../../../../assets/arrowDown.svg';
import { addOverlay, removePreviousOverlays, removeSourceOverlay } from '../../../../../helpers/dndOverlayUtils';
import { LinkedTonnageDto } from '../../../../../api/web-api-client';
import { isDragging } from '../../../../common/grid/helpers';

const sourceOverlayId = 'drag-source-overlay';
interface IPinnedRow {
  pinnedLeft?: Element;
  center?: Element;
  pinnedRight?: Element;
}

const setIsDraggingAtrribute = (row: IPinnedRow, value: boolean) => {
  row.pinnedLeft?.setAttribute(isDragging, value.toString());
  row.center?.setAttribute(isDragging, value.toString());
  row.pinnedRight?.setAttribute(isDragging, value.toString());
};

export function useTonnageDrag(data: LinkedTonnageDto): {
  onDragStart: (dragEvent: React.DragEvent) => void;
  onDragEnd: (dragEvent: React.DragEvent) => void;
  onDrag: (dragEvent: React.DragEvent) => void;
} {
  let backgroundImageOffset = 0;
  const rowParts = document.querySelectorAll(`#tonnage-grid  [row-id="${data.id}"]`);
  const row = { pinnedLeft: rowParts[0], center: rowParts[1], pinnedRight: rowParts[2] };

  const onDragStart = (dragEvent: React.DragEvent<Element>) => {
    const centerGridVieport = document.querySelector(`#tonnage-grid .ag-center-cols-viewport`);

    setIsDraggingAtrribute(row, true);

    dragEvent.dataTransfer.setData('application/json', JSON.stringify(data));

    row.center && dragEvent.dataTransfer.setDragImage(row.center, 10, 20);

    if (centerGridVieport) {
      backgroundImageOffset = centerGridVieport?.clientWidth / 2 + centerGridVieport?.scrollLeft;
    }
  };

  const onDragEnd = (dragEvent: React.DragEvent<Element>) => {
    if (dragEvent.dataTransfer.dropEffect == 'none') {
      setIsDraggingAtrribute(row, false);
      removePreviousOverlays();
      removeSourceOverlay();
    }
  };

  const onDrag = () => {
    if (row?.pinnedLeft?.getElementsByClassName(sourceOverlayId).length) {
      return;
    }

    if (row.pinnedLeft && row.pinnedRight) {
      addOverlay(row.pinnedLeft, sourceOverlayId);
      addOverlay(row.pinnedRight, sourceOverlayId);
    }

    row.center && addOverlay(row.center, sourceOverlayId, arrowDown, backgroundImageOffset);
  };

  return { onDragStart, onDragEnd, onDrag };
}
