import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/helpers';
import { closeNotificationSnackBar } from '../../../store';
import { ReactComponent as SuccessIcon } from '../../../assets/SuccessCircleIcon.svg';
import { capitalize } from '../../../helpers/helpers';

export const Notifications = () => {
  const { isOpen, message, title, type, ...rest } = useAppSelector((state) => state.notifications);
  const dispatch = useAppDispatch();
  const onClose = () => dispatch(closeNotificationSnackBar());

  return (
    <Snackbar
      data-testid="snackbar-notification"
      open={isOpen}
      {...rest}
      onClose={onClose}
      ClickAwayListenerProps={{ mouseEvent: false }}
    >
      <Alert
        severity={type}
        icon={type === 'success' ? <SuccessIcon data-testid="snackbar-success-icon" /> : undefined}
        sx={{ position: 'fixed' }}
      >
        <AlertTitle>{capitalize(title)}</AlertTitle>
        {message && <div>{message}</div>}
      </Alert>
    </Snackbar>
  );
};
