import { styled } from '@mui/material';

interface IRightPanelProps {
  isHidden: boolean;
  children: React.ReactNode;
  dataTestIdPrefix: string;
}

export const RightPanel: React.FC<IRightPanelProps> = (props) => {
  const { isHidden, children, dataTestIdPrefix } = props;

  return (
    <StyledRightPanel ishidden={isHidden} data-testid={`${dataTestIdPrefix}-split-view-right-panel`}>
      {children}
    </StyledRightPanel>
  );
};

const StyledRightPanel = styled('div')((props: { ishidden: boolean }) => ({
  flex: '1',
  height: '100%',
  display: props.ishidden ? 'none' : 'block',
}));
