import { CellEditingStoppedEvent, IRowNode } from 'ag-grid-community';
import {
  AssignmentDto,
  LinkedTonnageDto,
  CreateAndAssignResponseDto,
  UpdateCargoCommand,
  UpdateAssignmentCommand,
} from '../../../../../api/web-api-client';
import { AssignmentsGridColumn } from './useAssignmentsGrid';
import { useGridRefs } from '../../../../../contexts/GridRefsContext';
import { useAppDispatch, useAppSelector } from '../../../../../store/helpers';
import {
  addAndAssignTonnage,
  IDateRange,
  updateAssignment as updateAssignmentAPI,
  updateAssignmentCargo,
  updateTonnage as updateTonnageAPI,
} from '../../../../../store';
import { createLocationUpdateObject, createVesselAddObject, createVesselUpdateObject } from '../../../../../helpers/helpers';
import { TonnagesGridColumn } from '../../../tonnage/grid/hooks';
import { TonnagePatchProperty } from '../../../tonnage/grid/hooks/useTonnageGridEditing';
import {
  MixpanelEventAction,
  MixpanelEventCategory,
  MixpanelEventLabel,
  MixpanelEventLocation,
  MixpanelEventName,
  useMixpanel,
} from '../../../../../contexts/MixpanelContext';
import { ICustomRowData } from '../../../../common/grid/hooks/useDefaultGridOptions';
import { isRejected } from '@reduxjs/toolkit';
import { getUnsavedTonnageColumnsInRow, markCellAsUnsaved, unmarkCellAsUnsaved, updateCellAndRefresh } from '../../../helpers';

type CargoPatchProperty = keyof Omit<UpdateCargoCommand, 'cargoId'>;
type AssignmentPatchProperty = keyof Omit<UpdateAssignmentCommand, 'assignmentId'>;

export const useAssignmentsGridEditing = () => {
  const { assignmentsGridRef: gridRef, tonnagesGridRef } = useGridRefs();
  const currentPlan = useAppSelector((state) => state.plans.currentPlan);
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel();

  const updateCell = (node: IRowNode<ICustomRowData<AssignmentDto>>, column: string, newValue: any, dateRange: IDateRange) => {
    unmarkCellAsUnsaved(gridRef, node, column);

    if (column.includes('tonnage.')) {
      const propertyToPatch = column.split('.')[1] as TonnagePatchProperty;
      const tonnageId = node.data?.tonnage?.id;

      if (tonnageId) {
        const tonnageNode = tonnagesGridRef?.current?.api.getRowNode(tonnageId.toString());

        if (tonnageNode) {
          unmarkCellAsUnsaved(tonnagesGridRef, tonnageNode, propertyToPatch);
        }
        updateTonnage(newValue, propertyToPatch, node, tonnageNode);
      } else {
        addTonnage(propertyToPatch, node, dateRange, newValue);
      }
    } else if (column.includes('cargo.')) {
      updateCargo(column, node, newValue);
    } else {
      updateAssignment(column, node, newValue);
    }
  };

  const onCellEditingStopped = async (event: CellEditingStoppedEvent<AssignmentDto>, dateRange: IDateRange) => {
    if (event.valueChanged) {
      const column = event.column.getColId();

      updateCell(event.node, column, event.newValue, dateRange);
    }
  };

  async function updateCargo(column: string, node: IRowNode<ICustomRowData<AssignmentDto>>, newValue: any) {
    const cargoId = node.data?.cargo?.id;
    if (!cargoId) {
      return;
    }

    const propertyToPatch = column.split('.')[1] as CargoPatchProperty;
    const isLocationColumn = propertyToPatch === 'loadLocation' || propertyToPatch === 'dischargeLocation';

    const payload = {
      cargoId,
      [propertyToPatch]: {
        ...(isLocationColumn && createLocationUpdateObject(newValue?.name, newValue?.id)),
        ...(!isLocationColumn && { newValue }),
      },
    };

    const result = await dispatch(updateAssignmentCargo(payload));

    if (isRejected(result)) {
      markCellAsUnsaved(gridRef, node, column);
    }
  }

  async function updateAssignment(column: string, node: IRowNode<ICustomRowData<AssignmentDto>>, newValue: any) {
    const assignmentId = node.data?.id;

    if (!assignmentId) {
      return;
    }

    const propertyToPatch = column as AssignmentPatchProperty;

    const result = await dispatch(
      updateAssignmentAPI({
        assignmentId: assignmentId,
        [propertyToPatch]: {
          newValue,
        },
      })
    );

    if (isRejected(result)) {
      markCellAsUnsaved(gridRef, node, column);
    }
  }

  const addTonnageToGrid = (node: IRowNode<ICustomRowData<AssignmentDto>>, tonnage: LinkedTonnageDto) => {
    tonnagesGridRef.current?.api.applyTransaction({ add: [tonnage] });

    if (node.data) {
      node.data.tonnage = tonnage;
      updateCellAndRefresh(gridRef, node, AssignmentsGridColumn.Options);
    }
  };

  async function updateTonnage(
    newValue: any,
    column: TonnagePatchProperty,
    cargoAssignmentNode: IRowNode<ICustomRowData<AssignmentDto>>,
    tonnageNode?: IRowNode<ICustomRowData<LinkedTonnageDto>>
  ) {
    const tonnageId = cargoAssignmentNode.data?.tonnage?.id;
    if (tonnageNode) {
      tonnageNode.data = { ...tonnageNode.data, [column]: newValue };
      updateCellAndRefresh(tonnagesGridRef, tonnageNode, column);
    }

    const isVesselColumn = column === TonnagesGridColumn.Vessel;

    const payload = {
      tonnageId: tonnageId!,
      [column]: {
        ...(isVesselColumn && createVesselUpdateObject(newValue?.name, newValue?.seaId)),
        ...(!isVesselColumn && { newValue }),
      },
    };

    const result = await dispatch(updateTonnageAPI(payload));

    if (isRejected(result)) {
      markCellAsUnsaved(gridRef, cargoAssignmentNode, 'tonnage.' + column);
      tonnageNode && markCellAsUnsaved(tonnagesGridRef, tonnageNode, column);
    }
  }

  async function addTonnage(column: string, node: IRowNode<ICustomRowData<AssignmentDto>>, dateRange: IDateRange, newValue: any) {
    const assignmentId = node.data?.id;

    const laycan = {
      from: dateRange.from.toISOString(),
      to: dateRange.to.toISOString(),
    };
    const isVesselColumn = column === TonnagesGridColumn.Vessel;

    const payload = {
      assignmentId: assignmentId!,
      planId: currentPlan!.id!,
      sourceLaycan: column === 'laycan' ? newValue : laycan,

      [column]: isVesselColumn
        ? {
            ...createVesselAddObject(newValue?.name, newValue?.seaId),
          }
        : newValue,
    };

    const response = await dispatch(addAndAssignTonnage(payload));
    const data = response.payload as CreateAndAssignResponseDto;

    if (!isRejected(response)) {
      const tonnage: ICustomRowData<LinkedTonnageDto> = {
        ...node.data?.tonnage,
        id: data.tonnageId,
        linkedAssignmentsIds: [data.assignmentId!],
        unsavedColumnsNames: getUnsavedTonnageColumnsInRow(node), // It's important to copy unsaved columns from assignment to tonnage when creating tonnage from assignment fails
        [column]: newValue,
      };

      addTonnageToGrid(node, tonnage);
    } else {
      if (node.data) {
        node.data.tonnage = { ...node.data.tonnage, [column]: newValue };
      }
      markCellAsUnsaved(gridRef, node, 'tonnage.' + column);
    }

    trackEvent(MixpanelEventCategory.INTERACTION, {
      event: MixpanelEventName.ADD_TONNAGE_FROM_CARGO_ASSIGNMENT,
      eventAction: MixpanelEventAction.ENTER_TEXT,
      eventLabel: MixpanelEventLabel.GRID_CELL_EDITOR,
      eventLocation: MixpanelEventLocation.CARGO_ASSIGNMENT_GRID,
      eventVersion: 1.1,
      cargoId: node.data?.cargo?.id,
      tonnageId: data?.tonnageId,
    });
  }

  return { onCellEditingStopped, updateCell };
};
