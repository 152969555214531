import { IRowNode } from 'ag-grid-community';
import { AssignmentDto } from '../../../../api/web-api-client';
import { ISelectItem } from '../../../common/grid/SelectCellEditor';
import { sortWithEmptyLast } from '../../../common/grid/gridComparators';
import { incoterms } from '../../../common/Constants';

export const assignmentStatusComparator = (
  _valueA: unknown,
  _valueB: unknown,
  nodeA: IRowNode<AssignmentDto>,
  nodeB: IRowNode<AssignmentDto>
) => {
  const statusA = nodeA.data?.tonnage?.id ? 1 : 0;
  const statusB = nodeB.data?.tonnage?.id ? 1 : 0;
  return statusA - statusB;
};

export const incotermComparator = (
  valueA: number | undefined,
  valueB: number | undefined,
  rowNodeA: IRowNode,
  rowNodeB: IRowNode,
  isInverted: boolean
) => {
  const foundIncotermA = incoterms.find((incoterm: ISelectItem) => incoterm.id === valueA);
  const foundIncotermB = incoterms.find((incoterm: ISelectItem) => incoterm.id === valueB);
  return sortWithEmptyLast(foundIncotermA?.name, foundIncotermB?.name, rowNodeA, rowNodeB, isInverted);
};
