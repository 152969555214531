import { Button, styled, Typography } from '@mui/material';
import { useAuth } from '../contexts';

const StyledContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '20px',
});

interface ICreatePlanView {
  openCreatePlanModal: () => void;
}

const NO_PLANS_INFO = "You don't have any plans yet";

const CreatePlanView: React.FC<ICreatePlanView> = ({ openCreatePlanModal }) => {
  const { hasWriteRights } = useAuth();

  return (
    <StyledContainer>
      <Typography variant="h5" fontWeight={700}>
        {NO_PLANS_INFO}
      </Typography>
      {hasWriteRights && (
        <Button id="button-create-a-plan" variant="contained" onClick={openCreatePlanModal}>
          Create a plan
        </Button>
      )}
    </StyledContainer>
  );
};

export default CreatePlanView;
