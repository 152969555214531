import { Button, ClickAwayListener, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/system';

import { PropsWithChildren } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import { grey, neutral } from '../../../themes/palette';
import { useKeyDownEventListener } from '../../../hooks/useKeyDownEventListener';
import { KeyNames } from '../Constants';

interface IStyledDropdownBtn {
  isOpen?: boolean;
}

export interface ICustomSelectBox {
  id: string;
  isOpen?: boolean;
  isTooltipOpen?: boolean;
  hideArrowBtn?: boolean;
  mainButtonContent: React.ReactNode;
  toggleable?: boolean;
  tooltipMsg?: string;
  closeTooltip?: () => void;
  onClickHandler: (color?: string) => void;
  setAnchorEl?: (el: Element) => void;
  setIsOpen: (isOpen: boolean) => void;
}

const styledProps = {
  shouldForwardProp: (prop: unknown) => prop !== 'isOpen' && prop !== 'hideArrowBtn' && prop !== 'toggleable',
};

const StyledMainContainer = styled('div')({
  position: 'relative',
});

const StyledDropdownBtn = styled(
  IconButton,
  styledProps
)<IStyledDropdownBtn>(({ isOpen }) => ({
  width: '24px',
  backgroundColor: isOpen ? neutral.n200 : grey.g1,
  borderRadius: 0,
}));

interface IContainer {
  hideArrowBtn: boolean;
}
const Container = styled(
  'div',
  styledProps
)<IContainer>(({ hideArrowBtn }) => ({
  display: 'flex',
  height: '40px',
  width: hideArrowBtn ? '40px' : '65px',
  marginRight: '16px',
  borderRadius: '8px',
  overflow: 'hidden',
}));

interface IStyledActionBtn {
  hideArrowBtn?: boolean;
  toggleable?: boolean;
  onClick: (props?: unknown) => void;
}

const StyledActionBtn = styled(
  Button,
  styledProps
)<IStyledActionBtn>(({ hideArrowBtn, toggleable }) => ({
  minWidth: 0,
  padding: 0,
  margin: 0,
  height: '100%',
  borderRadius: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  backgroundColor: toggleable && !hideArrowBtn ? grey.g8 : grey.g1,
  borderRight: toggleable || hideArrowBtn ? 'none' : `1px solid ${grey.g8}`,
  cursor: 'pointer',
}));

const StyledArrowDropDown = styled(
  ArrowDropDown,
  styledProps
)<IStyledDropdownBtn>(({ isOpen }) => ({
  transform: isOpen ? 'rotate(180deg)' : '',
}));

export const CustomSelectBox: React.FC<PropsWithChildren<ICustomSelectBox>> = ({
  children,
  id,
  isOpen,
  isTooltipOpen,
  hideArrowBtn = false,
  mainButtonContent,
  tooltipMsg,
  toggleable,
  closeTooltip,
  onClickHandler,
  setAnchorEl,
  setIsOpen,
}) => {
  const toggleIsOpen = (e: React.MouseEvent) => {
    if (setAnchorEl) {
      setAnchorEl(e.currentTarget ?? null);
    }
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useKeyDownEventListener(KeyNames.ESCAPE, closeMenu);

  return (
    <StyledMainContainer>
      <Container hideArrowBtn={hideArrowBtn}>
        <Tooltip title={tooltipMsg} open={isTooltipOpen} onClose={closeTooltip} disableFocusListener disableTouchListener>
          <StyledActionBtn
            data-testid={`${id}-action-button`}
            onClick={() => onClickHandler()}
            hideArrowBtn={hideArrowBtn}
            toggleable={toggleable}
          >
            {mainButtonContent}
          </StyledActionBtn>
        </Tooltip>
        {!hideArrowBtn && (
          <StyledDropdownBtn data-testid={`${id}-dropdown-button`} onClick={toggleIsOpen} isOpen={isOpen}>
            <StyledArrowDropDown isOpen={isOpen} />
          </StyledDropdownBtn>
        )}
      </Container>
      {isOpen && (
        <ClickAwayListener onClickAway={closeMenu}>
          <div>{children}</div>
        </ClickAwayListener>
      )}
    </StyledMainContainer>
  );
};
