import { AssignmentsGridColumn } from './useAssignmentsGrid';
import { incoterms } from '../../../../common/Constants';
import { formatDate, formatDateRange } from '../../../../../helpers/dateHelpers';
import { AgGridReact } from 'ag-grid-react';
import { getProperty, isEmptyObject } from '../../../../../helpers/helpers';
import { AssignmentDto } from '../../../../../api/web-api-client';
import { isDateCell, isLaycanCell, isLocationCell, isVesselCell } from './helpers';

export const useCopyToClipboard = (gridRef: React.MutableRefObject<AgGridReact | null>) => {
  const ASSIGNMENT_COLUMNS = [
    AssignmentsGridColumn.Id,
    AssignmentsGridColumn.CargoRefId,
    AssignmentsGridColumn.CargoIncoterm,
    AssignmentsGridColumn.CargoCustomer,
    AssignmentsGridColumn.CargoLoadLocation,
    AssignmentsGridColumn.CargoDischargeLocation,
    AssignmentsGridColumn.CargoType,
    AssignmentsGridColumn.CargoLaycan,
    AssignmentsGridColumn.CargoQuantity,
    AssignmentsGridColumn.TargetEta,
    AssignmentsGridColumn.Notes,
  ];
  const TONNAGE_COLUMNS = [
    AssignmentsGridColumn.TonnageLaycan,
    AssignmentsGridColumn.TonnageContractType,
    AssignmentsGridColumn.TonnageVessel,
    AssignmentsGridColumn.TonnageEta,
    AssignmentsGridColumn.TonnageEtb,
    AssignmentsGridColumn.TonnageRefId,
  ];

  const getColumnsToCopy = (rowData: AssignmentDto): AssignmentsGridColumn[] => {
    const isLinkedWithTonnage = !!rowData.tonnage;
    const columnsToCopy = [...ASSIGNMENT_COLUMNS, ...(isLinkedWithTonnage ? TONNAGE_COLUMNS : [])];
    return columnsToCopy;
  };

  const getFormattedCellValue = (rowData: AssignmentDto, columnName: AssignmentsGridColumn): string => {
    const value = getProperty(rowData, columnName);

    if (!value || isEmptyObject(value)) {
      return '';
    }

    if (columnName === AssignmentsGridColumn.CargoIncoterm) {
      const foundIncoterm = incoterms.find((incoterm) => incoterm.id === value);
      return foundIncoterm?.name ?? '';
    }
    if (isLocationCell(columnName) && !!value) {
      return value.name ?? '';
    }
    if (isLaycanCell(columnName) && !!value && value.from && value.to) {
      return formatDateRange(value, { showYear: true });
    }
    if (isDateCell(columnName) && !!value) {
      return formatDate(value) ?? '';
    }
    if (isVesselCell(columnName) && !!value && value.name) {
      const vesselNamePart = value.name ?? '';
      const vesselImoPart = value.imo ? ` (IMO ${value.imo})` : '';
      return `${vesselNamePart}${vesselImoPart}`;
    }
    if (columnName === AssignmentsGridColumn.CargoQuantity && typeof value === 'number') {
      return `${value.toLocaleString('en-US')} MT`;
    }

    return value;
  };

  const getDataToCopy = (rowId: string): string[] => {
    const rowData = gridRef?.current?.api.getRowNode(rowId)?.data;
    const displayedColumns = gridRef.current?.columnApi.getAllDisplayedColumns();
    const columnsToCopy = getColumnsToCopy(rowData);

    return (
      displayedColumns
        ?.filter((column) => columnsToCopy.includes(column.getColId() as AssignmentsGridColumn))
        .map((column) => {
          const columnName = column.getColId() as AssignmentsGridColumn;
          const columnHeaderName = column.getColDef().headerName;
          const formattedCellValue = getFormattedCellValue(rowData, columnName);
          return `${columnHeaderName}: ${formattedCellValue}`;
        }) ?? []
    );
  };

  const getTextToCopy = (rowId: string): string => {
    const dataToCopy = getDataToCopy(rowId);
    const dataPart = dataToCopy.join('\n');
    const separatorPart = '\n\n';
    const footerPart = 'Exported from Sea Freight Planner – https://sea.live';
    return `${dataPart}${separatorPart}${footerPart}`;
  };

  const copyToClipboard = async (rowId: string) => {
    const textToCopy = getTextToCopy(rowId);
    await navigator.clipboard.writeText(textToCopy);
  };

  return { copyToClipboard };
};
