import { useAppSelector } from '../../../../store/helpers';
import { forwardRef, memo, useImperativeHandle, useMemo, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { searchLocations, resetState } from '../../../../store/slices/locations.slice';
import { LocationDto } from '../../../../api/web-api-client';
import { isValidForEditorChar } from '../helpers';
import { InfoContentType } from '../SearchEditor/components/InfoContent/InfoContent';
import { SearchEditor } from '../SearchEditor/SearchEditor';
import { RenderLocationOption } from './RenderLocationOption';

interface ILocationSearchEditorCellEditorParams extends ICellEditorParams {
  testIdPrefix: string;
  maxLength: number;
}

export const LocationSearchEditor = memo(
  forwardRef((props: ILocationSearchEditorCellEditorParams, ref) => {
    const initialState = useMemo(() => {
      let startValue: LocationDto | null = null;
      let startInputValue = '';

      if (isValidForEditorChar(props.eventKey)) {
        startInputValue = props.eventKey!;
      } else {
        startValue = props?.value ?? null;
        startInputValue = props?.value?.name ?? '';
      }

      return {
        value: startValue,
        inputValue: startInputValue,
      };
    }, []);

    const [editorValue, setEditorValue] = useState<LocationDto | null>(initialState.value);
    const { locations, status } = useAppSelector((state) => state.locations);

    const messages = {
      userTip: 'Enter 3 characters of port name...',
      optionNotLinkedMessageTitle: 'Entry is not linked to our location database',
      optionNotLinkedMessageDescription: 'Location-based information will be unavailable',
      loader: 'Loading ports...',
      selectOption: 'Select a port...',
      infoContent: {
        [InfoContentType.cannotFindMatch]: {
          main: 'Sorry, we couldn’t find a match',
          details: 'Please try a different port name',
        },
        [InfoContentType.cannotConnectToDb]: {
          main: 'Sorry, we couldn’t connect to \n our locations database',
          details: 'Please try again later or contact\x20',
        },
      },
    };

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return editorValue?.name ? editorValue : undefined;
        },
      };
    });

    return (
      <SearchEditor<LocationDto>
        editorValue={editorValue}
        setEditorValue={setEditorValue}
        searchedOptions={locations}
        searchOptions={searchLocations}
        searchOptionsStatus={status.searchLocations}
        resetSearchedOptions={resetState}
        initialEditorState={initialState}
        mainUiOptionKey="name"
        optionIdentifierKey="id"
        messages={messages}
        renderOption={RenderLocationOption}
        {...props}
      />
    );
  })
);
