import { styled } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../../../../../store/helpers';

interface IDuplicateCargoesDialogDescription {
  monthDifference: number;
}

export const DuplicateCargoesDialogDescription: React.FC<IDuplicateCargoesDialogDescription> = ({ monthDifference }) => {
  const selectedRows = useAppSelector((state) => state.assignments.selectedRows);
  const isOneCargo = selectedRows.size && selectedRows.size === 1;

  const duplicateCargoDescription = `Cargo details will be copied to ${isOneCargo ? 'a ' : ''}new cargo assignment${
    isOneCargo ? '' : 's'
  }.`;
  const duplicateCargoToNewLaycanDescription = `Cargo Laycan${isOneCargo ? '' : 's'} will be updated in the new cargo${
    isOneCargo ? '' : 'es'
  }.`;

  return (
    <StyledContainer>
      <div>{duplicateCargoDescription}</div>
      {monthDifference !== 0 && <div>{duplicateCargoToNewLaycanDescription}</div>}
    </StyledContainer>
  );
};

const StyledContainer = styled('div')({
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '150%',
  marginTop: '16px',
});
