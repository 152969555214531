import { createTheme } from '@mui/material';

import { MuiButton } from './styleOverrides/MuiButton';
import { MuiDialogComponents } from './styleOverrides/MuiDialog';
import { MuiTooltip } from './styleOverrides/MuiTooltip';
import { MuiBaseline } from './styleOverrides/MuiBaseline';

const themeBase = createTheme({
  typography: {
    fontFamily: ['Inter'].join(','),
    fontSize: 16,

    h5: {
      fontSize: '24px',
      fontWeight: '700',
      letterSpacing: '-0.02em',
      lineHeight: '32px',
    },
    h6: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '32px',
    },
  },
  components: {
    ...MuiDialogComponents,
    MuiButton: MuiButton,
    MuiTooltip: MuiTooltip,
    MuiCssBaseline: MuiBaseline,
  },
});

export default themeBase;
