import { Alert, AlertTitle, Button, Snackbar, styled } from '@mui/material';
import { ReactComponent as SuccessIcon } from '../../../../../assets/SuccessCircleIcon.svg';
import React, { useEffect, useState } from 'react';
import { IDateRange } from '../../../../../store';
import { REQUEST_STATUS, useAppSelector } from '../../../../../store/helpers';
import * as Num2Words from 'number-to-words';
import { neutral } from '../../../../../themes/palette';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { capitalize } from '../../../../../helpers/helpers';
dayjs.extend(utc);

interface IDuplicateCargoesNotification {
  dateRange: IDateRange;
  notificationAction: () => void;
  monthDifference: number;
  open: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const DuplicateCargoesNotification: React.FC<IDuplicateCargoesNotification> = ({
  dateRange,
  notificationAction,
  monthDifference,
  open,
  setIsOpen,
}) => {
  const [title, setTitle] = useState<string>('');
  const selectedRows = useAppSelector((state) => state.assignments.selectedRows);
  const requestStatus = useAppSelector((state) => state.assignments.status.duplicateAssignmentCargoes);

  const getMessage = (): string => {
    const cargoIds = Array.from(selectedRows);
    const cargoesNumberText = Num2Words.toWords(cargoIds.length);
    const dateText = dateRange.from.format('MMMM YYYY').toString();
    return `${cargoesNumberText} ${cargoIds.length === 1 ? 'cargo' : 'cargoes'} added to ${dateText}`;
  };

  useEffect(() => {
    if (requestStatus === REQUEST_STATUS.fulfilled) {
      setTitle(getMessage());
      setIsOpen(true);
    }
  }, [requestStatus]);

  return (
    <Snackbar data-testid="snackbar-notification" autoHideDuration={6000} open={open} onClose={() => setIsOpen(false)}>
      <Alert
        severity="success"
        icon={<SuccessIcon data-testid="snackbar-success-icon-duplicate-cargoes" />}
        sx={{ position: 'fixed' }}
      >
        <AlertTitle>
          {capitalize(title)}
          {!!monthDifference && (
            <StyledButton onClick={notificationAction} data-testid="button-snackbar-view-duplicate-cargoes">
              VIEW
            </StyledButton>
          )}
        </AlertTitle>
      </Alert>
    </Snackbar>
  );
};

const StyledButton = styled(Button)({
  minWidth: 'initial',
  height: '100%',
  padding: '4px 5px',
  fontWeight: '600',
  fontSize: '13px',
  lineHeight: '16px',
  letterSpacing: '0.46px',
  color: neutral.n100,
  marginLeft: '16px',
});
