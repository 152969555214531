import { styled } from '@mui/material';
import { grey } from '../../../themes/palette';

export const DIVIDER_WIDTH = 8;

interface IDividerProps {
  onMouseDown: React.MouseEventHandler<HTMLDivElement>;
  onTouchStart: React.TouchEventHandler<HTMLDivElement>;
  onTouchEnd: React.TouchEventHandler<HTMLDivElement>;
  isHidden: boolean;
  dataTestIdPrefix: string;
}

export const Divider: React.FC<IDividerProps> = (props) => {
  const { onMouseDown, onTouchStart, onTouchEnd, isHidden, dataTestIdPrefix } = props;

  return (
    <StyledContainer
      data-testid={`${dataTestIdPrefix}-split-view-divider`}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      ishidden={isHidden}
    >
      <StyledDivider />
      <StyledHandle data-testid={`${dataTestIdPrefix}-split-view-handle`} />
    </StyledContainer>
  );
};

const StyledContainer = styled('div')((props: { ishidden: boolean }) => ({
  width: `${DIVIDER_WIDTH}px`,
  height: '100%',
  position: 'relative',
  display: props.ishidden ? 'none' : 'flex',
  justifyContent: 'center',
  cursor: 'col-resize',
}));

const StyledDivider = styled('div')({
  width: '2px',
  height: '100%',
  backgroundColor: grey.g8,
});

const StyledHandle = styled('div')({
  width: '100%',
  height: '60px',
  backgroundColor: grey.g8,
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%',
});
