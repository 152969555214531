import { Menu, MenuItem, styled } from '@mui/material';
import { blue } from '../../../../../themes/palette';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/helpers';
import { setIsOpenDuplicateBatchOfCargoesDialog, showNotificationSnackbar } from '../../../../../store';

interface IBatchActionsMenu {
  isOpen: boolean;
  anchorEl: Element | null;
  onClose: () => void;
}

export const StyledAddIcon = styled(AddCircleOutlineOutlinedIcon)({
  height: '24px',
  width: '24px',
  marginRight: '14px',
  fillOpacity: 0.54,
});

const StyledMenuItem = styled(MenuItem)({
  maxWidth: '100% ',
  height: '100%',
  fontSize: '14px',
  padding: '11px 16px 11px 17px',
  margin: 0,
  ':hover': {
    backgroundColor: blue.b10,
  },
});

export const BatchActionsMenu: React.FC<IBatchActionsMenu> = ({ isOpen, anchorEl, onClose }) => {
  const dispatch = useAppDispatch();
  const shouldOpenDialog = useAppSelector((state) => state.assignments.selectedRows.size);

  const duplicateCargoesHandler = () => {
    if (shouldOpenDialog) {
      dispatch(setIsOpenDuplicateBatchOfCargoesDialog(true));
    } else {
      dispatch(showNotificationSnackbar({ title: 'Please select at least one cargo.', type: 'error' }));
    }
    onClose();
  };

  return (
    <Menu
      data-testid="batch-actions-menu"
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          marginTop: '4px',
          width: '210px',
          borderRadius: '8px',
          boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2)',
        },
      }}
      MenuListProps={{
        sx: { paddingTop: 'unset', paddingBottom: 'unset' },
      }}
    >
      <StyledMenuItem onClick={duplicateCargoesHandler} data-testid="duplicate-cargoes-action">
        <StyledAddIcon />
        Duplicate cargoes
      </StyledMenuItem>
    </Menu>
  );
};
