import { UserManager } from './UserManager';
import axios, { AxiosInstance, CreateAxiosDefaults } from 'axios';
import { UserManager as OidcUserManager } from 'oidc-client-ts';
import {
  AssignmentsClient,
  CargosClient,
  GridConfigurationsClient,
  LocationsClient,
  PlansClient,
  TonnagesClient,
  VesselsClient,
} from '../api/web-api-client';
export interface IAppSettings {
  apiUrl: string;
  identityServerStsAuthority: string;
  identityServerClientId: string;
  identityServerScope: string;
  headerCdnUrl: string;
  appInsightsConnectionString: string;
  mixpanelTrackingEnabled: boolean;
  seaNetBaseUrl: string;
}

export class ApiManager {
  private axiosOptions: CreateAxiosDefaults = {
    headers: {
      'Content-Type': 'application/json',
    },
    /*
      transformResponse function by default is calling JSON.parse(data) on response
      and works like this (https://github.com/axios/axios/blob/b4c5d35d2875191dfa8c3919d4227dce8e2ad23f/lib/defaults.js#L58-L66)

      changed it by removing JSON.parse(data) call
      because src/ui/src/api/web-api-client.ts is calling this one by his own and it was causing errors
    */ transformResponse: (data) => data,
  };
  public axiosInstance: AxiosInstance;
  public assignmentsAPI: AssignmentsClient;
  public cargosAPI: CargosClient;
  public tonnagesAPI: TonnagesClient;
  public plansAPI: PlansClient;
  public oidcUserManager: OidcUserManager;
  public gridConfigurationAPI: GridConfigurationsClient;
  public vesselsAPI: VesselsClient;
  public locationsAPI: LocationsClient;

  constructor(appSettings: IAppSettings) {
    const userManager = new UserManager(appSettings);
    this.oidcUserManager = userManager.oidcUserManager;

    this.axiosInstance = axios.create(this.axiosOptions);
    this.assignmentsAPI = new AssignmentsClient(appSettings.apiUrl, this.axiosInstance);
    this.cargosAPI = new CargosClient(appSettings.apiUrl, this.axiosInstance);
    this.tonnagesAPI = new TonnagesClient(appSettings.apiUrl, this.axiosInstance);
    this.plansAPI = new PlansClient(appSettings.apiUrl, this.axiosInstance);
    this.gridConfigurationAPI = new GridConfigurationsClient(appSettings.apiUrl, this.axiosInstance);
    this.vesselsAPI = new VesselsClient(appSettings.apiUrl, this.axiosInstance);
    this.locationsAPI = new LocationsClient(appSettings.apiUrl, this.axiosInstance);

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalConfig = error.config;
        if (error.response) {
          if (error.response.status === 401 && !originalConfig._retry) {
            try {
              await this.oidcUserManager.signinSilent();
            } catch {
              await this.oidcUserManager.signoutRedirect();
            } finally {
              originalConfig._retry = true;
            }
            return this.axiosInstance(originalConfig);
          }
        }
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.request.use(async (request) => {
      const user = await this.oidcUserManager.getUser();
      let token: string | undefined = undefined;
      if (user && user.access_token && request.headers) {
        token = user.access_token;
      } else if (user) {
        try {
          const renewedUser = await this.oidcUserManager.signinSilent();
          if (renewedUser && request.headers) {
            token = renewedUser.access_token;
          }
        } catch {
          await this.oidcUserManager.signoutRedirect();
        }
      }
      if (request.headers && token) {
        request.headers.Authorization = `Bearer ${token}`;
      }
      return request;
    });
  }
}
