import { CreateTonnageCommand, UpdateTonnageCommand, UpdateFormattingDto } from '../../api/web-api-client';
import { addAbortSignalListener, REQUEST_STATUS } from '../helpers';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiManager } from '../../contexts/ApiManager';
import { ErrorCounterType, incrementErrorCounter } from './errors.slice';

export enum TonnagesActions {
  GET_TONNAGES = 'getTonnages',
  ADD_TONNAGE = 'addTonnage',
  UPDATE_TONNAGE = 'updateTonnage',
  REMOVE_TONNAGE = 'removeTonnage',
  UPDATE_FORMATTING_METADATA = 'updateFormattingMetadata',
}

export type TonnagesState = {
  status: {
    [key in TonnagesActions]?: REQUEST_STATUS;
  };
};

export const tonnagesInitialState: TonnagesState = {
  status: {},
};

export interface IGetTonnagesPayload {
  dateRangeFrom?: string;
  dateRangeTo?: string;
  planId?: number;
}

export interface IAddTonnagePayload extends CreateTonnageCommand {}
export interface IUpdateTonnagePayload extends UpdateTonnageCommand {}
export interface IUpdateFormattingMetadataPayload extends UpdateFormattingDto {}

export const getTonnages = createAsyncThunk(
  TonnagesActions.GET_TONNAGES,
  async (payload: IGetTonnagesPayload, { signal, getState }) => {
    addAbortSignalListener(signal);
    try {
      const { dateRangeFrom, dateRangeTo, planId } = payload;
      const state = (await getState()) as { api: { apiManager: ApiManager } };

      const response = await state.api.apiManager.tonnagesAPI.getTonnages(dateRangeFrom, dateRangeTo, planId);

      return response.tonnages ?? [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const addTonnage = createAsyncThunk(
  TonnagesActions.ADD_TONNAGE,
  async (payload: IAddTonnagePayload, { signal, getState }) => {
    addAbortSignalListener(signal);
    try {
      const state = (await getState()) as { api: { apiManager: ApiManager } };
      const response = await state.api.apiManager.tonnagesAPI.create(payload);
      return {
        id: response,
        formatting: null,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const updateTonnage = createAsyncThunk(
  TonnagesActions.UPDATE_TONNAGE,
  async (payload: IUpdateTonnagePayload, { signal, getState, dispatch, rejectWithValue }) => {
    addAbortSignalListener(signal);
    try {
      const { tonnageId, ...patchProperties } = payload;
      const state = (await getState()) as { api: { apiManager: ApiManager } };

      return await state.api.apiManager.tonnagesAPI.update({
        tonnageId,
        ...patchProperties,
      });
    } catch (error) {
      dispatch(incrementErrorCounter(ErrorCounterType.editingCellErrorCounter));
      return rejectWithValue(error);
    }
  }
);

export const removeTonnage = createAsyncThunk(TonnagesActions.REMOVE_TONNAGE, async (payload: number, { signal, getState }) => {
  addAbortSignalListener(signal);
  try {
    const state = (await getState()) as { api: { apiManager: ApiManager } };
    return await state.api.apiManager.tonnagesAPI.delete(payload);
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const updateTonnagesFormattingMetadata = createAsyncThunk(
  TonnagesActions.UPDATE_FORMATTING_METADATA,
  async (payload: IUpdateFormattingMetadataPayload, { signal, getState, rejectWithValue }) => {
    const source = addAbortSignalListener(signal);

    try {
      const state = (await getState()) as { api: { apiManager: ApiManager } };

      return state.api.apiManager.tonnagesAPI.updateFormattingMetadata(payload, source.token);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const tonnagesSlice = createSlice({
  name: 'tonnages',
  initialState: tonnagesInitialState,
  reducers: {},
  extraReducers(builder) {},
});

export default tonnagesSlice.reducer;
