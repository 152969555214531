import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { AuthContextProps, AuthProvider as OidcAuthProvider, useAuth as useOidcAuth } from 'oidc-react';
import { User, UserManager as OidcUserManager } from 'oidc-client-ts';
import jwt_decode from 'jwt-decode';

interface IAuthContext extends Partial<AuthContextProps> {
  isLoggedIn: boolean;
  user: User | null;
  setUser: (user: User) => void;
  isAuthorized: (roles: UserRoles[]) => boolean;
  hasWriteRights: boolean;
}

export enum UserRoles {
  View = 'FreightPlanner.View',
  Admin = 'FreightPlanner.Admin',
  Write = 'FreightPlanner.Write',
}

const initialState = {
  isLoggedIn: false,
  user: null,
  setUser: () => {},
  isAuthorized: (roles: UserRoles[]) => false,
  hasWriteRights: false,
};

export const AuthContext = createContext<IAuthContext>(initialState);

interface IAuthContextProvider {
  userManager: OidcUserManager;
  children: React.ReactNode;
}

const AuthContextProvider: React.FC<IAuthContextProvider> = ({ children }) => {
  const auth = useOidcAuth();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [roles, setRoles] = useState<UserRoles[]>([]);

  const updateRoles = (token: string) => {
    const decodedToken = jwt_decode(token) as object;

    if (!('permissions' in decodedToken)) return;
    const userRoles = decodedToken['permissions'];

    if (Array.isArray(userRoles)) {
      setRoles(userRoles);
    } else if (typeof userRoles === 'string') {
      setRoles([userRoles as UserRoles]);
    }
  };

  const isAuthorized = (userRoles: UserRoles[]) => {
    return userRoles.some((role) => !!role && roles.includes(role));
  };

  const hasWriteRights = isAuthorized([UserRoles.Write]);

  useEffect(() => {
    if (user) {
      setIsLoggedIn(true);
      updateRoles(user.access_token);
    }
  }, [user]);

  const contextValue = {
    ...auth,
    isLoggedIn: isLoggedIn,
    user,
    setUser,
    isAuthorized,
    hasWriteRights,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

interface IAuthProvider {
  userManager: OidcUserManager;
}

export const AuthProvider: React.FC<PropsWithChildren<IAuthProvider>> = ({ children, userManager }) => {
  return (
    <OidcAuthProvider userManager={userManager} onSignIn={() => window.location.replace('/')}>
      <AuthContextProvider userManager={userManager}>{children}</AuthContextProvider>
    </OidcAuthProvider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('Component beyond AuthContext');
  }

  return context;
}
