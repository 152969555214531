import { Tab, Tabs, Box, Popover, PopoverProps, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { CalendarMonthView } from './CalendarMonthView/CalendarMonthView';
import { CustomRangeView } from './CustomRangeView/CustomRangeView';
import { IDateRange } from '../../../../store';
import { grey } from '../../../../themes/palette';
import { TabPanel } from './TabPanel';
import { DASHBOARD_DATE_RANGE_TYPE } from '../PlanDateFilter';

interface IPlanDateFilterModalProps extends PopoverProps {
  dateRange: IDateRange;
  updateDateRange: (newRange: IDateRange) => void;
  onClose: () => void;
  dashboardDateRangeType: DASHBOARD_DATE_RANGE_TYPE;
}

export const PlanDateFilterModal: React.FC<IPlanDateFilterModalProps> = (props) => {
  const { dateRange, updateDateRange, onClose, dashboardDateRangeType, open, ...restProps } = props;
  const [view, setView] = useState(dashboardDateRangeType);

  useEffect(() => {
    if (open) {
      setView(dashboardDateRangeType);
    }
  }, [open]);

  const handleViewChange = (_: React.SyntheticEvent, newValue: number) => {
    setView(newValue);
  };

  return (
    <StyledPopover
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={onClose}
      open={open}
      {...restProps}
    >
      <Box data-testid="plandatefilter-modal">
        <StyledTabs centered value={view} onChange={handleViewChange}>
          <StyledTab label="Calendar Month" data-testid="plandatefilter-modal-button-month-view" />
          <StyledTab label="Custom Range" data-testid="plandatefilter-modal-button-custom-range-view" />
        </StyledTabs>

        <TabPanel value={view} index={DASHBOARD_DATE_RANGE_TYPE.MONTH}>
          <CalendarMonthView dateRange={dateRange} updateDateRange={updateDateRange} onClose={onClose} />
        </TabPanel>
        <TabPanel value={view} index={DASHBOARD_DATE_RANGE_TYPE.CUSTOM}>
          <CustomRangeView dateRange={dateRange} updateDateRange={updateDateRange} onClose={onClose} />
        </TabPanel>
      </Box>
    </StyledPopover>
  );
};

const StyledPopover = styled(Popover)({
  '.MuiPickersCalendarHeader-label': {
    fontSize: '16px',
    lineHeight: '24px',
  },
  '.MuiPickersMonth-monthButton': {
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const StyledTab = styled(Tab)({
  textTransform: 'initial',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 600,
  minWidth: '160px',
  borderBottom: '1px solid transparent',
  ':not(.Mui-selected)': {
    color: grey.g15,
    borderColor: 'transparent',
  },
});

const StyledTabs = styled(Tabs)({
  border: `1px solid ${grey.g8}`,
});
