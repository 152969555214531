import { AgGridReact } from 'ag-grid-react';
import { Button, Divider, styled } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import React, { useCallback, useEffect, useState } from 'react';
import { MonthPicker, MonthPickerSize } from '../../common';
import { AssignmentDto, CreateAssignmentCommand, LinkedTonnageDto } from '../../../api/web-api-client';
import { AssignmentsGridColumn, useAssignmentsGrid } from './grid';
import { grey, neutral } from '../../../themes/palette';
import { StyledGridContainer, StyledGridHeaderContainer, StyledGridName } from '../../common/grid/GridContainer';
import { GridFooter } from '../../common/grid/GridFooter/GridFooter';
import { useTonnageDrop } from './grid/hooks/useTonnageDrop';
import { ColumnEverythingChangedEvent, ColumnVisibleEvent, ProcessRowParams, RowDataUpdatedEvent } from 'ag-grid-community';
import { useGridRefs } from '../../../contexts/GridRefsContext';
import AssignmentConfirmations, { IAssignmentConfirmationsRef } from './grid/AssignmentConfirmations';
import { REQUEST_STATUS, useAppDispatch, useAppSelector } from '../../../store/helpers';
import {
  addAssignment,
  assignTonnage,
  deselectAllCargoes,
  getAssignments as getAssignmentsFromAPI,
  IDateRange,
  setAllRowsIds,
  toggleCustomisationDrawer,
  updateDateRange,
} from '../../../store';
import { GridId } from '../../common/grid/helpers';
import { ColorPicker } from '../../ColorPicker/ColorPicker';
import { ReactComponent as ForwardArrowIcon } from '../../../assets/forwardArrow.svg';
import { ReactComponent as ShipIcon } from '../../../assets/ship.svg';
import { BatchActionsSelectBox } from './batchActions/BatchActionsSelectBox';
import { useCellFormatting } from '../../common/grid/hooks/useCellFormatting/useCellFormatting';
import { ICustomRowData } from '../../common/grid/hooks/useDefaultGridOptions';
import { ReactComponent as DownloadIcon } from '../../../assets/downloadIcon.svg';
import { ButtonIcon } from '../../common/ButtonIcon';
import { useAuth } from '../../../contexts';
import {
  MixpanelEventAction,
  MixpanelEventCategory,
  MixpanelEventLabel,
  MixpanelEventLocation,
  MixpanelEventName,
  useMixpanel,
} from '../../../contexts/MixpanelContext';
import { PlanDateFilter } from '../../common/PlanDateFilter/PlanDateFilter';
import { FeatureFlagsEnum } from '../../../api/featureFlags';
import { useFeatureFlags } from '../../../contexts/FeatureFlagsContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export type AssignmentRowData = ICustomRowData<AssignmentDto>;

const StyledGridActionsContainer = styled('div')({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
});

interface IAssignmentDashboard {
  isTonnageHidden?: boolean;
  showTonnage?: () => void;
}

export const AssignmentsDashboard: React.FC<IAssignmentDashboard> = ({ isTonnageHidden, showTonnage }) => {
  const currentPlan = useAppSelector((state) => state.plans.currentPlan);
  const getGridconfigStatus = useAppSelector((state) => state.gridConfigurations.status);
  const addPlanStatus = useAppSelector((state) => state.plans.status.addPlan);
  const { rowDragEnter, rowDragEnterPinned } = useTonnageDrop(onDrop);
  const { assignmentsGridRef: gridRef, tonnagesGridRef } = useGridRefs();
  const { setCellClassRules } = useCellFormatting();
  const [rowData, setRowData] = useState<AssignmentRowData[] | null>(null);
  const confirmationsRef = React.useRef<IAssignmentConfirmationsRef>(null);
  const {
    autoWidthLastColumn,
    columnDefs,
    defaultColDef,
    onCellEditingStopped,
    getRowId,
    onCellFocused,
    onRowSelected,
    addEmptyRow,
    loadingOverlayComponent,
    excelStyles,
    noRowsOverlayComponent,
    updateTonnageDetails,
    updateTonnageLinkedAssignmentsIds,
    updateGridState,
    applyGridState,
    exportDataToExcel,
    rowClassRules,
  } = useAssignmentsGrid();
  const dispatch = useAppDispatch();
  const dateRange = useAppSelector((state) => state.dateRange.dateRange);
  const { hasWriteRights } = useAuth();
  const { trackEvent } = useMixpanel();
  const { isFeatureFlagActive } = useFeatureFlags();

  const customColumnsConfig = [
    {
      colId: AssignmentsGridColumn.BatchActionsSelect,
      hide: true,
    },
  ];

  const getAssignments = useCallback(async (dateRangeToFilterBy: IDateRange, planId?: number) => {
    const res = await dispatch(
      getAssignmentsFromAPI({
        dateRangeFrom: dateRangeToFilterBy.from.toString(),
        dateRangeTo: dateRangeToFilterBy.to.toString(),
        planId,
      })
    );
    const assignments = res.payload as AssignmentRowData[];

    if (!assignments?.length) {
      gridRef.current?.api?.showNoRowsOverlay();
      setTimeout(() => {
        setRowData([]);
      }, 0);
      return;
    }

    setRowData(assignments);
  }, []);

  useEffect(() => {
    if (currentPlan) {
      gridRef.current?.api?.showLoadingOverlay();
      getAssignments(dateRange, currentPlan.id);
    }
  }, [dateRange, currentPlan]);

  useEffect(() => {
    if (gridRef.current?.columnApi && getGridconfigStatus.getGridConfiguration === REQUEST_STATUS.fulfilled) {
      applyGridState(gridRef.current.columnApi, customColumnsConfig);
    }
  }, [getGridconfigStatus.getGridConfiguration, gridRef.current?.columnApi]);

  useEffect(() => {
    if (addPlanStatus === REQUEST_STATUS.fulfilled) {
      gridRef.current?.columnApi?.resetColumnState();
    }
  }, [addPlanStatus]);

  const onAddCargoClick = async (isFromFooter = false) => {
    const dateFromISO = dateRange.from.toISOString();
    const dateToISO = dateRange.to.toISOString();

    const createAssignmentCommand: CreateAssignmentCommand = {
      planId: currentPlan!.id!,
      sourceLaycan: {
        from: dateFromISO,
        to: dateToISO,
      },
    };
    const res = await dispatch(addAssignment(createAssignmentCommand));
    const newEmptyRow = res.payload as AssignmentRowData;
    newEmptyRow.cargo!.loadLocation = {};
    newEmptyRow.cargo!.dischargeLocation = {};
    addEmptyRow(newEmptyRow);

    trackEvent(MixpanelEventCategory.INTERACTION, {
      event: isFromFooter ? MixpanelEventName.ADD_CARGO_FROM_FOOTER : MixpanelEventName.ADD_CARGO_FROM_EMPTY,
      eventAction: MixpanelEventAction.CLICK,
      eventLabel: MixpanelEventLabel.BUTTON,
      eventLocation: MixpanelEventLocation.CARGO_ASSIGNMENT_GRID,
      eventVersion: 1.1,
      assignmentId: newEmptyRow.id,
      cargoId: newEmptyRow.cargo?.id,
      planId: currentPlan?.id,
    });
  };

  const onAddCargoClickFromFooter = () => {
    onAddCargoClick(true);
  };

  const onAddCargoClickFromEmptyGrid = () => {
    onAddCargoClick();
  };

  const processRowPostCreate = (params: ProcessRowParams<AssignmentRowData>) => {
    params.eRow.addEventListener('dragenter', (event: DragEvent) => rowDragEnter(event, params));
    params.ePinnedRightRow?.addEventListener('dragenter', (event: DragEvent) => rowDragEnterPinned(event, params));
    params.ePinnedLeftRow?.addEventListener('dragenter', (event: DragEvent) => rowDragEnterPinned(event, params));
  };

  async function onDrop(tonnage: LinkedTonnageDto, assignment: AssignmentRowData) {
    confirmationsRef.current?.onDrop(tonnage, assignment);
  }

  async function assign(assignmentId: number, tonnage: LinkedTonnageDto) {
    if (tonnage.id) {
      try {
        await dispatch(assignTonnage({ assignmentId, tonnageId: tonnage.id }));
        updateTonnageDetails(assignmentId, tonnage);
        updateTonnageLinkedAssignmentsIds(tonnage.id, assignmentId);
      } catch (error: unknown) {
        console.error(error);
      }
    }
  }

  const onRowDataUpdated = (e: RowDataUpdatedEvent<AssignmentRowData>) => {
    const allCargosIds: number[] = [];
    e.api.forEachNode((node) => {
      if (node.data?.cargo?.id) {
        allCargosIds.push(node.data?.cargo?.id);
      }
    });
    dispatch(setAllRowsIds(allCargosIds));
  };

  const onColumnEverythingChanged = (event: ColumnEverythingChangedEvent) => {
    if (event.source === 'rowDataUpdated') {
      applyGridState(gridRef.current?.columnApi, customColumnsConfig);
    }
  };

  // TODO start -> remove after with ff_dragToResizeTonnage removal
  const changeDateRange = (newDateRange: IDateRange) => {
    dispatch(updateDateRange(newDateRange));
    dispatch(deselectAllCargoes());
  };

  const CargoContainer = isFeatureFlagActive(FeatureFlagsEnum.ff_dragToResizeTonnage)
    ? StyledCargoContainer
    : StyledCargoContainerNoFeatureFlag;
  // TODO end -> remove after with ff_dragToResizeTonnage removal

  const toggleClassOnColumVisibleChange = (e: ColumnVisibleEvent<AssignmentRowData>) => {
    if (e.column?.getColId() === AssignmentsGridColumn.BatchActionsSelect) {
      if (e?.visible) {
        e.api.setRowClass('is-pinning-left');
      } else {
        e.api.setRowClass(undefined);
      }
    }
  };

  const toggleCustomizationSideBar = () => {
    dispatch(toggleCustomisationDrawer());
  };

  return (
    <>
      {/*TODO replace CargoContainer with StyledCargoContainer after with ff_dragToResizeTonnage removal*/}
      <CargoContainer id={GridId.cargoAssigment} data-testid={GridId.cargoAssigment} isTonnageHidden={isTonnageHidden}>
        <StyledGridHeaderContainer>
          <StyledGridName variant="h6">Cargo Assignments</StyledGridName>
          <StyledGridActionsContainer>
            {hasWriteRights && <ColorPicker onClickHandler={setCellClassRules} />}
            {hasWriteRights && <BatchActionsSelectBox />}
            <ButtonIcon
              icon={<DownloadIcon />}
              onClick={exportDataToExcel}
              data-testid="button-assignments-export-to-excel"
              style={{ marginRight: isFeatureFlagActive(FeatureFlagsEnum.ff_dragToResizeTonnage) ? '0px' : '16px' }}
              //TODO: leave marginRight: 16px after remove ff_dragToResizeTonnage
              tooltip="Export to Excel"
            />

            {isFeatureFlagActive(FeatureFlagsEnum.ff_CustomizeCargoAssignmentsGrid) && (
              <ButtonIcon
                icon={<TuneIcon />}
                onClick={toggleCustomizationSideBar}
                data-testid="button-assignments-customise-grid"
                tooltip="Customise"
                style={{
                  marginRight: isFeatureFlagActive(FeatureFlagsEnum.ff_dragToResizeTonnage) ? '0px' : '16px',
                  marginLeft: isFeatureFlagActive(FeatureFlagsEnum.ff_dragToResizeTonnage) ? '16px' : '0px',
                }}
                //TODO: remove style prop (marginRight) with ff_dragToResizeTonnage
              />
            )}
            {isFeatureFlagActive(FeatureFlagsEnum.ff_dragToResizeTonnage) ? null : (
              <>
                {isFeatureFlagActive(FeatureFlagsEnum.ff_CustomDateRange) ? (
                  <PlanDateFilter
                    dateRange={{ from: dayjs(dateRange.from), to: dayjs(dateRange.to) }}
                    updateDateRange={changeDateRange}
                  />
                ) : (
                  <MonthPicker
                    dateRange={dateRange}
                    updateDateRange={changeDateRange}
                    id="current-plan"
                    monthPickerSize={MonthPickerSize.LARGE}
                  />
                )}
              </>
            )}
            <StyledDivider orientation="vertical" flexItem istonnagehidden={isTonnageHidden ? 1 : 0} />
            <StyledGridToggle
              onClick={showTonnage}
              istonnagehidden={isTonnageHidden ? 1 : 0}
              data-testid={'button-show-tonnage-grid'}
            >
              <StyledHideTonnageArrowIcon />
              <ShipIcon />
            </StyledGridToggle>
          </StyledGridActionsContainer>
        </StyledGridHeaderContainer>
        <StyledGridContainer className="ag-theme-alpine" isreadonly={hasWriteRights ? 0 : 1}>
          <AgGridReact<AssignmentRowData>
            getRowId={getRowId}
            onCellEditingStopped={(event) => onCellEditingStopped(event, dateRange)}
            onColumnEverythingChanged={onColumnEverythingChanged}
            ref={gridRef}
            rowData={rowData}
            defaultColDef={defaultColDef}
            animateRows={true}
            columnDefs={columnDefs}
            rowSelection="multiple"
            onRowSelected={onRowSelected}
            onCellFocused={(event) => {
              onCellFocused(event);
              if (event.column) {
                tonnagesGridRef.current?.api.clearFocusedCell();
              }
            }}
            stopEditingWhenCellsLoseFocus={true}
            enterNavigatesVertically={true}
            enterNavigatesVerticallyAfterEdit={true}
            excelStyles={excelStyles}
            suppressRowClickSelection={true}
            loadingOverlayComponent={loadingOverlayComponent}
            enableCellEditingOnBackspace={false}
            loadingOverlayComponentParams={{ footerText: 'Loading Cargo Assignments...' }}
            noRowsOverlayComponent={noRowsOverlayComponent}
            noRowsOverlayComponentParams={{
              headerText: 'No cargo assignments to display',
              addButtonLabel: 'Add Cargo',
              onAddButtonClick: onAddCargoClickFromEmptyGrid,
            }}
            enableCellChangeFlash
            processRowPostCreate={processRowPostCreate}
            onDragStopped={autoWidthLastColumn}
            suppressContextMenu={true}
            onColumnVisible={toggleClassOnColumVisibleChange}
            onRowDataUpdated={onRowDataUpdated}
            suppressDragLeaveHidesColumns={true}
            tooltipShowDelay={400}
            rowClassRules={rowClassRules}
            onColumnResized={updateGridState}
            onColumnMoved={updateGridState}
            onColumnPinned={updateGridState}
            onSortChanged={updateGridState}
            rowHeight={48}
          />
          <GridFooter
            gridData={rowData}
            gridApi={gridRef.current?.api}
            onAddClick={onAddCargoClickFromFooter}
            addButtonLabel="ADD CARGO"
          />
        </StyledGridContainer>
      </CargoContainer>
      <AssignmentConfirmations ref={confirmationsRef} assign={assign} />
    </>
  );
};

interface IStyledCargoContainer {
  isTonnageHidden?: boolean;
}

// TODO start -> remove after with ff_dragToResizeTonnage removal
const StyledCargoContainerNoFeatureFlag = styled('div')<IStyledCargoContainer>`
  padding: 16px 24px 24px;
  background-color: ${neutral.n99};
  height: 100%;
  width: ${(props) => (props.isTonnageHidden ? '100%' : '65%')};

  .ag-center-cols-container {
    min-width: 100% !important;
  }
`;
// TODO end -> remove after with ff_dragToResizeTonnage removal

const StyledCargoContainer = styled('div')<IStyledCargoContainer>`
  padding: ${(props) => (props.isTonnageHidden ? '16px 24px 24px' : '16px 21px 24px')};
  background-color: ${neutral.n99};
  height: 100%;
  width: 100%;

  .ag-center-cols-container {
    min-width: 100% !important;
  }
`;

const StyledDivider = styled(Divider)((props: { istonnagehidden: number }) => ({
  margin: '0px 16px',
  width: '2px',
  display: props.istonnagehidden === 1 ? 'inherit' : 'none',
}));

const StyledGridToggle = styled(Button)((props: { istonnagehidden: number }) => ({
  display: props.istonnagehidden === 1 ? 'flex' : 'none',
  justifyContent: 'start',
  height: '40px',
  marginRight: '-24px',
  width: '80px',
  minWidth: '80px',
  backgroundColor: grey.g1,
  borderRadius: '8px 0px 0px 8px',
  padding: '12px 0px 12px 8px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

const StyledHideTonnageArrowIcon = styled(ForwardArrowIcon)({
  transform: 'rotate(180deg)',
  marginRight: '7px',
});
