import { PropsWithChildren } from 'react';
import { InfoContent, InfoContentType } from '../InfoContent/InfoContent';
import { SearchEditorHeader } from '../SearchEditorHeader/SearchEditorHeader';
import { SearchEditorLoader } from '../SearchEditorLoader/SearchEditorLoader';
import { UserTip } from '../UserTip/UserTip';
import { OptionNotLinkedMessage } from '../OptionNotLinkedMessage/OptionNotLinkedMessage';
import { Messages } from '../../SearchEditor';

interface ICustomPaperComponent {
  testIdPrefix: string;
  shouldShowUserTip?: boolean;
  shouldShowOptionNotLinkedMessage?: boolean;
  shouldShowCannotConnectToDbMessage?: boolean;
  shouldShowNoResultsMessage?: boolean;
  shouldShowHeader?: boolean;
  searchedOptionsTotalCount?: number;
  searchedOptionsCount?: number;
  isLoading?: boolean;
  messages: Messages;
}

export const CustomPaperComponent: React.FC<PropsWithChildren<ICustomPaperComponent>> = (props) => {
  const {
    children,
    testIdPrefix,
    shouldShowUserTip,
    shouldShowOptionNotLinkedMessage,
    shouldShowCannotConnectToDbMessage,
    shouldShowNoResultsMessage,
    shouldShowHeader,
    searchedOptionsTotalCount,
    searchedOptionsCount,
    isLoading,
    messages,
  } = props;

  if (shouldShowUserTip) {
    return <UserTip text={messages.userTip} />;
  }

  if (isLoading) {
    return (
      <>
        {shouldShowOptionNotLinkedMessage && (
          <OptionNotLinkedMessage
            testIdPrefix={testIdPrefix}
            title={messages.optionNotLinkedMessageTitle}
            description={messages.optionNotLinkedMessageDescription}
          />
        )}
        <SearchEditorLoader testIdPrefix={testIdPrefix} footerText={messages.loader} />
      </>
    );
  }

  if (shouldShowCannotConnectToDbMessage) {
    return (
      <>
        {shouldShowOptionNotLinkedMessage && (
          <OptionNotLinkedMessage
            testIdPrefix={testIdPrefix}
            title={messages.optionNotLinkedMessageTitle}
            description={messages.optionNotLinkedMessageDescription}
          />
        )}
        <InfoContent testIdPrefix={testIdPrefix} type={InfoContentType.cannotConnectToDb} messages={messages.infoContent} />
      </>
    );
  }

  if (shouldShowNoResultsMessage) {
    return (
      <>
        {shouldShowOptionNotLinkedMessage && (
          <OptionNotLinkedMessage
            testIdPrefix={testIdPrefix}
            title={messages.optionNotLinkedMessageTitle}
            description={messages.optionNotLinkedMessageDescription}
          />
        )}
        <InfoContent testIdPrefix={testIdPrefix} type={InfoContentType.cannotFindMatch} messages={messages.infoContent} />
      </>
    );
  }

  return (
    <>
      {shouldShowOptionNotLinkedMessage && (
        <OptionNotLinkedMessage
          testIdPrefix={testIdPrefix}
          title={messages.optionNotLinkedMessageTitle}
          description={messages.optionNotLinkedMessageDescription}
        />
      )}
      {shouldShowHeader && (
        <SearchEditorHeader
          searchedOptionsCount={searchedOptionsCount}
          searchedOptionsTotalCount={searchedOptionsTotalCount}
          title={messages.selectOption}
        />
      )}
      {children}
    </>
  );
};
