import React from 'react';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import {
  ActionsCellRenderer,
  StyledDeleteSpan,
  StyledMenuItem,
  StyledTrashIcon,
  StyledUnlinkIcon,
} from '../../../common/grid/ActionsCellRenderer';
import { ConfirmationDialogType, createDetails, getWarningMsg } from '../../../common/dialog/helpers';
import ConfirmationDialog from '../../../common/dialog/ConfirmationDialog';
import { LinkedTonnageDto } from '../../../../api/web-api-client';
import { GridId } from '../../../common/grid/helpers';
import { SeaNetLinkIcon } from './SeaNetLinkIcon';
import { useAppSelector } from '../../../../store/helpers';

interface ITonnageActionsCellRendererParams extends ICellRendererParams<LinkedTonnageDto & { id: number }> {
  handleDelete: (id: number) => Promise<void>;
  handleUnassign: (id: number) => Promise<void>;
}

export const TonnageActionsCellRenderer: React.FC<ITonnageActionsCellRendererParams> = (props) => {
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = React.useState(false);
  const [isUnassignDialogOpen, setIsUnassignDialogOpen] = React.useState(false);

  const planId = useAppSelector((state) => state.plans.currentPlan?.id);

  const data = props.data;

  if (!data) {
    return <></>;
  }

  const onDeleteClick = () => {
    setIsDeletionDialogOpen(true);
  };

  const onUnassignClick = () => {
    setIsUnassignDialogOpen(true);
  };

  return (
    <ActionsCellRenderer
      kebabTestId="tonnage-grid-kebab-button"
      ariaLabel="tonnage menu"
      isDialogOpen={isDeletionDialogOpen || isUnassignDialogOpen}
      gridId={GridId.tonnage}
      preMenuIcon={
        <SeaNetLinkIcon vessel={data.vessel} tonnageId={data?.id} planId={planId} />
      }
      dialogs={
        <>
          <ConfirmationDialog
            actionHandler={props.handleDelete}
            itemId={data.id}
            isConfirmationDialogOpen={isDeletionDialogOpen}
            setIsConfirmationDialogOpen={setIsDeletionDialogOpen}
            details={createDetails(data.contractType, data.vessel?.name)}
            dialogType={
              data.linkedAssignmentsIds?.length
                ? ConfirmationDialogType.deleteAssignedTonnage
                : ConfirmationDialogType.deleteUnassignedTonnage
            }
            warningMsg={getWarningMsg(data.linkedAssignmentsIds)}
          />
          <ConfirmationDialog
            actionHandler={props.handleUnassign}
            itemId={data.id}
            isConfirmationDialogOpen={isUnassignDialogOpen}
            setIsConfirmationDialogOpen={setIsUnassignDialogOpen}
            dialogType={ConfirmationDialogType.removeTonnageFromCargo}
          />
        </>
      }
      {...props}
    >
      {data.linkedAssignmentsIds?.length ? (
        <StyledMenuItem onClick={onUnassignClick} data-testid="remove-from-cargo-action">
          <StyledUnlinkIcon />
          Remove from cargo
        </StyledMenuItem>
      ) : null}
      <StyledMenuItem data-testid="delete-tonnage-action" onClick={onDeleteClick}>
        <StyledTrashIcon />
        <StyledDeleteSpan>Delete tonnage</StyledDeleteSpan>
      </StyledMenuItem>
    </ActionsCellRenderer>
  );
};
