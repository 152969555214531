import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { INoRowsOverlayParams } from 'ag-grid-community';
import { grey } from '../../../themes/palette';
import { useAuth } from '../../../contexts/AuthContext';

interface INoRowsOverlay extends INoRowsOverlayParams {
  headerText: string;
  addButtonLabel: string;
  onAddButtonClick: () => void;
}

const StyledNoRowsHeader = styled(Typography)({
  marginBottom: '12px',
  fontSize: '16px',
  fontWeight: '700',
  color: grey.g5,
});

const StyledAddButton = styled(Button)({
  minWidth: '150px',
  height: '40px',
  fontSize: '16px',
  pointerEvents: 'all',
});

export const NoRowsOverlay: React.FC<INoRowsOverlay> = ({ headerText, addButtonLabel, onAddButtonClick }) => {
  const { hasWriteRights } = useAuth();

  return (
    <>
      <StyledNoRowsHeader>{headerText}</StyledNoRowsHeader>
      {hasWriteRights && (
        <StyledAddButton variant="contained" onClick={onAddButtonClick} data-testid={`overlay-button-${addButtonLabel}`}>
          {addButtonLabel}
        </StyledAddButton>
      )}
    </>
  );
};
