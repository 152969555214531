import React, { PropsWithChildren, useEffect } from 'react';
import { styled, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

import Dialog from './Dialog';
import { ConfirmationDialogType, DialogPresets } from './helpers';
import { orange } from '../../../themes/palette';

const StyledErrorIcon = styled(ErrorIcon)({
  color: orange.o10,
  height: '24px',
  alignSelf: 'flex-start',
});

const StyledDetails = styled(Typography)({
  fontSize: '16px',
  fontWeight: 700,
  marginBottom: '16px',
});

const StyledDescription = styled(Typography)({
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: '0.005em',
});

const StyledWarningMsg = styled('div')({
  marginBottom: '16px',
  borderRadius: '4px',
  backgroundColor: orange.o100,
  color: orange.o20,
  padding: '8px 10px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '14px',
});

interface IConfirmationDialog {
  actionHandler?: (id: number) => Promise<void>;
  actionReduxHandler?: () => Promise<unknown>;
  isLoading?: boolean; // State from redux
  dialogType: ConfirmationDialogType;
  details?: string;
  warningMsg?: React.ReactNode;
  isConfirmationDialogOpen: boolean;
  itemId?: number; // TODO: probably can be removed in future
  itemsIds?: number[];
  description?: string;
  setIsConfirmationDialogOpen: (isConfirmationDialogOpen: boolean) => void;
  onCancel?: () => void;
}

const ConfirmationDialog: React.FC<PropsWithChildren<IConfirmationDialog>> = ({
  actionHandler,
  actionReduxHandler,
  isLoading = false,
  children,
  description,
  dialogType,
  isConfirmationDialogOpen,
  itemId,
  itemsIds,
  setIsConfirmationDialogOpen,
  details,
  warningMsg,
  onCancel,
}) => {
  const [isDialogLoading, setIsDialogLoading] = React.useState(false);

  useEffect(() => {
    setIsDialogLoading(isLoading);
  }, [isLoading]);

  const onActionClick = async () => {
    try {
      if (itemId && actionHandler) {
        setIsDialogLoading(true);
        await actionHandler(itemId);
      } else if (itemsIds?.length && actionReduxHandler) {
        actionReduxHandler();
      }
    } catch (error: unknown) {
      console.error(error);
    } finally {
      if (itemId) {
        setIsDialogLoading(false);
        closeConfirmationDialog();
      }
    }
  };

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
    onCancel?.();
  };

  return (
    <Dialog
      id={dialogType}
      open={isConfirmationDialogOpen}
      onClose={closeConfirmationDialog}
      cancelCb={closeConfirmationDialog}
      actionCb={onActionClick}
      isLoading={isDialogLoading}
      {...DialogPresets[dialogType]}
      title={DialogPresets[dialogType].title(itemId ?? itemsIds?.length)}
    >
      {children ?? (
        <>
          {details && <StyledDetails>{details} </StyledDetails>}
          {warningMsg && (
            <StyledWarningMsg>
              <StyledErrorIcon />
              {warningMsg}
            </StyledWarningMsg>
          )}
          {<StyledDescription>{description ?? DialogPresets[dialogType].description}</StyledDescription>}
        </>
      )}
    </Dialog>
  );
};

export default ConfirmationDialog;
