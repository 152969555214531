import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { black, blue, grey, neutral, orange, red } from '../../../themes/palette';
import { FLASH_ANIMATION_TIME } from '../Constants';
import { isDragging, isDropTarget, kebabMenuOpenAttribute, notLinkedApiIndicatorClass } from './helpers';

export const StyledGridName = styled(Typography)({
  alignSelf: 'center',
  letterSpacing: '-0.01em',
  fontWeight: 700,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const StyledGridHeaderContainer = styled('div')({
  display: 'flex',
  height: '40px',
  marginBottom: '16px',
});

export const StyledGridContainer = styled('div')(
  (props: { isreadonly: number }) => `
  height: calc(100% - 108px);
  width: 100%;
  box-shadow: 0px 2px 4px -2px rgba(19, 20, 20, 0.25), 0px 4px 6px -1px rgba(19, 20, 20, 0.1);
  --ag-data-color: ${black.b9};
  --ag-border-color: ${grey.g2};
  --ag-row-border-color: ${grey.g2};
  --ag-range-selection-border-color: ${blue.b50};
  --ag-row-hover-color: transparent;
  --ag-header-cell-hover-background-color: ${grey.g1};
  --ag-value-change-value-highlight-background-color: ${red.r10};
  --ag-odd-row-background-color: ${grey.g11};
  --ag-selected-row-background-color: rgba(25, 118, 255, 0.14);
  --ag-row-border-width: 0px;
  --ag-cell-border: ${props.isreadonly === 0 ? `1px solid ${blue.b50}` : 'none'};
  --ag-cell-horizontal-padding: 17px;
  --ag-row-height: 48px;

  .ag-row-selected::before {
    z-index: 10;
  }
  .default-highlight-row {
    background-color: ${blue.b10};
  }

  font-family: 'Inter', serif;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .ag-cell-not-inline-editing.${notLinkedApiIndicatorClass}::after {
    position: absolute;
    content: '\u25CF';
    font-size: 8px;
    color: ${orange.o50};
    top: -10px;
    left: 5px;
  }

  .ag-input-field-input,
  input[class^='ag-'][type='text'] {
    font-family: 'Inter', serif;
    border: 1px solid ${blue.b50};
    border-radius: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.17px;

    &:focus-within {
      border: 1px solid ${blue.b50};
      border-radius: 0;
    }
  }

  .ag-cell {
    &:hover {
      border: var(--ag-cell-border);
    }
  }

  .ag-row-focus[${kebabMenuOpenAttribute}='true'] {
    border: 1px solid ${blue.b50};
  }

  .ag-pinned-left-cols-container .is-pinning-left {
    border-right: 1px solid ${grey.g6} !important;
  }
  .ag-center-cols-clipper .is-pinning-left {
    border-left: none !important;
  }
  .ag-center-cols-clipper .ag-row {
    border-right: none !important;
  }
  .ag-pinned-right-cols-container .ag-row {
    border-left: none !important;
  }

  .ag-row[${isDragging}='true'] {
    border: 1px solid ${blue.b70};
    & .ag-cell-focus {
      border: unset;
    }
    & #dragIndicator {
      fill: ${blue.b70};
    }
  }

  .ag-row[${isDropTarget}='true'] {
    border: 1px solid ${blue.b70};
  }

  .ag-root-wrapper {
    border: none;
  }

  .ag-root-wrapper {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .ag-pinned-right-header {
    .ag-header-row {
      width: 100% !important;
      ::after {
        content: none;
      }
    }
    .ag-header-cell-resize {
      display: none;
    }
    .ag-header-cell {
      padding-left: 16px;
    }
  }

  .ag-pinned-left-header {
    .ag-header-cell-resize {
      display: none;
    }
  }
  .ag-pinned-left-header {
    padding: 0;
  }

  .ag-cell-focus {
    border: var(--ag-cell-border) !important;
  }

  .ag-header-container {
    min-width: 100% !important;
  }

  .ag-header-viewport {
    background-color: ${grey.g1};
  }

  .ag-header-row {
    background-color: ${grey.g1};
    min-width: 100% !important;

    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.17px;
  }

  .ag-header-active[aria-sort='none'] {
    .ag-sort-ascending-icon {
      display: block !important;
      color: ${grey.g4};
    }
  }

  .ag-header-cell {
    background-color: ${grey.g1};
  }

  .ag-header-cell-resize {
    ::after {
      background-color: ${grey.g3};
    }
  }

  .ag-cell {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.17px;
    line-height: var(--ag-internal-calculated-line-height)
  }

  .ag-ltr textarea[class^='ag-'] {
    padding-left: 16px;
  }

  .ag-popup-editor {
    border: none;
  }

  textarea[class^='ag-']:focus {
    box-shadow: none;
  }

  .ag-large-text-input {
    padding: 16px;
    border-radius: 8px;
    background-color: ${neutral.n200};
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    .ag-input-field-input {
      border: 1px solid ${blue.b70};
      border-radius: 0;
      padding: 12px 16px;
      line-height: 20px;
      letter-spacing: 0;
      resize: none;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .no-border {
    border: none !important;
  }
  .no-padding {
    padding: 0;
  }

  .only-left-border {
    border: none !important;
    border-left: 1px solid ${grey.g6} !important ;
  }

  .only-right-border {
    border: none !important;
    border-right: 1px solid ${grey.g6} !important ;
  }

  .ag-cell-popup-editing[col-id='notes'] {
    background-image: linear-gradient(0deg, ${blue.b10}, ${blue.b10});
    background-blend-mode: multiply;
  }

  .custom-flash-cell {
    animation-name: customFlashCell;
    animation-duration: ${FLASH_ANIMATION_TIME}ms;
  }

  .ag-cell-inline-editing {
    border: none !important;
    border-radius: 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25), 0 3px 1px rgba(0, 0, 0, 0.2);
    background-color: var(--ag-control-panel-background-color);
  }


  @keyframes customFlashCell {
    0% {
      background-color: ${blue.b50};
      opacity: 0.2;
    }

    50% {
      background-color: ${blue.b50};
      opacity: 0.2;
    }

    70% {
      background-color: ${blue.b10};
    }

    100% {
      background-color: ${neutral.n100};
    }
  }`
);
