import { Typography, styled } from '@mui/material';
import { ColumnSwitchListElement, ColumnSwitchProps } from './ColumnSwitchListElement';
import { neutral } from '../../../themes/palette';

const StyledColumnList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  paddingBottom: '24px',
});

const StyledContainer = styled('div')({
  padding: '24px',
  height: '100%',
});

export interface IGridColumnList {
  title: string;
  columns: ColumnSwitchProps[];
  setVisibleHandler: (id: string, value: boolean) => void;
}

const StyledColumnListHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '24.5px',
});

export const GridColumnList: React.FC<IGridColumnList> = (props) => {
  const { columns, title, setVisibleHandler } = props;
  return (
    <StyledContainer>
      <StyledColumnListHeader>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 600,
            letterSpacing: '0.15px',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            fontStyle: 'italic',
            letterSpacing: '0.17px',
            color: neutral.n65,
          }}
        >
          Visibility
        </Typography>
      </StyledColumnListHeader>
      <StyledColumnList>
        {columns.map((column) => (
          <ColumnSwitchListElement key={column.id} {...column} setVisibleHandler={setVisibleHandler} />
        ))}
      </StyledColumnList>
    </StyledContainer>
  );
};
