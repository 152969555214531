import { AssignmentDto, LinkedTonnageDto } from '../../../../../api/web-api-client';
import { useGridRefs } from '../../../../../contexts/GridRefsContext';
import {
  MixpanelEventAction,
  MixpanelEventCategory,
  MixpanelEventLabel,
  MixpanelEventLocation,
  MixpanelEventName,
  useMixpanel,
} from '../../../../../contexts/MixpanelContext';
import { getRowNodes, removeRows } from '../../../../../helpers/gridUtilities';
import { removeTonnage, unassignTonnage as unassignTonnageAPI } from '../../../../../store';
import { useAppDispatch, useAppSelector } from '../../../../../store/helpers';
import { GridId } from '../../../../common/grid/helpers';
import { useDefaultGridOptions } from '../../../../common/grid/hooks/useDefaultGridOptions';

export const useTonnageGridActions = () => {
  const { assignmentsGridRef, tonnagesGridRef: gridRef } = useGridRefs();
  const dispatch = useAppDispatch();
  const { customFlashRow, unassignCargo, unassignTonnage } = useDefaultGridOptions(gridRef, GridId.tonnage);

  const { trackEvent } = useMixpanel();

  const planId = useAppSelector((state) => state.plans.currentPlan?.id);

  const handleUnassign = async (id: number) => {
    let assignmentId: number | undefined;
    let cargoId: number | undefined;
    const linkedAssignmentNodes = getRowNodes<AssignmentDto>(
      assignmentsGridRef?.current?.api,
      (assignment) => assignment.data?.tonnage?.id === id
    );
    if (!linkedAssignmentNodes.length || !linkedAssignmentNodes[0]) {
      const tonnageNodes = getRowNodes<LinkedTonnageDto>(gridRef?.current?.api, (tonnage) => tonnage.data?.id === id);
      assignmentId = tonnageNodes?.[0].data?.linkedAssignmentsIds?.[0];
    } else {
      const data = linkedAssignmentNodes[0].data;
      assignmentId = data?.id;
      cargoId = data?.cargo?.id;
    }

    if(!assignmentId){
      return;
    }
    try {
      await dispatch(unassignTonnageAPI({ assignmentId }));
      if (linkedAssignmentNodes && linkedAssignmentNodes[0]) {
        customFlashRow(GridId.cargoAssigment, linkedAssignmentNodes[0].data?.id);
        unassignCargo(linkedAssignmentNodes[0]);
      }

      const rowNodeToRefresh = gridRef.current?.api.getRowNode(id.toString() ?? '');
      if (rowNodeToRefresh) {
        unassignTonnage(rowNodeToRefresh);
      }

      trackEvent(MixpanelEventCategory.INTERACTION, {
        event: MixpanelEventName.CONFIRM_REMOVE_FROM_CARGO,
        eventAction: MixpanelEventAction.CLICK,
        eventLabel: MixpanelEventLabel.BUTTON,
        eventLocation: MixpanelEventLocation.REMOVE_FROM_CARGO_MODAL,
        eventVersion: 1.1,
        assignmentId: assignmentId,
        cargoId: cargoId,
        tonnageId: id,
        planId,
      });
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const handleDelete = async (id: number) => {
    const rowToDelete = gridRef.current?.api.getRowNode(id.toString());
    if (rowToDelete?.data?.id && gridRef.current?.api) {
      await dispatch(removeTonnage(rowToDelete.data.id));
      removeRows(gridRef.current.api, [rowToDelete]);
      handleUnassign(id);
      trackEvent(MixpanelEventCategory.INTERACTION, {
        event: MixpanelEventName.CONFIRM_DELETE_TONNAGE,
        eventAction: MixpanelEventAction.CLICK,
        eventLabel: MixpanelEventLabel.BUTTON,
        eventLocation: MixpanelEventLocation.DELETE_TONNAGE_MODAL,
        eventVersion: 1.1,
        tonnageId: rowToDelete?.data?.id,
        planId,
      });
    }
  };

  return { handleDelete, handleUnassign };
};
