import { useGridRefs } from '../../../../../contexts/GridRefsContext';
import dayjs from 'dayjs';
import { deselectAllCargoes, duplicateAssignmentCargoes, IDateRange, updateDateRange } from '../../../../../store';
import { REQUEST_STATUS, useAppDispatch, useAppSelector } from '../../../../../store/helpers';
import { AssignmentDto } from '../../../../../api/web-api-client';
import { useAssignmentsGrid } from '../../grid';
import React, { useEffect, useState } from 'react';
import utc from 'dayjs/plugin/utc';
import { DuplicateCargoesNotification } from './DuplicateCargoesNotification';
import { DuplicateCargoesDialog } from './DuplicateCargoesDialog/DuplicateCargoesDialog';
import { scrollToFirstAddedRow } from '../../../../../helpers/gridUtilities';
import { ICustomRowData } from '../../../../common/grid/hooks/useDefaultGridOptions';
import {
  MixpanelEventAction,
  MixpanelEventCategory,
  MixpanelEventLabel,
  MixpanelEventLocation,
  MixpanelEventName,
  useMixpanel,
} from '../../../../../contexts/MixpanelContext';
dayjs.extend(utc);

export const DuplicateCargoesAction = () => {
  const dispatch = useAppDispatch();
  const { assignmentsGridRef } = useGridRefs();
  const { unhighlightOnMouseUp, deselectAll } = useAssignmentsGrid();

  const planId = useAppSelector((state) => state.plans.currentPlan?.id);
  const planDateRange = useAppSelector((state) => state.dateRange.dateRange);
  const isOpen = useAppSelector((state) => state.dialogs.isDuplicateCargoesDialogOpen);
  const selectedRows = useAppSelector((state) => state.assignments.selectedRows);
  const getAssignmentsStatus = useAppSelector((state) => state.assignments.status.getAssignments);

  const getMonthDifference = (): number => {
    const yearDifference = dateRange.from.utc().year() - planDateRange.from.utc().year();
    const newMonthDifference = dateRange.from.utc().month() - planDateRange.from.utc().month() + yearDifference * 12;
    return newMonthDifference;
  };
  const [dateRange, setDateRange] = useState<IDateRange>(planDateRange);
  const [monthDifference, setMonthDifference] = useState<number>(getMonthDifference());
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [duplicatedCargoes, setDuplicatedCargoes] = useState<AssignmentDto[]>([]);

  const { trackEvent } = useMixpanel();

  useEffect(() => {
    if (planDateRange) {
      setDateRange(planDateRange);
    }
  }, [planDateRange]);

  useEffect(() => {
    const newMonthDifference = getMonthDifference();
    setMonthDifference(newMonthDifference);
  }, [dateRange, planDateRange]);

  useEffect(() => {
    // Highlight duplicated cargoes in new month view
    if (getAssignmentsStatus === REQUEST_STATUS.fulfilled && duplicatedCargoes.length) {
      setTimeout(() => {
        highlightDuplicatedCargoes(duplicatedCargoes);
        setDuplicatedCargoes([]);
      });
    }
  }, [getAssignmentsStatus]);

  const onConfirm = async () => {
    const cargoIds = Array.from(selectedRows);
    const res = await dispatch(duplicateAssignmentCargoes({ cargoIds, monthDifference }));
    const newCargoes = res.payload as ICustomRowData<AssignmentDto>[];
    newCargoes.forEach((cargo) => (cargo.shouldHighlight = true));
    if (monthDifference === 0) {
      assignmentsGridRef.current?.api.applyTransactionAsync({ add: newCargoes }, () => postTransactionHandler(newCargoes));
    }
    setDuplicatedCargoes(newCargoes);

    trackEvent(MixpanelEventCategory.INTERACTION, {
      event: MixpanelEventName.CONFIRM_BATCH_DUPLICATE,
      eventAction: MixpanelEventAction.CLICK,
      eventLabel: MixpanelEventLabel.BUTTON,
      eventLocation: MixpanelEventLocation.BATCH_DUPLICATE_MODAL,
      eventVersion: 1.1,
      planId,
      duplicatedCargoesIds: cargoIds,
      newCargoesIds: newCargoes.map((cargo) => cargo?.id),
    });
  };

  const changeMonthView = async () => {
    await dispatch(updateDateRange(dateRange));
    await dispatch(deselectAllCargoes());
    deselectAll();
    setIsNotificationOpen(false);
  };

  const highlightDuplicatedCargoes = (assignments: ICustomRowData<AssignmentDto>[]) => {
    if (assignments[0].id && assignmentsGridRef.current?.api.getRowNode(assignments[0].id.toString())) {
      assignments.forEach((node) => (node.shouldHighlight = true));
      assignmentsGridRef.current?.api.applyTransactionAsync({ update: assignments }, () => postTransactionHandler(assignments));
    }
  };

  const postTransactionHandler = (assignments: ICustomRowData<AssignmentDto>[]) => {
    scrollToFirstAddedRow(assignmentsGridRef.current?.api, assignments);
    document.body?.addEventListener('mouseup', unhighlightOnMouseUp);
  };

  return (
    <>
      <DuplicateCargoesDialog
        open={isOpen}
        actionCb={onConfirm}
        dateRange={dateRange}
        setDateRange={setDateRange}
        monthDifference={monthDifference}
      />
      <DuplicateCargoesNotification
        dateRange={dateRange}
        open={isNotificationOpen}
        setIsOpen={setIsNotificationOpen}
        notificationAction={changeMonthView}
        monthDifference={monthDifference}
      />
    </>
  );
};
