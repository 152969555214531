import {
  AssignmentDto,
  AssignmentFormattingDto,
  CargoFormattingDto,
  TonnageFormattingDto,
} from '../../../../../api/web-api-client';

export const getOldClassName = (rowData: AssignmentDto, columnName: string) => {
  let className = '';

  if (columnName.includes('tonnage.') && rowData.tonnage?.formatting) {
    const tonnageColumn = columnName.split('.')[1] as keyof TonnageFormattingDto;
    const formatting = rowData.tonnage?.formatting;
    if (Array.isArray(formatting[tonnageColumn])) {
      className = formatting[tonnageColumn]!.pop() ?? '';
    }
  } else if (columnName.includes('cargo.') && rowData.cargo?.formatting) {
    const cargoColumn = columnName.split('.')[1] as keyof CargoFormattingDto;
    const formatting = rowData.cargo?.formatting;
    if (Array.isArray(formatting[cargoColumn])) {
      className = formatting[cargoColumn]!.pop() ?? '';
    }
  } else if (rowData?.formatting) {
    const formatting = rowData?.formatting;
    const colName = columnName as keyof AssignmentFormattingDto;
    if (Array.isArray(formatting[colName])) {
      className = formatting[colName]!.pop() ?? '';
    }
  }
  return className;
};

export const updateAssignmentCellFormattingObject = (rowData: AssignmentDto, columnName: string, className?: string) => {
  if (columnName.includes('tonnage.') && rowData.tonnage) {
    const tonnageColumn = columnName.split('.')[1];
    rowData.tonnage.formatting = { ...rowData.tonnage.formatting, [tonnageColumn]: [className] };
  } else if (columnName.includes('cargo.') && rowData.cargo) {
    const cargoColumn = columnName.split('.')[1];
    rowData.cargo.formatting = { ...rowData.cargo.formatting, [cargoColumn]: [className] };
  } else {
    rowData.formatting = { ...rowData.formatting, [columnName]: [className] };
  }
};

export class FormattingRequest {
  columnName: string;
  rowId: number;
  requestPromise?: any;
  oldClassName: string;

  constructor(rowId: number, columnName: string, oldClassName: string) {
    this.columnName = columnName;
    this.rowId = rowId;
    this.oldClassName = oldClassName;
  }

  abort() {
    this.requestPromise?.abort();
  }
}

class FormattingRequestMonitor {
  private requestList: FormattingRequest[] = [];

  public getRequest(rowId: number, columnName: string) {
    return this.requestList.find((request) => request.rowId === rowId && request.columnName === columnName);
  }
  public addRequest(request: FormattingRequest) {
    this.requestList.push(request);
  }

  public removeRequest(rowId: number, columnName: string) {
    this.requestList = this.requestList.filter((request) => request.rowId !== rowId && request.columnName !== columnName);
  }
}

export const TonnageFormattingRequestMonitor = new FormattingRequestMonitor();
export const AssignmentsFormattingRequestMonitor = new FormattingRequestMonitor();
