import { ChangeEvent, forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { styled } from '@mui/material';
import { ICellEditorParams } from 'ag-grid-community/dist/lib/interfaces/iCellEditor';
import { blue, neutral } from '../../../../themes/palette';
import { KeyNames, StringLenghts } from '../../Constants';
import { TextareaFooter } from './TextareaFooter';
import { autoHeightTextarea, GridId, isValidForEditorChar } from '../helpers';

interface ITextareaCellEditorParams extends ICellEditorParams {
  gridId: GridId;
}

const TextareaCellEditor = memo(
  forwardRef((props: ITextareaCellEditorParams, ref) => {
    const createInitialState = () => {
      let startValue: string;

      if (props.eventKey === KeyNames.BACKSPACE || props.eventKey === KeyNames.DELETE) {
        startValue = '';
      } else if (isValidForEditorChar(props.eventKey)) {
        startValue = props.eventKey!;
      } else {
        startValue = props.value;
      }

      return {
        value: startValue,
      };
    };

    const initialState = createInitialState();

    const [value, setValue] = useState<string>(initialState.value);
    const [valueLength, setValueLength] = useState<number>(initialState.value?.length ?? 0);
    const refInput = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
      };
    });

    useEffect(() => {
      if (refInput) {
        const currentRef = refInput.current!;
        currentRef.focus();

        const length = currentRef.value?.length ?? 0;
        currentRef.setSelectionRange(length, length);

        if (currentRef.scrollHeight > 170) {
          autoHeightTextarea(currentRef, props.gridId);
        }
      }
    }, [refInput]);

    const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      setValueLength(newValue.length);
      setValue(newValue);
      const currentRef = refInput.current!;

      if (currentRef.scrollHeight > 170) {
        autoHeightTextarea(currentRef, props.gridId);
      }
    };

    return (
      <StyledContainer>
        <StyledTextarea
          ref={refInput}
          onChange={onChange}
          maxLength={StringLenghts.XLARGE}
          rows={6}
          cols={60}
          value={value ?? ''}
          className="ag-input-field-input ag-text-area-input"
        />
        <TextareaFooter contentLength={valueLength} />
      </StyledContainer>
    );
  })
);

export default TextareaCellEditor;

const StyledTextarea = styled('textarea')({
  fontWeight: 500,
  fontSize: 14,
  letterSpacing: '0.17px',
  width: '450px',
  lineHeight: '20px',
  resize: 'none',
  padding: '12px 16px',
  borderRadius: 0,
  border: `1px solid ${blue.b70}`,
  backgroundColor: neutral.n100,
  marginBottom: 10,
  '&:focus': {
    boxShadow: 'none',
  },
});

const StyledContainer = styled('div')({
  padding: '16px 16px 12px 16px',
  borderRadius: '8px',
  backgroundColor: neutral.n200,
  boxShadow: '0 4px 4px rgba(0, 0, 0, .25)',
});
