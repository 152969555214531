import { useEffect, useMemo, useState } from 'react';
import CellTooltip from '../../../../common/grid/CellTooltip';
import {
  dateRangeValueFormatter,
  dateValueFormatter,
  decimalValueFormatter,
  incotermValueFormatter,
  integerValueFormatter,
  locationFormatter,
  vesselFormatter,
} from '../../../../../helpers/gridValueFormatters';
import { ColDef, SuppressKeyboardEventParams } from 'ag-grid-community';
import { AssignmentDto } from '../../../../../api/web-api-client';
import { NumericCellEditor } from '../../../../common';
import { SelectCellEditor } from '../../../../common/grid/SelectCellEditor';
import dateRangeCellEditor from '../../../../common/grid/DateRangeCellEditor';
import { incoterms, KeyNames, StringLenghts } from '../../../../common/Constants';
import { isContentInElementTruncated } from '../../../../../helpers/helpers';
import { AssignmentsActionsCellRenderer } from './../AssignmentsActionsCellRenderer';
import { DragStoppedEvent } from 'ag-grid-community/dist/lib/events';
import { useDefaultGridOptions } from '../../../../common/grid/hooks/useDefaultGridOptions';
import { useGridRefs } from '../../../../../contexts/GridRefsContext';
import TextareaCellEditor from '../../../../common/grid/TextareaCellEditor/TextareaCellEditor';
import DateCellEditor from '../../../../common/grid/DateCellEditor';
import { adjustLastColumnWidth, GridId, notLinkedApiIndicatorClass } from '../../../../common/grid/helpers';
import { BatchActionsSelectCellRenderer } from './../BatchActionsSelectCellRenderer';
import { BatchActionsSelectHeaderRenderer } from './../BatchActionsSelectHeaderRenderer';
import { useCellFormatting } from '../../../../common/grid/hooks/useCellFormatting/useCellFormatting';
import { AssignmentRowData } from '../../AssignmentsDashboard';
import { useExportToExcel } from './useExportToExcel';
import { useAuth } from '../../../../../contexts';
import { useAssignmentsGridActions } from './useAssignmentGridActions';
import { useAssignmentsGridEditing } from './useAssignmentGridEditing';
import { assignmentStatusComparator, incotermComparator } from '../assignmentGridComparators';
import {
  dateRangeComparator,
  locationComparator,
  nullsLastComparator,
  vesselComparator,
} from '../../../../common/grid/gridComparators';
import { useFeatureFlags } from '../../../../../contexts/FeatureFlagsContext';
import { FeatureFlagsEnum } from '../../../../../api/featureFlags';
import { VesselSearchEditor } from '../../../../common/grid/VesselSearchEditor/VesselSearchEditor';
import { LocationSearchEditor } from '../../../../common/grid/LocationSearchEditor/LocationSearchEditor';
import { useGridHeaders } from '../../../../common/grid/hooks/gridHeaders/useGridHeaders';
import VesselCellTooltip from '../../../../common/grid/VesselCellTooltip';

export enum AssignmentsGridColumn {
  BatchActionsSelect = 'batchActionsSelect',
  Id = 'id',
  CargoIncoterm = 'cargo.incotermId',
  CargoCustomer = 'cargo.customer',
  CargoLoadLocationName = 'cargo.loadLocation.name', //TODO: Remove with locationSearch feature flag also each occurence
  CargoLoadLocation = 'cargo.loadLocation',
  CargoDischargeLocationName = 'cargo.dischargeLocation.name', //TODO: Remove with locationSearch feature flag also each occurence
  CargoDischargeLocation = 'cargo.dischargeLocation',
  CargoType = 'cargo.cargoType',
  CargoLaycan = 'cargo.laycan',
  CargoQuantity = 'cargo.quantity',
  TonnageContractType = 'tonnage.contractType',
  TonnageDemurrage = 'tonnage.demurrage',
  TonnageEta = 'tonnage.eta',
  TonnageEtb = 'tonnage.etb',
  TonnageFreightRate = 'tonnage.freightRate',
  TonnageLaycan = 'tonnage.laycan',
  TonnageOwner = 'tonnage.owner',
  TonnageRefId = 'tonnage.tonnageRefId',
  TonnageVessel = 'tonnage.vessel',
  TargetEta = 'targetEta',
  CargoRefId = 'cargo.cargoRefId',
  Notes = 'notes',
  Options = 'options',
}

export const useAssignmentsGrid = () => {
  const { assignmentsGridRef: gridRef } = useGridRefs();
  const {
    getRowId,
    onCellFocused,
    onRowSelected,
    cellRendererSelector,
    addEmptyRow,
    loadingOverlayComponent,
    noRowsOverlayComponent,
    unassignCargo,
    unassignTonnage,
    updateTonnageDetails,
    updateTonnageLinkedAssignmentsIds,
    suppressKeyboardEvent,
    supressCellRendererSelection,
    unhighlightOnMouseUp,
    updateGridState,
    applyGridState,
    selectAll,
    deselectAll,
    rowClassRules,
  } = useDefaultGridOptions(gridRef, GridId.cargoAssigment);
  const { vesselHeaderComponentParams, freightRateHeaderComponentParams, demurrageComponentParams } = useGridHeaders(
    GridId.cargoAssigment
  );
  const { exportDataToExcel, excelStyles } = useExportToExcel(gridRef);
  const { getCellClassRules } = useCellFormatting();
  const { isFeatureFlagActive } = useFeatureFlags();
  const { hasWriteRights } = useAuth();
  const { handleDelete, handleUnassign, handleDuplicate, handleCopyToClipboard } = useAssignmentsGridActions();
  const { onCellEditingStopped, updateCell } = useAssignmentsGridEditing();

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    {
      field: AssignmentsGridColumn.BatchActionsSelect,
      pinned: 'left',
      editable: false,
      sortable: false,
      filter: false,
      suppressNavigable: true,
      maxWidth: 48,
      minWidth: 48,
      cellRenderer: BatchActionsSelectCellRenderer,
      headerComponent: BatchActionsSelectHeaderRenderer,
      cellClass: 'only-right-border',
      headerClass: ['no-padding'],
      hide: true,
      cellRendererSelector: supressCellRendererSelection,
    },
    {
      field: AssignmentsGridColumn.Id,
      headerName: 'Sea ID',
      minWidth: 80,
      cellClassRules: getCellClassRules<AssignmentDto>('id'),
      cellRendererSelector: supressCellRendererSelection,
    },
    {
      field: AssignmentsGridColumn.CargoIncoterm,
      headerName: 'Incoterm',
      cellEditor: SelectCellEditor,
      cellEditorParams: { options: incoterms },
      valueFormatter: incotermValueFormatter,
      editable: hasWriteRights,
      minWidth: 80,
      cellClassRules: getCellClassRules<AssignmentDto>(AssignmentsGridColumn.CargoIncoterm),
      comparator: incotermComparator,
    },
    {
      field: AssignmentsGridColumn.CargoRefId,
      headerName: 'Cargo ID',
      editable: hasWriteRights,
      tooltipField: AssignmentsGridColumn.CargoRefId,
      tooltipComponentParams: { isContentInElementTruncated },
      cellEditorParams: {
        maxLength: StringLenghts.SMALL,
      },
      minWidth: 100,
      cellClassRules: getCellClassRules<AssignmentDto>(AssignmentsGridColumn.CargoRefId),
    },
    {
      field: AssignmentsGridColumn.CargoCustomer,
      headerName: 'Customer',
      tooltipField: AssignmentsGridColumn.CargoCustomer,
      editable: hasWriteRights,
      tooltipComponentParams: { isContentInElementTruncated },
      cellEditorParams: {
        maxLength: StringLenghts.SMALL,
      },
      minWidth: 140,
      cellClassRules: getCellClassRules<AssignmentDto>(AssignmentsGridColumn.CargoCustomer),
    },
    {
      field: AssignmentsGridColumn.CargoLoadLocation,
      headerName: 'Load Location',
      editable: hasWriteRights,
      tooltipField: `${AssignmentsGridColumn.CargoLoadLocation}.name`,
      cellEditor: LocationSearchEditor,
      valueFormatter: locationFormatter,
      tooltipComponentParams: { isContentInElementTruncated },
      cellEditorParams: {
        maxLength: StringLenghts.SMALL,
        testIdPrefix: 'load-location',
      },
      cellClassRules: {
        ...getCellClassRules<AssignmentDto>(AssignmentsGridColumn.CargoLoadLocation),
        [notLinkedApiIndicatorClass]: (params) => params.value?.name && params.value?.id == null,
      },
      minWidth: 140,
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => 
      params.event.key === KeyNames.ENTER || suppressKeyboardEvent<AssignmentRowData>(params),
      comparator: locationComparator,
    },
    {
      field: AssignmentsGridColumn.CargoDischargeLocation,
      headerName: 'Discharge Location',
      editable: hasWriteRights,
      tooltipField: `${AssignmentsGridColumn.CargoDischargeLocation}.name`,
      cellEditor: LocationSearchEditor,
      valueFormatter: locationFormatter,
      tooltipComponentParams: { isContentInElementTruncated },
      cellEditorParams: {
        maxLength: StringLenghts.SMALL,
        testIdPrefix: 'discharge-location',
      },
      cellClassRules: {
        ...getCellClassRules<AssignmentDto>(AssignmentsGridColumn.CargoDischargeLocation),
        [notLinkedApiIndicatorClass]: (params) => params.value?.name && params.value?.id == null,
      },
      minWidth: 140,
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => 
       params.event.key === KeyNames.ENTER || suppressKeyboardEvent<AssignmentRowData>(params),
      comparator: locationComparator,
    },
    {
      field: AssignmentsGridColumn.CargoType,
      headerName: 'Cargo Type',
      editable: hasWriteRights,
      minWidth: 120,
      cellClassRules: getCellClassRules<AssignmentDto>(AssignmentsGridColumn.CargoType),
      cellEditorParams: {
        maxLength: StringLenghts.SMALL,
      },
    },

    {
      field: AssignmentsGridColumn.CargoLaycan,
      headerName: 'Cargo Laycan',
      valueFormatter: dateRangeValueFormatter,
      editable: hasWriteRights,
      cellEditor: dateRangeCellEditor,
      comparator: dateRangeComparator,
      minWidth: 150,
      cellClassRules: getCellClassRules<AssignmentDto>(AssignmentsGridColumn.CargoLaycan),
    },
    {
      field: AssignmentsGridColumn.CargoQuantity,
      headerName: 'Quantity',
      valueFormatter: integerValueFormatter,
      cellEditor: NumericCellEditor,
      editable: hasWriteRights,
      minWidth: 100,
      cellClassRules: getCellClassRules<AssignmentDto>(AssignmentsGridColumn.CargoQuantity),
    },
    {
      field: AssignmentsGridColumn.TonnageLaycan,
      headerName: 'Vessel Laycan',
      valueFormatter: dateRangeValueFormatter,
      cellEditor: dateRangeCellEditor,
      editable: hasWriteRights,
      comparator: dateRangeComparator,
      minWidth: 150,
      cellClassRules: getCellClassRules<AssignmentDto>(AssignmentsGridColumn.TonnageLaycan),
    },
    {
      field: AssignmentsGridColumn.TonnageContractType,
      headerName: 'Contract',
      tooltipField: AssignmentsGridColumn.TonnageContractType,
      tooltipComponentParams: { isContentInElementTruncated },
      editable: hasWriteRights,
      cellEditorParams: {
        maxLength: StringLenghts.SMALL,
      },
      minWidth: 160,
      cellClassRules: getCellClassRules<AssignmentDto>(AssignmentsGridColumn.TonnageContractType),
    },
    {
      field: AssignmentsGridColumn.TonnageVessel,
      headerName: 'Vessel',
      headerComponentParams: vesselHeaderComponentParams,
      tooltipField: isFeatureFlagActive(FeatureFlagsEnum.ff_vesselCellDetails)
        ? AssignmentsGridColumn.TonnageVessel
        : `${AssignmentsGridColumn.TonnageVessel}.name`,
      tooltipComponent: isFeatureFlagActive(FeatureFlagsEnum.ff_vesselCellDetails) ? VesselCellTooltip : CellTooltip,
      tooltipComponentParams: { isContentInElementTruncated },
      editable: hasWriteRights,
      cellEditorParams: {
        maxLength: StringLenghts.SMALL,
        testIdPrefix: 'assignments-grid-vessel',
      },
      minWidth: 160,
      cellEditor: VesselSearchEditor,
      cellClassRules: {
        ...getCellClassRules<AssignmentDto>(AssignmentsGridColumn.TonnageVessel),
        [notLinkedApiIndicatorClass]: (params) => params.value?.name && params.value?.seaId == null,
      },
      cellRendererParams: { dataTestIdPrefix: 'cargo' },
      valueFormatter: vesselFormatter,
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        return params.event.key === KeyNames.ENTER || suppressKeyboardEvent<AssignmentRowData>(params);
      },
      comparator: vesselComparator,
    },
    {
      field: AssignmentsGridColumn.TonnageOwner,
      headerName: 'Owner',
      editable: hasWriteRights,
      tooltipField: AssignmentsGridColumn.TonnageOwner,
      tooltipComponentParams: { isContentInElementTruncated },
      cellEditorParams: {
        maxLength: StringLenghts.SMALL,
      },
      minWidth: 140,
      cellClassRules: getCellClassRules<AssignmentDto>(AssignmentsGridColumn.TonnageOwner),
    },

    {
      field: AssignmentsGridColumn.TonnageEta,
      headerName: 'ETA',
      valueFormatter: dateValueFormatter,
      editable: hasWriteRights,
      cellEditor: DateCellEditor,
      minWidth: 90,
      cellClassRules: getCellClassRules<AssignmentDto>(AssignmentsGridColumn.TonnageEta),
    },
    {
      field: AssignmentsGridColumn.TargetEta,
      headerName: 'Target ETA',
      valueFormatter: dateValueFormatter,
      editable: hasWriteRights,
      cellEditor: DateCellEditor,
      minWidth: 100,
      cellClassRules: getCellClassRules<AssignmentDto>('targetEta'),
    },
    {
      field: AssignmentsGridColumn.TonnageEtb,
      headerName: 'ETB',
      valueFormatter: dateValueFormatter,
      editable: hasWriteRights,
      cellEditor: DateCellEditor,
      minWidth: 90,
      cellClassRules: getCellClassRules<AssignmentDto>(AssignmentsGridColumn.TonnageEtb),
    },
    {
      field: AssignmentsGridColumn.TonnageFreightRate,
      headerName: 'Freight Rate',
      headerComponentParams: freightRateHeaderComponentParams,
      editable: hasWriteRights,
      tooltipField: AssignmentsGridColumn.TonnageFreightRate,
      tooltipComponentParams: { isContentInElementTruncated },
      valueFormatter: decimalValueFormatter,
      cellEditor: NumericCellEditor,
      cellEditorParams: {
        isFloat: true,
      },
      minWidth: 90,

      cellClassRules: getCellClassRules<AssignmentDto>(AssignmentsGridColumn.TonnageFreightRate),
    },

    {
      field: AssignmentsGridColumn.TonnageDemurrage,
      headerName: 'Demurrage',
      headerComponentParams: demurrageComponentParams,
      editable: hasWriteRights,
      tooltipField: AssignmentsGridColumn.TonnageDemurrage,
      tooltipComponentParams: { isContentInElementTruncated },
      valueFormatter: decimalValueFormatter,
      cellEditor: NumericCellEditor,
      cellEditorParams: {
        isFloat: true,
      },
      minWidth: 90,
      cellClassRules: getCellClassRules<AssignmentDto>(AssignmentsGridColumn.TonnageDemurrage),
    },
    {
      field: AssignmentsGridColumn.Notes,
      headerName: 'Notes',
      editable: hasWriteRights,
      cellEditorPopup: true,
      cellEditor: TextareaCellEditor,
      cellEditorParams: {
        gridId: GridId.cargoAssigment,
      },
      cellEditorPopupPosition: 'under',
      tooltipField: 'notes',
      tooltipComponentParams: { isContentInElementTruncated },
      minWidth: 250,
      cellClassRules: getCellClassRules<AssignmentDto>(AssignmentsGridColumn.Notes),
    },
    {
      field: AssignmentsGridColumn.TonnageRefId,
      headerName: 'Tonnage Reference ID',
      editable: hasWriteRights,
      tooltipField: AssignmentsGridColumn.TonnageRefId,
      tooltipComponentParams: { isContentInElementTruncated },
      cellEditorParams: {
        maxLength: StringLenghts.SMALL,
      },
      minWidth: 100,
      cellClassRules: getCellClassRules<AssignmentDto>(AssignmentsGridColumn.TonnageRefId),
    },
    {
      field: AssignmentsGridColumn.Options,
      headerName: '',
      editable: false,
      sortable: true,
      filter: false,
      lockPosition: 'right',
      maxWidth: !isFeatureFlagActive(FeatureFlagsEnum.copyAssignmentToClipboard) ? (hasWriteRights ? 90 : 50) : 90,
      minWidth: !isFeatureFlagActive(FeatureFlagsEnum.copyAssignmentToClipboard) ? (hasWriteRights ? 90 : 50) : 90,
      cellRenderer: AssignmentsActionsCellRenderer,
      cellRendererParams: { handleDelete, handleUnassign, handleDuplicate, handleCopyToClipboard },
      comparator: assignmentStatusComparator,
      cellClass: 'only-left-border',
      pinned: 'right',
      suppressNavigable: true,
      cellRendererSelector: supressCellRendererSelection,
    },
  ]);

  //Remove below useeEffect with ff_ownerRateDemurrageColumns feature flag
  useEffect(() => {
    if (isFeatureFlagActive(FeatureFlagsEnum.ff_ownerRateDemurrageColumns)) {
      return;
    }

    const filteredColumnDefinitions = columnDefs.filter((columnDefinition) => {
      if (
        columnDefinition.field &&
        [
          AssignmentsGridColumn.TonnageFreightRate,
          AssignmentsGridColumn.TonnageDemurrage,
          AssignmentsGridColumn.TonnageOwner,
        ].includes(columnDefinition?.field as AssignmentsGridColumn)
      ) {
        return;
      }
      return columnDefinition;
    });

    setColumnDefs(filteredColumnDefinitions);
  }, [isFeatureFlagActive(FeatureFlagsEnum.ff_ownerRateDemurrageColumns)]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      minWidth: 65,
      flex: 1,
      resizable: true,
      sortable: true,
      tooltipComponent: CellTooltip,
      lockPinned: true,
      cellRendererSelector: cellRendererSelector<AssignmentRowData>,
      cellRendererParams: {
        retryUpdateCell: updateCell,
      },
      suppressCellFlash: true,
      suppressKeyboardEvent: suppressKeyboardEvent<AssignmentRowData>,
      suppressMenu: true,
      comparator: nullsLastComparator,
    }),
    []
  );

  const autoWidthLastColumn = (e: DragStoppedEvent) => {
    adjustLastColumnWidth(GridId.cargoAssigment, e.columnApi);
  };

  return {
    autoWidthLastColumn,
    columnDefs,
    defaultColDef,
    onCellEditingStopped,
    getRowId: getRowId<AssignmentRowData>,
    onCellFocused,
    onRowSelected: onRowSelected<AssignmentRowData>,
    addEmptyRow: addEmptyRow<AssignmentRowData>,
    loadingOverlayComponent,
    noRowsOverlayComponent,
    unassignTonnage,
    unassignCargo,
    updateTonnageDetails,
    updateTonnageLinkedAssignmentsIds,
    unhighlightOnMouseUp,
    updateGridState,
    applyGridState,
    selectAll,
    deselectAll,
    rowClassRules,
    excelStyles,
    exportDataToExcel,
  };
};
