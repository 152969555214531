import React, { useState } from 'react';
import { Collapse, styled } from '@mui/material';
import { ReactComponent as NoEditIcon } from '../../assets/noEditIcon.svg';
import { orange } from '../../themes/palette';
import { useAuth } from '../../contexts';
import { getItem, LOCAL_STORAGE_KEYS, setItem } from '../../helpers/localStorage';
import { OrangeButton } from '../common/OrangeButton';

// TODO before completing US #182337 - Write tests in #183848 task (https://dev.azure.com/clarksonscloud/Fix/_sprints/taskboard/Freight%20Planner%20team/Fix/Sprint%2013?workitem=183848)
export const ViewOnlyModeBanner: React.FC = () => {
  const auth = useAuth();
  const [showBanner, setShowBanner] = useState<boolean>(getItem(LOCAL_STORAGE_KEYS.SHOW_VIEW_ONLY_MODE_BANNER) ?? true);
  const hideBanner = () => {
    setShowBanner(false);
    setItem(LOCAL_STORAGE_KEYS.SHOW_VIEW_ONLY_MODE_BANNER, false);
  };

  const logOut = () => {
    auth?.userManager?.signoutRedirect();
  };

  return (
    // Set minHeight to 'initial' to overwrite Collapse element default minHeight: 0
    // minHeight: 0 was causing to display smaller banner than expected (bottom part was cut)
    <Collapse in={showBanner} style={{ minHeight: 'initial' }}>
      <StyledContainer>
        <StyledNoEditIcon />

        <StyledTextContainer>
          <StyledTitle>View Only</StyledTitle>
          <StyledDescription>
            Your user account has view-only access to Freight Planner. To make changes, please contact the owner or administrator.
          </StyledDescription>
        </StyledTextContainer>

        <StyledButtonsContainer>
          <OrangeButton onClick={hideBanner} data-testid="button-view-only-mode-dismiss">
            Dismiss
          </OrangeButton>
          <OrangeButton variant="contained" onClick={logOut} data-testid="button-view-only-mode-banner-logout">
            Switch User
          </OrangeButton>
        </StyledButtonsContainer>
      </StyledContainer>
    </Collapse>
  );
};

const StyledContainer = styled('div')({
  display: 'flex',
  backgroundColor: orange.o100,
  padding: '7px 24px',
});

const StyledNoEditIcon = styled(NoEditIcon)({
  height: 22,
  width: 22,
  margin: '7px 12px 7px 0',
});

const StyledTextContainer = styled('div')({
  margin: '7px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
});

const StyledTitle = styled('span')({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: '.15px',
  color: orange.o20,
});

const StyledDescription = styled('span')({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '20.02px',
  letterSpacing: '.17px',
  color: orange.o20,
});

const StyledButtonsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  padding: '12.5px 0 12.5px 16px',
  marginLeft: 'auto',
});
