import { PayloadAction, createAsyncThunk, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { addAbortSignalListener, REQUEST_STATUS } from '../helpers';
import {
  AssignCommand,
  AssignmentDto,
  CreateAndAssignCommand,
  CreateAssignmentCommand,
  UnassignCommand,
  UpdateAssignmentCommand,
  UpdateCargoCommand,
  UpdateFormattingDto,
} from '../../api/web-api-client';
import { ApiManager } from '../../contexts/ApiManager';
import { showNotificationSnackbar } from './notifications.slice';
import { setIsOpenDuplicateBatchOfCargoesDialog } from './dialogs.slice';
import { ERROR_MSG } from '../../components/common/Constants';
import { ErrorCounterType, incrementErrorCounter } from './errors.slice';

export enum AssignmentsActions {
  GET_ASSIGNMENTS = 'getAssignments',
  ASSIGN_TONNAGE = 'assignTonnage',
  UPDATE_ASSIGNMENT_CARGO = 'updateAssignmentCargo',
  UPDATE_ASSIGNMENT = 'updateAssignment',
  ADD_ASSIGNMENT = 'addAssignment',
  DUPLICATE_ASSIGNMENT_CARGO = 'duplicateAssignmentCargo',
  REMOVE_ASSIGNMENT = 'removeAssignment',
  UNASSIGN_TONNAGE = 'unassignTonnage',
  ADD_AND_ASSIGN_TONNAGE = 'addAndAssignTonnage',
  UPDATE_ASSIGNMENT_FORMATTING_METADATA = 'updateAssignmentFormattingMetadata',
  DUPLICATE_ASSIGNMENT_CARGOES = 'duplicateAssignmentCargoes',
}

export type AssignmentsState = {
  status: {
    [key in AssignmentsActions]?: REQUEST_STATUS;
  };
  isBatchActionSelectBoxActive: boolean;
  isCustomisationDrawerOpen: boolean;
  selectedRows: Set<number>;
  allRowsCargoIds: number[];
};

export const assignmentsInitialState: AssignmentsState = {
  status: {},
  isBatchActionSelectBoxActive: false,
  isCustomisationDrawerOpen: false,
  selectedRows: new Set(),
  allRowsCargoIds: [],
};

export interface IGetAssignmentsPayload {
  dateRangeFrom?: string;
  dateRangeTo?: string;
  planId?: number;
}

interface IDuplicateAssignmentCargoesPayload {
  cargoIds: number[];
  monthDifference: number;
}

export interface IAssignTonnagePayload extends AssignCommand {}
export interface IUpdateAssignmentPayload extends UpdateAssignmentCommand {}
export interface IUpdateAssignmentCargoPayload extends UpdateCargoCommand {}
export interface IAddAssignmentPayload extends CreateAssignmentCommand {}
export interface IDuplicateAssignmentCargoPayload extends AssignmentDto {}
export interface IUnassignTonnagePayload extends UnassignCommand {}
export interface IAddAndAssignTonnage extends CreateAndAssignCommand {}
export interface IUpdateAssignmentFormattingMetadataPayload extends UpdateFormattingDto {}

export const getAssignments = createAsyncThunk(
  AssignmentsActions.GET_ASSIGNMENTS,
  async (payload: IGetAssignmentsPayload, { signal, getState }) => {
    addAbortSignalListener(signal);
    try {
      const { dateRangeFrom, dateRangeTo, planId } = payload;
      const state = (await getState()) as { api: { apiManager: ApiManager } };
      const { assignments } = await state.api.apiManager.assignmentsAPI.getAssignments(dateRangeFrom, dateRangeTo, planId);

      return assignments ?? [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const assignTonnage = createAsyncThunk(
  AssignmentsActions.ASSIGN_TONNAGE,
  async (payload: IAssignTonnagePayload, { signal, getState }) => {
    addAbortSignalListener(signal);
    try {
      const { assignmentId, tonnageId } = payload;
      const state = (await getState()) as { api: { apiManager: ApiManager } };
      return await state.api.apiManager.assignmentsAPI.assign({ assignmentId, tonnageId });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const updateAssignment = createAsyncThunk(
  AssignmentsActions.UPDATE_ASSIGNMENT,
  async (payload: IUpdateAssignmentPayload, { signal, getState, dispatch, rejectWithValue }) => {
    addAbortSignalListener(signal);
    try {
      const { assignmentId, ...patchProperties } = payload;
      const state = (await getState()) as { api: { apiManager: ApiManager } };
      return await state.api.apiManager.assignmentsAPI.update({
        assignmentId: assignmentId!,
        ...patchProperties,
      });
    } catch (error) {
      dispatch(incrementErrorCounter(ErrorCounterType.editingCellErrorCounter));
      return rejectWithValue(error);
    }
  }
);

export const addAssignment = createAsyncThunk(
  AssignmentsActions.ADD_ASSIGNMENT,
  async (payload: IAddAssignmentPayload, { signal, getState }) => {
    addAbortSignalListener(signal);
    try {
      const state = (await getState()) as { api: { apiManager: ApiManager } };
      const response = await state.api.apiManager.assignmentsAPI.create(payload);
      return {
        id: response?.assignmentId,
        formatting: null,
        cargo: {
          id: response?.cargoId,
          formatting: null,
        },
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const duplicateAssignmentCargo = createAsyncThunk(
  AssignmentsActions.DUPLICATE_ASSIGNMENT_CARGO,
  async (payload: IDuplicateAssignmentCargoPayload, { signal, getState }) => {
    addAbortSignalListener(signal);
    try {
      const cargoId = payload.cargo!.id as number;
      const state = (await getState()) as { api: { apiManager: ApiManager } };
      const response = await state.api.apiManager.assignmentsAPI.duplicateCargos({ cargoIds: [cargoId], monthsShift: 0 });
      return response[0];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const duplicateAssignmentCargoes = createAsyncThunk(
  AssignmentsActions.DUPLICATE_ASSIGNMENT_CARGOES,
  async ({ cargoIds, monthDifference }: IDuplicateAssignmentCargoesPayload, { signal, getState, dispatch }) => {
    addAbortSignalListener(signal);

    try {
      const state = (await getState()) as { api: { apiManager: ApiManager } };
      const res = await state.api.apiManager.assignmentsAPI.duplicateCargos({ cargoIds, monthsShift: monthDifference });
      dispatch(setIsOpenDuplicateBatchOfCargoesDialog(false));
      return res;
    } catch (error) {
      dispatch(showNotificationSnackbar({ type: 'error', title: ERROR_MSG }));
      console.error(error);
      throw error;
    }
  }
);

export const updateAssignmentCargo = createAsyncThunk(
  AssignmentsActions.UPDATE_ASSIGNMENT_CARGO,
  async (payload: IUpdateAssignmentCargoPayload, { signal, getState, rejectWithValue, dispatch }) => {
    addAbortSignalListener(signal);
    try {
      const { cargoId, ...patchProperties } = payload;
      const state = (await getState()) as { api: { apiManager: ApiManager } };
      return await state.api.apiManager.cargosAPI.updateCargo({
        cargoId,
        ...patchProperties,
      });
    } catch (error) {
      dispatch(incrementErrorCounter(ErrorCounterType.editingCellErrorCounter));
      return rejectWithValue(error);
    }
  }
);

export const removeAssignment = createAsyncThunk(
  AssignmentsActions.REMOVE_ASSIGNMENT,
  async (payload: number, { signal, getState }) => {
    addAbortSignalListener(signal);
    try {
      const state = (await getState()) as { api: { apiManager: ApiManager } };
      return state.api.apiManager.cargosAPI.delete(payload);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const unassignTonnage = createAsyncThunk(
  AssignmentsActions.UNASSIGN_TONNAGE,
  async (payload: IUnassignTonnagePayload, { signal, getState }) => {
    addAbortSignalListener(signal);
    try {
      const { assignmentId } = payload;
      const state = (await getState()) as { api: { apiManager: ApiManager } };
      return state.api.apiManager.assignmentsAPI.unassign({ assignmentId });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const addAndAssignTonnage = createAsyncThunk(
  AssignmentsActions.ADD_AND_ASSIGN_TONNAGE,
  async (payload: IAddAndAssignTonnage, { signal, getState, dispatch, rejectWithValue }) => {
    addAbortSignalListener(signal);
    try {
      const state = (await getState()) as { api: { apiManager: ApiManager } };
      return await state.api.apiManager.assignmentsAPI.createAndAssignTonnage(payload);
    } catch (error) {
      dispatch(incrementErrorCounter(ErrorCounterType.editingCellErrorCounter));
      return rejectWithValue(error);
    }
  }
);

export const updateAssignmentFormattingMetadata = createAsyncThunk(
  AssignmentsActions.UPDATE_ASSIGNMENT_FORMATTING_METADATA,
  async (payload: IUpdateAssignmentFormattingMetadataPayload, { signal, getState, rejectWithValue }) => {
    const source = addAbortSignalListener(signal);

    try {
      const state = (await getState()) as { api: { apiManager: ApiManager } };
      return state.api.apiManager.assignmentsAPI.updateFormattingMetadata(payload, source.token);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const assignmentsSlice = createSlice({
  name: 'assignments',
  initialState: assignmentsInitialState,
  reducers: {
    toggleCustomisationDrawer: (state) => {
      state.isCustomisationDrawerOpen = !state.isCustomisationDrawerOpen;
    },
    toggleBatchActionsSelectBox: (state) => {
      state.isBatchActionSelectBoxActive = !state.isBatchActionSelectBoxActive;
    },
    deactivateBatchActionsSelectBox: (state) => {
      state.isBatchActionSelectBoxActive = false;
    },
    selectCargo: (state, action: PayloadAction<number>) => {
      state.selectedRows.add(action.payload);
    },
    deselectCargo: (state, action: PayloadAction<number>) => {
      state.selectedRows.delete(action.payload);
    },
    selectAllCargoes: (state) => {
      state.selectedRows = new Set(state.allRowsCargoIds);
    },
    deselectAllCargoes: (state) => {
      state.selectedRows = new Set();
    },
    setAllRowsIds: (state, action: PayloadAction<number[]>) => {
      state.allRowsCargoIds = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAssignments.fulfilled, (state) => {
      state.status[AssignmentsActions.GET_ASSIGNMENTS] = REQUEST_STATUS.fulfilled;
    });
    builder.addCase(getAssignments.pending, (state) => {
      state.status[AssignmentsActions.GET_ASSIGNMENTS] = REQUEST_STATUS.pending;
    });
    builder.addCase(getAssignments.rejected, (state) => {
      state.status[AssignmentsActions.GET_ASSIGNMENTS] = REQUEST_STATUS.rejected;
    });

    builder.addCase(duplicateAssignmentCargoes.fulfilled, (state) => {
      state.status[AssignmentsActions.DUPLICATE_ASSIGNMENT_CARGOES] = REQUEST_STATUS.fulfilled;
    });

    builder.addMatcher(isPending(duplicateAssignmentCargoes), (state) => {
      state.status[AssignmentsActions.DUPLICATE_ASSIGNMENT_CARGOES] = REQUEST_STATUS.pending;
    });
    builder.addMatcher(isRejected(duplicateAssignmentCargoes), (state) => {
      state.status[AssignmentsActions.DUPLICATE_ASSIGNMENT_CARGOES] = REQUEST_STATUS.rejected;
    });
  },
});

export const {
  setAllRowsIds,
  toggleBatchActionsSelectBox,
  toggleCustomisationDrawer,
  deactivateBatchActionsSelectBox,
  selectCargo,
  deselectCargo,
  selectAllCargoes,
  deselectAllCargoes,
} = assignmentsSlice.actions;

export default assignmentsSlice.reducer;
