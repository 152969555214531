import React from 'react';
import { Button, styled } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import { neutral, orange } from '../../themes/palette';

interface IOrangeButton extends ButtonProps {}

// extracted "sx" prop outside "props" because "sx" is incompatible with "styled" interface
export const OrangeButton: React.FC<IOrangeButton> = ({ sx, children, ...rest }) => {
  return (
    <>
      {rest.variant === 'contained' ? (
        <StyledContainedButton {...rest}>{children}</StyledContainedButton>
      ) : (
        <StyledTextButton {...rest}>{children}</StyledTextButton>
      )}
    </>
  );
};

const StyledTextButton = styled(Button)({
  height: '36px',
  minWidth: 'initial',
  fontWeight: 600,
  color: orange.o20,
  borderRadius: '4px',
  padding: '6px 8px',
  '&:hover': {
    backgroundColor: orange.o2,
  },
});

const StyledContainedButton = styled(Button)({
  height: '36px',
  minWidth: 'initial',
  fontWeight: 600,
  color: neutral.n100,
  borderRadius: '4px',
  padding: '6px 16px',
  backgroundColor: orange.o20,
  '&:hover': {
    backgroundColor: orange.o15,
  },
});
