import { IRowNode } from 'ag-grid-community';
import { LinkedTonnageDto } from '../../../../api/web-api-client';

export const tonnageStatusComparator = (
  _valueA: unknown,
  _valueB: unknown,
  nodeA: IRowNode<LinkedTonnageDto>,
  nodeB: IRowNode<LinkedTonnageDto>
) => {
  const statusA = nodeA.data?.linkedAssignmentsIds?.length ? 1 : 0;
  const statusB = nodeB.data?.linkedAssignmentsIds?.length ? 1 : 0;
  return statusA - statusB;
};
