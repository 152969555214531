import React from 'react';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import {
  ActionsCellRenderer,
  StyledAddIcon,
  StyledDeleteSpan,
  StyledMenuItem,
  StyledTrashIcon,
  StyledUnlinkIcon,
} from '../../../common/grid/ActionsCellRenderer';
import ConfirmationDialog from '../../../common/dialog/ConfirmationDialog';
import { ConfirmationDialogType, getWarningMsg } from '../../../common/dialog/helpers';
import { AssignmentDto } from '../../../../api/web-api-client';
import { AssignmentStatusIcon } from '../../../common/grid/AssignmentStatusIcon';
import { GridId } from '../../../common/grid/helpers';
import { styled } from '@mui/system';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useAuth } from '../../../../contexts';
import { useFeatureFlags } from '../../../../contexts/FeatureFlagsContext';
import { FeatureFlagsEnum } from '../../../../api/featureFlags';

interface IAssignmentsActionsCellRendererParams extends ICellRendererParams<AssignmentDto & { id: number }> {
  handleDelete: (id: number) => Promise<void>;
  handleUnassign: (id: number) => Promise<void>;
  handleDuplicate: (assignmentData?: AssignmentDto) => Promise<void>;
  handleCopyToClipboard: (rowNodeId: string) => void;
}

export const AssignmentsActionsCellRenderer: React.FC<IAssignmentsActionsCellRendererParams> = (props) => {
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = React.useState(false);
  const [isUnassignDialogOpen, setIsUnassignDialogOpen] = React.useState(false);
  const [forceCloseKebabMenu, setForceCloseKebabMenu] = React.useState(false);
  const { hasWriteRights } = useAuth();
  const { isFeatureFlagActive } = useFeatureFlags();

  const data = props.data;

  if (!data) {
    return <></>;
  }

  const onDeleteClick = () => {
    setIsDeletionDialogOpen(true);
  };

  const onUnassignClick = () => {
    setIsUnassignDialogOpen(true);
  };

  const onDuplicateClick = () => {
    setForceCloseKebabMenu(true);

    props.handleDuplicate(props.data);

    setTimeout(() => {
      setForceCloseKebabMenu(false);
    });
  };

  const onCopyToClipboardClick = () => {
    setForceCloseKebabMenu(true);

    props.handleCopyToClipboard(data.id.toString());

    setTimeout(() => {
      setForceCloseKebabMenu(false);
    });
  };

  return (
    <ActionsCellRenderer
      kebabTestId="assignment-grid-kebab-button"
      ariaLabel="assignment menu"
      isDialogOpen={isDeletionDialogOpen || isUnassignDialogOpen || forceCloseKebabMenu}
      preMenuIcon={
        <StyledStatusIconContainer>
          <AssignmentStatusIcon isLinked={!!props.data?.tonnage?.id} type={GridId.cargoAssigment} />
        </StyledStatusIconContainer>
      }
      gridId={GridId.cargoAssigment}
      dialogs={
        <>
          <ConfirmationDialog
            actionHandler={props.handleDelete}
            itemId={data.id}
            isConfirmationDialogOpen={isDeletionDialogOpen}
            setIsConfirmationDialogOpen={setIsDeletionDialogOpen}
            dialogType={
              data.tonnage?.id ? ConfirmationDialogType.deleteAssignedCargo : ConfirmationDialogType.deleteUnassignedCargo
            }
            warningMsg={getWarningMsg(data.tonnage)}
          />
          <ConfirmationDialog
            actionHandler={props.handleUnassign}
            itemId={data.id}
            isConfirmationDialogOpen={isUnassignDialogOpen}
            setIsConfirmationDialogOpen={setIsUnassignDialogOpen}
            dialogType={ConfirmationDialogType.removeTonnage}
          />
        </>
      }
      {...props}
    >
      {isFeatureFlagActive(FeatureFlagsEnum.copyAssignmentToClipboard) && (
        <StyledMenuItem onClick={onCopyToClipboardClick} data-testid="copy-cargo-to-clipboard-action">
          <StyledCopyIcon />
          Copy to clipboard
        </StyledMenuItem>
      )}
      {hasWriteRights && (
        <StyledMenuItem onClick={onDuplicateClick} data-testid="duplicate-cargo-action">
          <StyledAddIcon />
          Duplicate cargo
        </StyledMenuItem>
      )}
      {data.tonnage?.id && hasWriteRights && (
        <StyledMenuItem onClick={onUnassignClick} data-testid="remove-tonnage-action">
          <StyledUnlinkIcon />
          Remove tonnage
        </StyledMenuItem>
      )}
      {hasWriteRights && (
        <StyledMenuItem onClick={onDeleteClick} data-testid="delete-cargo-action">
          <StyledTrashIcon />
          <StyledDeleteSpan>Delete Cargo</StyledDeleteSpan>
        </StyledMenuItem>
      )}
    </ActionsCellRenderer>
  );
};

const StyledStatusIconContainer = styled('div')({
  paddingLeft: '13px',
  marginRight: '9px',
});

const StyledCopyIcon = styled(ContentCopyOutlinedIcon)({
  height: '24px',
  width: '24px',
  marginRight: '14px',
  fillOpacity: 0.54,
});
