import React from 'react';
import { styled } from '@mui/system';
import { grey } from '../../themes/palette';
import { Button, ButtonProps, Tooltip } from '@mui/material';

export interface IButtonIcon extends ButtonProps {
  icon: React.ReactNode;
  tooltip?: string;
}

// extracted "sx" prop outside "props" because "sx" is incompatible with "styled" interface
export const ButtonIcon: React.FC<IButtonIcon> = ({ sx, icon, tooltip, ...rest }) => {
  return (
    <Tooltip title={tooltip}>
      {/* Closed Button in span to make a Tooltip work */}
      <span>
        <StyledButton {...rest}>{icon}</StyledButton>
      </span>
    </Tooltip>
  );
};

const StyledButton = styled(Button)({
  backgroundColor: grey.g1,
  fontSize: '28.85px',
  padding: '8px',
  gap: '8px',
  width: '40px',
  height: '40px',
  minWidth: 'initial',
});
