import { ProcessRowParams } from 'ag-grid-community';
import { AssignmentDto, LinkedTonnageDto } from '../../../../../api/web-api-client';
import linkIcon from '../../../../../assets/linkedIcon.svg';
import { addOverlay, removePreviousOverlays, removeSourceOverlay } from '../../../../../helpers/dndOverlayUtils';
import { isDropTarget } from '../../../../common/grid/helpers';

export const targetOverlayClass = 'drag-target-overlay';

export function useTonnageDrop(onDrop: (vessel: LinkedTonnageDto, assignment: AssignmentDto) => void): {
  rowDragEnter: (event: DragEvent, params: ProcessRowParams<AssignmentDto>) => void;
  rowDragEnterPinned: (event: DragEvent, params: ProcessRowParams<AssignmentDto>) => void;
} {
  const overlayDragOver = (event: DragEvent) => {
    event.preventDefault();
    const dragSupported = event.dataTransfer?.types.length;
    if (dragSupported) {
      event.dataTransfer.dropEffect = 'copy';
      removeSourceOverlay();
    }
  };

  const overlayDrop = (event: DragEvent, assignment?: AssignmentDto) => {
    const tonnageData = parseTonnageData(event.dataTransfer);

    if (tonnageData && assignment) {
      onDrop(tonnageData, assignment);
    }
  };

  const overlayDragLeave = (event: DragEvent) => {
    const relatedTarget = event.relatedTarget as HTMLDivElement;
    if (!relatedTarget.classList.contains('drag-target-overlay')) {
      removePreviousOverlays();
    }
  };

  const rowDragEnter = (event: DragEvent, params?: ProcessRowParams<AssignmentDto>) => {
    event.preventDefault();

    const row = event.currentTarget as HTMLDivElement;
    if (row.getElementsByClassName('drag-target-overlay').length) {
      return;
    }

    removePreviousOverlays();

    row.setAttribute(isDropTarget, 'true');
    row && createTargetOverlay(row, params?.node.data);

    const pinnedRowRight = params?.ePinnedRightRow as HTMLDivElement;
    const pinnedRowLeft = params?.ePinnedLeftRow as HTMLDivElement;

    pinnedRowRight && pinnedRowRight.setAttribute(isDropTarget, 'true');
    pinnedRowLeft && pinnedRowLeft.setAttribute(isDropTarget, 'true');

    pinnedRowRight && createTargetOverlay(pinnedRowRight, params?.node.data, true);
    pinnedRowLeft && createTargetOverlay(pinnedRowLeft, params?.node.data, true);
  };

  const rowDragEnterPinned = (event: DragEvent, params?: ProcessRowParams<AssignmentDto>) => {
    event.preventDefault();
    const pinnedRow = event.currentTarget as HTMLDivElement;

    if (pinnedRow.getElementsByClassName('drag-target-overlay').length) {
      return;
    }

    removePreviousOverlays();
    pinnedRow.setAttribute(isDropTarget, 'true');
    params?.eRow.setAttribute(isDropTarget, 'true');
    params?.ePinnedLeftRow?.setAttribute(isDropTarget, 'true');

    params?.eRow && createTargetOverlay(params.eRow as HTMLDivElement, params?.node.data);
    params?.ePinnedLeftRow && createTargetOverlay(params.ePinnedLeftRow as HTMLDivElement, params?.node.data, true);
    pinnedRow && createTargetOverlay(pinnedRow, params?.node.data, true);
  };

  function createTargetOverlay(row: HTMLDivElement, assignment?: AssignmentDto, pinned = false) {
    let backgroundImageOffset = 0;
    const centerGridViewport = document.querySelector(`#cargo-assignment-grid .ag-center-cols-viewport`);

    if (centerGridViewport) {
      backgroundImageOffset = centerGridViewport?.clientWidth / 2 + centerGridViewport?.scrollLeft;
    }

    const overlay = addOverlay(row, targetOverlayClass, pinned ? undefined : linkIcon, backgroundImageOffset);
    overlay.addEventListener('dragleave', overlayDragLeave);
    overlay.addEventListener('drop', (dropEvent: DragEvent) => overlayDrop(dropEvent, assignment));
    overlay.addEventListener('dragover', overlayDragOver);
  }

  function parseTonnageData(dataTransfer: DataTransfer | null): LinkedTonnageDto | undefined {
    const jsonData = dataTransfer?.getData('application/json');
    if (jsonData) {
      return JSON.parse(jsonData) as LinkedTonnageDto;
    }
    return undefined;
  }

  return { rowDragEnter, rowDragEnterPinned };
}
