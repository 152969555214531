import dayjs from 'dayjs';

interface DateRangeString {
  from: string;
  to: string;
}

export enum LOCAL_STORAGE_KEYS {
  CURRENT_PLAN = 'currentPlan',
  DASHBOARD_DATE_RANGE = 'dashboardDateRange',
  HIDE_TONNAGE_GRID = 'hideTonnageGrid',
  HIGHLIGHT_COLOR = 'highlightColor',
  SHOW_VIEW_ONLY_MODE_BANNER = 'showViewOnlyModeBanner',
  DASHBOARD_DATE_RANGE_TYPE = 'dashboardDateRangeType',
  ASSIGNMENTS_DASHBOARD_WIDTH = 'assignmentsDashboardWidth',
}

export const setItem = <T>(key: LOCAL_STORAGE_KEYS, value: T): void => {
  if (value !== undefined) {
    const stringifiedValue = JSON.stringify(value);
    localStorage.setItem(key, stringifiedValue);
  }
};

export const getItem = <T>(key: LOCAL_STORAGE_KEYS): T | undefined => {
  const jsonValue = localStorage.getItem(key);
  if (jsonValue !== null) {
    if (key === LOCAL_STORAGE_KEYS.DASHBOARD_DATE_RANGE) {
      const parsedValue: DateRangeString = JSON.parse(jsonValue);
      return {
        from: dayjs(parsedValue.from),
        to: dayjs(parsedValue.to),
      } as T;
    }
    return JSON.parse(jsonValue);
  }

  return undefined;
};
