import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Typography } from '@mui/material';
import { TonnageDto } from '../../../api/web-api-client';
import { ActionButtonColor } from './Dialog';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { neutral } from '../../../themes/palette';

export enum ConfirmationDialogType {
  deleteAssignedCargo = 'delete-assigned-cargo',
  deleteUnassignedCargo = 'delete-unassigned-cargo',
  deleteUnassignedTonnage = 'delete-unassigned-tonnage',
  deleteAssignedTonnage = 'delete-assigned-tonnage',
  duplicateCargoes = 'duplicate-cargoes',
  removeTonnage = 'remove-tonnage',
  removeTonnageFromCargo = 'remove-tonnage-from-cargo',
  replaceTonnageOnDrop = 'replace-tonnage-on-drop',
  reassignTonnageOnDrop = 'reassign-tonnage-on-drop',
  updateTonnageAssignmentsOnDrop = 'update-tonnage-assignments-on-drop',
}

interface ConfirmationDialogProps {
  actionButtonColor?: ActionButtonColor;
  actionBtnContent: React.ReactNode;
  description: string;
  loadingLabel: string;
  title?: (value?: number) => string;
}

type DialogPresets = { [key in ConfirmationDialogType]: ConfirmationDialogProps };

const StyledDeleteIcon = styled(DeleteIcon)({
  fontSize: '18px',
  marginBottom: '2px',
  marginRight: '10px',
});
export const StyledAddIcon = styled(AddCircleOutlineOutlinedIcon)({
  fill: neutral.n100,
  height: '22px',
  width: '22px',
  marginRight: '8.63px',
});

const StyledLinkOffIcon = styled(LinkOffIcon)({
  fontSize: '25px',
  marginRight: '10px',
  marginBottom: '2px',
});

const DeleteButtonContent = () => {
  return (
    <>
      <StyledDeleteIcon />
      Delete
    </>
  );
};

const RemoveButtonContent = () => {
  return (
    <>
      <StyledLinkOffIcon />
      Remove
    </>
  );
};

const DuplicateButtonContent = () => {
  return (
    <>
      <StyledAddIcon />
      Duplicate
    </>
  );
};

const DefaultButtonContent = (props: { label: string }) => {
  return <>{props.label}</>;
};

export const DialogPresets = {
  [ConfirmationDialogType.deleteUnassignedCargo]: {
    actionButtonColor: 'error' as ActionButtonColor,
    actionBtnContent: <DeleteButtonContent />,
    description: 'All cargo details will be deleted.',
    loadingLabel: 'Deleting cargo...',
    title: (id?: number) => `Delete cargo #${id}?`,
  },
  [ConfirmationDialogType.deleteAssignedCargo]: {
    actionButtonColor: 'error' as ActionButtonColor,
    actionBtnContent: <DeleteButtonContent />,
    description: 'The tonnage will be removed from this assignment and the cargo deleted.',
    loadingLabel: 'Deleting cargo...',
    title: (id?: number) => `Delete cargo #${id}?`,
  },
  [ConfirmationDialogType.deleteUnassignedTonnage]: {
    actionButtonColor: 'error' as ActionButtonColor,
    actionBtnContent: <DeleteButtonContent />,
    description: 'All tonnage details will be deleted.',
    loadingLabel: 'Deleting tonnage...',
    title: () => `Delete tonnage?`,
  },
  [ConfirmationDialogType.deleteAssignedTonnage]: {
    actionButtonColor: 'error' as ActionButtonColor,
    actionBtnContent: <DeleteButtonContent />,
    description: 'The tonnage will be removed from its assignment and deleted.',
    loadingLabel: 'Deleting tonnage...',
    title: () => `Delete tonnage?`,
  },
  [ConfirmationDialogType.duplicateCargoes]: {
    actionBtnContent: <DuplicateButtonContent />,
    description: 'Cargo details will be copied to new cargo assignments.',
    loadingLabel: 'Duplicating cargoes...',
    title: (cargosNumber?: number) => `Duplicate ${cargosNumber} ${cargosNumber === 1 ? 'cargo' : 'cargoes'}?`,
  },
  [ConfirmationDialogType.removeTonnage]: {
    actionBtnContent: <RemoveButtonContent />,
    description: 'The tonnage will be removed from this cargo but it will still be available in the Tonnage list.',
    loadingLabel: 'Removing tonnage...',
    title: () => `Remove tonnage?`,
  },
  [ConfirmationDialogType.removeTonnageFromCargo]: {
    actionBtnContent: <RemoveButtonContent />,
    description: 'This tonnage will be removed from its cargo assignment but will remain in the Tonnage list.',
    loadingLabel: 'Removing tonnage...',
    title: () => `Remove from cargo?`,
  },
  [ConfirmationDialogType.replaceTonnageOnDrop]: {
    actionBtnContent: <DefaultButtonContent label={'Replace'} />,
    description: 'This cargo already has tonnage assigned. Current tonnage will be removed and selected tonnage will replace it.',
    title: () => `Replace tonnage?`,
  },
  [ConfirmationDialogType.reassignTonnageOnDrop]: {
    actionBtnContent: <DefaultButtonContent label={'Reassign'} />,
    description: 'This tonnage is assigned to another cargo. It will be reassigned to this cargo.',
    title: () => `Reassign tonnage?`,
  },
  [ConfirmationDialogType.updateTonnageAssignmentsOnDrop]: {
    actionBtnContent: <DefaultButtonContent label={'Update'} />,
    description:
      'This tonnage and cargo have other assignments. Current cargo tonnage will be removed and selected tonnage reassigned to replace it.',
    title: () => `Update tonnage assignments?`,
  },
};

const getTonnageWarnMsg = (cargoIds?: number[]) =>
  !!cargoIds?.length && `This tonnage is assigned to Cargo ${cargoIds.map((id) => `#${id}`).join(', ')}`;

const StyledMsg = styled(Typography)({
  fontSize: '14px',
  fontWeight: 500,
});

type CreateDetails = (contractType?: string, vesselName?: string | null) => string;

export const createDetails: CreateDetails = (contractType = '', vesselName = '') => {
  if (!contractType && vesselName) {
    return vesselName;
  } else if (contractType && !vesselName) {
    return contractType;
  } else if (!contractType && !vesselName) {
    return '';
  }
  return `${contractType} - ${vesselName}`;
};

const getAssignmentWarnMsg = (tonnage?: TonnageDto | null) =>
  tonnage?.id && (
    <StyledMsg>
      This cargo has a tonnage assigned: <br />
      <strong>{createDetails(tonnage?.contractType, tonnage?.vessel?.name)}</strong>
    </StyledMsg>
  );

export const getWarningMsg = (value?: TonnageDto | number[] | null): string | React.ReactNode =>
  Array.isArray(value) ? getTonnageWarnMsg(value) : getAssignmentWarnMsg(value);
