import React from 'react';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { styled } from '@mui/system';
import { blue, green, neutral } from '../../../../themes/palette';
import { useTonnageDrag } from './hooks/useTonnageDrag';
import { AssignmentStatusIcon } from '../../../common/grid/AssignmentStatusIcon';
import { GridId } from '../../../common/grid/helpers';
import { useAuth } from '../../../../contexts/AuthContext';

const StyledDragSourceContainer = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledDragIndicator = styled(DragIndicatorIcon)((props: { islinked: number }) => ({
  fill: props.islinked ? neutral.n65 : green.g50,
  height: '22px',
  cursor: 'grab',
  '&[islinked="0"]:hover': {
    fill: blue.b70,
  },
}));

interface ITonnageStatusCellRendererParams extends ICellRendererParams {
  onDropped: (tonnageId: number) => void;
}

export const TonnageStatusCellRenderer: React.FC<ITonnageStatusCellRendererParams> = ({ data }) => {
  const { onDragStart, onDragEnd, onDrag } = useTonnageDrag(data);
  const { hasWriteRights } = useAuth();

  return (
    <StyledDragSourceContainer
      data-testid="tonnage-drag-cell"
      draggable={hasWriteRights}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDrag={onDrag}
    >
      {hasWriteRights && <StyledDragIndicator id="dragIndicator" islinked={data.linkedAssignmentsIds?.length ? 1 : 0} />}
      <AssignmentStatusIcon isLinked={!!data.linkedAssignmentsIds?.length} type={GridId.tonnage} />
    </StyledDragSourceContainer>
  );
};
