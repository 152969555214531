import { useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, DragStoppedEvent, SuppressKeyboardEventParams } from 'ag-grid-community';
import {
  dateRangeValueFormatter,
  dateValueFormatter,
  decimalValueFormatter,
  vesselFormatter,
} from '../../../../../helpers/gridValueFormatters';
import { isContentInElementTruncated } from '../../../../../helpers/helpers';
import CellTooltip from '../../../../common/grid/CellTooltip';
import { useDefaultGridOptions } from '../../../../common/grid/hooks/useDefaultGridOptions';
import DateCellEditor from '../../../../common/grid/DateCellEditor';
import dateRangeCellEditor from '../../../../common/grid/DateRangeCellEditor';
import { KeyNames, StringLenghts } from '../../../../common/Constants';
import { TonnageActionsCellRenderer } from '../TonnageActionsCellRenderer';
import { GridId, adjustLastColumnWidth, notLinkedApiIndicatorClass } from '../../../../common/grid/helpers';
import { useCellFormatting } from '../../../../common/grid/hooks/useCellFormatting/useCellFormatting';
import TextareaCellEditor from '../../../../common/grid/TextareaCellEditor/TextareaCellEditor';
import { FeatureFlagsEnum } from '../../../../../api/featureFlags';
import { useFeatureFlags } from '../../../../../contexts/FeatureFlagsContext';
import { useAuth } from '../../../../../contexts';
import { dateRangeComparator, nullsLastComparator, vesselComparator } from '../../../../common/grid/gridComparators';
import { useTonnageGridActions } from './useTonnageGridActions';
import { useTonnageGridEditing } from './useTonnageGridEditing';
import { tonnageStatusComparator } from '../tonnageGridComparators';
import { LinkedTonnageDto, TonnageDto, TonnageFormattingDto } from '../../../../../api/web-api-client';
import { TonnageStatusCellRenderer } from '../TonnageStatusCellRenderer';
import { VesselSearchEditor } from '../../../../common/grid/VesselSearchEditor/VesselSearchEditor';
import { useGridHeaders } from '../../../../common/grid/hooks/gridHeaders/useGridHeaders';
import VesselCellTooltip from '../../../../common/grid/VesselCellTooltip';
import { NumericCellEditor } from '../../../../common';

export enum TonnagesGridColumn {
  ContractType = 'contractType',
  Demurrage = 'demurrage',
  Eta = 'eta',
  Etb = 'etb',
  FreightRate = 'freightRate',
  Laycan = 'laycan',
  Notes = 'notes',
  Options = 'options',
  Owner = 'owner',
  Status = 'status',
  TonnageRefId = 'tonnageRefId',
  Vessel = 'vessel',
}

export const useTonnageGrid = (gridRef: React.MutableRefObject<AgGridReact | null>) => {
  const {
    getRowId,
    onCellFocused,
    onRowSelected,
    cellRendererSelector,
    addEmptyRow,
    loadingOverlayComponent,
    noRowsOverlayComponent,
    suppressKeyboardEvent,
    updateGridState,
    applyGridState,
    rowClassRules,
  } = useDefaultGridOptions(gridRef, GridId.tonnage);
  const { vesselHeaderComponentParams, freightRateHeaderComponentParams, demurrageComponentParams } = useGridHeaders(
    GridId.tonnage
  );
  const { getCellClassRules } = useCellFormatting();
  const { isFeatureFlagActive } = useFeatureFlags();
  const { hasWriteRights } = useAuth();
  const { handleDelete, handleUnassign } = useTonnageGridActions();
  const { onCellEditingStopped, updateCell } = useTonnageGridEditing();

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    {
      field: TonnagesGridColumn.Status,
      headerName: '',
      maxWidth: hasWriteRights ? 70 : 50,
      minWidth: hasWriteRights ? 70 : 50,
      cellRenderer: TonnageStatusCellRenderer,
      comparator: tonnageStatusComparator,
      cellClass: 'only-right-border',
      suppressNavigable: true,
      pinned: 'left',
    },
    {
      field: TonnagesGridColumn.Laycan,
      headerName: 'Laycan',
      valueFormatter: dateRangeValueFormatter,
      cellEditor: dateRangeCellEditor,
      editable: hasWriteRights,
      comparator: dateRangeComparator,
      minWidth: 150,
      cellClassRules: getCellClassRules<TonnageFormattingDto>(TonnagesGridColumn.Laycan),
    },
    {
      field: TonnagesGridColumn.ContractType,
      headerName: 'Contract',
      tooltipField: TonnagesGridColumn.ContractType,
      tooltipComponentParams: { isContentInElementTruncated },
      editable: hasWriteRights,
      cellEditorParams: {
        maxLength: StringLenghts.SMALL,
      },
      minWidth: 160,
      cellClassRules: getCellClassRules<TonnageFormattingDto>(TonnagesGridColumn.ContractType),
    },
    {
      field: TonnagesGridColumn.Vessel,
      headerName: 'Vessel',
      headerComponentParams: vesselHeaderComponentParams,
      tooltipField: isFeatureFlagActive(FeatureFlagsEnum.ff_vesselCellDetails)
        ? TonnagesGridColumn.Vessel
        : `${TonnagesGridColumn.Vessel}.name`,
      tooltipComponent: isFeatureFlagActive(FeatureFlagsEnum.ff_vesselCellDetails) ? VesselCellTooltip : CellTooltip,
      tooltipComponentParams: { isContentInElementTruncated },
      editable: hasWriteRights,
      cellEditorParams: {
        maxLength: StringLenghts.SMALL,
        testIdPrefix: 'tonnages-grid-vessel',
      },
      cellEditor: VesselSearchEditor,
      minWidth: 160,
      cellClassRules: {
        ...getCellClassRules<TonnageFormattingDto>(TonnagesGridColumn.Vessel),
        [notLinkedApiIndicatorClass]: (params) => params.value?.name && params.value?.seaId == null,
      },
      cellRendererParams: { dataTestIdPrefix: 'tonnage' },
      valueFormatter: vesselFormatter,
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        return params.event.key === KeyNames.ENTER || suppressKeyboardEvent<LinkedTonnageDto>(params);
      },
      comparator: vesselComparator,
    },

    {
      field: TonnagesGridColumn.Owner,
      headerName: 'Owner',
      editable: hasWriteRights,
      tooltipField: TonnagesGridColumn.Owner,
      tooltipComponentParams: { isContentInElementTruncated },
      cellEditorParams: {
        maxLength: StringLenghts.SMALL,
      },
      minWidth: 140,
      cellClassRules: getCellClassRules<TonnageFormattingDto>(TonnagesGridColumn.Owner),
    },
    {
      field: TonnagesGridColumn.Eta,
      headerName: 'ETA',
      valueFormatter: dateValueFormatter,
      editable: hasWriteRights,
      cellEditor: DateCellEditor,
      minWidth: 90,
      cellClassRules: getCellClassRules<TonnageFormattingDto>(TonnagesGridColumn.Eta),
    },
    {
      field: TonnagesGridColumn.Etb,
      headerName: 'ETB',
      valueFormatter: dateValueFormatter,
      editable: hasWriteRights,
      cellEditor: DateCellEditor,
      minWidth: 90,
      cellClassRules: getCellClassRules<TonnageFormattingDto>(TonnagesGridColumn.Etb),
    },
    {
      field: TonnagesGridColumn.FreightRate,
      headerName: 'Freight Rate',
      headerComponentParams: freightRateHeaderComponentParams,
      editable: hasWriteRights,
      tooltipField: TonnagesGridColumn.FreightRate,
      tooltipComponentParams: { isContentInElementTruncated },
      valueFormatter: decimalValueFormatter,
      cellEditor: NumericCellEditor,
      cellEditorParams: {
        isFloat: true,
      },
      minWidth: 90,

      cellClassRules: getCellClassRules<TonnageFormattingDto>(TonnagesGridColumn.FreightRate),
    },
    {
      field: TonnagesGridColumn.Demurrage,
      headerName: 'Demurrage',
      headerComponentParams: demurrageComponentParams,
      editable: hasWriteRights,
      tooltipField: TonnagesGridColumn.Demurrage,
      tooltipComponentParams: { isContentInElementTruncated },
      valueFormatter: decimalValueFormatter,
      cellEditor: NumericCellEditor,
      cellEditorParams: {
        isFloat: true,
      },
      minWidth: 90,
      cellClassRules: getCellClassRules<TonnageFormattingDto>(TonnagesGridColumn.Demurrage),
    },
    {
      field: TonnagesGridColumn.TonnageRefId,
      headerName: 'Reference ID',
      editable: hasWriteRights,
      tooltipField: TonnagesGridColumn.TonnageRefId,
      tooltipComponentParams: { isContentInElementTruncated },
      cellEditorParams: {
        maxLength: StringLenghts.SMALL,
      },
      minWidth: 100,
      cellClassRules: getCellClassRules<TonnageFormattingDto>(TonnagesGridColumn.TonnageRefId),
    },
    {
      field: TonnagesGridColumn.Notes,
      headerName: 'Notes',
      editable: hasWriteRights,
      cellEditorPopup: true,
      cellEditor: TextareaCellEditor,
      cellEditorParams: {
        gridId: GridId.tonnage,
      },
      cellEditorPopupPosition: 'under',
      tooltipField: 'notes',
      tooltipComponentParams: { isContentInElementTruncated },
      minWidth: 250,
      cellClassRules: getCellClassRules<TonnageDto>(TonnagesGridColumn.Notes),
    },
    {
      field: TonnagesGridColumn.Options,
      headerName: '',
      editable: false,
      sortable: false,
      filter: false,
      lockPosition: hasWriteRights ? 'right' : undefined,
      maxWidth: 90,
      minWidth: 90,
      cellRenderer: TonnageActionsCellRenderer,
      cellRendererParams: { handleDelete, handleUnassign },
      cellClass: 'only-left-border',
      suppressNavigable: true,
      resizable: false,
      hide: !hasWriteRights,
      pinned: 'right',
    },
  ]);

  //Remove below useeEffect with ff_ownerRateDemurrageColumns feature flag
  useEffect(() => {
    if (isFeatureFlagActive(FeatureFlagsEnum.ff_ownerRateDemurrageColumns)) {
      return;
    }
    const filteredColumnDefinitions = columnDefs.filter((columnDefinition) => {
      if (
        columnDefinition.field &&
        [TonnagesGridColumn.FreightRate, TonnagesGridColumn.Demurrage, TonnagesGridColumn.Owner].includes(
          columnDefinition?.field as TonnagesGridColumn
        )
      ) {
        return;
      }
      return columnDefinition;
    });

    setColumnDefs(filteredColumnDefinitions);
  }, [isFeatureFlagActive(FeatureFlagsEnum.ff_ownerRateDemurrageColumns)]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: true,
      sortable: true,
      tooltipComponent: CellTooltip,
      lockPinned: true,
      suppressKeyboardEvent: suppressKeyboardEvent<LinkedTonnageDto>,
      suppressMenu: true,
      comparator: nullsLastComparator,
      cellRendererSelector: cellRendererSelector<LinkedTonnageDto>,
      cellRendererParams: {
        retryUpdateCell: updateCell,
      },
    }),
    []
  );

  const autoWidthLastColumn = (e: DragStoppedEvent) => {
    adjustLastColumnWidth(GridId.tonnage, e.columnApi);
  };

  return {
    autoWidthLastColumn,
    columnDefs,
    defaultColDef,
    onCellEditingStopped,
    getRowId: getRowId<LinkedTonnageDto>,
    onCellFocused,
    onRowSelected: onRowSelected<LinkedTonnageDto>,
    addEmptyRow: addEmptyRow<LinkedTonnageDto>,
    loadingOverlayComponent,
    noRowsOverlayComponent,
    updateGridState,
    applyGridState,
    rowClassRules,
  };
};
