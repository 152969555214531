import { useEffect } from 'react';

export function useKeyDownEventListener(key: string, callBack: () => void) {
  const eventListenerHandler = (e: KeyboardEvent) => {
    if (e.key === key) {
      callBack();
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', eventListenerHandler);
    return () => {
      document.removeEventListener('keydown', eventListenerHandler);
    };
  }, []);
}
