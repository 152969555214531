import { IRowNode } from 'ag-grid-community';
import { ICustomRowData } from './useDefaultGridOptions';
import { AgGridReact } from 'ag-grid-react';
import { GridId } from '../helpers';

export const useUnhighlightRows = (gridRef: React.MutableRefObject<AgGridReact | null>, currentGridId: GridId) => {
  const unhighlightOnMouseUp = ({ target }: Event) => {
    if (target) {
      unhighlightOnClickOutsideGrid(target);
    }
  };

  const unhighlightOnClickOutsideGrid = (target: EventTarget) => {
    const container = document?.getElementById(currentGridId);
    const rowContainer = container?.querySelector('.ag-center-cols-container');
    const popupEditorElement = (target as Element).parentElement?.parentElement;
    //additional condition on popup is needed to not remove the selection if popup editor was opened on newly created row
    if (!rowContainer?.contains(target as Node) && !popupEditorElement?.classList.contains('ag-custom-component-popup')) {
      unhighlightAll();
    }
  };

  const unhighlightAll = () => {
    let shouldRemovePlaceholder = false;
    const nodesToUpdate: IRowNode<ICustomRowData<unknown>>[] = [];
    gridRef.current?.api.forEachNode((node) => {
      node.data.shouldHighlight = false;
      if (node.data.showPlaceholders) {
        node.data.showPlaceholders = false;
        shouldRemovePlaceholder = true;
      }
      nodesToUpdate.push(node);
    });
    gridRef.current?.api.applyTransaction({ update: nodesToUpdate.map((node) => node.data) });
    if (shouldRemovePlaceholder) {
      gridRef.current?.api.refreshCells({ rowNodes: nodesToUpdate, force: true });
    }
    document.body?.removeEventListener('mouseup', unhighlightOnMouseUp);
  };

  return { unhighlightOnMouseUp, unhighlightAll };
};
