import { ChangeEvent, forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { styled } from '@mui/material';
import { ICellEditorParams } from 'ag-grid-community/dist/lib/interfaces/iCellEditor';
import { MaritimeDateRange } from '@maritech/maritime-date';
import { blue, neutral } from '../../../themes/palette';
import { KeyNames } from '../Constants';
import { formatDateRange, fromMaritimeDateRangeToDateRange } from '../../../helpers/dateHelpers';
import { scrollToEndOfInput } from '../../../helpers/helpers';
import dayjs from 'dayjs';
import { isValidForEditorChar } from './helpers';

interface IDateRange {
  from: Date;
  to: Date;
}

interface IDateRangeCellEditorParams extends ICellEditorParams {}

const DateRangeCellEditor = memo(
  forwardRef((props: IDateRangeCellEditorParams, ref) => {
    const createInitialState = () => {
      const isBackspaceOrDeletePressed = props.eventKey === KeyNames.BACKSPACE || props.eventKey === KeyNames.DELETE;
      let startValue;
      let highlightAllOnFocus = true;

      if (isBackspaceOrDeletePressed) {
        startValue = '';
      } else if (isValidForEditorChar(props.eventKey)) {
        startValue = props.eventKey!;
        highlightAllOnFocus = false;
      } else {
        if (props.value) {
          startValue = formatDateRange(
            {
              from: dayjs.utc(props.value.from),
              to: dayjs.utc(props.value.to),
            },
            { showYear: true }
          );
        }
        if (props.eventKey === KeyNames.F2) {
          highlightAllOnFocus = false;
        }
      }

      return {
        inputValue: startValue,
        highlightAllOnFocus,
        // to reset value on backspace
        isBackspaceOrDeletePressed,
      };
    };

    const initialState = createInitialState();

    const [value, setValue] = useState<IDateRange | null>(initialState.isBackspaceOrDeletePressed ? null : props.value);
    const [inputValue] = useState<string | undefined>(initialState.inputValue);
    const [highlightAllOnFocus, setHighlightAllOnFocus] = useState<boolean>(initialState.highlightAllOnFocus);
    const refInput = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
      };
    });

    useEffect(() => {
      if (refInput) {
        const currentRef = refInput.current!;
        currentRef.focus();

        currentRef.value = inputValue ?? '';

        if (highlightAllOnFocus) {
          currentRef.select();
          setHighlightAllOnFocus(false);
        } else {
          scrollToEndOfInput(currentRef);
        }
      }
    }, [refInput]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.value) {
        setValue(null);
        return;
      }

      const maritimeDateRange = MaritimeDateRange.parse(event.target.value);
      if (maritimeDateRange.isValid) {
        const newValue = fromMaritimeDateRangeToDateRange(maritimeDateRange);
        setValue(newValue);
      }
    };

    return <StyledInput type="text" ref={refInput} onChange={onChange} className="ag-input-field-input ag-text-field-input" />;
  })
);

export default DateRangeCellEditor;

const StyledInput = styled('input')({
  fontWeight: 500,
  fontSize: 14,
  letterSpacing: '0.17px',
  minWidth: 'initial',
  padding: '1px 2px 1px 6px',
  height: 'var(--ag-row-height)',
  borderRadius: 0,
  width: '100%',
  border: `1px solid ${blue.b50}`,
  backgroundColor: neutral.n100,
});
