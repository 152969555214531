import { Autocomplete, AutocompleteHighlightChangeReason, AutocompleteInputChangeReason, TextField } from '@mui/material';
import { ICellEditorParams } from 'ag-grid-community';
import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { KeyNames } from '../Constants';
import { isValidForEditorChar } from './helpers';

export interface ISelectItem {
  id: number;
  name: string;
  description?: React.ReactNode;
}

export interface ISelectCellEditorParams extends ICellEditorParams {
  options: ISelectItem[];
}

export const SelectCellEditor = memo(
  forwardRef((props: ISelectCellEditorParams, ref) => {
    const { options, eventKey } = props;
    const initialState = useMemo(() => {
      let startValue: ISelectItem | null = null;
      let startInputValue = '';
      let highlightAllOnFocus = true;

      if (eventKey === KeyNames.BACKSPACE || eventKey === KeyNames.DELETE) {
        startValue = null;
      } else if (isValidForEditorChar(eventKey)) {
        startInputValue = eventKey!;
        highlightAllOnFocus = false;
      } else {
        const index = props.value ? options.findIndex((x) => x.id === props.value) : -1;
        const defaultValue = index > -1 ? options[index] : null;
        startValue = defaultValue;
        startInputValue = defaultValue?.name ?? '';
        if (eventKey === KeyNames.F2) {
          highlightAllOnFocus = false;
        }
      }

      return {
        value: startValue,
        inputValue: startInputValue,
        highlightAllOnFocus,
      };
    }, []);

    const [value, setValue] = useState<ISelectItem | null>(initialState.value);
    const [inputValue, setInputValue] = useState<string>(initialState.inputValue);
    const refInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
      const currentRef = refInput.current!;
      currentRef.focus();
      if (initialState.highlightAllOnFocus) {
        currentRef.select();
      } else {
        const length = currentRef.value?.length ?? 0;
        if (length > 0) {
          currentRef.setSelectionRange(length, length);
        }
      }
    }, []);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value?.id;
        },
      };
    });

    const handleChange = (_event: React.SyntheticEvent, newValue: ISelectItem | null) => {
      setValue(newValue);
    };

    const handleInputChange = (_event: React.SyntheticEvent, newInputValue: string, reason: AutocompleteInputChangeReason) => {
      if (reason !== 'reset') {
        setInputValue(newInputValue);
        const option = options.find((x) => x.name.toLowerCase() === newInputValue.toLowerCase());
        setValue(option ?? null);
      }
    };

    const handleHighlightChange = (
      _event: React.SyntheticEvent,
      option: ISelectItem | null,
      reason: AutocompleteHighlightChangeReason
    ) => {
      if (option && (reason === 'keyboard' || reason === 'mouse')) {
        setValue(option);
        setInputValue(option.name);
      }
    };

    const handleFilterOptions = (currentOptions: ISelectItem[]) => currentOptions;

    const renderOption = (optionProps: object, option: ISelectItem) => (
      <li {...optionProps}>{option.description ?? option.name}</li>
    );

    return (
      <Autocomplete
        value={value}
        forcePopupIcon={false}
        disableClearable={value !== null}
        inputValue={inputValue}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        getOptionLabel={(option) => option.name}
        blurOnSelect
        onHighlightChange={handleHighlightChange}
        componentsProps={{
          paper: {
            style: { minWidth: '320px', fontSize: 14 },
            className: 'ag-custom-component-popup',
            id: `${props.column.getColDef().headerName}-select-editor`,
          },
        }}
        renderOption={renderOption}
        openOnFocus
        filterOptions={handleFilterOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={refInput}
            InputProps={{
              ...params.InputProps,
              endAdornment: null,
              className: 'ag-input-field-input ag-text-field-input',
            }}
            style={{ height: 'var(--ag-row-height)' }}
          />
        )}
      />
    );
  })
);
