interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel: React.FC<ITabPanelProps> = (props) => {
  const { children, value, index, ...otherProps } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...otherProps}>
      {value === index && <>{children}</>}
    </div>
  );
};
