import styled from '@emotion/styled';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import { AutocompleteRenderOptionState, Typography } from '@mui/material';
import { blue, grey } from '../../../../../themes/palette';

export interface ISearchEditorRenderOptions<T> extends IRenderOptions<T> {
  infoInBrackets: JSX.Element | null;
  infoInTag: JSX.Element | null;
}

export interface IRenderOptions<T> {
  option: T;
  optionProps: React.HTMLAttributes<HTMLLIElement>;
  state: AutocompleteRenderOptionState;
  minSearchTriggerTextLength: number;
  mainUiOptionKey: keyof T;
}

type Part = {
  text: string;
  highlight: boolean;
};

const getName = (parts: Part[]) => {
  return parts.map((part, index) => (
    <span
      key={index}
      style={{
        fontWeight: part.highlight ? 700 : 500,
      }}
    >
      {part.text}
    </span>
  ));
};

export const SearchEditorRenderOptions = <T,>(props: ISearchEditorRenderOptions<T>) => {
  const { option, state, minSearchTriggerTextLength, mainUiOptionKey, infoInBrackets, infoInTag, optionProps } = props;
  const { inputValue, selected } = state;

  const name = (option?.[mainUiOptionKey] as string) ?? '';
  let parts: Part[] = [];

  if (inputValue.length >= minSearchTriggerTextLength) {
    const matches = AutosuggestHighlightMatch(name, inputValue, { insideWords: true });
    parts = AutosuggestHighlightParse(name, matches);
  }

  return (
    <StyledLiElement {...optionProps} selected={selected}>
      <StyledContainer>
        <StyledName>
          {getName(parts)}
          <>{infoInBrackets}</>
        </StyledName>
        {infoInTag}
      </StyledContainer>
    </StyledLiElement>
  );
};

const StyledContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  lineHeight: '150%',
});

const StyledName = styled(Typography)({
  display: 'inline-block',
  fontSize: '14px',
  color: grey.g12,
  maxWidth: '205px',
});

interface StyledSearchEditorInfoInBrackets {
  bold?: boolean;
}
export const StyledSearchEditorInfoInBrackets = styled('span')<StyledSearchEditorInfoInBrackets>(({ bold }) => ({
  fontWeight: bold ? 700 : 500,
  marginLeft: '4px',
}));

export const StyledSearchEditorInfoInTag = styled('div')({
  fontSize: '12px',
  color: blue.b80,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2px 4px',
  margin: 'auto 0 ',
  backgroundColor: blue.b15,
  height: '16px',
  borderRadius: '4px',
});

interface StyledLiElement {
  selected: boolean;
}

const StyledLiElement = styled('li')<StyledLiElement>(({ selected }) => ({
  backgroundColor: selected ? `${blue.b10}!important` : '',
  minHeight: '41px',
  height: '41px',
}));
