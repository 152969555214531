import { styled, Tooltip } from '@mui/material';
import { grey } from '../../../../themes/palette';
import { useEffect, useState, Fragment } from 'react';
import { GridApi } from 'ag-grid-community';
import { getCargoesData, getCargoesIncotermText, getCargoesText, getTooltip, groupCargoes, GroupedCargoesData } from './helpers';
import { ICustomRowData } from '../../../common/grid/hooks/useDefaultGridOptions';

interface IAssignmentsGridDetailsProps<T> {
  gridData?: ICustomRowData<T>[] | null;
  gridApi?: GridApi;
}

export const AssignmentsGridDetails = <T,>(props: IAssignmentsGridDetailsProps<T>) => {
  const { gridApi } = props;
  const [cargoesData, setCargoesData] = useState<GroupedCargoesData>(groupCargoes(getCargoesData(gridApi!)));

  const calculateCargoesData = () => {
    setCargoesData(groupCargoes(getCargoesData(gridApi!)));
  };

  useEffect(() => {
    gridApi?.addEventListener('modelUpdated', calculateCargoesData);
    return () => gridApi?.removeEventListener('modelUpdated', calculateCargoesData);
  }, []);

  return (
    <Tooltip title={getTooltip(cargoesData)} placement="top-start">
      <StyledContainer data-testid="cargo-grid-footer-details">
        <StyledText>{getCargoesText(cargoesData)}</StyledText>
        {cargoesData.byIncotermId.map((cargoes) => (
          <Fragment key={cargoes.incotermId}>
            <StyledSeparator />
            <StyledIncoterm>{getCargoesIncotermText(cargoes)}</StyledIncoterm>
          </Fragment>
        ))}
      </StyledContainer>
    </Tooltip>
  );
};

const StyledContainer = styled('div')({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '20px',
  color: grey.g15,
  display: 'inline-block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flexShrink: 20,
});

const StyledText = styled('span')({
  display: 'inline-block',
  verticalAlign: 'middle',
});

const StyledIncoterm = styled('span')({
  fontWeight: 500,
  display: 'inline-block',
  verticalAlign: 'middle',
});

const StyledSeparator = styled('span')({
  display: 'inline-block',
  width: '4px',
  height: '4px',
  backgroundColor: grey.g9,
  borderRadius: '50%',
  margin: '0 10px',
  verticalAlign: 'middle',
});
