import { IconButton, Typography, styled } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '../../../themes/palette';

const StyledGridCustomisationHeader = styled('div')({
  minHeight: '67px',
  maxHeight: '67px',
  padding: '24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${grey.g2}`,
});

const StyledTitleWithIcon = styled('div')({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
});

const StyledTuneIcon = styled(TuneIcon)({
  height: '24px',
});

const StyledCloseIcon = styled(CloseIcon)({
  height: '24px',
  width: '24px',
  color: grey.g12,
});

interface IGridCustomisationDrawerHeader {
  closeButtonCb: () => void;
}

export const GridCustomisationDrawerHeader: React.FC<IGridCustomisationDrawerHeader> = ({ closeButtonCb }) => {
  return (
    <StyledGridCustomisationHeader>
      <StyledTitleWithIcon>
        <StyledTuneIcon data-testid="grid-customisation-drawer-tune-icon" />
        <Typography variant="h5" style={{ fontSize: '18px' }}>
          Customise
        </Typography>
      </StyledTitleWithIcon>
      <IconButton size="small" onClick={closeButtonCb} data-testid={'grid-customisation-drawer-close-button'}>
        <StyledCloseIcon />
      </IconButton>
    </StyledGridCustomisationHeader>
  );
};
