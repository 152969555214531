import styled from '@emotion/styled';
import { Loader } from '../../../../../layout/Loader';

interface ISearchEditorLoader {
  footerText: string;
  testIdPrefix: string;
}

export const SearchEditorLoader: React.FC<ISearchEditorLoader> = (props) => {
  const { footerText, testIdPrefix } = props;

  return (
    <StyledContainer>
      <Loader id={`${testIdPrefix}-search`} footerText={footerText} footerFontSize={14} size={40} />
    </StyledContainer>
  );
};

const StyledContainer = styled('div')({
  height: '166px',
});
