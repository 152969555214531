import React from 'react';
import { ITooltipParams } from 'ag-grid-community';
import { styled } from '@mui/material';

export interface ICellTooltip extends ITooltipParams {
  isContentInElementTruncated: (element: HTMLElement) => boolean;
}

const StyledContainer = styled('div')({
  backgroundColor: 'white',
  padding: '12px 16px',
  borderRadius: 8,
  boxShadow: '0 4px 4px rgb(0 0 0 / 25%), 0 4px 4px rgb(0 0 0 / 25%), 0 3px 1px rgb(0 0 0 / 20%)',
  maxWidth: '450px',
  whiteSpace: 'pre-wrap',
});

const CellTooltip: React.FC<ICellTooltip> = (props) => {
  const { value, isContentInElementTruncated } = props;
  const element = document.querySelector('.ag-cell:hover') as HTMLElement;
  const isCellEditing = element?.classList.contains('ag-cell-popup-editing');
  const showTooltip = isContentInElementTruncated(element) && !isCellEditing;

  return <>{showTooltip && <StyledContainer data-testid="tooltip">{value}</StyledContainer>}</>;
};

CellTooltip.displayName = 'CellTooltip';

export default CellTooltip;
