import { isDropTarget } from '../components/common/grid/helpers';
import { blue } from '../themes/palette';

const overlayStyles = (overlayIconPath?: string, offset?: number) => `
  opacity: 0.8;
  position: absolute;
  top: 0;
  left:  0;
  background-color: ${blue.b10};
  ${overlayIconPath && `background-image: url(${overlayIconPath});`}
  background-position: ${offset ? `left ${offset}px top 50%;` : 'center;'}
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

export const addOverlay = (row: Element, overlayClassName: string, overlayIconPath = '', offset?: number): HTMLDivElement => {
  const overlay = document.createElement('div');
  overlay.classList.add(overlayClassName);

  overlay.setAttribute('style', overlayStyles(overlayIconPath, offset));
  row.appendChild(overlay);

  return overlay;
};

function removeOverlay(overlay: HTMLDivElement) {
  const row = overlay.closest('.ag-row') as HTMLDivElement;
  if (row) {
    row.setAttribute(isDropTarget, 'false');
  }
  overlay.remove();
}

export function removePreviousOverlays() {
  const overlays = document.getElementsByClassName('drag-target-overlay');
  if (overlays) {
    Array.from(overlays).forEach((overlay) => {
      removeOverlay(overlay as HTMLDivElement);
    });
  }
}

export function removeSourceOverlay() {
  const overlays = document.getElementsByClassName('drag-source-overlay');
  if (overlays) {
    Array.from(overlays).forEach((overlay) => {
      const row = overlay.closest('.ag-row') as HTMLDivElement;
      row.classList.remove('ag-row-hover');
      overlay.remove();
    });
  }
}
