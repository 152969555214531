import { addAbortSignalListener, REQUEST_STATUS } from '../helpers';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiManager } from '../../contexts/ApiManager';
import { LocationDto } from '../../api/web-api-client';

const MAX_LOCATIONS_COUNT = 15;

export enum LocationsActions {
  SEARCH_LOCATIONS = 'searchLocations',
}

export type LocationsState = {
  locations: LocationDto[];
  errors: string[];
  status: {
    [key in LocationsActions]?: REQUEST_STATUS;
  };
};

export const locationsInitialState: LocationsState = {
  locations: [],
  errors: [],
  status: {},
};

export const searchLocations = createAsyncThunk(
  LocationsActions.SEARCH_LOCATIONS,
  async (payload: string, { signal, getState, rejectWithValue }) => {
    const source = addAbortSignalListener(signal);

    try {
      const state = (await getState()) as { api: { apiManager: ApiManager } };
      return await state.api.apiManager.locationsAPI.searchLocations(payload, undefined, MAX_LOCATIONS_COUNT, source.token);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const locationsSlice = createSlice({
  name: 'locations',
  initialState: locationsInitialState,
  reducers: {
    resetState: (state) => {
      state.locations = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(searchLocations.fulfilled, (state, { payload }) => {
      state.locations = payload ?? [];
      state.status[LocationsActions.SEARCH_LOCATIONS] = REQUEST_STATUS.fulfilled;
    });

    builder.addCase(searchLocations.pending, (state) => {
      state.status[LocationsActions.SEARCH_LOCATIONS] = REQUEST_STATUS.pending;
    });

    builder.addCase(searchLocations.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.status[LocationsActions.SEARCH_LOCATIONS] = REQUEST_STATUS.rejected;
      }
    });
  },
});

export const { resetState } = locationsSlice.actions;

export default locationsSlice.reducer;
