import { MouseEventHandler } from 'react';
import {
  Dialog as MuiDialog,
  DialogProps,
  DialogActions,
  DialogContent,
  styled,
  Typography,
  Button,
  Backdrop,
  ButtonPropsColorOverrides,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { neutral } from '../../../themes/palette';
import { OverridableStringUnion } from '@mui/types';
import { KeyNames } from '../Constants';

const singleButtonStyles = {
  flexGrow: '1',
  padding: '10px 20px',
  height: '41px',
};

const multipleButtonsStyles = {
  height: '41px',
  minWidth: 'unset',
  width: '200px',
  fontSize: '14px',
};

const StyledCloseIcon = styled(CloseIcon)({
  position: 'absolute',
  top: '20px',
  right: '20px',
  height: '21px',
  ':hover': {
    cursor: 'pointer',
  },
  color: neutral.n30,
});

const StyledDialogTitle = styled('div')({
  maxWidth: '95%',
  overflowX: 'hidden',
  flexShrink: 0,
});

const StyledLoadingBtn = styled(Button)({
  flexGrow: '1',
  height: '41px',
  fontSize: '14px',
});

const StyledCircularProgress = styled(CircularProgress)({
  color: neutral.n100,
  maxHeight: '20px',
  maxWidth: '20px',
  marginRight: '12px',
});

const StyledDescription = styled(Typography)({
  fontWeight: 500,
  fontSize: '14px',
});

export type ActionButtonColor = OverridableStringUnion<
  'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
  ButtonPropsColorOverrides
>;

export interface IDialogProps extends DialogProps {
  title?: string;
  actionCb: () => void;
  actionBtnContent?: React.ReactNode;
  disableActionButton?: boolean;
  actionButtonColor?: ActionButtonColor;
  isLoading?: boolean;
  loadingLabel?: string;
  cancelCb?: () => void;
  cancelBtnContent?: React.ReactNode;
  closeBtnCb?: MouseEventHandler<Element>;
  shouldConfirmOnEnter?: boolean;
}

const Dialog = (props: IDialogProps) => {
  const {
    actionBtnContent,
    cancelBtnContent,
    children,
    closeBtnCb,
    cancelCb,
    disableActionButton,
    open,
    title,
    actionCb,
    onClose,
    id,
    isLoading,
    actionButtonColor,
    loadingLabel,
    shouldConfirmOnEnter,
  } = props;

  const onPressEnter = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === KeyNames.ENTER && shouldConfirmOnEnter && actionCb) {
      actionCb();
    }
  };

  return (
    <MuiDialog id={id} open={open} onClose={onClose} BackdropComponent={Backdrop} onKeyDown={onPressEnter}>
      {closeBtnCb && <StyledCloseIcon data-testid={`button-${id}-cross`} onClick={closeBtnCb} />}
      <StyledDialogTitle>
        <Typography variant="h6">{title}</Typography>
      </StyledDialogTitle>
      <DialogContent>{typeof children == 'string' ? <StyledDescription>{children}</StyledDescription> : children}</DialogContent>
      {isLoading ? (
        <DialogActions>
          <StyledLoadingBtn
            data-testid={`dialog-button-loading-${id}`}
            variant="contained"
            color={actionButtonColor ?? 'primary'}
          >
            <StyledCircularProgress data-testid="button-spinner-dialog" />
            {loadingLabel ?? 'Loading...'}
          </StyledLoadingBtn>
        </DialogActions>
      ) : (
        <DialogActions>
          {cancelCb && (
            <Button
              data-testid={`dialog-button-cancel-${id}`}
              variant="outlined"
              onClick={cancelCb}
              color={'secondary'}
              sx={multipleButtonsStyles}
            >
              {cancelBtnContent ?? 'Cancel'}
            </Button>
          )}
          <Button
            id={`button-${id}`}
            data-testid={`button-${id}`}
            variant="contained"
            onClick={actionCb}
            color={actionButtonColor ?? 'primary'}
            disabled={disableActionButton}
            sx={cancelCb ? multipleButtonsStyles : singleButtonStyles}
          >
            {actionBtnContent ?? 'Action'}
          </Button>
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default Dialog;
