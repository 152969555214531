import { ButtonProps, IconButton, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

interface IPlanDateFilterRangeChangeButtonProps extends ButtonProps {}

export const PlanDateFilterRangeChangeButton: React.FC<PropsWithChildren<IPlanDateFilterRangeChangeButtonProps>> = (props) => {
  return <StyledButton {...props}>{props.children}</StyledButton>;
};

const StyledButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: 'unset',
  borderRadius: 'inherit',
  width: '40px',
}));
