import { neutral } from '../../palette';

export const MuiSwitch = {
  styleOverrides: {
    root: {
      '& .Mui-checked + .MuiSwitch-track': {
        opacity: '1 !important',
      },
      '& .Mui-disabled + .MuiSwitch-track': {
        opacity: 0.4,
        cursor: 'not-allowed',
      },

      '& .Mui-checked.MuiSwitch-switchBase': {
        transform: 'translateX(15px)',
      },
    },
    thumb: {
      color: neutral.n100,
      height: '11px',
      width: '11px',
    },

    track: {
      width: '36px',
      height: '18px',
      borderRadius: '50px',
      backgroundColor: neutral.n40,
      opacity: 1,
    },
    switchBase: {
      top: '6.5px',
      left: '7px',
    },
  },
};

export const darkThemeSwitch = {
  styleOverrides: {
    ...MuiSwitch.styleOverrides,
  },
};

export const lightThemeSwitch = {
  styleOverrides: {
    ...MuiSwitch.styleOverrides,
  },
};
