import { ThemeProvider, Theme } from '@mui/material';
import ThemeLight from './ThemeLight';

export interface IDefaultThemeProviderProps {
  theme?: Theme;
  children: React.ReactNode;
}

const DefaultThemeProvider = ({ theme, children }: IDefaultThemeProviderProps) => {
  return <ThemeProvider theme={theme || ThemeLight}>{children}</ThemeProvider>;
};

export default DefaultThemeProvider;
