export const MuiTextField = {
  styleOverrides: {
    root: {
      '& > .MuiFormHelperText-root': {
        margin: '4px 0',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        height: '42px',
        '&.Mui-focused fieldset': {
          borderWidth: '1px',
        },
      },
      '& input': {
        padding: '0 16px',
        fontSize: '14px',
      },
    },
  },
};

export const lightThemeTextField = {
  styleOverrides: {
    ...MuiTextField.styleOverrides,
    root: {
      ...MuiTextField.styleOverrides.root,
    },
  },
};

export const darkThemeTextField = {
  styleOverrides: {
    ...MuiTextField.styleOverrides,
  },
};
