import React, { createContext, useContext } from 'react';
import { AppInsightsContext as MicrosoftAppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

interface IAppInsightsContext extends ReactPlugin {}

const AppInsightsContext = createContext<IAppInsightsContext | null>(null);

interface IAuthContextProvider {
  connectionString: string;
  children: React.ReactNode;
}

export const AppInsightsProvider: React.FC<IAuthContextProvider> = ({ children, connectionString }) => {
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      connectionString,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin],
    },
  });
  appInsights.loadAppInsights();

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <MicrosoftAppInsightsContext.Provider value={reactPlugin}>{children}</MicrosoftAppInsightsContext.Provider>
    </AppInsightsContext.Provider>
  );
};

export function useAppInsights() {
  const context = useContext(AppInsightsContext);

  if (!context) {
    throw new Error('Component beyond AppInsightsContext');
  }

  return context;
}
