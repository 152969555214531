import * as React from 'react';
import { Button, styled } from '@mui/material';
import { neutral } from '../../../../../themes/palette';
import { Dayjs } from 'dayjs';
import { getAmountOfDaysInDateRange } from '../../../../../helpers/dateHelpers';

interface IActionsFooterProps {
  saveDateRange: () => void;
  dateFrom: Dayjs | null;
  dateTo: Dayjs | null;
}

export const ActionsFooter: React.FC<IActionsFooterProps> = (props) => {
  const { saveDateRange, dateFrom, dateTo } = props;

  const getAmountOfSelectedDaysText = (dateOne: Dayjs | null, dateTwo: Dayjs | null) => {
    const amountOfSelectedDays = getAmountOfDaysInDateRange({ from: dateOne!, to: dateTwo! });
    const pluralForm = amountOfSelectedDays > 1 ? 's' : '';
    return `${amountOfSelectedDays} day${pluralForm}`;
  };

  return (
    <StyledContainer>
      <StyledText data-testid="plandatefilter-modal-custom-range-view-amount-of-days">
        {getAmountOfSelectedDaysText(dateFrom, dateTo)}
      </StyledText>
      <StyledButton
        onClick={saveDateRange}
        variant="contained"
        data-testid="plandatefilter-modal-custom-range-view-button-update-date-range"
      >
        Apply
      </StyledButton>
    </StyledContainer>
  );
};

const StyledContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  marginTop: '16px',
  gap: '25px',
});

const StyledText = styled('div')({
  color: neutral.n65,
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 500,
});

const StyledButton = styled(Button)({
  height: '36px',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '24px',
  borderRadius: '4px',
  padding: '6px 16px',
  minWidth: '100px',
});
