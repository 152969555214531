import { Button, ButtonProps, styled, Typography } from '@mui/material';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { IDateRange } from '../../../../store';
import { formatDateRange } from '../../../../helpers/dateHelpers';
import { DASHBOARD_DATE_RANGE_TYPE } from '../PlanDateFilter';

interface IPlanDateFilterModalButtonProps extends ButtonProps {
  dateRange: IDateRange;
  dashboardDateRangeType: DASHBOARD_DATE_RANGE_TYPE;
}

export const PlanDateFilterModalButton: React.FC<IPlanDateFilterModalButtonProps> = (props) => {
  const { dateRange, dashboardDateRangeType, ...restProps } = props;

  const getDisplayedDate = () => {
    if (dashboardDateRangeType === DASHBOARD_DATE_RANGE_TYPE.MONTH) {
      return dateRange.from.utc().format('MMMM YYYY');
    } else {
      return formatDateRange(dateRange, { showYear: true, yearFormat: 'YYYY' });
    }
  };

  return (
    <StyledButton daterangetype={dashboardDateRangeType} variant="text" endIcon={<StyledCalendarIcon />} {...restProps}>
      <StyledText>{getDisplayedDate()}</StyledText>
    </StyledButton>
  );
};

const StyledButton = styled(Button)<{ daterangetype: DASHBOARD_DATE_RANGE_TYPE }>(({ daterangetype }) => ({
  display: 'flex',
  alignItems: 'center',
  textTransform: 'none',
  padding: '10px 16px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  height: '40px',
  minWidth: '172px',
  width: daterangetype === DASHBOARD_DATE_RANGE_TYPE.CUSTOM ? 'fit-content' : 'initial',
}));

const StyledText = styled(Typography)({
  fontSize: '16px',
  lineHeight: '1.5',
  fontWeight: '700',
});

const StyledCalendarIcon = styled(CalendarTodayOutlined)({
  height: '16px',
  width: '16px',
  marginBottom: '2px',
});
