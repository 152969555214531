import { ILoadingOverlayParams } from 'ag-grid-community';
import { Loader } from '../../layout/Loader';

interface ILoadingOverlay extends ILoadingOverlayParams {
  footerText: string;
}

export const LoadingOverlay: React.FC<ILoadingOverlay> = ({ footerText }) => {
  return <Loader id="grid-loading" footerText={footerText} footerFontSize={14} />;
};
