export const MuiInputLabel = {
  styleOverrides: {
    root: {
      fontWeight: '500',
      fontSize: '14px',
      marginBottom: '8px',
    },
  },
};

export const lightThemeInputLabel = {
  styleOverrides: {
    ...MuiInputLabel.styleOverrides,
    root: {
      ...MuiInputLabel.styleOverrides.root,
    },
  },
};

export const darkThemeInputLabel = {
  styleOverrides: {
    ...MuiInputLabel.styleOverrides,
  },
};
