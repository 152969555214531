import { ISelectItem } from './grid/SelectCellEditor';

export enum KeyNames {
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  F2 = 'F2',
  ENTER = 'Enter',
  ESCAPE = 'Escape',
}

export enum StringLenghts {
  SMALL = 40,
  MEDIUM = 100,
  LARGE = 200,
  XLARGE = 500,
}

export const ERROR_MSG = 'Something went wrong.';

export const FLASH_ANIMATION_TIME = 1000;

const incotermDescription = (abbreviation: string, description: string) => (
  <>
    <b>{abbreviation}</b>&nbsp;-&nbsp;{description}
  </>
);

// TODO remove this after backend task for incoterm table will be done
export const incoterms: ISelectItem[] = [
  { id: 1, name: 'CFR', description: incotermDescription('CFR', 'Cost and Freight') },
  { id: 2, name: 'CIF', description: incotermDescription('CIF', 'Cost Insurance and Freight') },
  { id: 3, name: 'FAS', description: incotermDescription('FAS', 'Free Alongside Ship') },
  { id: 4, name: 'FOB', description: incotermDescription('FOB', 'Free on Board') },
  { id: 5, name: 'CIP', description: incotermDescription('CIP', 'Carriage and Insurance Paid To') },
  { id: 6, name: 'CPT', description: incotermDescription('CPT', 'Carriage Paid To') },
  { id: 7, name: 'DAP', description: incotermDescription('DAP', 'Delivered at Place') },
  { id: 8, name: 'DDP', description: incotermDescription('DDP', 'Delivered Duty Paid') },
  { id: 9, name: 'DPU', description: incotermDescription('DPU', 'Delivered at Place Unloaded') },
  { id: 10, name: 'EXW', description: incotermDescription('EXW', 'Ex Works (insert place of delivery)') },
  { id: 11, name: 'FCA', description: incotermDescription('FCA', 'Free Carrier') },
];

export const SUPPORT_MAIL = 'support@sea.live';
