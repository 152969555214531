import { CreatePlanCommand, PlanDto } from '../../api/web-api-client';
import { addAbortSignalListener, ApiError, REQUEST_STATUS } from '../helpers';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiManager } from '../../contexts/ApiManager';
import { getItem, LOCAL_STORAGE_KEYS, setItem } from '../../helpers/localStorage';
import { ErrorMsg } from '../../helpers/planErrors';
import { getGridConfigurations, resetGridConfiguration } from './gridConfigurations.slice';

export enum PlansActions {
  GET_PLANS = 'getPlans',
  ADD_PLAN = 'addPlan',
}

export type PlansState = {
  plans: PlanDto[];
  currentPlan: PlanDto | undefined;
  errors: string[];
  status: {
    [key in PlansActions]?: REQUEST_STATUS;
  };
};

export const plansInitialState: PlansState = {
  plans: [],
  currentPlan: undefined,
  errors: [],
  status: {},
};

export interface IAddAndSelectPlanPayload extends CreatePlanCommand {}

export const getPlans = createAsyncThunk(PlansActions.GET_PLANS, async (_, { signal, getState, dispatch }) => {
  addAbortSignalListener(signal);
  try {
    const state = (await getState()) as { api: { apiManager: ApiManager }; plans: PlansState };

    const plans = await state.api.apiManager.plansAPI.getPlans();

    const planFromLocalStorage = getItem<PlanDto>(LOCAL_STORAGE_KEYS.CURRENT_PLAN);
    const newPlan = plans.find(({ id }) => id == planFromLocalStorage?.id) ?? plans[0];

    if (!state.plans.currentPlan && newPlan.id) {
      dispatch(getGridConfigurations(newPlan.id));
    }

    return {
      plans,
      newPlan,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const addPlan = createAsyncThunk(
  PlansActions.ADD_PLAN,
  async (payload: IAddAndSelectPlanPayload, { signal, getState, rejectWithValue, dispatch }) => {
    addAbortSignalListener(signal);
    try {
      const { title } = payload;
      const state = (await getState()) as { api: { apiManager: ApiManager } };
      const id = await state.api.apiManager.plansAPI.createPlan(payload);
      dispatch(resetGridConfiguration());
      return {
        id,
        title,
      };
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const planSlice = createSlice({
  name: 'plans',
  initialState: plansInitialState,
  reducers: {
    selectCurrentPlan: (state, action: PayloadAction<PlanDto>) => {
      setItem(LOCAL_STORAGE_KEYS.CURRENT_PLAN, action.payload);
      state.currentPlan = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getPlans.fulfilled, (state, { payload }) => {
      state.plans = payload.plans;
      state.currentPlan = payload.newPlan;

      state.status[PlansActions.GET_PLANS] = REQUEST_STATUS.fulfilled;
    });
    builder.addCase(addPlan.fulfilled, (state, { payload }) => {
      state.plans.push(payload);
      setItem(LOCAL_STORAGE_KEYS.CURRENT_PLAN, payload);
      state.errors = [];
      state.status[PlansActions.ADD_PLAN] = REQUEST_STATUS.fulfilled;
    });

    builder.addCase(addPlan.pending, (state) => {
      state.status[PlansActions.ADD_PLAN] = REQUEST_STATUS.pending;
    });
    builder.addCase(getPlans.pending, (state) => {
      state.status[PlansActions.GET_PLANS] = REQUEST_STATUS.pending;
    });

    builder.addCase(getPlans.rejected, (state) => {
      state.status[PlansActions.GET_PLANS] = REQUEST_STATUS.rejected;
    });
    builder.addCase(addPlan.rejected, (state, action) => {
      const error = action.payload as ApiError;
      if (error?.status === 409) {
        state.errors.push(ErrorMsg.NAME_CONFLICT, ErrorMsg.ENTER_DIFFERENT_NAME);
      }
      state.status[PlansActions.ADD_PLAN] = REQUEST_STATUS.rejected;
    });
  },
});

export const { selectCurrentPlan } = planSlice.actions;

export default planSlice.reducer;
