import { IAppSettings } from '../contexts/ApiManager';
import { UserManager as OidcUserManager } from 'oidc-client-ts';

declare global {
  interface Window {
    seaTracker: { trackEvent: (eventName: string, properties: unknown) => void };
  }
}

interface SeaHeaderConfig {
  container: HTMLElement;
  authority: string;
  getTokenCallback: () => Promise<string | undefined>;
  logoutCallback: () => void;
  moduleId: number;
  tracking: SeaHeaderTracking;
}

interface SeaHeaderTracking {
  isEnabled: boolean;
  properties: {
    debug: boolean;
  };
}

export class SeaHeader {
  private readonly appSettings: IAppSettings | null = null;
  private readonly oidcUserManager: OidcUserManager | null = null;
  private readonly container = document.getElementsByTagName('header')[0];
  private readonly config: SeaHeaderConfig | null = null;

  constructor(appSettings: IAppSettings, oidcUserManager: OidcUserManager) {
    this.appSettings = appSettings;
    this.oidcUserManager = oidcUserManager;
    this.config = {
      container: this.container,
      authority: this.oidcUserManager!.settings.authority,
      getTokenCallback: () =>
        this.oidcUserManager!.getUser().then((user) => {
          if (user) {
            return user.access_token;
          }
        }),
      logoutCallback: () => this.oidcUserManager!.signoutRedirect(),
      moduleId: 66,
      tracking: {
        isEnabled: appSettings.mixpanelTrackingEnabled,
        properties: {
          debug: false,
        },
      },
    };

    this.setup();
  }

  private setup = () => {
    const now = new Date();
    const timestamp = `${now.getFullYear()}${now.getMonth() + 1}${now.getDate()}${now.getHours()}`;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `${this.appSettings!.headerCdnUrl}/v3/latest/sea-header.js?v=${timestamp}`;
    script.id = 'SeaHeaderScript';

    const head = document.head || document.getElementsByTagName('head')[0];
    head.insertBefore(script, head.firstChild);

    script.onload = this.onScriptLoad;
  };

  private onScriptLoad = () => {
    const header = new window.Sea.HeaderComponent.Header(this.config);
  };
}
