import { styled, Checkbox } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { useAppDispatch, useAppSelector } from '../../../../store/helpers';
import { deselectCargo, selectCargo } from '../../../../store';

const StyledContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledCheckbox = styled(Checkbox)({
  '& .MuiSvgIcon-root': { fontSize: 24 },
});

export const BatchActionsSelectCellRenderer: React.FC<ICellRendererParams> = (props) => {
  const dispatch = useAppDispatch();
  const selectedRows = useAppSelector((state) => state.assignments.selectedRows);

  const isChecked = selectedRows.has(props.data?.cargo?.id);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      props.node.setSelected(true);
      dispatch(selectCargo(props.data?.cargo?.id));
    } else {
      props.node.setSelected(false);
      dispatch(deselectCargo(props.data?.cargo?.id));
    }
  };

  return (
    <StyledContainer>
      <StyledCheckbox onChange={onChange} data-testid={`checkbox-select-cargo-${props.data?.id}`} checked={isChecked} />
    </StyledContainer>
  );
};
