import React from 'react';
import { styled } from '@mui/material/styles';
import { IconButton, Tooltip } from '@mui/material';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { red } from '../../../themes/palette';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';
import { useAppSelector } from '../../../store/helpers';
import { IDateRange } from '../../../store';
import { ICustomRowData } from './hooks/useDefaultGridOptions';
import { AssignmentDto, LinkedTonnageDto } from '../../../api/web-api-client';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  overflow: 'hidden',
});

const TruncatedText = styled('div')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginRight: '2px',
  flex: 1,
});

interface IUnsavedValueCellRenderer<T> extends ICellRendererParams<T, string, unknown> {
  retryUpdateCell: (node: IRowNode<ICustomRowData<T>>, column: string, newValue: unknown, dateRange?: IDateRange) => void;
}

const TOOLTIP_CONTENT = 'We had a problem saving your changes. Click to retry.';

export const UnsavedValueCellRenderer: React.FC<IUnsavedValueCellRenderer<AssignmentDto & LinkedTonnageDto>> = ({
  valueFormatted,
  value,
  retryUpdateCell,
  ...rest
}) => {
  const dateRange = useAppSelector((state) => state.dateRange.dateRange);
  const column = rest?.column?.getColId();
  const onClickHandler = () => {
    if (column) {
      retryUpdateCell(rest.node, column, value, dateRange);
    }
  };

  return (
    <Container>
      <TruncatedText>{valueFormatted ?? value}</TruncatedText>
      <Tooltip title={TOOLTIP_CONTENT}>
        <IconButton data-testid="button-sync-problem" onClick={onClickHandler}>
          <SyncProblemIcon sx={{ color: red.r60, fontSize: '24px' }} />
        </IconButton>
      </Tooltip>
    </Container>
  );
};
