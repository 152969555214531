import { blue, green, orange, purple, red, yellow } from '../../palette';
import CSS from 'csstype';

export enum CellClassName {
  HighlightBgColor1 = 'highlight-bg-color-1',
  HighlightBgColor2 = 'highlight-bg-color-2',
  HighlightBgColor3 = 'highlight-bg-color-3',
  HighlightBgColor4 = 'highlight-bg-color-4',
  HighlightBgColor5 = 'highlight-bg-color-5',
  HighlightBgColor6 = 'highlight-bg-color-6',
}

type CellClassStyles = {
  [key in CellClassName]: CSS.Properties;
};

export const cellClassStyles: CellClassStyles = {
  [CellClassName.HighlightBgColor1]: {
    backgroundColor: red.r20,
  },
  [CellClassName.HighlightBgColor2]: {
    backgroundColor: orange.o5,
  },
  [CellClassName.HighlightBgColor3]: {
    backgroundColor: yellow.y10,
  },
  [CellClassName.HighlightBgColor4]: {
    backgroundColor: green.g10,
  },
  [CellClassName.HighlightBgColor5]: {
    backgroundColor: blue.b20,
  },
  [CellClassName.HighlightBgColor6]: {
    backgroundColor: purple.p10,
  },
};

const cellClassStylesInTheme: { [key: string]: CSS.Properties } = {};
for (const [key, value] of Object.entries(cellClassStyles)) {
  cellClassStylesInTheme[`.${key}`] = value;
}

export const MuiBaseline = {
  styleOverrides: {
    html: {
      ...cellClassStylesInTheme,
    },
  },
};
