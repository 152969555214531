import { neutral } from '../../palette';

export const MuiAutocomplete = {
  styleOverrides: {
    root: {
      backgroundColor: neutral.n100,
      '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
};

export const darkThemeAutocomplete = {
  styleOverrides: {
    ...MuiAutocomplete.styleOverrides,
  },
};

export const lightThemeAutocomplete = {
  styleOverrides: {
    ...MuiAutocomplete.styleOverrides,
    root: {
      ...MuiAutocomplete.styleOverrides.root,
    },
  },
};
