import { useMemo } from 'react';
import { ColumnApi, ColumnEvent, ColumnState } from 'ag-grid-community';
import { GridId } from './../helpers';
import { useAppDispatch, useAppSelector } from '../../../../store/helpers';
import { updateGridConfiguration } from '../../../../store';
import { debounce } from '../../../../helpers/helpers';
import { useAuth } from '../../../../contexts';

const subscribedGridEvents = ['columnVisible', 'columnPinned', 'columnResized', 'columnMoved', 'sortChanged'];
const continiousGridEvent = ['columnResized', 'columnMoved'];

export const useGridConfiguration = (currentGridId: GridId) => {
  const dispatch = useAppDispatch();
  const currentPlan = useAppSelector((state) => state.plans.currentPlan);
  const gridConfigurations = useAppSelector((state) => state.gridConfigurations.gridConfigurations);
  const { hasWriteRights } = useAuth();

  const isEventFinished = (gridEvent: any) => {
    return (
      !continiousGridEvent.includes(gridEvent.type) ||
      (continiousGridEvent.includes(gridEvent.type) && gridEvent.finished == true)
    );
  };

  const isUserEvent = (gridEvent: ColumnEvent) => {
    return gridEvent.source !== 'flex' && gridEvent.source !== 'api' && gridEvent.source !== 'rowDataUpdated';
  };

  const updateGridConfig = (columnApi: ColumnApi) => {
    const updatedGridConfiguration = {
      ...gridConfigurations,
      [currentGridId]: columnApi.getColumnState(),
    };
    dispatch(updateGridConfiguration({ planId: currentPlan?.id, gridConfigurations: updatedGridConfiguration }));
  };

  const debouncedUpdateGridConfig = useMemo(() => {
    return debounce(updateGridConfig);
  }, [updateGridConfig]);

  const updateGridState = (gridEvent: ColumnEvent) => {
    if (hasWriteRights && subscribedGridEvents.includes(gridEvent.type) && isUserEvent(gridEvent) && isEventFinished(gridEvent)) {
      debouncedUpdateGridConfig(gridEvent.columnApi);
    }
  };

  const adjustOrderIfStoredConfigDoesntHaveSomeColumns = (columnApi: ColumnApi | undefined, storedColumState: ColumnState[]) => {
    const storedColumStateCopy = [...storedColumState];
    const columnsInDefaultOrder = columnApi?.getColumns();
    const columnIdListFromConfig = storedColumStateCopy?.map((column) => column.colId);
    const currentColumnsState = columnApi?.getColumnState();

    columnsInDefaultOrder?.forEach((column, index) => {
      const colId = column.getColId();

      if (!columnIdListFromConfig?.includes(colId)) {
        const newColumnState = currentColumnsState?.find((col) => col.colId === colId);
        if (newColumnState) {
          storedColumStateCopy?.splice(index, 0, newColumnState);
        }
      }
    });
    return storedColumStateCopy;
  };

  const applyGridState = (columnApi: ColumnApi | undefined, customColumnsConfig: ColumnState[]) => {
    const state = gridConfigurations[currentGridId]?.map((columnConfig) => ({
      ...columnConfig,
      ...customColumnsConfig.find((customColumnConfig) => customColumnConfig.colId === columnConfig.colId && customColumnConfig),
      flex: null, //is needed for custom width to be applied, see https://www.ag-grid.com/react-data-grid/column-state/#width-and-flex
    }));

    if (!state?.length) {
      columnApi?.resetColumnState();
      return;
    }

    columnApi?.applyColumnState({
      state: adjustOrderIfStoredConfigDoesntHaveSomeColumns(columnApi, state),
      applyOrder: true,
    });
  };

  return {
    updateGridState,
    applyGridState,
  };
};
