export const isContentInElementTruncated = (element: HTMLElement) => {
  return element.offsetWidth < element.scrollWidth;
};

export const scrollToEndOfInput = (input: HTMLInputElement) => {
  const length = input.value ? input.value.length : 0;
  if (length > 0) {
    input.scrollLeft = input.scrollWidth;
  }
};

export const capitalize = (str: string) => {
  const values = str.split('.');
  const capitalizedValues = values.map((value) => value.charAt(0).toUpperCase() + value.slice(1));
  return capitalizedValues.join('.');
};

export type DeepKeyOf<T> = T extends Array<infer U>
  ? `${DeepKeyOf<U>}`
  : T extends object
  ? {
      [K in keyof T & (string | number)]: K extends string ? `${K}` | `${K}.${DeepKeyOf<T[K]>}` : never;
    }[keyof T & (string | number)]
  : never;

export const debounce = <F extends (...args: Parameters<F>) => ReturnType<F>>(func: F, waitFor = 300) => {
  let timeout: NodeJS.Timeout;

  const debounced = (...args: Parameters<F>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced;
};

export const createLocationUpdateObject = (name = '', id?: string) => {
  return {
    name: {
      newValue: name,
    },
    id: {
      newValue: id ?? null,
    },
  };
};

export const createVesselUpdateObject = (name = '', seaId?: number) => {
  return {
    name: {
      newValue: name,
    },
    seaId: {
      newValue: seaId ?? null,
    },
  };
};

export const createVesselAddObject = (name = '', seaId?: number) => {
  return {
    name: name ?? null,
    seaId: seaId ?? null,
  };
};

export const isNil = (value: unknown) => {
  // true when is null or undefined
  return value == null;
};

type GenericObject = { [key: string]: any };

const getPropRecursiveHelper = (obj: GenericObject, keys: string[]): unknown => {
  if (!keys.length) {
    return obj;
  }
  const [first, ...rest] = keys;
  return getPropRecursiveHelper(obj[first], rest);
};

export const getProperty = (obj: GenericObject, propPath: string): any => {
  const keys = propPath.split('.');
  return getPropRecursiveHelper(obj, keys);
};

export const isEmptyObject = (value: any) => {
  return typeof value === 'object' && !Object.keys(value).length;
};

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
