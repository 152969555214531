import { styled } from '@mui/system';
import { grey } from '../../../../themes/palette';
import { useEffect, useState } from 'react';
import { CustomSelectBox } from '../../../common/CustomSelectBox/CustomSelectBox';
import { CheckBox } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../../store/helpers';
import { toggleBatchActionsSelectBox } from '../../../../store';
import { BatchActionsMenu } from './BatchActionsMenu/BatchActionsMenu';
import { useGridRefs } from '../../../../contexts/GridRefsContext';
import { DuplicateCargoesAction } from './duplicateCargoes/DuplicateCargoesAction';

export const BatchActionsSelectBox: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const { assignmentsGridRef } = useGridRefs();

  const dispatch = useAppDispatch();
  const isActive = useAppSelector((state) => state.assignments.isBatchActionSelectBoxActive);

  useEffect(() => {
    assignmentsGridRef.current?.columnApi?.setColumnVisible('batchActionsSelect', isActive);
  }, [isActive]);

  const closeMenu = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const hideTooltip = () => {
    setShowTooltip(false);
  };

  const onClick = () => {
    dispatch(toggleBatchActionsSelectBox());
  };

  return (
    <>
      <CustomSelectBox
        id="cargoes-batch-actions"
        onClickHandler={onClick}
        mainButtonContent={<StyledCheckBoxIcon isActive={isActive} />}
        isTooltipOpen={showTooltip}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        closeTooltip={hideTooltip}
        hideArrowBtn={!isActive}
        setAnchorEl={setAnchorEl}
        toggleable
      >
        <BatchActionsMenu isOpen={isOpen} anchorEl={anchorEl} onClose={closeMenu} />
      </CustomSelectBox>
      <DuplicateCargoesAction />
    </>
  );
};

interface IStyledCheckBoxIcon {
  isActive: boolean;
}
const styledProps = {
  shouldForwardProp: (prop: unknown) => prop !== 'isActive',
};

const StyledCheckBoxIcon = styled(
  CheckBox,
  styledProps
)<IStyledCheckBoxIcon>(({ isActive }) => ({
  color: isActive ? grey.g10 : grey.g9,
  fontSize: '28.85px',
}));
