import React from 'react';
import { LinkedTonnageDto, AssignmentDto } from '../../../../api/web-api-client';
import { useGridRefs } from '../../../../contexts/GridRefsContext';
import ConfirmationDialog from '../../../common/dialog/ConfirmationDialog';
import { ConfirmationDialogType } from '../../../common/dialog/helpers';
import { GridId, isDragging, isDropTarget } from '../../../common/grid/helpers';
import { useAssignmentsGrid } from './hooks/useAssignmentsGrid';
import { targetOverlayClass } from './hooks/useTonnageDrop';
import {
  MixpanelEventAction,
  MixpanelEventCategory,
  MixpanelEventLabel,
  MixpanelEventLocation,
  MixpanelEventName,
  useMixpanel,
} from '../../../../contexts/MixpanelContext';
import { useAppSelector } from '../../../../store/helpers';

interface IAssignmentConfirmations {
  assign: (assignmentId: number, vessel: LinkedTonnageDto) => Promise<void>;
}

export interface IAssignmentConfirmationsRef {
  onDrop: (tonnage: LinkedTonnageDto, assignment: AssignmentDto) => void;
}

const AssignmentConfirmations = React.forwardRef<IAssignmentConfirmationsRef, IAssignmentConfirmations>(({ assign }, ref) => {
  const [isReplaceDialogOpen, setIsReplaceDialogOpen] = React.useState(false);
  const [isReassignDialogOpen, setIsReassignDialogOpen] = React.useState(false);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = React.useState(false);
  const [linkedData, setLinkedData] = React.useState<{
    tonnage: LinkedTonnageDto | undefined;
    assignment: AssignmentDto | undefined;
  }>({ assignment: undefined, tonnage: undefined });

  const { assignmentsGridRef, tonnagesGridRef } = useGridRefs();
  const { unassignTonnage, unassignCargo } = useAssignmentsGrid();
  const planId = useAppSelector((state) => state.plans.currentPlan?.id);

  const { trackEvent } = useMixpanel();

  React.useImperativeHandle(ref, () => ({
    onDrop(tonnage: LinkedTonnageDto, assignment: AssignmentDto) {
      onDrop(tonnage, assignment);
    },
  }));

  async function onDrop(tonnage: LinkedTonnageDto, assignment: AssignmentDto) {
    if (assignment.tonnage?.id === tonnage.id) {
      removeRowHighlight();
      return;
    }

    if (assignment.id && tonnage.id) {
      setLinkedData({ assignment: assignment, tonnage: tonnage });
      const isLinked = tonnage.linkedAssignmentsIds?.length;
      if (assignment.tonnage?.id && !isLinked) {
        setIsReplaceDialogOpen(true);
      } else if (!assignment.tonnage?.id && isLinked) {
        setIsReassignDialogOpen(true);
      } else if (assignment.tonnage?.id && isLinked) {
        setIsUpdateDialogOpen(true);
      } else {
        await assign(assignment.id, tonnage);
        removeRowHighlight();

        trackEvent(MixpanelEventCategory.INTERACTION, {
          event: MixpanelEventName.ASSIGN_TONNAGE_BY_DRAG_AND_DROP,
          eventAction: MixpanelEventAction.DRAG_AND_DROP,
          eventLabel: MixpanelEventLabel.DRAG_SOURCE_AND_DRAG_TARGET,
          eventLocation: MixpanelEventLocation.TONNAGE_ROW_AND_CARGO_ASSIGNMENT_ROW,
          eventVersion: 1.1,
          assignmentId: assignment.id,
          cargoId: assignment?.cargo?.id,
          tonnageId: tonnage.id,
          planId,
        });
      }
    }
  }

  async function handleReplace(id?: number): Promise<void> {
    if (id && linkedData.tonnage) {
      const oldTonnageId = linkedData.assignment?.tonnage?.id;
      await assign(id, linkedData.tonnage);
      unassignPreviousTonnage(oldTonnageId);
      removeRowHighlight();

      trackEvent(MixpanelEventCategory.INTERACTION, {
        event: MixpanelEventName.CONFIRM_REPLACE_TONNAGE,
        eventAction: MixpanelEventAction.CLICK,
        eventLabel: MixpanelEventLabel.BUTTON,
        eventLocation: MixpanelEventLocation.REPLACE_TONNAGE_MODAL,
        eventVersion: 1.1,
        planId,
        oldTonnageId,
        tonnageId: linkedData.tonnage?.id,
        assignmentId: id,
        cargoId: linkedData?.assignment?.cargo?.id,
      });
    }
  }

  async function handleReassign(id?: number): Promise<void> {
    if (id && linkedData.tonnage) {
      const oldAssignmentId = linkedData.tonnage?.linkedAssignmentsIds?.[0];
      await assign(id, linkedData.tonnage);
      unassignPreviousCargo(oldAssignmentId);
      removeRowHighlight();

      trackEvent(MixpanelEventCategory.INTERACTION, {
        event: MixpanelEventName.CONFIRM_REASSIGN_TONNAGE,
        eventAction: MixpanelEventAction.CLICK,
        eventLabel: MixpanelEventLabel.BUTTON,
        eventLocation: MixpanelEventLocation.REASSIGN_TONNAGE_MODAL,
        eventVersion: 1.1,
        planId,
        oldAssignmentId,
        assignmentId: id,
        tonnageId: linkedData.tonnage?.id,
        cargoId: linkedData?.assignment?.cargo?.id,
      });
    }
  }

  async function handleUpdate(id?: number): Promise<void> {
    if (id && linkedData.tonnage) {
      const oldTonnageId = linkedData.assignment?.tonnage?.id;
      const oldAssignmentId = linkedData.tonnage?.linkedAssignmentsIds?.[0];
      await assign(id, linkedData.tonnage);
      unassignPreviousTonnage(oldTonnageId);
      unassignPreviousCargo(oldAssignmentId);
      removeRowHighlight();

      trackEvent(MixpanelEventCategory.INTERACTION, {
        event: MixpanelEventName.CONFIRM_UPDATE_TONNAGE_ASSIGNMENT,
        eventAction: MixpanelEventAction.CLICK,
        eventLabel: MixpanelEventLabel.BUTTON,
        eventLocation: MixpanelEventLocation.UPDATE_TONNAGE_ASSIGNMENT_MODAL,
        eventVersion: 1.1,
        oldAssignmentId,
        planId,
        oldTonnageId,
        assignmentId: id,
        tonnageId: linkedData.tonnage?.id,
        cargoId: linkedData?.assignment?.cargo?.id,
      });
    }
  }

  function unassignPreviousTonnage(oldTonnageId: number | undefined) {
    if (oldTonnageId) {
      const tonnageToUnlink = tonnagesGridRef.current?.api.getRowNode(oldTonnageId.toString());
      if (tonnageToUnlink) {
        unassignTonnage(tonnageToUnlink);
      }
    }
  }

  function unassignPreviousCargo(oldAssignmentId: number | undefined) {
    if (oldAssignmentId) {
      const assignmentToUnlink = assignmentsGridRef.current?.api.getRowNode(oldAssignmentId.toString());
      if (assignmentToUnlink) {
        unassignCargo(assignmentToUnlink);
      }
    }
  }

  function removeRowHighlight() {
    const tonnageGrid = document.getElementById(GridId.tonnage);
    const highlightedTonnages = tonnageGrid?.querySelectorAll(`[${isDragging}="true"]`);
    if (highlightedTonnages) {
      highlightedTonnages.forEach((row) => row.setAttribute(isDragging, 'false'));
    }

    const assignmentGrid = document.getElementById(GridId.cargoAssigment);
    const highlightedAssignments = assignmentGrid?.querySelectorAll(`[${isDropTarget}="true"]`);
    if (highlightedAssignments) {
      highlightedAssignments.forEach((row) => {
        row.setAttribute(isDropTarget, 'false');
        const overlay = row.querySelector(`.${targetOverlayClass}`);
        overlay?.remove();
      });
    }
  }

  return (
    <>
      <ConfirmationDialog
        actionHandler={handleReplace}
        itemId={linkedData.assignment?.id}
        isConfirmationDialogOpen={isReplaceDialogOpen}
        setIsConfirmationDialogOpen={setIsReplaceDialogOpen}
        dialogType={ConfirmationDialogType.replaceTonnageOnDrop}
        onCancel={removeRowHighlight}
      />
      <ConfirmationDialog
        actionHandler={handleReassign}
        itemId={linkedData.assignment?.id}
        isConfirmationDialogOpen={isReassignDialogOpen}
        setIsConfirmationDialogOpen={setIsReassignDialogOpen}
        dialogType={ConfirmationDialogType.reassignTonnageOnDrop}
        onCancel={removeRowHighlight}
      />
      <ConfirmationDialog
        actionHandler={handleUpdate}
        itemId={linkedData.assignment?.id}
        isConfirmationDialogOpen={isUpdateDialogOpen}
        setIsConfirmationDialogOpen={setIsUpdateDialogOpen}
        dialogType={ConfirmationDialogType.updateTonnageAssignmentsOnDrop}
        onCancel={removeRowHighlight}
      />
    </>
  );
});

AssignmentConfirmations.displayName = 'AssignmentConfirmations';

export default AssignmentConfirmations;
