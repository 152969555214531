import { addAbortSignalListener, REQUEST_STATUS } from '../helpers';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiManager } from '../../contexts/ApiManager';
import { VesselDto } from '../../api/web-api-client';

const MAX_VESSELS_COUNT = 15;

export enum VesselsActions {
  SEARCH_VESSELS = 'searchVessels',
}

export type VesselsState = {
  vessels: VesselDto[];
  vesselsTotalCount: number;
  errors: string[];
  status: {
    [key in VesselsActions]?: REQUEST_STATUS;
  };
};

export const vesselsInitialState: VesselsState = {
  vessels: [],
  vesselsTotalCount: 0,
  errors: [],
  status: {},
};

export const searchVessels = createAsyncThunk(
  VesselsActions.SEARCH_VESSELS,
  async (payload: string, { signal, getState, rejectWithValue }) => {
    const source = addAbortSignalListener(signal);

    try {
      const state = (await getState()) as { api: { apiManager: ApiManager } };
      return await state.api.apiManager.vesselsAPI.searchVessels(payload, undefined, MAX_VESSELS_COUNT, source.token);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const vesselsSlice = createSlice({
  name: 'vessels',
  initialState: vesselsInitialState,
  reducers: {
    resetState: (state) => {
      state.vesselsTotalCount = 0;
      state.vessels = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(searchVessels.fulfilled, (state, { payload }) => {
      state.vessels = payload?.vessels ?? [];
      state.vesselsTotalCount = payload?.count ?? 0;
      state.status[VesselsActions.SEARCH_VESSELS] = REQUEST_STATUS.fulfilled;
    });

    builder.addCase(searchVessels.pending, (state) => {
      state.status[VesselsActions.SEARCH_VESSELS] = REQUEST_STATUS.pending;
    });

    builder.addCase(searchVessels.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.status[VesselsActions.SEARCH_VESSELS] = REQUEST_STATUS.rejected;
      }
    });
  },
});

export const { resetState } = vesselsSlice.actions;

export default vesselsSlice.reducer;
