import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import { Chip, styled } from '@mui/material';
import { blue, grey, neutral } from '../../../../../themes/palette';

export const dateFromShortcuts = [
  {
    label: 'Today',
    amountOfDays: 0,
    dataTestIdPrefix: 'today',
  },
  {
    label: '+10d',
    amountOfDays: 10,
    dataTestIdPrefix: '10-days',
  },
  {
    label: '+20d',
    amountOfDays: 20,
    dataTestIdPrefix: '20-days',
  },
  {
    label: '+30d',
    amountOfDays: 30,
    dataTestIdPrefix: '30-days',
  },
];

export const dateToShortcuts = [
  {
    label: '30 Days',
    amountOfDays: 29,
    dataTestIdPrefix: '30-days',
  },
  {
    label: '45 Days',
    amountOfDays: 44,
    dataTestIdPrefix: '45-days',
  },
  {
    label: '60 Days',
    amountOfDays: 59,
    dataTestIdPrefix: '60-days',
  },
];

interface IShortcutButtonsProps {
  dateFrom: Dayjs;
  dateTo: Dayjs;
  onDateFromShortcutClick: (amountOfDays: number) => void;
  onDateToShortcutClick: (amountOfDays: number) => void;
}

export const ShortcutButtons: React.FC<IShortcutButtonsProps> = (props) => {
  const { dateFrom, dateTo, onDateFromShortcutClick, onDateToShortcutClick } = props;

  const areDatesSameDayMonthAndYear = (dateOne: Dayjs, dateTwo: Dayjs | null): boolean =>
    dateOne?.isSame(dateTwo, 'date') && dateOne?.isSame(dateTwo, 'month') && dateOne?.isSame(dateTwo, 'year');

  return (
    <StyledContainer>
      <StyledChipsContainer>
        {dateFromShortcuts.map((dateFromShortcut) => (
          <StyledChip
            key={dateFromShortcut.label}
            isselected={`${areDatesSameDayMonthAndYear(dateFrom, dayjs().utc().add(dateFromShortcut.amountOfDays, 'day'))}`}
            color="primary"
            role="button"
            label={dateFromShortcut.label}
            onClick={() => onDateFromShortcutClick(dateFromShortcut.amountOfDays)}
            data-testid={`plandatefilter-modal-custom-range-view-button-shortcut-from-${dateFromShortcut.dataTestIdPrefix}`}
          />
        ))}
      </StyledChipsContainer>

      <StyledChipsContainer>
        <StyledText>Duration</StyledText>
        {dateToShortcuts.map((dateToShortcut) => (
          <StyledChip
            key={dateToShortcut.label}
            isselected={`${areDatesSameDayMonthAndYear(dateTo, dateFrom.add(dateToShortcut.amountOfDays, 'day') || null)}`}
            color="primary"
            role="button"
            label={dateToShortcut.label}
            onClick={() => onDateToShortcutClick(dateToShortcut.amountOfDays)}
            data-testid={`plandatefilter-modal-custom-range-view-button-shortcut-to-${dateToShortcut.dataTestIdPrefix}`}
          />
        ))}
      </StyledChipsContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled('div')({
  paddingTop: '12px',
  paddingBottom: '16px',
  display: 'flex',
  gap: '16px',
});

const StyledChipsContainer = styled('div')({
  width: '50%',
  display: 'flex',
  gap: '6px',
  alignItems: 'center',
});

const StyledChip = styled(Chip)<{ isselected?: string }>(({ isselected }) => ({
  backgroundColor: isselected === 'true' ? blue.b70 : grey.g1,
  color: isselected === 'true' ? neutral.n100 : grey.g12,
  padding: '3px 10px',
  height: 'initial',
  ':hover, :focus': {
    color: neutral.n100,
  },
  '.MuiChip-label': {
    padding: 0,
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: 400,
    letterSpacing: '.16px',
  },
}));

const StyledText = styled('span')({
  color: neutral.n65,
  fontSize: '13px',
  lineHeight: '18px',
});
