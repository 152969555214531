import { styled } from '@mui/system';
import { neutral } from '../../themes/palette';
import { useState } from 'react';
import { ReactComponent as ColourClearIcon } from '../../assets/colourClear.svg';
import { ColorMenu } from './ColorMenu/ColorMenu';
import { getItem, LOCAL_STORAGE_KEYS, setItem } from '../../helpers/localStorage';
import { CustomSelectBox } from '../common/CustomSelectBox/CustomSelectBox';
import { IHighlightColor } from '../common/grid/hooks/useCellFormatting/useCellFormatting';

const tooltipTitle = 'Please select a cell';

interface IStyledColorBox {
  bgColor: string;
}

interface IColorPicker {
  onClickHandler: (color?: string) => boolean;
}

const StyledColorBox = styled('div')<IStyledColorBox>(({ bgColor }) => ({
  height: '20px',
  width: '20px',
  borderRadius: '4px',
  backgroundColor: bgColor,
}));

const StyledClearIcon = styled(ColourClearIcon)({
  backgroundColor: neutral.n100,
  borderRadius: '4px',
  width: '20px',
  height: '20px',
});

export const ColorPicker: React.FC<IColorPicker> = ({ onClickHandler }) => {
  const [currentColor, setCurrentColor] = useState<IHighlightColor | null>(getItem(LOCAL_STORAGE_KEYS.HIGHLIGHT_COLOR) ?? null);
  const [isOpen, setIsOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const closeMenu = () => {
    setIsOpen(false);
  };

  const displayTooltip = () => {
    setShowTooltip(true);
  };

  const hideTooltip = () => {
    setShowTooltip(false);
  };

  const onClick = (classname?: string) => {
    const response = onClickHandler(classname);

    if (!response) {
      displayTooltip();
    }
  };

  const onSelectColor = (color: IHighlightColor | null) => {
    setItem(LOCAL_STORAGE_KEYS.HIGHLIGHT_COLOR, color);
    setCurrentColor(color);
    onClick(color?.className);
    closeMenu();
  };

  return (
    <CustomSelectBox
      id="color-picker"
      onClickHandler={() => onClick(currentColor?.className)}
      mainButtonContent={currentColor ? <StyledColorBox bgColor={currentColor.button} /> : <StyledClearIcon />}
      tooltipMsg={tooltipTitle}
      isTooltipOpen={showTooltip}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      closeTooltip={hideTooltip}
    >
      <ColorMenu onClickHandler={onSelectColor} />
    </CustomSelectBox>
  );
};
