import React from 'react';
import { styled } from '@mui/material';
import { ReactComponent as ShiftEnterIcon } from '../../../../assets/shiftEnter.svg';
import { StringLenghts } from '../../Constants';

interface ITextareaFooterProps {
  contentLength: number;
}

export const TextareaFooter: React.FC<ITextareaFooterProps> = ({contentLength}) => {
  return (
    <StyledContainer>
      <StyledLegend>
        <span>New line:</span>
        <ShiftEnterIcon/>
      </StyledLegend>
      <div>{contentLength}/{StringLenghts.XLARGE}</div>
    </StyledContainer>
  );
};

const StyledContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 12,
  letterSpacing: 0,
  lineHeight: '12px',
});

const StyledLegend = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& span': {
    marginRight: 3
  }
});