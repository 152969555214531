import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { MaritimeDateRange } from '@maritech/maritime-date/dist/types/date-range';
dayjs.extend(utc);

interface IDateRange {
  from: Dayjs;
  to: Dayjs;
}

export const areDatesSameDay = (dateRange: IDateRange): boolean => {
  return dateRange.from.utc().date() === dateRange.to.utc().date();
};

export const areDatesSameMonth = (dateRange: IDateRange): boolean => {
  return dateRange.from.utc().month() === dateRange.to.utc().month();
};

export const areDatesSameYear = (dateRange: IDateRange): boolean => {
  return dateRange.from.utc().year() === dateRange.to.utc().year();
};

export const areDatesSameDayMonthAndYear = (dateRange: IDateRange): boolean => {
  return areDatesSameDay(dateRange) && areDatesSameMonth(dateRange) && areDatesSameYear(dateRange);
};

export const areDatesDifferentDayMonthAndYear = (dateRange: IDateRange): boolean => {
  return !areDatesSameDay(dateRange) && !areDatesSameMonth(dateRange) && !areDatesSameYear(dateRange);
};

export const areDatesDifferentDayOnly = (dateRange: IDateRange): boolean => {
  return !areDatesSameDay(dateRange) && areDatesSameMonth(dateRange) && areDatesSameYear(dateRange);
};

export const areDatesSameYearOnly = (dateRange: IDateRange): boolean => {
  return !areDatesSameDay(dateRange) && !areDatesSameMonth(dateRange) && areDatesSameYear(dateRange);
};

export const areDatesDifferentMonthOnly = (dateRange: IDateRange): boolean => {
  return areDatesSameDay(dateRange) && !areDatesSameMonth(dateRange) && areDatesSameYear(dateRange);
};

export const areDatesSameDayOnly = (dateRange: IDateRange): boolean => {
  return areDatesSameDay(dateRange) && !areDatesSameMonth(dateRange) && !areDatesSameYear(dateRange);
};

export const areDatesDifferentYearOnly = (dateRange: IDateRange): boolean => {
  return areDatesSameDay(dateRange) && areDatesSameMonth(dateRange) && !areDatesSameYear(dateRange);
};

export const areDatesSameMonthOnly = (dateRange: IDateRange): boolean => {
  return !areDatesSameDay(dateRange) && areDatesSameMonth(dateRange) && !areDatesSameYear(dateRange);
};

interface IFormatDateRangeFunctionOptions {
  yearFormat?: string;
  showYear?: boolean;
}

type FormatDateRangeFunction = (dateRange: IDateRange, options?: IFormatDateRangeFunctionOptions) => string;

export const formatDateRange: FormatDateRangeFunction = (dateRange: IDateRange, options) => {
  const { showYear, yearFormat = 'YY' } = { ...options };
  const from = dayjs.utc(dateRange.from);
  const to = dayjs.utc(dateRange.to);
  const dateRangeDayJs = { from, to };
  const fromUtc = from.utc();
  const toUtc = to.utc();

  let formattedDateRange;

  if (areDatesSameDayMonthAndYear(dateRangeDayJs)) {
    const format = showYear ? `D MMM ${yearFormat}` : 'D MMM';
    formattedDateRange = `${fromUtc.format(format)}`;
  } else if (
    areDatesDifferentDayMonthAndYear(dateRangeDayJs) ||
    areDatesSameDayOnly(dateRangeDayJs) ||
    areDatesDifferentYearOnly(dateRangeDayJs) ||
    areDatesSameMonthOnly(dateRangeDayJs)
  ) {
    const format = `D MMM ${yearFormat}`;
    formattedDateRange = `${fromUtc.format(format)} - ${toUtc.format(format)}`;
  } else if (areDatesDifferentDayOnly(dateRangeDayJs)) {
    const fromFormat = 'D';
    const toFormat = showYear ? `D MMM ${yearFormat}` : 'D MMM';
    formattedDateRange = `${fromUtc.format(fromFormat)} - ${toUtc.format(toFormat)}`;
  } else if (areDatesSameYearOnly(dateRangeDayJs) || areDatesDifferentMonthOnly(dateRangeDayJs)) {
    const fromFormat = 'D MMM';
    const toFormat = showYear ? `D MMM ${yearFormat}` : 'D MMM';
    formattedDateRange = `${fromUtc.format(fromFormat)} - ${toUtc.format(toFormat)}`;
  } else {
    const format = `D MMM ${yearFormat}`;
    formattedDateRange = `${fromUtc.format(format)} - ${toUtc.format(format)}`;
  }

  return formattedDateRange;
};

export const fromMaritimeDateRangeToDateRange = (maritimeDateRange: MaritimeDateRange) => {
  const dateRangeISO = maritimeDateRange.toISO().split('/');
  const from = new Date(dateRangeISO[0]);
  const to = new Date(dateRangeISO[1]);

  return {
    from,
    to,
  };
};

export const formatDate = (date: Date) => {
  const dayjsDate = dayjs.utc(date);
  return dayjsDate.utc().format('D MMM YYYY');
};

/*
  TODO Remove and use dayjs plugin instead after bug fix
  AdvancedFormat plugin from dayjs package should return abbreviated named offset with 'z' format (https://day.js.org/docs/en/plugin/advanced-format)
  but it has a bug and returns for example 'GMT+1' instead (https://github.com/iamkun/dayjs/issues/1713)
  This is a workaround until bug will be fixed
*/
export const getTimeZoneAbbreviation = (date: Date | Dayjs): string => {
  const inputDate = date instanceof Date ? date : date.toDate();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneAbbreviation = new Intl.DateTimeFormat('en-GB', {
    timeZone: timeZone,
    timeZoneName: 'short',
  })
    .format(inputDate)
    .split(' ')[1];

  return timeZoneAbbreviation;
};

export const getDownloadFileDateText = (date: Date | Dayjs): string => {
  const inputDate = date instanceof Date ? dayjs(date) : date;
  return inputDate.format('YYYY-MM-DD--HHmmss');
};

export const getAmountOfDaysInDateRange = (dateRange: IDateRange): number => {
  const dateFrom = dayjs.utc(dateRange.from);
  const dateTo = dayjs.utc(dateRange.to);
  const dateRangeDatesDifference = dateTo?.diff(dateFrom, 'day');
  return dateRangeDatesDifference + 1;
};
