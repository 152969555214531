import { AlertColor } from '@mui/material';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type NotificationsState = {
  autoHideDuration: number;
  isOpen: boolean;
  message: React.ReactNode;
  title: string;
  type: AlertColor;
};

export const notificationsInitialState: NotificationsState = {
  isOpen: false,
  autoHideDuration: 6000,
  title: '',
  message: null,
  type: 'success',
};

interface IShowNotificationSnackbar {
  title: string;
  type: AlertColor;
  message?: React.ReactNode;
  autoHideDuration?: number;
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: notificationsInitialState,
  reducers: {
    showNotificationSnackbar: (_, action: PayloadAction<IShowNotificationSnackbar>) => {
      return { ...notificationsInitialState, ...action.payload, isOpen: true };
    },
    closeNotificationSnackBar: (state) => {
      state.isOpen = false;
    },
  },
});

export const { showNotificationSnackbar, closeNotificationSnackBar } = notificationsSlice.actions;

export default notificationsSlice.reducer;
