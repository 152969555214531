import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type DialogsState = {
  isDuplicateCargoesDialogOpen: boolean;
};

export const dialogsInitialState: DialogsState = {
  isDuplicateCargoesDialogOpen: false,
};

const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState: dialogsInitialState,
  reducers: {
    setIsOpenDuplicateBatchOfCargoesDialog: (state, action: PayloadAction<boolean>) => {
      state.isDuplicateCargoesDialogOpen = action.payload;
    },
  },
});

export const { setIsOpenDuplicateBatchOfCargoesDialog } = dialogsSlice.actions;

export default dialogsSlice.reducer;
