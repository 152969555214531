import { grey } from '../../palette';

export const MuiCheckbox = {
  styleOverrides: {
    root: {
      color: grey.g9,
    },
  },
};

export const darkThemeMuiCheckbox = {
  styleOverrides: {
    ...MuiCheckbox.styleOverrides,
    root: {
      ...MuiCheckbox.styleOverrides.root,
    },
  },
};

export const lightThemeMuiCheckbox = {
  styleOverrides: {
    ...MuiCheckbox.styleOverrides,
    root: {
      ...MuiCheckbox.styleOverrides.root,
    },
  },
};
