import { CellClassParams, CellClassRules, CellPosition, GridApi } from 'ag-grid-community';
import { useGridRefs } from '../../../../../contexts/GridRefsContext';
import { blue, green, orange, purple, red, yellow } from '../../../../../themes/palette';
import { useAppDispatch } from '../../../../../store/helpers';
import { updateAssignmentFormattingMetadata, updateTonnagesFormattingMetadata } from '../../../../../store';
import { DeepKeyOf, capitalize, delay } from '../../../../../helpers/helpers';
import { AssignmentDto, LinkedTonnageDto, TonnageFormattingDto } from '../../../../../api/web-api-client';
import { CellClassName } from '../../../../../themes/ThemeBase/styleOverrides/MuiBaseline';
import { useFeatureFlags } from '../../../../../contexts/FeatureFlagsContext';
import { FeatureFlagsEnum } from '../../../../../api/featureFlags';
import { ErrorCounterType, incrementErrorCounter } from '../../../../../store/slices/errors.slice';
import { AssignmentsGridColumn } from '../../../../dashboards/cargo/grid';
import {
  AssignmentsFormattingRequestMonitor,
  FormattingRequest,
  TonnageFormattingRequestMonitor,
  getOldClassName,
  updateAssignmentCellFormattingObject,
} from './helpers';

export interface IHighlightColor {
  menu: string;
  button: string;
  className: CellClassName;
}

export const highlightColors: IHighlightColor[] = [
  {
    menu: red.r25,
    button: red.r30,
    className: CellClassName.HighlightBgColor1,
  },
  {
    menu: orange.o6,
    button: orange.o7,
    className: CellClassName.HighlightBgColor2,
  },
  {
    menu: yellow.y20,
    button: yellow.y30,
    className: CellClassName.HighlightBgColor3,
  },
  {
    menu: green.g20,
    button: green.g30,
    className: CellClassName.HighlightBgColor4,
  },
  {
    menu: blue.b25,
    button: blue.b30,
    className: CellClassName.HighlightBgColor5,
  },
  {
    menu: purple.p15,
    button: purple.p20,
    className: CellClassName.HighlightBgColor6,
  },
];

export interface ICellFormatting {
  columnName: string;
  rowId?: number;
  className?: string;
  formatting?: TonnageFormattingDto;
}

export function useCellFormatting() {
  const { tonnagesGridRef, assignmentsGridRef } = useGridRefs();
  const dispatch = useAppDispatch();
  const { isFeatureFlagActive } = useFeatureFlags();

  const setCellClassRules = (className?: string): boolean => {
    const tonnageCell = tonnagesGridRef.current?.api.getFocusedCell();
    const assignmentCell = assignmentsGridRef.current?.api.getFocusedCell();

    if (!tonnageCell && !assignmentCell) {
      return false;
    }

    if (tonnageCell?.rowIndex !== undefined) {
      return setClassRules(updateTonnageFormatting, tonnagesGridRef.current?.api, className, tonnageCell);
    }
    if (assignmentCell?.rowIndex !== undefined) {
      return setClassRules(updateAssignmentFormatting, assignmentsGridRef.current?.api, className, assignmentCell);
    }
    return true;
  };

  const setClassRules = <T>(
    updateFormatting: (rowData: T, columnName: string, className?: string) => Promise<void>,
    gridApi?: GridApi<T>,
    className?: string,
    focusedCell?: CellPosition
  ): boolean => {
    if (focusedCell?.rowIndex !== undefined) {
      const columnName = focusedCell.column.getColId();
      const row = gridApi?.getDisplayedRowAtIndex(focusedCell.rowIndex);
      if (!row || focusedCell.column.isSuppressNavigable(row)) {
        return false;
      }
      if (row.data) {
        updateFormatting(row.data, columnName, className);
      }
    }
    return true;
  };

  const updateAssignmentFormatting = async (rowData: AssignmentDto, columnName: string, className?: string): Promise<void> => {
    const rowId = rowData.id;
    let oldClassName = '';

    if (!rowId) {
      return;
    }
    const request = AssignmentsFormattingRequestMonitor.getRequest(rowId, columnName);

    if (request) {
      request?.requestPromise?.abort();
      oldClassName = request.oldClassName;
      updateAssignmentCellFormattingObject(rowData, columnName, className);

      updateAssignmentWithTonnage(rowData);
      request.requestPromise = dispatch(
        updateAssignmentFormattingMetadata({
          [rowId.toString()]: { [capitalize(columnName)]: [className ?? ''] },
        })
      );
    } else {
      oldClassName = getOldClassName(rowData, columnName);

      const newRequest = new FormattingRequest(rowId, columnName, oldClassName);
      updateAssignmentCellFormattingObject(rowData, columnName, className);

      updateAssignmentWithTonnage(rowData);

      newRequest.requestPromise = dispatchUpdateAssignmentFormattingMetadata(rowData, columnName, className);
      AssignmentsFormattingRequestMonitor.addRequest(newRequest);
    }

    const reqTmp = AssignmentsFormattingRequestMonitor.getRequest(rowId, columnName);
    const requestStatus = await reqTmp?.requestPromise;

    if (requestStatus.meta.requestStatus === 'rejected') {
      if (!requestStatus.meta.aborted) {
        dispatch(incrementErrorCounter(ErrorCounterType.formattingErrorCounter));
        //purpose of this delay is to avoid quick flickering of cell background color and change color as a kind of animation
        await delay(300);
        updateAssignmentCellFormattingObject(rowData, columnName, oldClassName);
        updateAssignmentWithTonnage(rowData);
      }
    }

    if (requestStatus.meta.requestStatus === 'fulfilled') {
      AssignmentsFormattingRequestMonitor.removeRequest(rowId, columnName);
    }
  };

  const updateTonnageFormatting = async (rowData: LinkedTonnageDto, columnName: string, className = ''): Promise<void> => {
    const rowId = rowData.id;
    let oldClassName = '';

    if (!rowId) {
      return;
    }

    const request = TonnageFormattingRequestMonitor.getRequest(rowId, columnName);

    if (request) {
      request?.requestPromise?.abort();
      oldClassName = request.oldClassName;

      rowData.formatting = { ...rowData.formatting, [columnName]: [className] };
      updateTonnageWithAssignment(rowData);

      request.requestPromise = dispatch(
        updateTonnagesFormattingMetadata({
          [rowId.toString()]: { [capitalize(columnName)]: [className ?? ''] },
        })
      );
    } else {
      if (rowData.formatting) {
        oldClassName = rowData.formatting[columnName as keyof TonnageFormattingDto]?.pop() ?? '';
      }

      const newRequest = new FormattingRequest(rowId, columnName, oldClassName);

      rowData.formatting = { ...rowData.formatting, [columnName]: [className] };
      updateTonnageWithAssignment(rowData);

      newRequest.requestPromise = dispatch(
        updateTonnagesFormattingMetadata({
          [rowId.toString()]: { [capitalize(columnName)]: [className ?? ''] },
        })
      );

      TonnageFormattingRequestMonitor.addRequest(newRequest);
    }

    const reqTmp = TonnageFormattingRequestMonitor.getRequest(rowId, columnName);
    const requestStatus = await reqTmp?.requestPromise;

    if (requestStatus.meta.requestStatus === 'rejected') {
      if (!requestStatus.meta.aborted) {
        dispatch(incrementErrorCounter(ErrorCounterType.formattingErrorCounter));
        //purpose of this delay is to avoid quick flickering of cell background color and change color as a kind of animation
        await delay(300);

        rowData.formatting = { ...rowData.formatting, [columnName]: [oldClassName] };
        updateTonnageWithAssignment(rowData);
      }
    }

    if (requestStatus.meta.requestStatus === 'fulfilled') {
      TonnageFormattingRequestMonitor.removeRequest(rowId, columnName);
    }
  };

  const updateLinkedAssignments = (rowData: LinkedTonnageDto) => {
    if (rowData.linkedAssignmentsIds?.length) {
      rowData.linkedAssignmentsIds.forEach((assignmentId) => {
        const assignmentRowData = assignmentsGridRef.current?.api?.getRowNode(assignmentId.toString())?.data;
        if (assignmentRowData?.tonnage) {
          assignmentRowData.tonnage.formatting = { ...rowData.formatting };
          updateAssignmentGrid(assignmentRowData);
        }
      });
    }
  };

  const updateLinkedTonnage = (rowData: AssignmentDto) => {
    if (rowData.tonnage?.id) {
      const tonnageRowData = tonnagesGridRef.current?.api?.getRowNode(rowData.tonnage?.id.toString())?.data;
      if (tonnageRowData) {
        tonnageRowData.formatting = { ...rowData.tonnage.formatting };
        updateTonnageGrid(tonnageRowData);
      }
    }
  };

  const updateTonnageWithAssignment = (rowData: LinkedTonnageDto) => {
    updateTonnageGrid(rowData);
    updateLinkedAssignments(rowData);
  };

  const updateAssignmentWithTonnage = (rowData: AssignmentDto) => {
    updateAssignmentGrid(rowData);
    updateLinkedTonnage(rowData);
  };

  const updateTonnageGrid = (rowData: LinkedTonnageDto) => {
    tonnagesGridRef.current?.api.applyTransaction({ update: [rowData] });
  };

  const updateAssignmentGrid = (rowData: AssignmentDto) => {
    assignmentsGridRef.current?.api.applyTransaction({ update: [rowData] });
  };

  const dispatchUpdateAssignmentFormattingMetadata = (rowData: AssignmentDto, columnName: string, className?: string) => {
    if (rowData.id && !columnName.includes('tonnage.')) {
      return dispatch(
        updateAssignmentFormattingMetadata({
          [rowData.id.toString()]: { [capitalize(columnName)]: [className ?? ''] },
        })
      );
    } else {
      const tonnageColumn = columnName.split('.')[1];
      if (rowData?.tonnage?.id) {
        return dispatch(
          updateTonnagesFormattingMetadata({
            [rowData.tonnage.id!.toString()]: { [capitalize(tonnageColumn)]: [className ?? ''] },
          })
        );
      }
    }
  };

  const getCellClassRules = <T>(columnName: DeepKeyOf<T>): CellClassRules<CellClassName> => {
    let cellClassRules;
    if (columnName.includes('tonnage.')) {
      const vesselColumn = columnName.split('.')[1];
      cellClassRules = highlightColors.map((color) => [
        color.className,
        (params: CellClassParams) => params.data?.tonnage?.formatting?.[vesselColumn]?.includes(color.className) ?? false,
      ]);
    } else if (columnName.includes('cargo.')) {
      const cargoColumn = columnName.split('.')[1];
      cellClassRules = highlightColors.map((color) => [
        color.className,
        (params: CellClassParams) => params.data?.cargo?.formatting?.[cargoColumn]?.includes(color.className) ?? false,
      ]);
    } else {
      cellClassRules = highlightColors.map((color) => [
        color.className,
        (params: CellClassParams) => params.data?.formatting?.[columnName]?.includes(color.className) ?? false,
      ]);
    }
    return Object.fromEntries(cellClassRules);
  };

  return { setCellClassRules, getCellClassRules };
}
