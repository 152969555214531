import { useEffect } from 'react';
import { useAuth } from '../contexts';

export const SigninPage = () => {
  const auth = useAuth();

  useEffect(() => {
    auth?.userManager?.getUser().then((user) => {
      if (user) {
        auth.setUser(user);
      }
    });
  }, [auth?.userManager]);

  return <div>Logging in...</div>;
};
