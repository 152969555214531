import { Drawer, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/helpers';
import { useGridRefs } from '../../../contexts/GridRefsContext';
import { GridColumnList } from './GridColumnList';
import { GridCustomisationDrawerHeader } from './GridCustomisationDrawerHeader';
import { grey } from '../../../themes/palette';
import { GridId, adjustLastColumnWidth } from '../grid/helpers';
import { ColumnSwitchProps } from './ColumnSwitchListElement';
import { toggleCustomisationDrawer, updateGridConfiguration } from '../../../store';

const StyledContentContainer = styled('div')({
  overflowY: 'scroll',
  height: '100%',
  backgroundColor: grey.g11,
});

export const GridCustomisationDrawer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { assignmentsGridRef } = useGridRefs();
  const [columnList, setColumnList] = useState<ColumnSwitchProps[]>([]);

  const isOpen = useAppSelector((state) => state.assignments.isCustomisationDrawerOpen);
  const currentPlan = useAppSelector((state) => state.plans.currentPlan);
  const gridConfigurations = useAppSelector((state) => state.gridConfigurations.gridConfigurations);
  const gridConfigurationsGetRequestState = useAppSelector((state) => state.gridConfigurations.status.getGridConfiguration);

  const setVisibleHandler = (id: string, value: boolean) => {
    assignmentsGridRef.current?.columnApi?.setColumnVisible(id, value);
    adjustLastColumnWidth(GridId.cargoAssigment, assignmentsGridRef.current?.columnApi, false);

    const updatedGridConfiguration = {
      ...gridConfigurations,
      [GridId.cargoAssigment]: assignmentsGridRef.current?.columnApi.getColumnState(),
    };
    dispatch(updateGridConfiguration({ planId: currentPlan?.id, gridConfigurations: updatedGridConfiguration }));
  };

  useEffect(() => {
    const notPinnedColumnList = assignmentsGridRef.current?.columnApi?.getAllGridColumns().filter((col) => !col.isPinned());
    const newColumnList =
      notPinnedColumnList?.map((el) => ({ id: el.getColId(), name: el.getColDef()?.headerName, isVisible: el?.isVisible() })) ||
      [];

    setColumnList(newColumnList);
  }, [gridConfigurationsGetRequestState, isOpen]);

  const closeCustomisationDrawer = () => {
    dispatch(toggleCustomisationDrawer());
  };

  return (
    <Drawer
      data-tesdid="grid-customisation-drawer"
      anchor="right"
      variant="persistent"
      open={isOpen}
      sx={{
        '& .MuiDrawer-paper': {
          width: '450px',
          maxHeight: 'calc(100vh - var(--sea-header-height))',
          height: 'calc(100vh - var(--sea-header-height))',
          marginTop: 'var(--sea-header-height)',
        },
      }}
      PaperProps={{ elevation: 6 }}
    >
      <GridCustomisationDrawerHeader closeButtonCb={closeCustomisationDrawer} />
      <StyledContentContainer>
        <GridColumnList columns={columnList} setVisibleHandler={setVisibleHandler} title="Cargo Assignment Columns" />
      </StyledContentContainer>
    </Drawer>
  );
};
