import dayjs from 'dayjs';
import { IDateRange, setIsOpenDuplicateBatchOfCargoesDialog } from '../../../../../../store';
import { REQUEST_STATUS, useAppDispatch, useAppSelector } from '../../../../../../store/helpers';
import { StyledAddIcon } from '../../../../../common/dialog/helpers';
import Dialog, { IDialogProps } from '../../../../../common/dialog/Dialog';
import React from 'react';
import utc from 'dayjs/plugin/utc';
import { MonthPickerBox } from './MonthPickerBox';
import { DuplicateCargoesDialogDescription } from './DuplicateCargoesDialogDescription';
dayjs.extend(utc);

interface IDuplicateCargoesDialog extends IDialogProps {
  dateRange: IDateRange;
  setDateRange: (dateRange: IDateRange) => void;
  monthDifference: number;
}

export const DuplicateCargoesDialog: React.FC<IDuplicateCargoesDialog> = ({
  actionCb,
  dateRange,
  setDateRange,
  monthDifference,
}) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.dialogs.isDuplicateCargoesDialogOpen);
  const selectedRows = useAppSelector((state) => state.assignments.selectedRows);
  const requestStatus = useAppSelector((state) => state.assignments.status.duplicateAssignmentCargoes);

  return (
    <Dialog
      id="duplicate-batch-of-cargoes-dialog"
      title={`Duplicate ${Array.from(selectedRows).length} ${Array.from(selectedRows).length === 1 ? 'cargo' : 'cargoes'}?`}
      actionBtnContent={
        <>
          <StyledAddIcon />
          Duplicate
        </>
      }
      open={isOpen}
      cancelCb={() => dispatch(setIsOpenDuplicateBatchOfCargoesDialog(false))}
      onClose={() => dispatch(setIsOpenDuplicateBatchOfCargoesDialog(false))}
      actionCb={actionCb}
      loadingLabel="Duplicating cargoes..."
      isLoading={requestStatus === REQUEST_STATUS.pending}
      shouldConfirmOnEnter
    >
      <MonthPickerBox dateRange={dateRange} updateDateRange={setDateRange} />
      <DuplicateCargoesDialogDescription monthDifference={monthDifference} />
    </Dialog>
  );
};
