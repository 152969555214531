import { styled } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import { grey } from '../themes/palette';
import { SUPPORT_MAIL } from '../components/common/Constants';

const StyledMainContainer = styled('div')({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

const StyledBlockIcon = styled(BlockIcon)({
  fontSize: '200px',
  color: grey.g1,
  marginBottom: '40px',
  transform: 'rotate(90deg)',
});

const StyledMainMsg = styled('h1')({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  letterSpacing: '-0.02em',
  width: '372px',
  textAlign: 'center',
  marginTop: 0,
  marginBottom: '20px',
});

const StyledDetails = styled('p')({
  fontSize: '16px',
  letterSpacing: '-0.02em',
  fontWeight: 500,
  marginTop: 0,
});

const MAIN_MSG = `Sorry, your user account doesn't have access to this page`;

export const YouHaveNoAccessPage = () => (
  <StyledMainContainer>
    <StyledBlockIcon />
    <StyledMainMsg>{MAIN_MSG}</StyledMainMsg>
    <StyledDetails>
      To request access, contact <a href={`mailto: ${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</a>
    </StyledDetails>
  </StyledMainContainer>
);
