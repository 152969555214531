import { IRowNode } from 'ag-grid-community';
import { AssignmentsGridColumn } from '../../../dashboards/cargo/grid';
import { useGridRefs } from '../../../../contexts/GridRefsContext';
import { LinkedTonnageDto } from '../../../../api/web-api-client';
import { TonnagesGridColumn } from '../../../dashboards/tonnage/grid/hooks';

export const useGridDetailsAssignUpdater = () => {
  const { assignmentsGridRef, tonnagesGridRef } = useGridRefs();

  const setAssignmentTonnageDetails = (tonnage: LinkedTonnageDto | null, rowNodeToUpdate: IRowNode) => {
    rowNodeToUpdate.data.tonnage = tonnage;
    assignmentsGridRef?.current?.api.applyTransaction({ update: [rowNodeToUpdate.data] });
    assignmentsGridRef?.current?.api.refreshCells({
      rowNodes: [rowNodeToUpdate],
      columns: [AssignmentsGridColumn.Options],
      force: true,
    });
  };

  const setTonnageLinkedAssignmentsIds = (linkedAssignmentsIds: number[], rowNodeToUpdate: IRowNode) => {
    rowNodeToUpdate.data.linkedAssignmentsIds = linkedAssignmentsIds;
    tonnagesGridRef.current?.api.applyTransaction({ update: [rowNodeToUpdate.data] });
    tonnagesGridRef.current?.api.refreshCells({
      rowNodes: [rowNodeToUpdate],
      columns: [TonnagesGridColumn.Options, TonnagesGridColumn.Status],
      force: true,
    });
  };

  const updateTonnageLinkedAssignmentsIds = (tonnageId: number, assignmentId: number) => {
    const rowNodeToRefresh = tonnagesGridRef?.current?.api.getRowNode(tonnageId.toString());
    if (rowNodeToRefresh?.data) {
      setTonnageLinkedAssignmentsIds([assignmentId], rowNodeToRefresh);
    }
  };

  const updateTonnageDetails = (assignmentId: number, tonnage: LinkedTonnageDto) => {
    const rowNodeToRefresh = assignmentsGridRef?.current?.api.getRowNode(assignmentId.toString());
    if (rowNodeToRefresh?.data) {
      setAssignmentTonnageDetails(tonnage, rowNodeToRefresh);
    }
  };

  const unassignCargo = (assignmentNode: IRowNode) => {
    setAssignmentTonnageDetails(null, assignmentNode);
  };

  const unassignTonnage = (tonnageNode: IRowNode) => {
    setTonnageLinkedAssignmentsIds([], tonnageNode);
  };

  return { updateTonnageLinkedAssignmentsIds, updateTonnageDetails, unassignCargo, unassignTonnage };
};
