import { green, neutral, red } from '../../palette';

export const MuiAlert = {
  styleOverrides: {
    root: {
      bottom: '20px',
      left: '20px',
      minWidth: '288px',
      minHeight: '48px',
      borderRadius: '4px',
      padding: '6px 20px 6px 16px',
      boxShadow: '0px 4px 6px -1px rgba(19, 20, 20, 0.1), 0px 2px 4px -2px rgba(19, 20, 20, 0.25)',
      zIndex: 1000,
      color: neutral.n100,
      '& svg': {
        fill: neutral.n100,
      },
    },
    standardError: {
      backgroundColor: red.r60,
    },
    standardSuccess: {
      backgroundColor: green.g60,
    },
    icon: {
      margin: 0,
      padding: '7px 12px 7px 0',
    },
    message: {
      padding: '6px 0 6px 0',
      margin: 0,
      display: 'flex',
      flexDirection: 'column' as const,
      gap: '4px',
      fontFamily: 'Noto Sans',
      fontSize: '14px',
      letterSpacing: '0.15px',
      fontWeight: 500,
    },
  },
};

export const darkThemeAlert = {
  styleOverrides: {
    ...MuiAlert.styleOverrides,
    root: {
      ...MuiAlert.styleOverrides.root,
    },
  },
};

export const lightThemeAlert = {
  styleOverrides: {
    ...MuiAlert.styleOverrides,
    root: {
      ...MuiAlert.styleOverrides.root,
    },
  },
};
