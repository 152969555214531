import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useState } from 'react';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { grey, neutral } from '../../../themes/palette';
import { CreateTonnageCommand, LinkedTonnageDto } from '../../../api/web-api-client';
import { TonnagesGridColumn, useTonnageGrid } from './grid/hooks/useTonnageGrid';
import { StyledGridContainer, StyledGridHeaderContainer, StyledGridName } from '../../common/grid/GridContainer';
import { GridFooter } from '../../common/grid/GridFooter/GridFooter';
import { useGridRefs } from '../../../contexts/GridRefsContext';
import { GridId } from '../../common/grid/helpers';
import { REQUEST_STATUS, useAppDispatch, useAppSelector } from '../../../store/helpers';
import { addTonnage, getTonnages, IDateRange } from '../../../store';
import { Button } from '@mui/material';
import { ReactComponent as VerticalRuleIcon } from '../../../assets/verticalRule.svg';
import { ReactComponent as ForwardArrowIcon } from '../../../assets/forwardArrow.svg';
import { ICustomRowData } from '../../common/grid/hooks/useDefaultGridOptions';
import { useAuth } from '../../../contexts';
import {
  MixpanelEventAction,
  MixpanelEventCategory,
  MixpanelEventLabel,
  MixpanelEventLocation,
  MixpanelEventName,
  useMixpanel,
} from '../../../contexts/MixpanelContext';
import { ColumnEverythingChangedEvent, ColumnState } from 'ag-grid-community';
import { useFeatureFlags } from '../../../contexts/FeatureFlagsContext';
import { FeatureFlagsEnum } from '../../../api/featureFlags';
dayjs.extend(utc);

export type TonnageRowData = ICustomRowData<LinkedTonnageDto>;
interface ITonnageDashboard {
  isTonnageHidden?: boolean;
  hideTonnage?: () => void;
}

export const TonnageDashboard: React.FC<ITonnageDashboard> = ({ hideTonnage, isTonnageHidden }) => {
  const currentPlan = useAppSelector((state) => state.plans.currentPlan);
  const getGridconfigStatus = useAppSelector((state) => state.gridConfigurations.status);
  const addPlanStatus = useAppSelector((state) => state.plans.status.addPlan);
  const { tonnagesGridRef: gridRef, assignmentsGridRef } = useGridRefs();
  const [rowData, setRowData] = useState<LinkedTonnageDto[] | null>(null);
  const {
    autoWidthLastColumn,
    columnDefs,
    defaultColDef,
    getRowId,
    onRowSelected,
    onCellFocused,
    onCellEditingStopped,
    addEmptyRow,
    loadingOverlayComponent,
    noRowsOverlayComponent,
    updateGridState,
    applyGridState,
    rowClassRules,
  } = useTonnageGrid(gridRef);
  const dispatch = useAppDispatch();
  const dateRange = useAppSelector((state) => state.dateRange.dateRange);
  const { hasWriteRights } = useAuth();

  const { trackEvent } = useMixpanel();
  const { isFeatureFlagActive } = useFeatureFlags();

  const customColumnsConfig: ColumnState[] = [
    {
      colId: TonnagesGridColumn.Etb,
      hide: false,
    },
    {
      colId: TonnagesGridColumn.TonnageRefId,
      hide: false,
    },
    {
      colId: TonnagesGridColumn.Status,
      pinned: 'left',
    },
    {
      colId: TonnagesGridColumn.Options,
      pinned: 'right',
    },
  ];

  const getTonnagesCallback = useCallback(async (dateRangeToFilterBy: IDateRange, planId?: number) => {
    const res = await dispatch(
      getTonnages({
        dateRangeFrom: dateRangeToFilterBy.from.toString(),
        dateRangeTo: dateRangeToFilterBy.to.toString(),
        planId,
      })
    );
    const tonnages = res.payload as LinkedTonnageDto[];

    if (!tonnages?.length) {
      gridRef.current?.api?.showNoRowsOverlay();
      setRowData([]);
      return;
    }

    setRowData(tonnages);
  }, []);

  useEffect(() => {
    if (currentPlan) {
      gridRef.current?.api?.showLoadingOverlay();
      getTonnagesCallback(dateRange, currentPlan.id);
    }
  }, [dateRange, currentPlan]);

  useEffect(() => {
    if (gridRef.current?.columnApi && getGridconfigStatus.getGridConfiguration === REQUEST_STATUS.fulfilled) {
      applyGridState(gridRef.current.columnApi, customColumnsConfig);
    }
  }, [getGridconfigStatus.getGridConfiguration, gridRef.current?.columnApi]);

  useEffect(() => {
    if (addPlanStatus === REQUEST_STATUS.fulfilled) {
      gridRef.current?.columnApi?.resetColumnState();
    }
  }, [addPlanStatus]);

  const onColumnEverythingChanged = (event: ColumnEverythingChangedEvent) => {
    if (event.source === 'rowDataUpdated') {
      applyGridState(gridRef.current?.columnApi, customColumnsConfig);
    }
  };

  const onAddTonnageClick = async (isFromFooter = false) => {
    const dateFromISO = dateRange.from.toISOString();
    const dateToISO = dateRange.to.toISOString();

    const createTonnageCommand: CreateTonnageCommand = {
      planId: currentPlan!.id!,
      sourceLaycan: {
        from: dateFromISO,
        to: dateToISO,
      },
    };
    const res = await dispatch(addTonnage(createTonnageCommand));
    const newEmptyRow = res.payload as LinkedTonnageDto;
    newEmptyRow.vessel = {};
    addEmptyRow(newEmptyRow);

    trackEvent(MixpanelEventCategory.INTERACTION, {
      event: isFromFooter ? MixpanelEventName.ADD_TONNAGE_FROM_FOOTER : MixpanelEventName.ADD_TONNAGE_FROM_EMPTY,
      eventAction: MixpanelEventAction.CLICK,
      eventLabel: MixpanelEventLabel.BUTTON,
      eventLocation: MixpanelEventLocation.TONNAGE_GRID,
      eventVersion: 1.1,
      tonnageId: newEmptyRow?.id,
      planId: currentPlan?.id,
    });
  };

  const onAddTonnageClickFromFooter = () => {
    onAddTonnageClick(true);
  };

  const onAddTonnageClickFromEmptyGrid = () => {
    onAddTonnageClick();
  };

  // TODO start -> remove after with ff_dragToResizeTonnage removal
  const TonnageContainer = isFeatureFlagActive(FeatureFlagsEnum.ff_dragToResizeTonnage)
    ? StyledTonnageContainer
    : StyledTonnageContainerNoFeatureFlag;
  // TODO end -> remove after with ff_dragToResizeTonnage removal

  return (
    // TODO replace TonnageContainer with StyledTonnageContainer after with ff_dragToResizeTonnage removal
    <TonnageContainer id={GridId.tonnage} data-testid={GridId.tonnage} isHidden={isTonnageHidden ? 1 : 0}>
      <StyledGridHeaderContainer>
        <StyledGridName variant="h6">Tonnage</StyledGridName>
        {hideTonnage && (
          <StyledGridToggle onClick={hideTonnage} data-testid={'button-hide-tonnage-grid'}>
            <StyledHideTonnageArrowIcon />
            <VerticalRuleIcon />
          </StyledGridToggle>
        )}
      </StyledGridHeaderContainer>
      <StyledGridContainer className="ag-theme-alpine" isreadonly={hasWriteRights ? 0 : 1}>
        <AgGridReact<LinkedTonnageDto>
          getRowId={getRowId}
          onCellEditingStopped={onCellEditingStopped}
          ref={gridRef}
          rowData={rowData}
          defaultColDef={defaultColDef}
          animateRows={true}
          columnDefs={columnDefs}
          rowSelection={'single'}
          onRowSelected={onRowSelected}
          onCellFocused={(event) => {
            onCellFocused(event);
            if (event.column) {
              assignmentsGridRef.current?.api.clearFocusedCell();
            }
          }}
          stopEditingWhenCellsLoseFocus={true}
          enterNavigatesVertically={true}
          enterNavigatesVerticallyAfterEdit={true}
          suppressRowClickSelection={true}
          loadingOverlayComponent={loadingOverlayComponent}
          loadingOverlayComponentParams={{ footerText: 'Loading Tonnage...' }}
          noRowsOverlayComponent={noRowsOverlayComponent}
          noRowsOverlayComponentParams={{
            headerText: 'No tonnage to display',
            addButtonLabel: 'Add Tonnage',
            onAddButtonClick: onAddTonnageClickFromEmptyGrid,
          }}
          rowDragManaged={true}
          suppressContextMenu={true}
          suppressDragLeaveHidesColumns={true}
          tooltipShowDelay={400}
          rowClassRules={rowClassRules}
          onDragStopped={autoWidthLastColumn}
          onColumnResized={updateGridState}
          onColumnMoved={updateGridState}
          onColumnPinned={updateGridState}
          onSortChanged={updateGridState}
          onColumnVisible={updateGridState}
          onColumnEverythingChanged={onColumnEverythingChanged}
          rowClass={['is-pinning-left']}
          rowHeight={48}
        />
        <GridFooter onAddClick={onAddTonnageClickFromFooter} addButtonLabel="ADD TONNAGE" />
      </StyledGridContainer>
    </TonnageContainer>
  );
};

// TODO start -> remove after with ff_dragToResizeTonnage removal
const StyledTonnageContainerNoFeatureFlag = styled('div')((props: { isHidden: number }) => ({
  padding: '16px 24px 24px',
  backgroundColor: neutral.n99,
  height: '100%',
  width: props.isHidden === 1 ? '0px' : '35%',
  display: props.isHidden === 1 ? 'none' : 'block',
}));
// TODO end -> remove after with ff_dragToResizeTonnage removal

const StyledTonnageContainer = styled('div')((props: { isHidden: number }) => ({
  padding: '16px 24px 24px 21px',
  backgroundColor: neutral.n99,
  height: '100%',
  width: '100%',
  display: props.isHidden === 1 ? 'none' : 'block',
}));

const StyledGridToggle = styled(Button)({
  marginLeft: 'auto',
  display: 'flex',
  justifyContent: 'start',
  height: '40px',
  width: '40px',
  minWidth: '40px',
  backgroundColor: grey.g1,
  borderRadius: '8px',
  padding: '9px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
});

const StyledHideTonnageArrowIcon = styled(ForwardArrowIcon)({
  marginRight: '4px',
});
