import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import { Dayjs } from 'dayjs';
import { grey, neutral } from '../../../themes/palette';
import { IDateRange } from '../../../store';
import { MonthPickerButton } from './MonthPickerButton';

export enum MonthPickerSize {
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

interface IMonthPickerProps extends DatePickerProps<Dayjs> {
  dateRange: IDateRange;
  updateDateRange: (newRange: IDateRange) => void;
  monthPickerSize?: MonthPickerSize;
  id: string;
}

export const MonthPicker: React.FC<IMonthPickerProps> = ({
  dateRange,
  updateDateRange,
  monthPickerSize = MonthPickerSize.MEDIUM,
  id,
  ...restProps
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

  function setPreviousMonth() {
    const previousMonthBeginning = dateRange.from.utc().subtract(1, 'month');
    const newValue: IDateRange = { from: previousMonthBeginning.utc(), to: previousMonthBeginning.utc().endOf('month') };

    return updateDateRange(newValue);
  }

  function setNextMonth() {
    const nextMonthBeginning = dateRange.from.utc().add(1, 'month');
    const newValue: IDateRange = { from: nextMonthBeginning.utc(), to: nextMonthBeginning.utc().endOf('month') };
    return updateDateRange(newValue);
  }

  function onDateChange(date: Dayjs | null) {
    if (date) {
      const newDateRange = { from: date.utc().startOf('month'), to: date.utc().endOf('month') };
      updateDateRange(newDateRange);
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledMonthPickerContainer monthpickersize={monthPickerSize}>
        <DatePicker
          views={['year', 'month']}
          value={dateRange.from}
          onChange={onDateChange}
          openTo="month"
          slots={{ field: MonthPickerButton as any, ...restProps.slots }}
          slotProps={{ field: { setIsCalendarOpen, monthPickerSize, id } as any }}
          open={isCalendarOpen}
          onOpen={() => setIsCalendarOpen(true)}
          onClose={() => setIsCalendarOpen(false)}
        />
        <StyledIconButton
          monthpickersize={monthPickerSize}
          data-testid={`monthpicker-button-previous-month-${id}`}
          onClick={setPreviousMonth}
        >
          <KeyboardArrowLeft fontSize="small" />
        </StyledIconButton>
        <StyledIconButton
          monthpickersize={monthPickerSize}
          data-testid={`monthpicker-button-next-month-${id}`}
          onClick={setNextMonth}
        >
          <KeyboardArrowRight fontSize="small" />
        </StyledIconButton>
      </StyledMonthPickerContainer>
    </LocalizationProvider>
  );
};

export interface MonthPickerStylesBySize {
  [MonthPickerSize.MEDIUM]: Styles;
  [MonthPickerSize.LARGE]: Styles;
}

interface Styles {
  iconButton: {
    width: string;
  };
  calendarButton: {
    height: string;
    minWidth: string;
  };
  monthPickerContainer: {
    height: string;
    backgroundColor: string;
    border: string;
  };
  calendarText: {
    fontSize: string;
    lineHeight: string;
    fontWeight: string;
  };
}

export const monthPickerStylesBySize: MonthPickerStylesBySize = {
  MEDIUM: {
    iconButton: {
      width: '24px',
    },
    calendarButton: {
      height: '34px',
      minWidth: 'initial',
    },
    monthPickerContainer: {
      height: '34px',
      backgroundColor: neutral.n100,
      border: `1px solid ${grey.g6}`,
    },
    calendarText: {
      fontSize: '14px',
      lineHeight: '1',
      fontWeight: '500',
    },
  },
  LARGE: {
    iconButton: {
      width: '40px',
    },
    calendarButton: {
      height: '40px',
      minWidth: '172px',
    },
    monthPickerContainer: {
      height: '40px',
      backgroundColor: grey.g1,
      border: 'none',
    },
    calendarText: {
      fontSize: '16px',
      lineHeight: '1.5',
      fontWeight: '700',
    },
  },
};

export type MonthPickerStyles = {
  monthpickersize: keyof MonthPickerStylesBySize;
  children?: React.ReactNode;
};

const StyledIconButton = styled(IconButton)<MonthPickerStyles>(({ theme, monthpickersize }) => ({
  color: theme.palette.text.primary,
  padding: 'unset',
  borderRadius: 'inherit',
  ...monthPickerStylesBySize[monthpickersize].iconButton,
}));

const StyledMonthPickerContainer = styled('div')<MonthPickerStyles>(({ monthpickersize }) => ({
  display: 'flex',
  borderRadius: '8px',
  ...monthPickerStylesBySize[monthpickersize].monthPickerContainer,
}));
