import { CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { blue } from '../../themes/palette';

interface ILoader {
  id: string;
  headerText?: string;
  footerText?: string;
  footerFontSize?: number;
  size?: number;
}

const StyledLoading = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

const StyledProgress = styled(CircularProgress)({
  color: blue.b70,
});

const StyledLoadingHeader = styled(Typography)({
  marginBottom: '32px',
});

const StyledLoadingFooter = styled(Typography)({
  marginTop: '10px',
});

export const Loader: React.FC<ILoader> = ({ id, headerText, footerText, footerFontSize = 16, size = 80 }) => {
  return (
    <StyledLoading>
      {headerText && (
        <StyledLoadingHeader variant="h5" data-testid={`${id}-loader-header`}>
          {headerText}
        </StyledLoadingHeader>
      )}
      <StyledProgress data-testid={`${id}-loader-spinner`} style={{ height: `${size}px`, width: `${size}px` }} />
      {footerText && (
        <StyledLoadingFooter fontSize={footerFontSize} data-testid={`${id}-loader-footer`}>
          {footerText}
        </StyledLoadingFooter>
      )}
    </StyledLoading>
  );
};
