import React, { createContext, PropsWithChildren, useContext } from 'react';
import { FeatureFlags, FeatureFlagsEnum } from '../api/featureFlags';

interface IFeatureFlagsContext {
  featureFlags: FeatureFlags | null;
  isFeatureFlagActive: (featureFlag: FeatureFlagsEnum) => boolean;
  areFeatureFlagsActive: (featureFlags: FeatureFlagsEnum[]) => boolean;
}

const initialState = {
  featureFlags: {
    copyAssignmentToClipboard: false,
    ff_CustomDateRange: false,
    ff_CustomizeCargoAssignmentsGrid: false,
    retrySaveCell: false,
    ff_dragToResizeTonnage: false,
    ff_vesselCellDetails: false,
    ff_cargoVolumeTotals: false,
    ff_ownerRateDemurrageColumns: false,
  },
  isFeatureFlagActive: (featureFlag: FeatureFlagsEnum) => false,
  areFeatureFlagsActive: (featureFlags: FeatureFlagsEnum[]) => false,
};

const FeatureFlagsContext = createContext<IFeatureFlagsContext | null>(initialState);

interface IFeatureFlagsContextProvider {
  featureFlags: FeatureFlags | null;
}

export const FeatureFlagsProvider: React.FC<PropsWithChildren<IFeatureFlagsContextProvider>> = ({ children, featureFlags }) => {
  const isFeatureFlagActive = (featureFlag: FeatureFlagsEnum) => {
    return !!featureFlags && featureFlags[featureFlag];
  };

  const areFeatureFlagsActive = (features: FeatureFlagsEnum[]) => {
    return features.every((feature) => !!featureFlags && featureFlags[feature]);
  };

  const value = {
    featureFlags,
    isFeatureFlagActive,
    areFeatureFlagsActive,
  };

  return <FeatureFlagsContext.Provider value={value}>{children}</FeatureFlagsContext.Provider>;
};

export function useFeatureFlags() {
  const context = useContext(FeatureFlagsContext);

  if (!context) {
    throw new Error('Component beyond FeatureFlagsContext');
  }

  return context;
}
