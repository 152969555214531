import { MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../store/helpers';
import { deselectAllCargoes, getGridConfigurations, selectCurrentPlan } from '../store';
import { useAuth } from '../contexts';
import {
  MixpanelEventAction,
  MixpanelEventCategory,
  MixpanelEventLabel,
  MixpanelEventLocation,
  MixpanelEventName,
  useMixpanel,
} from '../contexts/MixpanelContext';

interface ICustomSelect {
  openCreatePlanModal: () => void;
}

const StyledAddPlanItem = styled(MenuItem)(({ theme }) => ({
  borderTop: '1px solid #E5E7EB',
  color: theme.palette.primary.main,
  margin: 0,
}));

export const PlanSelect: React.FC<ICustomSelect> = ({ openCreatePlanModal }) => {
  const dispatch = useAppDispatch();
  const plans = useAppSelector((state) => state.plans.plans);
  const currentPlan = useAppSelector((state) => state.plans.currentPlan);
  const { hasWriteRights } = useAuth();
  const { trackEvent } = useMixpanel();

  const selectAddPlanItem = () => {
    openCreatePlanModal();
  };

  const onChange = ({ target: { value } }: SelectChangeEvent<unknown>) => {
    if (value !== 'selectPlan') {
      const selectedPlan = plans.find(({ id }) => id === value);
      if (selectedPlan?.id) {
        dispatch(deselectAllCargoes());
        dispatch(selectCurrentPlan(selectedPlan));
        dispatch(getGridConfigurations(selectedPlan.id));

        trackEvent(MixpanelEventCategory.NAVIGATION, {
          event: MixpanelEventName.SELECT_PLAN,
          eventAction: MixpanelEventAction.CLICK,
          eventLabel: MixpanelEventLabel.SELECT_OPTION,
          eventLocation: MixpanelEventLocation.PLAN_SELECT,
          eventVersion: 1.1,
          planId: selectedPlan.id,
        });
      }
    }
  };
  return (
    <Select
      id="select-plan-dropdown-list"
      data-testid="select-plan-dropdown-list"
      autoWidth
      value={currentPlan?.id ?? 'selectPlan'}
      onChange={onChange}
    >
      {plans.map(({ id, title }) => (
        <MenuItem key={id} value={id}>
          {title}
        </MenuItem>
      ))}
      {hasWriteRights && (
        <StyledAddPlanItem key="selectPlan" value="selectPlan" onClick={selectAddPlanItem}>
          Add new plan
        </StyledAddPlanItem>
      )}
    </Select>
  );
};
