import { styled } from '@mui/material';

interface ILeftPanelProps {
  width: string;
  children: React.ReactNode;
  dataTestIdPrefix: string;
}

export const LeftPanel: React.FC<ILeftPanelProps> = (props) => {
  const { children, width, dataTestIdPrefix } = props;

  return (
    <StyledContainer width={width} data-testid={`${dataTestIdPrefix}-split-view-left-panel`}>
      {children}
    </StyledContainer>
  );
};

const StyledContainer = styled('div')((props: { width: string }) => ({
  width: props.width,
  height: '100%',
}));
