import { LocationDto } from '../../../../api/web-api-client';
import {
  IRenderOptions,
  SearchEditorRenderOptions,
  StyledSearchEditorInfoInBrackets,
  StyledSearchEditorInfoInTag,
} from '../SearchEditor/components/SearchEditorRenderOptions';

interface IRenderVesselOption extends IRenderOptions<LocationDto> {}

export const RenderLocationOption = (props: IRenderVesselOption) => {
  const { option, state } = props;
  const { inputValue } = state;

  const shouldBoldCountryUnCode = inputValue == option?.countryUnCode?.toString();

  return (
    <SearchEditorRenderOptions<LocationDto>
      {...props}
      key={option?.id ?? option?.name}
      mainUiOptionKey={'name'}
      infoInBrackets={
        option.countryUnCode ? (
          <StyledSearchEditorInfoInBrackets bold={shouldBoldCountryUnCode}>
            ({option.countryUnCode})
          </StyledSearchEditorInfoInBrackets>
        ) : null
      }
      infoInTag={option.country ? <StyledSearchEditorInfoInTag>{option.country}</StyledSearchEditorInfoInTag> : null}
    />
  );
};
