import { red } from '../../palette';
import { lightThemePalette } from './palette';

export const MuiButton = {
  styleOverrides: {
    root: {
      minWidth: '200px',
      height: '52px',
      padding: '14px 24px',
      borderRadius: '8px',
      textTransform: 'none' as const,
    },
    containedError: {
      backgroundColor: red.r40,
    },
  },
};

export const darkThemeButton = {
  styleOverrides: {
    ...MuiButton.styleOverrides,
  },
};

export const lightThemeButton = {
  styleOverrides: {
    ...MuiButton.styleOverrides,
    root: {
      ...MuiButton.styleOverrides.root,
    },
    text: {
      backgroundColor: 'unset',
      color: lightThemePalette.text?.primary,
    },
  },
};
