import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { updateTonnagesFormattingMetadata } from './tonnages.slice';
import { updateAssignmentFormattingMetadata } from './assignments.slice';

import { showNotificationSnackbar } from './notifications.slice';
import { AlertColor } from '@mui/material';

const MAX_FAILS_NUMBER = 3;

const ERROR_MSG_OBJECT = {
  title: 'Sorry, we are having a problem saving your changes. Please try again later.',
  message: 'Changes will not be saved when you leave this view. If the error continues, contact support.',
  type: 'error' as AlertColor,
  autoHideDuration: 12000,
};

const formattingFulfilled = (action: PayloadAction) => {
  return [
    updateTonnagesFormattingMetadata.fulfilled.toString(),
    updateAssignmentFormattingMetadata.fulfilled.toString(),
  ].includes(action.type);
};

export enum ErrorCounterType {
  formattingErrorCounter = 'formattingErrorCounter',
  editingCellErrorCounter = 'editingCellErrorCounter',
}

export type ErrorState = {
  [T in ErrorCounterType]: number;
};

export enum ErrorsActions {
  INCREMENT_ERROR_COUNTER = 'incrementErrorCounter',
}

const shoulDisplayErrorNotification = (errorCounterType: ErrorCounterType, errorCounters: ErrorState) => {
  return errorCounters[errorCounterType] === MAX_FAILS_NUMBER - 1;
};

export const incrementErrorCounter = createAsyncThunk(
  ErrorsActions.INCREMENT_ERROR_COUNTER,
  async (payload: ErrorCounterType, { dispatch, getState }) => {
    const { errors } = (await getState()) as { errors: ErrorState };

    if (shoulDisplayErrorNotification(payload, errors)) {
      dispatch(showNotificationSnackbar(ERROR_MSG_OBJECT));
    }
    return payload;
  }
);

export const errorsInitialState: ErrorState = {
  formattingErrorCounter: 0,
  editingCellErrorCounter: 0,
};

const errors = createSlice({
  name: 'errors',
  initialState: errorsInitialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(incrementErrorCounter.fulfilled, (state, action) => {
      state[action.payload] = (state[action.payload] + 1) % MAX_FAILS_NUMBER;
    });
    builder.addMatcher(formattingFulfilled, (state) => {
      state.formattingErrorCounter = 0;
    });
  },
});

export default errors.reducer;
