import { DateRange, StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { RangePosition } from '@mui/x-date-pickers-pro/internals/models/range';
import * as React from 'react';
import { MaritimeDate } from '@maritech/maritime-date';
import { styled, TextField } from '@mui/material';
import { blue, grey, neutral } from '../../../../../themes/palette';
import { IDateRange } from '../../../../../store';
import utc from 'dayjs/plugin/utc';
import { ShortcutButtons } from './ShortcutButtons';
import { ActionsFooter } from './ActionsFooter';
import { LOCAL_STORAGE_KEYS, setItem } from '../../../../../helpers/localStorage';
import { DASHBOARD_DATE_RANGE_TYPE } from '../../PlanDateFilter';
import { KeyNames } from '../../../Constants';
dayjs.extend(utc);

interface ICustomRangeViewProps {
  dateRange: IDateRange;
  updateDateRange: (newRange: IDateRange) => void;
  onClose: () => void;
}

export const CustomRangeView: React.FC<ICustomRangeViewProps> = (props) => {
  const { dateRange, updateDateRange, onClose } = props;
  const INPUT_FORMAT = 'D MMM YYYY';

  const [value, setValue] = useState<DateRange<Dayjs>>([dayjs(dateRange.from).utc(), dayjs(dateRange.to).utc()]);
  const [dateFromValue, dateToValue] = [...value];

  const refInputFrom = useRef<HTMLInputElement>(null);
  const refInputTo = useRef<HTMLInputElement>(null);
  const [dateFromInputValue, setDateFromInputValue] = useState<string>(dateFromValue?.utc().format(INPUT_FORMAT) ?? '');
  const [dateToInputValue, setDateToInputValue] = useState<string>(dateToValue?.utc().format(INPUT_FORMAT) ?? '');
  const [rangePosition, setRangePosition] = useState<RangePosition>('start');

  useEffect(() => {
    if (refInputFrom?.current) {
      focusInputAndSelectValue(refInputFrom?.current);
    }
  }, [refInputFrom]);

  const alignDateFromAndDateTo = (newDatePickerValue: Dayjs, newDateInputValue: string) => {
    setValue([newDatePickerValue, newDatePickerValue]);
    setDateFromInputValue(newDateInputValue);
    setDateToInputValue(newDateInputValue);
  };

  const focusInputAndSelectValue = (input: HTMLInputElement | null) => {
    if (input) {
      input.focus();
      input.select();
    }
  };

  const onChange = (dateRangePickerValue: DateRange<Dayjs>) => {
    const newDateFrom = dateRangePickerValue[0];
    const newDateTo = dateRangePickerValue[1];
    const formattedDateFrom = dateRangePickerValue[0]?.format(INPUT_FORMAT) || '';
    const formattedDateTo = dateRangePickerValue[1] ? dateRangePickerValue[1].format(INPUT_FORMAT) : '';

    if (newDateFrom?.isAfter(dateToValue) || newDateTo === null) {
      alignDateFromAndDateTo(newDateFrom!, formattedDateFrom);
      setTimeout(() => focusInputAndSelectValue(refInputTo?.current), 0);
    } else if (newDateTo?.isSame(newDateFrom)) {
      alignDateFromAndDateTo(newDateTo!, formattedDateTo);
      setTimeout(() => focusInputAndSelectValue(refInputTo?.current), 0);
    } else {
      setValue([newDateFrom, newDateTo]);
      setDateFromInputValue(formattedDateFrom);
      setDateToInputValue(formattedDateTo);
    }
  };

  const onRangePositionChange = (rangePos: RangePosition) => {
    setRangePosition(rangePos);
    if (rangePos === 'start') {
      focusInputAndSelectValue(refInputFrom?.current);
    }
    if (rangePos === 'end') {
      focusInputAndSelectValue(refInputTo?.current);
    }
  };

  const onDateInputFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, rangePos: RangePosition) => {
    if (rangePosition !== rangePos) {
      setRangePosition(rangePos);
    }
    event.target.select();
  };

  const onDateFromInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const maritimeDate = MaritimeDate.parse(event.target.value);
    if (maritimeDate.isValid) {
      const isoDate = maritimeDate.toISO();
      const dayjsDate = dayjs(isoDate).utc();
      const formattedDate = dayjsDate.format(INPUT_FORMAT);

      if (dayjsDate.isAfter(dateToValue)) {
        alignDateFromAndDateTo(dayjsDate, formattedDate);
        setTimeout(() => focusInputAndSelectValue(refInputTo?.current), 0);
      } else {
        setValue([dayjsDate, dateToValue]);
        setDateFromInputValue(formattedDate);
      }
    } else if (event.target.value === '') {
      const datepickerFormattedDate = dateFromValue?.format(INPUT_FORMAT);
      setDateFromInputValue(datepickerFormattedDate || '');
    } else if (dateFromValue !== null) {
      const datepickerFormattedDate = dateFromValue.format(INPUT_FORMAT);
      setDateFromInputValue(datepickerFormattedDate);
      setTimeout(() => focusInputAndSelectValue(refInputFrom?.current), 0);
    }
  };

  const onDateToInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const maritimeDate = MaritimeDate.parse(event.target.value);
    if (maritimeDate.isValid) {
      const dateFrom = dateFromValue as Dayjs;
      const isoDate = maritimeDate.toISO();
      const dayjsDate = dayjs(isoDate).utc();
      const formattedDate = dayjsDate.format(INPUT_FORMAT);

      if (dayjsDate.isBefore(dateFrom)) {
        alignDateFromAndDateTo(dayjsDate, formattedDate);
        setTimeout(() => focusInputAndSelectValue(refInputTo?.current), 0);
      } else {
        setValue([dateFromValue, dayjsDate]);
        setDateToInputValue(formattedDate);
      }
    } else if (event.target.value === '') {
      const datepickerFormattedDate = dateToValue?.format(INPUT_FORMAT);
      setDateToInputValue(datepickerFormattedDate || '');
    } else if (dateToValue !== null) {
      const datepickerFormattedDate = dateToValue.format(INPUT_FORMAT);
      setDateToInputValue(datepickerFormattedDate);
      setTimeout(() => focusInputAndSelectValue(refInputTo?.current), 0);
    }
  };

  const onDateFromShortcutClick = (amountOfDays: number) => {
    const today = dayjs().utc();
    const newDateFrom = today.add(amountOfDays, 'day');
    const dateTo = dateToValue;
    const formattedDate = newDateFrom.format(INPUT_FORMAT);

    if (newDateFrom.isAfter(dateTo)) {
      alignDateFromAndDateTo(newDateFrom, formattedDate);
    } else {
      setValue([newDateFrom, dateTo]);
      setDateFromInputValue(formattedDate);
    }
    setTimeout(() => focusInputAndSelectValue(refInputTo?.current), 0);
  };

  const onDateToShortcutClick = (amountOfDays: number) => {
    const dateFrom = dateFromValue;
    const newDateTo = dateFrom!.add(amountOfDays, 'day');
    const formattedDate = newDateTo?.format(INPUT_FORMAT);
    setValue([dateFromValue, newDateTo]);
    setDateToInputValue(formattedDate ?? '');
    setTimeout(() => focusInputAndSelectValue(refInputTo?.current), 0);
  };

  const onDateInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, inputName: 'from' | 'to') => {
    if (event.key == KeyNames.ENTER) {
      if (inputName === 'from') {
        focusInputAndSelectValue(refInputTo?.current);
      } else if (inputName === 'to') {
        refInputTo?.current?.blur();
      }
    }
  };

  const saveDateRange = () => {
    if (dateFromValue && dateToValue) {
      updateDateRange({
        from: dateFromValue!.utc(),
        to: dateToValue!.utc(),
      });
      setItem<DASHBOARD_DATE_RANGE_TYPE>(LOCAL_STORAGE_KEYS.DASHBOARD_DATE_RANGE_TYPE, DASHBOARD_DATE_RANGE_TYPE.CUSTOM);
      onClose();
    }
  };

  return (
    <StyledContainer data-testid="plandatefilter-modal-custom-range-view-container">
      <StyledInputsContainer>
        <StyledField
          label="From"
          name="From"
          value={dateFromInputValue}
          inputRef={refInputFrom}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => setDateFromInputValue(event.target.value)}
          onBlur={onDateFromInputBlur}
          onFocus={(event) => onDateInputFocus(event, 'start')}
          onKeyDown={(event) => onDateInputKeyDown(event, 'from')}
          data-testid="plandatefilter-modal-custom-range-view-input-from-container"
          inputProps={{
            'data-testid': 'plandatefilter-modal-custom-range-view-input-from',
          }}
        />
        <StyledField
          label="To"
          value={dateToInputValue}
          inputRef={refInputTo}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => setDateToInputValue(event.target.value)}
          onBlur={onDateToInputBlur}
          onFocus={(event) => onDateInputFocus(event, 'end')}
          onKeyDown={(event) => onDateInputKeyDown(event, 'to')}
          data-testid="plandatefilter-modal-custom-range-view-input-to-container"
          inputProps={{
            'data-testid': 'plandatefilter-modal-custom-range-view-input-to',
          }}
        />
      </StyledInputsContainer>

      <ShortcutButtons
        dateFrom={dateFromValue!}
        dateTo={dateToValue!}
        onDateFromShortcutClick={onDateFromShortcutClick}
        onDateToShortcutClick={onDateToShortcutClick}
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledStaticDateRangePicker
          onRangePositionChange={onRangePositionChange}
          onChange={onChange}
          rangePosition={rangePosition}
          autoFocus={false}
          value={value}
          slotProps={{
            actionBar: { actions: [] },
          }}
          slots={{ toolbar: undefined }}
          calendars={2}
        />
      </LocalizationProvider>

      <ActionsFooter dateFrom={dateFromValue} dateTo={dateToValue} saveDateRange={saveDateRange} />
    </StyledContainer>
  );
};

const StyledField = styled(TextField)({
  '.Mui-focused:not(.Mui-error) fieldset': {
    borderColor: `${blue.b70}!important`,
  },
  '.Mui-focused fieldset': {
    borderWidth: '2px!important',
  },
  label: {
    color: neutral.n65,
    position: 'initial',
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 500,
    transform: 'initial',
    marginBottom: '4px',
  },
  input: {
    color: grey.g12,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  fieldset: {
    borderRadius: '4px',
    legend: {
      width: 0,
    },
  },
  width: '50%',
});

const StyledInputsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: '16px',
});

const StyledContainer = styled('div')({
  padding: '12px 16px 16px 16px',
});

const StyledStaticDateRangePicker = styled(StaticDateRangePicker<Dayjs>)({
  margin: '0 -16px',
  borderTop: `1px solid ${grey.g8}`,
  borderBottom: `1px solid ${grey.g8}`,
  '.MuiPickersArrowSwitcher-root': {
    '.MuiTypography-root': {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
    },
    '.MuiButtonBase-root': {
      padding: '4px',
      svg: {
        height: '24px',
        width: '24px',
      },
    },
  },
  '.MuiDateRangePickerDay-dayInsideRangeInterval': {
    backgroundColor: blue.b10,
    '.Mui-selected': {
      backgroundColor: blue.b70,
      fontWeight: '400!important',
    },
  },
  '.MuiDayCalendar-weekDayLabel': {
    color: neutral.n65,
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 500,
  },
  '.MuiDateRangePickerDay-day': {
    height: '100%',
    width: '100%',
    lineHeight: '100%',
  },
  '.MuiDateRangePickerDay-rangeIntervalDayHighlight:not(.MuiDateRangePickerDay-hiddenDayFiller)': {
    backgroundColor: blue.b10,
  },
  '.MuiDateRangePickerDay-rangeIntervalPreview': {
    height: '36px',
    width: '36px',
    display: 'flex',
  },
  '.MuiPickersSlideTransition-root': {
    minHeight: '241px',
  },
});
