import dayjs from 'dayjs';
import { DateRange, LocationDto, VesselDto } from '../../../api/web-api-client';
import { IRowNode } from 'ag-grid-community';

export const isEmptyCell = (value: unknown) => {
  return value == null || value === '';
};

export const dateRangeComparator = (
  valueA: DateRange | undefined,
  valueB: DateRange | undefined,
  rowNodeA: IRowNode,
  rowNodeB: IRowNode,
  isInverted: boolean
) => {
  return sortWithEmptyLast(valueA, valueB, rowNodeA, rowNodeB, isInverted, sortDateRanges);
};

export const vesselComparator = (
  valueA: VesselDto | undefined,
  valueB: VesselDto | undefined,
  rowNodeA: IRowNode,
  rowNodeB: IRowNode,
  isInverted: boolean
) => {
  return sortWithEmptyLast(valueA?.name, valueB?.name, rowNodeA, rowNodeB, isInverted);
};

export const locationComparator = (
  valueA: LocationDto | undefined,
  valueB: LocationDto | undefined,
  rowNodeA: IRowNode,
  rowNodeB: IRowNode,
  isInverted: boolean
) => {
  return sortWithEmptyLast(valueA?.name, valueB?.name, rowNodeA, rowNodeB, isInverted);
};

export const nullsLastComparator = (valueA: any, valueB: any, rowNodeA: IRowNode, rowNodeB: IRowNode, isInverted: boolean) => {
  return sortWithEmptyLast(valueA, valueB, rowNodeA, rowNodeB, isInverted);
};

export const sortWithEmptyLast = (
  valueA: any,
  valueB: any,
  rowNodeA: IRowNode,
  rowNodeB: IRowNode,
  isInverted: boolean,
  customComparator?: (valueA: any, valueB: any, rowNodeA: IRowNode, rowNodeB: IRowNode, isInverted: boolean) => number
) => {
  if (valueA === valueB) {
    return 0;
  } else if (isEmptyCell(valueA)) {
    return isInverted ? -1 : 1;
  } else if (isEmptyCell(valueB)) {
    return isInverted ? 1 : -1;
  } else {
    return customComparator
      ? customComparator(valueA, valueB, rowNodeA, rowNodeB, isInverted)
      : defaultComparator(valueA, valueB);
  }
};

const defaultComparator = (valueA: any, valueB: any) => {
  if (typeof valueA === 'string' && typeof valueB === 'string') {
    return valueA.localeCompare(valueB);
  } else {
    return valueA > valueB ? 1 : -1;
  }
};

export const sortDateRanges = (valueA: DateRange | undefined, valueB: DateRange | undefined) => {
  const startDateA = getTicks(valueA?.from);
  const startDateB = getTicks(valueB?.from);
  if (startDateA !== startDateB) {
    return startDateA - startDateB;
  } else {
    const endDateA = getTicks(valueA?.to);
    const endDateB = getTicks(valueB?.to);
    return endDateA - endDateB;
  }
};

const getTicks = (date: string | null | undefined) => {
  return date ? dayjs.utc(date).valueOf() : 0;
};
