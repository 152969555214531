import { ITooltipParams } from 'ag-grid-community';
import { styled } from '@mui/material';
import { grey } from '../../../themes/palette';

export interface IVesselCellTooltip extends ITooltipParams {}

const VesselCellTooltip: React.FC<IVesselCellTooltip> = (props) => {
  const { value } = props;
  const element = document.querySelector('.ag-cell:hover') as HTMLElement;
  const nameContainer = element.querySelector('[data-tooltip-container=name]') as HTMLElement;
  const detailsContainer = element.querySelector('[data-tooltip-container=details]') as HTMLElement;
  const isCellEditing = element?.classList.contains('ag-cell-popup-editing');
  const isContentInElementTruncated =
    nameContainer?.offsetWidth < nameContainer?.scrollWidth || detailsContainer?.offsetWidth < detailsContainer?.scrollWidth;
  const showTooltip = isContentInElementTruncated && !isCellEditing;
  const hasDetailsInfo = value?.deadweight || value?.age || value?.draught;

  return (
    <>
      {showTooltip && (
        <StyledContainer data-testid="tooltip">
          <div>{value?.name}</div>
          {value?.seaId ? (
            <StyledDetails>
              {hasDetailsInfo ? (
                <>
                  <div>{value?.deadweight?.toLocaleString('en-US') || '-'}</div>
                  <StyledSeparator />
                  <div>{value?.age || '-'}</div>
                  <StyledSeparator />
                  <div>{value?.draught || '-'}</div>
                </>
              ) : (
                '-'
              )}
            </StyledDetails>
          ) : null}
        </StyledContainer>
      )}
    </>
  );
};

VesselCellTooltip.displayName = 'CellTooltip';

export default VesselCellTooltip;

const StyledContainer = styled('div')({
  backgroundColor: 'white',
  padding: '12px 16px',
  borderRadius: 8,
  boxShadow: '0 4px 4px rgb(0 0 0 / 25%), 0 4px 4px rgb(0 0 0 / 25%), 0 3px 1px rgb(0 0 0 / 20%)',
  maxWidth: '450px',
  whiteSpace: 'pre-wrap',
});

const StyledDetails = styled('div')({
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
});

const StyledSeparator = styled('span')({
  display: 'inline-block',
  width: '4px',
  height: '4px',
  backgroundColor: grey.g8,
  borderRadius: '50%',
  margin: '0 8px',
});
