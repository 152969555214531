import { AssignmentDto, CargoDto } from '../../../../api/web-api-client';
import { getRowNodes } from '../../../../helpers/gridUtilities';
import { GridApi } from 'ag-grid-community';
import { Fragment } from 'react';
import { incoterms } from '../../../common/Constants';

export interface CargoesByIncotermId {
  incotermId: number;
  count: number;
  quantity: number;
}

export interface GroupedCargoesData {
  count: number;
  quantity: number;
  byIncotermId: CargoesByIncotermId[];
}

export const getCargoesData = (gridApi: GridApi): CargoDto[] =>
  getRowNodes<AssignmentDto>(gridApi).map((node) => node.data?.cargo) as CargoDto[];

export const groupCargoes = (cargoes: CargoDto[]) =>
  cargoes.reduce(
    (groupedCargoesData: GroupedCargoesData, cargo) => {
      groupedCargoesData.count = (groupedCargoesData.count || 0) + 1;
      groupedCargoesData.quantity = (groupedCargoesData.quantity || 0) + (cargo?.quantity || 0);
      if (cargo.incotermId) {
        groupedCargoesData.byIncotermId[cargo.incotermId] = {
          incotermId: cargo.incotermId,
          count: (groupedCargoesData.byIncotermId[cargo.incotermId]?.count || 0) + 1,
          quantity: (groupedCargoesData.byIncotermId[cargo.incotermId]?.quantity || 0) + (cargo?.quantity || 0),
        };
      }
      return groupedCargoesData;
    },
    {
      count: 0,
      quantity: 0,
      byIncotermId: [],
    }
  );

export const getIncotermById = (id: number) => {
  const foundIncoterm = incoterms.find((incoterm) => incoterm.id === id);
  if (!foundIncoterm) {
    throw Error(`Didn't find incoterm with id = ${id}`);
  }
  return foundIncoterm.name;
};

export const getCargoesText = (cargoesData: GroupedCargoesData): string =>
  `${cargoesData.count} cargo${cargoesData.count !== 1 ? 'es' : ''} (${cargoesData.quantity.toLocaleString('en-US')} MT)`;

export const getCargoesIncotermText = (cargoes: CargoesByIncotermId): string =>
  `${cargoes.count} ${getIncotermById(cargoes.incotermId as number)} (${cargoes.quantity.toLocaleString('en-US')} MT)`;

export const getTooltip = (cargoesData: GroupedCargoesData) => {
  const cargoesText = cargoesData.byIncotermId.map((cargoes) => {
    return (
      <Fragment key={cargoes.incotermId}>
        <br />
        {getCargoesIncotermText(cargoes)}
      </Fragment>
    );
  });
  return (
    <span data-testid="cargo-grid-footer-details-tooltip">
      {getCargoesText(cargoesData)}
      {cargoesText}
    </span>
  );
};
