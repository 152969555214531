import { ICellEditorParams } from 'ag-grid-community';
import { ChangeEvent, forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { blue } from '../../../themes/palette';
import { KeyNames } from '../Constants';
import { useAppDispatch } from '../../../store/helpers';
import { showNotificationSnackbar } from '../../../store/slices/notifications.slice';
import {
  defaultIntegerDigitsNumber,
  defaultDecimalDigitsNumber,
  isValidFloat,
  isValidForEditorChar,
  isValidInteger,
  parseFloatValue,
} from './helpers';

interface INumericCellEditor extends ICellEditorParams {
  isFloat?: boolean;
  maxIntegerDigits?: number;
  maxDecimalDigits?: number;
}

export const NumericCellEditor = memo(
  forwardRef((props: INumericCellEditor, ref) => {
    //using styled breaks input onChange event
    const inputStyle = { border: `1px solid ${blue.b50}`, borderRadius: 0, height: 'var(--ag-row-height)' };
    const {
      eventKey,
      isFloat = false,
      maxIntegerDigits = defaultIntegerDigitsNumber,
      maxDecimalDigits = defaultDecimalDigitsNumber,
    } = props;

    const createInitialState = () => {
      let startValue: string | number | undefined;
      let highlightAllOnFocus = true;
      if (eventKey === KeyNames.BACKSPACE || eventKey === KeyNames.DELETE) {
        startValue = undefined;
      } else if (isValidForEditorChar(eventKey)) {
        startValue = parseValue(eventKey!);
        highlightAllOnFocus = false;
      } else {
        const stringifiedValue = props.value?.toString();
        startValue = parseValue(stringifiedValue);
        if (eventKey === KeyNames.F2) {
          highlightAllOnFocus = false;
        }
      }

      return {
        value: startValue,
        highlightAllOnFocus,
      };
    };

    const initialState = createInitialState();

    const [value, setValue] = useState<number | undefined>(initialState.value);
    const [inputValueString, setInputValueString] = useState<string | undefined>(initialState.value?.toString());
    const refInput = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
      const currentRef = refInput.current!;
      currentRef.focus();
      if (initialState.highlightAllOnFocus) {
        currentRef.select();
      } else {
        const length = currentRef.value?.length ?? 0;
        if (length > 0) {
          currentRef.setSelectionRange(length, length);
        }
      }
    }, []);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
      };
    });

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === '') {
        setInputValueString(undefined);
        setValue(undefined);
        return;
      }

      const isValid = isFloat
        ? isValidFloat(event.target.value, maxIntegerDigits, maxDecimalDigits)
        : isValidInteger(event.target.value, maxIntegerDigits);

      if (!isValid) {
        return;
      }

      setInputValueString(event.target.value);

      const stringifiedValue = event.target.value.toString();
      const parsedValue = parseValue(stringifiedValue);

      if (parsedValue !== undefined) {
        setValue(parsedValue);
      }
    };

    function parseValue(valueToParse: string): number | undefined {
      if (isFloat) {
        const parsedValue = parseFloatValue(valueToParse, maxIntegerDigits, maxDecimalDigits);
        return isNaN(parsedValue) ? undefined : parsedValue;
      } else {
        const parsedValue = parseInt(valueToParse);
        return isNaN(parsedValue) ? undefined : parsedValue;
      }
    }

    function validate(event: React.KeyboardEvent<HTMLInputElement>) {
      if (event.key.length > 1 || event.ctrlKey) {
        return;
      }
      const regex = isFloat ? /[0-9.]/ : /[0-9]/;
      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    }

    function validatePaste(event: React.ClipboardEvent<HTMLInputElement>): void {
      const pastedValue = event.clipboardData?.getData('text') ?? '';
      const parsedValue = parseValue(pastedValue);
      if (parsedValue && Math.sign(parsedValue) < 0) {
        event.preventDefault();
        dispatch(showNotificationSnackbar({ title: 'Quantity cannot have a negative value', type: 'error' }));
      }
    }

    return (
      <input
        style={inputStyle}
        onKeyDown={validate}
        type="text"
        ref={refInput}
        value={inputValueString ?? ''}
        onChange={onChange}
        className="ag-input-field-input ag-text-field-input"
        onPaste={validatePaste}
      />
    );
  })
);
