import { GridId } from '../../helpers';
import { getGridHeaderTemplate } from './helpers';

export const useGridHeaders = (gridId: GridId) => {
  return {
    vesselHeaderComponentParams: {
      template: getGridHeaderTemplate(['DWT (MT)', 'Age (Y)', 'Draught (M)'], `${gridId}-vessel-header`),
    },
    freightRateHeaderComponentParams: {
      template: getGridHeaderTemplate(['($ per MT)'], `${gridId}-freight-rate-header`),
    },
    demurrageComponentParams: {
      template: getGridHeaderTemplate(['($ per day)'], `${gridId}-demurrage-header`),
    },
  };
};
