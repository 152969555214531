import { Paper, styled } from '@mui/material';
import { ReactComponent as ColourClearIcon } from '../../../assets/colourClear.svg';
import { Property } from 'csstype';
import { highlightColors, IHighlightColor } from '../../common/grid/hooks/useCellFormatting/useCellFormatting';

interface IStyledColorBoxWrapper {
  justifyContent?: Property.JustifyContent;
}

interface IStyledColorBox {
  bgColor: string;
}

interface IStyledRow {
  gridTemplateColumns?: Property.GridTemplateColumns;
}

interface IColorMenu {
  onClickHandler: (color: IHighlightColor | null) => void;
}

const StyledColorMenu = styled(Paper)({
  position: 'absolute',
  width: '176px',
  height: '96px',
  marginTop: '4px',
  zIndex: 10,
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2)',
  transform: 'translateX(-20%)',
});

const StyledColorBoxWrapper = styled('div')<IStyledColorBoxWrapper>(({ justifyContent = 'center' }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: justifyContent,
  padding: '8px',
  cursor: 'pointer',
  ':hover': {
    transform: 'scale(1.2)',
  },
}));

const StyledColorBox = styled('div')<IStyledColorBox>(({ bgColor }) => ({
  height: '24px',
  width: '24px',
  borderRadius: '4px',
  backgroundColor: bgColor,
}));

const StyledRow = styled('div')<IStyledRow>(({ gridTemplateColumns }) => ({
  height: '40px',
  display: 'grid',
  justifyContent: 'center',
  gridTemplateColumns: gridTemplateColumns ?? 'repeat(4, 1fr) ',
}));

const secondRowFlexJustify: Property.JustifyContent[] = ['flex-end', 'center', 'flex-start'];
const firstRowColors = highlightColors.slice(0, 3);
const secondRowColors = highlightColors.slice(3, 6);

export const ColorMenu: React.FC<IColorMenu> = ({ onClickHandler }) => {
  return (
    <StyledColorMenu data-testid="color-picker-color-menu">
      <StyledRow>
        <StyledColorBoxWrapper role="button" onClick={() => onClickHandler(null)}>
          <ColourClearIcon data-testid="color-picker-clear-color" />
        </StyledColorBoxWrapper>
        {firstRowColors.map((color) => (
          <StyledColorBoxWrapper
            key={`color-picker-${color.className}`}
            data-testid={`color-picker-${color.className}`}
            onClick={() => onClickHandler(color)}
            role="button"
          >
            <StyledColorBox bgColor={color.menu}></StyledColorBox>
          </StyledColorBoxWrapper>
        ))}
      </StyledRow>
      <StyledRow gridTemplateColumns="1fr 40px 1fr">
        {secondRowColors.map((color, index) => (
          <StyledColorBoxWrapper
            key={`color-picker-${color.className}`}
            data-testid={`color-picker-${color.className}`}
            onClick={() => onClickHandler(color)}
            justifyContent={secondRowFlexJustify[index]}
            role="button"
          >
            <StyledColorBox bgColor={color.menu}></StyledColorBox>
          </StyledColorBoxWrapper>
        ))}
      </StyledRow>
    </StyledColorMenu>
  );
};
