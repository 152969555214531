import { useCallback, useMemo } from 'react';
import { AssignmentsGridColumn } from './useAssignmentsGrid';
import { incoterms } from '../../../../common/Constants';
import {
  formatDate,
  formatDateRange,
  getDownloadFileDateText,
  getTimeZoneAbbreviation,
} from '../../../../../helpers/dateHelpers';
import { AgGridReact } from 'ag-grid-react';
import { ExcelStyle } from 'ag-grid-community';
import { ProcessCellForExportParams } from 'ag-grid-community/dist/lib/interfaces/exportParams';
import { cellClassStyles } from '../../../../../themes/ThemeBase/styleOverrides/MuiBaseline';
import { useAppSelector } from '../../../../../store/helpers';
import { isDateCell, isLaycanCell, isLocationCell, isVesselCell } from './helpers';
import dayjs from 'dayjs';

export const useExportToExcel = (gridRef: React.MutableRefObject<AgGridReact | null>) => {
  const EXPORT_TO_EXCEL_ASSIGNMENTS_EXCLUDED_COLUMNS = [AssignmentsGridColumn.BatchActionsSelect, AssignmentsGridColumn.Options];
  const currentPlan = useAppSelector((state) => state.plans.currentPlan);
  const planDateRange = useAppSelector((state) => state.dateRange.dateRange);

  const getColumnsToExport = (): AssignmentsGridColumn[] | undefined => {
    const displayedColumns = gridRef.current?.columnApi.getAllDisplayedColumns();
    const columnsToExport = displayedColumns
      ?.filter((column) => !EXPORT_TO_EXCEL_ASSIGNMENTS_EXCLUDED_COLUMNS.includes(column.getColId() as AssignmentsGridColumn))
      .map((column) => column.getColId() as AssignmentsGridColumn);
    return columnsToExport;
  };

  const getFormattedCellValue = (columnName: AssignmentsGridColumn, value: any): string => {
    if (columnName === AssignmentsGridColumn.CargoIncoterm) {
      const foundIncoterm = incoterms.find((incoterm) => incoterm.id === value);
      return foundIncoterm?.name ?? '';
    }
    if (isLaycanCell(columnName) && !!value && value.from && value.to) {
      return formatDateRange(value, { showYear: true });
    }
    if (isDateCell(columnName) && !!value) {
      return formatDate(value) ?? '';
    }
    if (isVesselCell(columnName) && !!value) {
      return value.name;
    }
    if (isLocationCell(columnName) && !!value) {
      return value.name;
    }
    return value;
  };

  const processCellCallback = (params: ProcessCellForExportParams): string => {
    const columnName = params.column.getColId() as AssignmentsGridColumn;
    const value = params.value;
    return getFormattedCellValue(columnName, value);
  };

  const getFileName = (): string => {
    const planTitle = currentPlan?.title?.replace(/\./g, '_');
    const planDateRangeText = planDateRange.from.format('MMMM YYYY');
    const downloadDate = dayjs();
    const timeZoneAbbreviation = getTimeZoneAbbreviation(downloadDate);
    const downloadDateText = getDownloadFileDateText(downloadDate);

    return `${planTitle} - ${planDateRangeText} [${downloadDateText}-${timeZoneAbbreviation}]`;
  };

  const exportDataToExcel = useCallback(() => {
    const columnsToExport = getColumnsToExport();
    const fileName = getFileName();
    gridRef.current?.api.exportDataAsExcel({
      processCellCallback,
      columnKeys: columnsToExport,
      fileName: fileName,
      sheetName: 'Sheet1',
      author: 'Freight Planner',
    });
  }, [currentPlan, planDateRange]);

  const getExcelStyles = () => {
    const newExcelStyles: ExcelStyle[] = [];
    for (const [key, value] of Object.entries(cellClassStyles)) {
      newExcelStyles.push({
        id: key,
        interior: {
          color: value.backgroundColor,
          pattern: 'Solid',
        },
      });
    }
    return newExcelStyles;
  };

  const excelStyles = useMemo<ExcelStyle[]>(() => {
    return getExcelStyles();
  }, []);

  return { exportDataToExcel, excelStyles };
};
