import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { styled } from '@mui/material';
import { grey } from '../../../themes/palette';

interface IVesselCellRenderer extends ICellRendererParams {
  dataTestIdPrefix: string;
}

export const VesselCellRenderer: React.FC<IVesselCellRenderer> = (props) => {
  const { value, data, dataTestIdPrefix } = props;
  const isLinked = !!value?.seaId;
  const hasDetailsInfo = value?.deadweight || value?.age || value?.draught;

  return (
    <StyledContainer data-testid={`${dataTestIdPrefix}-row-${data.id}-vessel-cell`} islinked={isLinked}>
      <StyledName
        data-testid={`${dataTestIdPrefix}-row-${data.id}-vessel-cell-name`}
        islinked={isLinked}
        data-tooltip-container="name"
      >
        {value?.name}
      </StyledName>
      {isLinked && (
        <StyledDetails data-testid={`${dataTestIdPrefix}-row-${data.id}-vessel-cell-details`} data-tooltip-container="details">
          {hasDetailsInfo ? (
            <>
              <StyledDwt data-testid={`${dataTestIdPrefix}-row-${data.id}-vessel-cell-dwt`}>
                {value?.deadweight?.toLocaleString('en-US') || '-'}
              </StyledDwt>
              <StyledSeparator />
              <StyledAge data-testid={`${dataTestIdPrefix}-row-${data.id}-vessel-cell-age`}>{value?.age || '-'}</StyledAge>
              <StyledSeparator />
              <StyledDraught data-testid={`${dataTestIdPrefix}-row-${data.id}-vessel-cell-draught`}>
                {value?.draught || '-'}
              </StyledDraught>
            </>
          ) : (
            '-'
          )}
        </StyledDetails>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled('div')((props: { islinked: boolean }) => ({
  height: props.islinked ? '48px' : 'initial',
  display: 'flex',
  justifyContent: 'center',
  gap: '4px',
  flexDirection: 'column',
  lineHeight: props.islinked ? 'initial' : 'inherit',
}));

const StyledName = styled('div')((props: { islinked: boolean }) => ({
  fontWeight: props.islinked ? 600 : 500,
  lineHeight: props.islinked ? '14px' : 'inherit',
  height: props.islinked ? '15px' : 'inherit',
  color: grey.g12,
  display: 'inline-block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const StyledDetails = styled('div')({
  height: '12px',
  fontSize: '12px',
  lineHeight: '12px',
  color: grey.g15,
  display: 'inline-block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const StyledDwt = styled('span')({
  display: 'inline-block',
  width: '45px',
  verticalAlign: 'middle',
});

const StyledAge = styled('span')({
  display: 'inline-block',
  width: '15px',
  textAlign: 'center',
  verticalAlign: 'middle',
});

const StyledDraught = styled('span')({
  display: 'inline-block',
  width: '27px',
  textAlign: 'center',
  verticalAlign: 'middle',
});

const StyledSeparator = styled('span')({
  display: 'inline-block',
  width: '4px',
  height: '4px',
  backgroundColor: grey.g8,
  borderRadius: '50%',
  margin: '0 8px',
  verticalAlign: 'middle',
});
