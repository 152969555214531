import { AgGridReact } from 'ag-grid-react';
import { ICustomRowData } from '../common/grid/hooks/useDefaultGridOptions';
import { IRowNode } from 'ag-grid-community';
import { AssignmentDto } from '../../api/web-api-client';

export const updateCellAndRefresh = <T>(
  gridRef: React.MutableRefObject<AgGridReact<ICustomRowData<T>> | null>,
  node: IRowNode<ICustomRowData<T>>,
  column: string | string[]
) => {
  if (!node.data) {
    return;
  }

  gridRef.current?.api.applyTransaction({ update: [node.data] });
  gridRef.current?.api.refreshCells({
    rowNodes: [node],
    columns: typeof column === 'string' ? [column] : [...column],
    force: true,
  });
};

export const unmarkCellAsUnsaved = <T>(
  gridRef: React.MutableRefObject<AgGridReact<ICustomRowData<T>> | null>,
  node: IRowNode<ICustomRowData<T>>,
  column: string
) => {
  if (node.data?.unsavedColumnsNames) {
    node.data.unsavedColumnsNames.delete(column);
  }

  updateCellAndRefresh(gridRef, node, column);
};

export const markCellAsUnsaved = <T>(
  gridRef: React.MutableRefObject<AgGridReact<ICustomRowData<T>> | null>,
  node: IRowNode<ICustomRowData<T>>,
  column: string
) => {
  if (!node.data) {
    return;
  }

  node.data?.unsavedColumnsNames
    ? node.data?.unsavedColumnsNames.add(column)
    : (node.data.unsavedColumnsNames = new Set([column]));

  updateCellAndRefresh(gridRef, node, column);
};

export const getUnsavedTonnageColumnsInRow = (cargoAssignmentRowNode: IRowNode<ICustomRowData<AssignmentDto>>): Set<string> => {
  const unsavedTonnageColumnsInRow = new Set<string>();
  cargoAssignmentRowNode.data?.unsavedColumnsNames?.forEach((column) => {
    if (column.startsWith('tonnage.')) {
      unsavedTonnageColumnsInRow.add(column.split('.')[1]);
    }
  });

  return unsavedTonnageColumnsInRow;
};
