import { PaletteOptions } from '@mui/material';
import { blue, green, grey, neutral, red, yellow } from '../../palette';

export const lightThemePalette: PaletteOptions = {
  mode: 'light',
  background: {
    default: neutral.n99,
    paper: neutral.n100,
  },
  text: {
    primary: neutral.n10,
    secondary: neutral.n10,
    disabled: neutral.n60,
  },
  primary: {
    main: blue.b70,
  },
  secondary: {
    main: grey.g5,
  },
  error: {
    main: red.r50,
  },
  warning: {
    main: yellow.y50,
  },
  success: {
    main: green.g50,
  },
  info: {
    main: blue.b50,
  },
};

export const darkThemePalette: PaletteOptions = {
  mode: 'dark',
};
