import { useEffect } from 'react';
import { SilentRefreshTokenUserManager } from '../contexts/UserManager';
import { REQUEST_STATUS, useAppSelector } from '../store/helpers';

export const SilentRefreshPage = () => {
  const appSettings = useAppSelector((state) => state.api.appSettings);
  const status = useAppSelector((state) => state.api.status);
  const getAppSettingsStatus = status.getAppSettings;

  useEffect(() => {
    if (getAppSettingsStatus === REQUEST_STATUS.fulfilled) {
      const silentRefreshTokenUserManager = new SilentRefreshTokenUserManager(appSettings!);
      silentRefreshTokenUserManager.oidcUserManager.signinSilentCallback();
    }
  }, [getAppSettingsStatus]);

  return null;
};
