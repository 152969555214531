import DirectSeanetLinkIcon from '@mui/icons-material/Public';
import NoDirectSeanetLinkIcon from '@mui/icons-material/PublicOff';
import { Button, IconButton, Tooltip, Typography, styled } from '@mui/material';
import { VesselDto } from '../../../../api/web-api-client';
import {
  MixpanelEventAction,
  MixpanelEventCategory,
  MixpanelEventLabel,
  MixpanelEventLocation,
  MixpanelEventName,
  useMixpanel,
} from '../../../../contexts/MixpanelContext';
import { grey, neutral } from '../../../../themes/palette';
import { useAppSelector } from '../../../../store/helpers';

interface ISeaNetLinkIcon {
  vessel?: VesselDto;
  tonnageId?: number;
  planId?: number;
}

export const SeaNetLinkIcon: React.FC<ISeaNetLinkIcon> = (props) => {
  const { vessel, tonnageId, planId } = props;
  const { trackEvent } = useMixpanel();
  const appSettings = useAppSelector((state) => state.api.appSettings);

  const onSeaNetClick = () => {
    trackEvent(MixpanelEventCategory.INTERACTION, {
      event: MixpanelEventName.OPEN_SEA_NET_LINK,
      eventAction: MixpanelEventAction.CLICK,
      eventLabel: MixpanelEventLabel.ICON_BUTTON,
      eventLocation: MixpanelEventLocation.TONNAGE_GRID,
      eventVersion: 1.1,
      planId,
      tonnageId,
      vesselSeaId: vessel?.seaId,
    });
  };

  const onOpenSeaNetClick = () => {
    window.open(appSettings?.seaNetBaseUrl, '_blank');
  };

  if (!vessel?.seaId) {
    return null;
  }

  return vessel?.imo ? (
    <Tooltip title="View vessel in Sea/net">
      <a
        href={`${appSettings?.seaNetBaseUrl}/vessel/${vessel.imo}`}
        target="_blank"
        rel="noreferrer"
        onClick={onSeaNetClick}
        data-testid="button-tonnage-seaNet-link"
      >
        <IconButton size="small">
          <DirectSeanetLinkIcon sx={{ color: neutral.n65 }} />
        </IconButton>
      </a>
    </Tooltip>
  ) : (
    <Tooltip
      title={
        <StyledTooltipContent>
          <StyledTypography>Direct link not available</StyledTypography>
          <a
            href={`${appSettings?.seaNetBaseUrl}`}
            target="_blank"
            rel="noreferrer"
            onClick={onOpenSeaNetClick}
            data-testid="button-tonnage-open-seaNet"
          >
            <StyledButton>Open Sea/net</StyledButton>
          </a>
        </StyledTooltipContent>
      }
    >
      <StyledIconWrapper>
        <NoDirectSeanetLinkIcon data-testid="icon-tonnage-no-direct-link" sx={{ color: neutral.n40, fontSize: '26px' }} />
      </StyledIconWrapper>
    </Tooltip>
  );
};

const StyledIconWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '5px',
});

const StyledTooltipContent = styled('div')({
  width: '127px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '6px',
  padding: 0,
  marginBottom: '4px',
});

const StyledTypography = styled(Typography)({
  fontSize: '10px',
  lineHeight: '14px',
  fontWeight: 500,
  letterSpacing: '0.6px',
});

const StyledButton = styled(Button)({
  minWidth: '86px',
  width: '86px',
  height: '22px',
  backgroundColor: grey.g13,
  padding: '4px 10px',
  borderRadius: '4px',
  fontWeight: 500,
  fontSize: '10px',
  '&:hover': {
    backgroundColor: grey.g1,
  },
});
