import React, { useEffect, useState } from 'react';
import { FeatureFlagsProvider } from '../contexts/FeatureFlagsContext';
import { AuthProvider } from '../contexts';
import { MixpanelProvider } from '../contexts/MixpanelContext';
import { GridRefsProvider } from '../contexts/GridRefsContext';
import { Layout } from '../components';
import { Notifications } from '../components/common/Notifications/Notifications';
import { AppInsightsProvider } from '../contexts/AppInsightsContext';
import { REQUEST_STATUS, useAppDispatch, useAppSelector } from '../store/helpers';
import { setApiManager } from '../store';
import { FeatureFlags, loadFeatureFlags } from '../api/featureFlags';
import { LicenseManager } from 'ag-grid-enterprise';
import { SeaHeader } from '../header/SeaHeader';
import { Outlet } from 'react-router';
import { LicenseInfo } from '@mui/x-date-pickers-pro';

export const AppPage = () => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags | null>(null);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const apiManager = useAppSelector((state) => state.api.apiManager);
  const appSettings = useAppSelector((state) => state.api.appSettings);
  const status = useAppSelector((state) => state.api.status);
  const getAppSettingsStatus = status.getAppSettings;

  useEffect(() => {
    if (getAppSettingsStatus === REQUEST_STATUS.fulfilled) {
      const setup = async () => {
        dispatch(setApiManager(appSettings!));
        const featureFlagsResult = await loadFeatureFlags();
        setFeatureFlags(featureFlagsResult);
        LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY as string);
        LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE_KEY as string);
        setIsInitialized(true);
      };

      setup();
    }
  }, [getAppSettingsStatus]);

  useEffect(() => {
    if (isInitialized) {
      const seaHeader = new SeaHeader(appSettings!, apiManager!.oidcUserManager);
    }
  }, [isInitialized]);

  return (
    <>
      {isInitialized ? (
        <AppInsightsProvider connectionString={appSettings!.appInsightsConnectionString}>
          <FeatureFlagsProvider featureFlags={featureFlags}>
            <AuthProvider userManager={apiManager!.oidcUserManager!}>
              <MixpanelProvider isTrackingEnabled={appSettings!.mixpanelTrackingEnabled}>
                <GridRefsProvider>
                  <Layout>
                    <Notifications />
                    <Outlet />
                  </Layout>
                </GridRefsProvider>
              </MixpanelProvider>
            </AuthProvider>
          </FeatureFlagsProvider>
        </AppInsightsProvider>
      ) : (
        <span>Initializing...</span>
      )}
    </>
  );
};
